import React from 'react';
import './SingleFileUpload.css';

class SingleFileUpload extends React.Component{

  constructor(props) {
        super(props);
        this.state = {
            fileName: "",
            fileType: "texture",
            object: "",
            material: "",
            thumbnail: "",
            showObject: true,
            showMaterial: true,
            showThumbnail: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

  componentDidMount(){
    this.setState({fileName: this.props.fileName});
  }

  componentDidUpdate(){
    if(this.state.fileType != "object" && this.props.object != "" && this.props.object != this.state.object){
      this.setState({showObject: false, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }else if(this.state.fileType != "object" && this.props.object == "" && this.props.object != this.state.object){
      this.setState({showObject: true, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }else if(this.props.object != this.state.object){
      this.setState({showObject: true, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }
    if(this.state.fileType != "material" && this.props.material != "" && this.props.material != this.state.material){
      this.setState({showMaterial: false, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }else if(this.state.fileType != "material" && this.props.material == "" && this.props.material != this.state.material){
      this.setState({showMaterial: true, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }else if(this.props.material != this.state.material){
      this.setState({showMaterial: true, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }
    if(this.state.fileType != "thumbnail" && this.props.thumbnail != "" && this.props.thumbnail != this.state.thumbnail){
      this.setState({showThumbnail: false, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }else if(this.state.fileType != "thumbnail" && this.props.thumbnail == "" && this.props.thumbnail != this.state.thumbnail){
      this.setState({showThumbnail: true, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }else if(this.props.thumbnail != this.state.thumbnail){
      this.setState({showThumbnail: true, object: this.props.object, material: this.props.material, thumbnail: this.props.thumbnail});
    }
  }

  removeFile(){
    this.props.removeFile(this.state.fileName, this.state.fileType);
  }

  handleChange(e){
    this.setState({[e.target.name]: e.target.value});
    this.props.updateMapping(this.state.fileName, e.target.value);
  }

  render(){
    return (
      <div className="singleFileWrapper">
        <div className="fileName">
            {this.state.fileName}
        </div>
        <div className="fileDelete" id={this.state.fileName} onClick={this.removeFile}>
            <img src="/images/close.png" id={this.state.fileName} onClick={this.removeFile} alt="Delete File" />
        </div>
        <div className="fileTypeWrapper">
          <select className="projectSelect" name="fileType" id="fileType" value={this.state.fileType} onChange={this.handleChange}>
            {this.state.showObject &&
              <option value="object">Object File</option>
            }
            {this.state.showMaterial &&
              <option value="material">Material File</option>
            }
            {this.state.showThumbnail &&
              <option value="thumbnail">Thumbnail</option>
            }
            <option value="texture">Texture File</option>
          </select>
        </div>
      </div>
    );
  }
}

export default SingleFileUpload;