import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
/*import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConfigContext as Provider } from "./components/configcontext/ConfigContext";
import { Redirect } from 'react-router';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/Home';
import Projects from './components/projects/Projects';
import ProjectView from './components/projectview/ProjectView';
import ProjectCreate from './components/projectcreate/ProjectCreate';
import Header from './components/header/Header';
import Leftrail from './components/leftrail/Leftrail';
import Login from './components/login/Login';
import Authorize from './components/authorize/Authorize';
import Requests from './components/requests/Requests';
import RequestView from './components/requestview/RequestView';
import ProfileSettings from './components/profilesettings/ProfileSettings';
import AccountSettings from './components/accountsettings/AccountSettings';
import AccountMapping from './components/accountmapping/AccountMapping';
import ManageUsers from './components/manageusers/ManageUsers';
import CreateUser from './components/createuser/CreateUser';
import EditSessions from './components/editsessions/EditSessions';
import EditSessionView from './components/editsessionview/EditSessionView';
import UpdateSessions from './components/updatesessions/UpdateSessions';
import UpdateSessionView from './components/updatesessionview/UpdateSessionView';
import HowItWorks from './components/howitworks/HowItWorks';
import AllProjectsMap from './components/allprojectsmap/AllProjectsMap';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import News from './components/news/News';
import NewsArticle from './components/newsarticle/NewsArticle';
import Privacy from './components/privacy/Privacy';
import CreateAccount from './components/createaccount/CreateAccount';
import Partners from './components/partners/Partners';
import Testimonials from './components/testimonials/Testimonials';
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import ResetPassword from './components/resetpassword/ResetPassword';
import AdminLogin from './components/adminlogin/AdminLogin';
import AdminConsole from './components/adminconsole/AdminConsole';
import Knowledgebase from './components/knowledgebase/Knowledgebase';
import Support from './components/support/Support';*/

/*const routing = (
  <div className="wrapper">
    <Provider>
      <Router>
        <div>
            <Switch> 
                <Route exact path="/" component={Home} />
                <Route exact path='/howitworks' component={HowItWorks}/>
                <Route exact path='/about' component={About}/>
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/news' component={News} />
                <Route path ='/news/article/:id' component={NewsArticle} />
                <Route exact path='/privacy' component={Privacy} />
                <Route path="/login/:accountName?" component={Login} />
                <Route path="/authorize" component={Authorize} />
        		<Route exact path="/projects" component={Projects} />
                <Route exact path="/project/create" component={ProjectCreate} />
                <Route exact path="/project/view/:id" component={ProjectView} />
                <Route path="/requests" component={Requests} />
                <Route exact path="/request/view/:id" component={RequestView} />
                <Route exact path="/profile/settings" component={ProfileSettings} />
                <Route path="/account/settings" component={AccountSettings} />
                <Route path="/account/create" component={CreateAccount} />
                <Route exact path="/account/mapping" component={AccountMapping} />
                <Route path="/users/manage" component={ManageUsers} />
                <Route path="/users/create" component={CreateUser} />
                <Route exact path="/session/edits" component={EditSessions} />
                <Route exact path="/community/map" component={AllProjectsMap} />
                <Route exact path="/session/edits/:id" component={EditSessionView} />
                <Route exact path="/session/updates" component={UpdateSessions} />
                <Route exact path="/session/updates/:id" component={UpdateSessionView} />
                <Route path="/partners" component={Partners} />
                <Route path="/testimonials" component={Testimonials} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route exact path="/admin/login" component={AdminLogin} />
                <Route exact path="/admin/console" component={AdminConsole} />
                <Route path="/knowledgebase" component={Knowledgebase} />
                <Route path="/support" component={Support} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
            <Header /> 
            <Leftrail /> 
          </div>
      </Router>
    </Provider>
  </div>
)*/

ReactDOM.render(<App/>, document.getElementById('root'));