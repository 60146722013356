import React from 'react';
import './EditSession.css';

class EditSession extends React.Component{

  render(){
    return (
      <div className="editSessionWrapper">
        <div className="editSessionId">
          {this.props.sessionId}
        </div>
        <div className="editSessionName">
          {this.props.sessionName != "" ?
              (this.props.sessionName)
            :
              <div>
              &nbsp;
              </div>
          }
        </div>
        <div className="editSessionCreator">
          {this.props.sessionCreator != "" ?
              (this.props.sessionCreator)
            :
              <div>
              &nbsp;
              </div>
          }
        </div>
        <div className="editSessionDate">
          {this.props.sessionDate}
        </div>
      </div>
    );
  }
}

export default EditSession;