import React from 'react';
import './UpdateSessionFilter.css';

class UpdateSessionFilter extends React.Component{

  render(){
    return (
    	<div className="projectsFilterWrapper">
      		<div className="editSessionId">
          Session ID
          </div>
          <div className="editSessionDate">
          Session Created
          </div>
      </div>
    );
  }
}

export default UpdateSessionFilter;