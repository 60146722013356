import React from 'react';
import './RequestsFilter.css';

class RequestsFilter extends React.Component{

  render(){
    return (
    	<div className="requestsFilterWrapper">
      		<div className="requestsFilterStatus">
          Status <img src={'/images/sorticon.png'} alt="statusFilter"/>
          </div>
          <div className="requestsFilterId">
          Ticket #
          </div>
          <div className="requestsFilterName">
          Project Name <img src={'/images/sorticon.png'} alt="projectNameFilter"/>
          </div>
          <div className="requestsFilterLocation">
          Location <img src={'/images/sorticon.png'} alt="locationFilter"/>
          </div>
          <div className="requestsFilterUtilityType">
          Utility Type <img src={'/images/sorticon.png'} alt="utilityTypeFilter"/>
          </div>
          <div className="requestsFilterStart">
          Start Date <img src={ '/images/sorticon.png'} alt="startDateFilter"/>
          </div>
          <div className="requestsFilterEnd">
          End Date <img src={'/images/sorticon.png'} alt="endDateFilter"/>
          </div>
          <div className="requestsFilterResponses">
          Response
          </div>
      </div>
    );
  }
}

export default RequestsFilter;