import React from 'react';
import axios from 'axios';
import { loadModules } from 'esri-loader';
import {Context} from "../configcontext/ConfigContext";
import './EsriKMLMap.css';

class EsriKMLMap extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			colors: []
		}
		this.mapRef = React.createRef();
		this.hexToRgb = this.hexToRgb.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	  	axios.post('https://www.arutility.com/api/v5/accounts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	      	if(res.data[0].colors != "" && JSON.parse(res.data[0].colors).length > 0){
	      		var colorsToUpdate = JSON.parse(res.data[0].colors);
	      	}else{
	      		var colorsToUpdate = [];
	      	}
			this.setState({colors: colorsToUpdate});
	    }).catch(error => {
		    //Add some error handling here.
		});
	    loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer', 'esri/widgets/Measurement'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer, Measurement]) => {
		    this.map = new ArcGISMap({
		        basemap: 'streets'
		    });

		    this.view = new MapView({
		        container: this.mapRef.current,
		        map: this.map,
		        center: [-98.579532, 39.828577],
		        zoom: 5
		    });

		    this.toggle = new BasemapToggle({
				view: this.view,  // view with map that uses "streets" basemap
				nextBasemap: "satellite"  // Allows for toggling to "hybrid" basemap
		    });

		    this.view.ui.add(this.toggle, "top-right");
		    this.layer = new GraphicsLayer({
			  	graphics: []
			});
			this.map.add(this.layer);
	    })
	}

	hexToRgb(hex) {
	  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	  return result ? {
	    r: parseInt(result[1], 16),
	    g: parseInt(result[2], 16),
	    b: parseInt(result[3], 16)
	  } : null;
	}

  	componentDidUpdate() {
	    loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer', 'esri/widgets/Measurement'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer, Measurement]) => {
		  	this.layer.removeAll();
		  	this.graphics = [];
	      	{this.props.features.map(feature => {
	      		if(feature.feature_type == "line"){
		      		if(feature.paths){
			            var featurePaths = feature.paths;
			            var numPaths = featurePaths.length;
			            var polylineCoords = [];
			            for(var i=0; i<numPaths; i++){
			                var polylineCoord = [];
			                polylineCoord.push(featurePaths[i].longitude);
			                polylineCoord.push(featurePaths[i].latitude);
			                polylineCoords.push(polylineCoord);
			            }

			            this.polyline = {
							type: "polyline", // autocasts as new Polyline()
							paths: polylineCoords
						};

						this.lineSymbol = {
						    type: "simple-line", // autocasts as new SimpleLineSymbol()
						    width: 2
						};
						var numFeatureServices = this.props.mappingOriginal.length;
						for(var i=0; i < numFeatureServices; i++){
							if(parseInt(this.props.mappingOriginal[i]["feature_service_type_id"]) == feature.feature_service_type_id){
								var color = this.props.mappingOriginal[i]["color"];
								//var colorArray = [];
								//colorArray.push(this.hexToRgb(color).r);
								//colorArray.push(this.hexToRgb(color).g);
								//colorArray.push(this.hexToRgb(color).b);
								//colorArray.push(1);
								for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == feature.feature_service_type_id){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				                break;
							}
						}

			            /*if(feature.feature_service_type == "water"){
			            	var color = [0,0,255,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 1){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "electric"){
			            	var color = [255,0,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 2){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "gas"){
			            	var color = [255,255,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 3){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "storm"){
			            	var color = [0,255,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 4){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "fiber"){
			            	var color = [128,0,128,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 5){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "telecom"){
			            	var color = [255,165,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 6){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "sanitary"){
			            	var color = [165,42,42,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 7){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			            	this.lineSymbol.color = color;
			            }else if(feature.feature_service_type == "steam"){
			            	var color = [0,0,255,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 9){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			            	this.lineSymbol.color = color;
			            }*/

					  	this.polylineGraphic = new Graphic({
					    	geometry: this.polyline, // Add the geometry created in step 4
					    	symbol: this.lineSymbol, // Add the symbol created in step 5
					    	//attributes: this.lineAtt, // Add the attributes created in step 6
					    	popupTemplate: {
						      title: "<b>" + feature.feature_layer_type + "</b>",
						      //content: attributeText
						    }
					  	});
					  	this.graphics.push(this.polylineGraphic);
					  	this.layer.graphics.add(this.polylineGraphic);
					}
		        }else if(feature.feature_type == "point"){
		        	if(feature.points){
			            var featurePoints = feature.points;
			            var circleCoords = [];
			            var circleCoord = [];
			            var radius;
			            circleCoord.push(featurePoints.longitude);
			            circleCoord.push(featurePoints.latitude);
			            circleCoords.push(circleCoord);
			            this.point = {
							type: "point", // autocasts as new Polyline()
							longitude: featurePoints.longitude,
							latitude: featurePoints.latitude
						};
						this.pointSymbol = {
						    type: "simple-marker", // autocasts as new SimpleLineSymbol()
						    radius: 0.75
						};
						var numFeatureServices = this.props.mappingOriginal.length;
						for(var i=0; i < numFeatureServices; i++){
							if(parseInt(this.props.mappingOriginal[i]["feature_service_type_id"]) == feature.feature_service_type_id){
								var color = this.props.mappingOriginal[i]["color"];
								//var colorArray = [];
								//colorArray.push(this.hexToRgb(color).r);
								//colorArray.push(this.hexToRgb(color).g);
								//colorArray.push(this.hexToRgb(color).b);
								//colorArray.push(1);
								for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == feature.feature_service_type_id){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				                break;
							}
						}
			            /*if(feature.feature_service_type == "water"){
			            	var color = [0,0,255,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 1){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "electric"){
			            	var color = [255,0,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 2){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "gas"){
			            	var color = [255,255,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 3){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "storm"){
			            	var color = [0,255,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 4){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "fiber"){
			            	var color = [128,0,128,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 5){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "telecom"){
			            	var color = [255,165,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 6){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			                this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "sanitary"){
			            	var color = [165,42,42,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 7){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			            	this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "steam"){
			            	var color = [0,0,255,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 9){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			            	this.pointSymbol.color = color;
			            }else if(feature.feature_service_type == "controlpoint"){
			            	var color = [0,0,0,1];
			            	for(var i4=0; i4 < this.state.colors.length; i4++){
			            		if(this.state.colors[i4].id == 10){
			            			color = this.state.colors[i4].color;
			            			break;
			            		}
			            	}
			            	this.pointSymbol.color = color;
			            }*/
			            //this.pointAtt = attributeText;
			            this.pointGraphic = new Graphic({
						  	geometry: this.point,
						  	symbol: this.pointSymbol,
						  	//attributes: this.pointAtt, // Add the attributes created in step 6
					    	popupTemplate: {
						      title: "<b>" + feature.feature_layer_type + "</b>",
						      //content: attributeText
						    }
						});
			            this.graphics.push(this.pointGraphic);
					  	this.layer.graphics.add(this.pointGraphic);
		        	}
		        }
	      	})}
			this.view.goTo(this.graphics);
	    });
  	}

  	componentWillUnmount() {
    	if (this.view) {
      		// destroy the map view
      		this.view.container = null;
   		}
  	}

  	render(){
	  	return(
	  		<div className="mapWrapper">
			  <div className="map" id="map" ref={this.mapRef}>
			      
			  </div>
			</div>
	  	);
  	}
}

export default EsriKMLMap;