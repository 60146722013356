import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Modal, Button} from 'react-bootstrap';
import './UserAdminPreview.css';

class UserAdminPreview extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        userName: "",
        accountName: "",
        accountId: "",
        accountIdStart: "",
        userNameStart: "",
        firstName: "",
        firstNameStart: "",
        lastName: "",
        lastNameStart: "",
        userEmail: "",
        userEmailStart: "",
        userPhone: "",
        userPhoneStart: "",
        userRole: "",
        userRoleStart: "",
        userId: "",
        userActive: false,
        updating: false,
        userPermissions: [],
        accountPermissions: [],
        deleteVerify: false,
        allAccounts: [],
        modalBody: "",
        modalHeader: "",
        show: false,
        selectAllBoxes: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.showUpdate = this.showUpdate.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.cancelUser = this.cancelUser.bind(this);
    this.changeRadio = this.changeRadio.bind(this);
    this.changeActiveStatus = this.changeActiveStatus.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.selectAll = this.selectAll.bind(this);

  }

  componentDidMount(){
    var userPermissionsArray;
    var accountPermissionsArray;
    var userActiveStatus;
    if(this.props.userPermissions != null){
      userPermissionsArray = this.props.userPermissions.split(',');
    }else{
      userPermissionsArray = [];
    }

    if(this.props.accountPermissions != null && this.props.accountPermissions.length > 0){
      accountPermissionsArray = this.props.accountPermissions.split(',');
    }else{
      accountPermissionsArray = [];
    }

    if(this.props.activeStatus == 1){
      userActiveStatus = true;
    }else{
      userActiveStatus = false;
    }

    accountPermissionsArray.sort();
    this.setState({userPermissions: userPermissionsArray, accountPermissions: accountPermissionsArray, allAccounts: this.props.allAccounts, userId: this.props.userId, userName: this.props.userName, accountName: this.props.accountName, accountId: this.props.accountId, accountIdStart: this.props.accountId, userNameStart: this.props.userName, userRole: this.props.userRole, userRoleStart: this.props.userRole, userEmail: this.props.userEmail, userEmailStart: this.props.userEmail, firstName: this.props.firstName, firstNameStart: this.props.firstName, lastName: this.props.lastName, lastNameStart: this.props.lastName, userPhone: this.props.userPhone, userPhoneStart: this.props.userPhone, userActive: userActiveStatus});
  }

  componentDidUpdate(){
    if(this.props.allAccounts != this.state.allAccounts && this.props.allAccounts.length >0){
      this.setState({allAccounts: this.props.allAccounts});
    }
  }

  handleChange(e){
    this.setState({[e.target.id]:e.target.value});
  }

  showUpdate(){
    this.setState({updating: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  deleteConfirm(){
    let value = this.context;
    this.setState({loading: true, show: false});
    axios.post('https://www.arutility.com/api/v4/users/adminDelete.php',{userId: this.state.userId},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
          this.setState({loading: false, show: true, modalHeader: "Success", modalBody: "User successfully deleted.", deleteVerify: false});
      }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, deleteVerify: false});
        } else if (error.request) {
          // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, deleteVerify: false});
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error, modalHeader: "Error", show: true, deleteVerify: false});
        }
    });
  }

  updateUser(){  
    let value = this.context;
    if(this.state.userName != "" && this.state.userRole != "" && this.state.userEmail != "" && this.state.userPhone != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.accountId != ""){
      this.setState({loading: true});
      axios.post('https://www.arutility.com/api/v4/users/adminUpdate.php',{userName: this.state.userName, userRole: this.state.userRole, updateUserId: this.state.userId, userPermissions: this.state.userPermissions, userEmail: this.state.userEmail, userPhone: this.state.userPhone, accountId: this.state.accountId, firstName: this.state.firstName, lastName: this.state.lastName, activeStatus: this.state.userActive},{headers: {Authorization: 'Bearer ' + value.jwt}})
      .then(res => {
          this.setState({loading: false, updating: false, show: true, modalHeader: "Success", modalBody: "User successfully updated", userNameStart: this.state.userName, userRoleStart: this.state.userRole, firstNameStart: this.state.firstName, lastNameStart: this.state.lastName, userEmailStart: this.state.userEmail, userPhoneStart: this.state.userPhone, accountIdStart: this.state.accountId});
      }).catch(error => {
        if (error.response) {
          this.setState({loading: false, updating: false, show: true, modalHeader: "Error", modalBody: "User was not updated", userName: this.state.userNameStart, userRole: this.state.userRoleStart, firstName: this.state.firstNameStart, lastName: this.state.lastNameStart, userEmail: this.state.userEmailStart, userPhone: this.state.userPhoneStart, accountId: this.state.accountIdStart});
        } else if (error.request) {
          // The request was made but no response was received
          this.setState({loading: false, updating: false, show: true, modalHeader: "Error", modalBody: "User was not updated", userName: this.state.userNameStart, userRole: this.state.userRoleStart, firstName: this.state.firstNameStart, lastName: this.state.lastNameStart, userEmail: this.state.userEmailStart, userPhone: this.state.userPhoneStart, accountId: this.state.accountIdStart});
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, updating: false, show: true, modalHeader: "Error", modalBody: "User was not updated", userName: this.state.userNameStart, userRole: this.state.userRoleStart, firstName: this.state.firstNameStart, lastName: this.state.lastNameStart, userEmail: this.state.userEmailStart, userPhone: this.state.userPhoneStart, accountId: this.state.accountIdStart});
        }
      });
    }else{
      this.setState({loading: false, updating: false, show: true, modalHeader: "Error", modalBody: "It looks like you are missing some required information", userName: this.state.userNameStart, userRole: this.state.userRoleStart, firstName: this.state.firstNameStart, lastName: this.state.lastNameStart, userEmail: this.state.userEmailStart, userPhone: this.state.userPhoneStart, accountId: this.state.accountIdStart});
    }  
  }

  deleteUser(){
    this.setState({deleteVerify: true, modalHeader: "Confirmation", modalBody: "Are you sure you want to delete this user? This action cannot be undone.", show: true});
  }

  cancelUser(){
    this.setState({updating: false, userName: this.state.userNameStart, userRole: this.state.userRoleStart, firstName: this.state.firstNameStart, lastName: this.state.lastNameStart, userEmail: this.state.userEmailStart, userPhone: this.state.userPhoneStart, accountId: this.state.accountIdStart});     
  }

  changeActiveStatus(e){
      if(this.state[e.target.id] == false){
        this.setState({[e.target.id]: true});
      }else{
        this.setState({[e.target.id]: false});
      }
    }

  changeRadio(e){
    const index = this.state.userPermissions.indexOf(e.target.id);
    var updatedUserPermissions = this.state.userPermissions;
    if (index > -1) {
      updatedUserPermissions.splice(index, 1);
    }else{
      updatedUserPermissions.push(e.target.id);
    }
    this.setState({userPermissions: updatedUserPermissions});

      // Check Condition for Select All Boxes
		if(this.state.userPermissions.length === this.state.accountPermissions.length){
			this.setState({selectAllBoxes: true})
		}else{
			this.setState({selectAllBoxes: false})
		}
  }

  // To select all checkboxes
	  selectAll(e){
		  if(this.state.selectAllBoxes){
			  this.setState({selectAllBoxes: false, userPermissions:[]});
		  }else{
			this.setState({selectAllBoxes: true, userPermissions: [...this.state.accountPermissions]});
		  }
	  }

  render(){
    let value = this.context;
    return (
      <div className="userWrapper">
        <div className="userInfoWrapper">
          <Modal show={this.state.show} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalBody}</Modal.Body>
            <Modal.Footer>
            {this.state.deleteVerify &&
              <Button variant="danger" onClick={this.deleteConfirm}>
                Delete
              </Button>
            }
              <Button variant="secondary" onClick={this.closeModal}>
                Close
              </Button> 
            </Modal.Footer>
          </Modal>
          {this.state.updating ?  
            <div>
              {this.state.loading && 
                <LoadingSpinner />
              }
              <div className="username">
                <input type="text" id="userName" name="userName" className="companyInput" onChange={this.handleChange} value={this.state.userName} />
              </div>
              <div className="accountUpdate">
                <Button variant="secondary" onClick={this.cancelUser} >Cancel</Button>
                <Button variant="success" onClick={this.updateUser} >Update</Button>
                <Button variant="danger" onClick={this.deleteUser} >Delete</Button>
              </div>
              <div className="userRolesWrapper">
                <div className="contactInfoWrapper">
                  <font color="#c62828">User Active: </font>
                  <input id="userActive" type="checkbox" name="userActive" className="featureSelectedCheckbox" checked={this.state.userActive} onChange={this.changeActiveStatus}/>
                  <br/>
                  <font color="#c62828">Account Name: </font>
                  <select className="userRoleSelect" id="accountId" name="accountName" onChange={this.handleChange} value={this.state.accountId}>
                    {this.state.allAccounts.map(account =>{
                      return(
                        <option key={account.accountId} value={account.accountId}>{account.accountName}</option>
                      );
                    })}
                  </select>
                  <br/>
                  <font color="#c62828">User Role: </font>
                  <select className="userRoleSelect" id="userRole" name="userRole" onChange={this.handleChange} value={this.state.userRole}>
                    <option value="1">Admin</option>
                    <option value="2">Creator</option>
                    <option value="3">User</option>
                  </select>
                  <br/>
                  <font color="#c62828">First Name: </font>
                  <br/>
                  <input id="firstName" type="text" name="firstName" className="companyInput" value={this.state.firstName} onChange={this.handleChange}/>
                  <br/>
                  <font color="#c62828">Last Name: </font>
                  <br/>
                  <input id="lastName" type="text" name="lastName" className="companyInput" value={this.state.lastName} onChange={this.handleChange}/>
                  <br/>
                  <font color="#c62828">Email: </font>
                  <br/>
                  <input id="userEmail" type="text" name="userEmail" className="companyInput" value={this.state.userEmail} onChange={this.handleChange}/>
                  <br/>
                  <font color="#c62828">Phone Number: </font>
                  <br/>
                  <input id="userPhone" type="text" name="userPhone" className="companyInput" value={this.state.userPhone} onChange={this.handleChange}/>
                  <br/>
                  <br/>
                  <b>USER PERMISSIONS</b>
                </div>
                <div className="userRoleWrapper" >
                  <input type="checkbox" name="Select All" checked={this.state.selectAllBoxes}  onChange={this.selectAll} className="featureSelectedCheckbox"/>
                  <b>Select All</b>
					      </div>
                {this.state.accountPermissions.map(permission => {
                  return(
                    <div className="userRoleWrapper" key={permission}>
                    <input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                    {permission}
                    </div>
                  );
                })}
              </div>
            </div>
          :
            <div>
              <div className="username" name="userName">
                {this.state.userName}
              </div>
              <div className="accountUpdate">
                {value.userPermissions.includes("user:super") &&
                  <Button variant="primary" onClick={this.showUpdate} >Change</Button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default UserAdminPreview;