import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Modal, Button} from 'react-bootstrap';
import './AccountAdminPreview.css';

class AccountAdminPreview extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        accountName: "",
        accountId: "",
        accountActive: "",
        accountAlias: "",
        accountLicenses: "",
        accountType: "",
        address1: "",
        address2: "",
        accountCity: "",
        accountState: "",
        accountCountry: "",
        esriServiceURL: "",
        esriAnalysisURL: "",
        updating: false,
        accountPermissions: [],
        allPermissions: [],
        deleteVerify: [],
        modalBody: "",
        modalHeader: "",
        show: false,
        selectAllBoxes: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.showUpdate = this.showUpdate.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.cancelAccount = this.cancelAccount.bind(this);
    this.changeRadio = this.changeRadio.bind(this);
    this.changeActiveStatus = this.changeActiveStatus.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.selectAll = this.selectAll.bind(this);

  }

  componentDidMount(){
    var accountPermissionsArray;
    var allPermissionsArray;
    var accountActiveStatus;
    if(this.props.accountPermissions != null){
      accountPermissionsArray = this.props.accountPermissions.split(',');
    }else{
      accountPermissionsArray = [];
    }
    /*if(this.props.allPermissions != null && this.props.allPermissions.length > 0){
      allPermissionsArray = this.props.allPermissions.split(',');
    }else{
      allPermissionsArray = [];
    }*/
    if(this.props.accountActive == "1"){
      accountActiveStatus = true;
    }else{
      accountActiveStatus = false;
    }
    this.setState({accountPermissions: accountPermissionsArray, allPermissions: this.props.allPermissions, accountId: this.props.accountId, accountName: this.props.accountName, accountActive: accountActiveStatus, accountAlias: this.props.accountAlias, accountLicenses: this.props.accountLicenses, accountType: this.props.accountType, address1: this.props.address1, address2: this.props.address2, accountCity: this.props.accountCity, accountState: this.props.accountState, esriServiceURL: this.props.esriServiceURL, esriAnalysisURL: this.props.esriAnalysisURL, accountCountry: this.props.accountCountry});
  }

  componentDidUpdate(){
    if(this.props.allPermissions != this.state.allPermissions && this.props.allPermissions.length >0){
      this.setState({allPermissions: this.props.allPermissions});
    }
  }

  handleChange(e){
    this.setState({[e.target.name]:e.target.value});
  }

  showUpdate(){
    this.setState({updating: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  deleteConfirm(){
    let value = this.context;
    this.setState({loading: true, show: false});
    axios.post('https://www.arutility.com/api/v4/accounts/adminDelete.php',{accountId: this.state.accountId},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
          this.setState({loading: false, show: true, modalHeader: "Success", modalBody: "Account successfully deleted.", deleteVerify: false});
      }).catch(error => {
          if (error.response) {
            this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, deleteVerify: false});
        } else if (error.request) {
          // The request was made but no response was received
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, deleteVerify: false});
        } else {
          // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error, modalHeader: "Error", show: true, deleteVerify: false});
        }
    });
  }

  updateAccount(){
    let value = this.context;
    if(this.state.accountName != "" && this.state.accountLicenses != "" && this.state.accountCountry != "" && this.state.address1 != "" && this.state.accountCity != "" && this.state.accountState != ""){
      this.setState({loading: true});
      axios.post('https://www.arutility.com/api/v4/accounts/adminUpdate.php',{accountId: this.state.accountId, accountName: this.state.accountName, companyCountry: this.state.accountCountry, address1: this.state.address1, address2: this.state.address2, accountCity: this.state.accountCity, accountState: this.state.accountState, accountLicenses: this.state.accountLicenses, accountPermissions: this.state.accountPermissions, accountActive: this.state.accountActive, accountAlias: this.state.accountAlias, accountType: this.state.accountType, esriServiceURL: this.state.esriServiceURL, esriAnalysisURL: this.state.esriAnalysisURL},{headers: {Authorization: 'Bearer ' + value.jwt}})
      .then(res => {
          this.setState({loading: false, updating: false});
          this.props.updateAccountSuccess();
      }).catch(error => {
        if (error.response) {
          this.setState({loading: false, updating: false});
          this.props.updateAccountError();
        } else if (error.request) {
          // The request was made but no response was received
          this.setState({loading: false, updating: false});
          this.props.updateAccountError();
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, updating: false});
          this.props.updateAccountError();
        }
      });
    }else{
      this.props.updateAccountError();
    }
  }

  deleteAccount(){
    this.setState({deleteVerify: true, modalHeader: "Confirmation", modalBody: "Are you sure you want to delete this account? This action cannot be undone.", show: true});
  }

  cancelAccount(){
    this.setState({updating: false, userName: this.state.startName, userRole: this.state.startRole});     
  }

  changeActiveStatus(e){
      if(this.state[e.target.id] == false){
        this.setState({[e.target.id]: true});
      }else{
        this.setState({[e.target.id]: false});
      }
    }

  changeRadio(e){
    const index = this.state.accountPermissions.indexOf(e.target.id);
    var updatedAccountPermissions = this.state.accountPermissions;
    if (index > -1) {
      updatedAccountPermissions.splice(index, 1);
    }else{
      updatedAccountPermissions.push(e.target.id);
    }
    this.setState({accountPermissions: updatedAccountPermissions});

     // Check Condition for Select All Boxes
		if(this.state.allPermissions.length === this.state.accountPermissions.length){
			this.setState({selectAllBoxes: true})
		}else{
			this.setState({selectAllBoxes: false})
		}
  }

   // To select all checkboxes
   selectAll(e){
    if(this.state.selectAllBoxes){
      this.setState({selectAllBoxes: false, accountPermissions:[]});
    }else{
    this.setState({selectAllBoxes: true, accountPermissions: [...this.state.allPermissions]});
    }
  }

  render(){
    let value = this.context;
    return (
      <div className="userWrapper">
        <div className="userInfoWrapper">
          {this.state.updating ?  
            <div>
            {this.state.loading && 
              <LoadingSpinner />
            }
              <Modal show={this.state.show} onHide={this.closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.modalBody}</Modal.Body>
                <Modal.Footer>
                {this.state.deleteVerify &&
                  <Button variant="danger" onClick={this.deleteConfirm}>
                    Delete
                  </Button>
                }
                  <Button variant="secondary" onClick={this.closeModal}>
                    Close
                  </Button> 
                </Modal.Footer>
              </Modal>
              <div className="username">
                <input type="text" name="accountName" className="companyInput" onChange={this.handleChange} value={this.state.accountName} />
              </div>
              <div className="accountUpdate">
                <Button variant="secondary" onClick={this.cancelAccount} >Cancel</Button>
                <Button variant="success" onClick={this.updateAccount} >Update</Button>
                <Button variant="danger" onClick={this.deleteAccount} >Delete</Button>
              </div>
              <div className="userRolesWrapper">
                <div className="contactInfoWrapper">
                  <font color="#c62828">Account Active: </font>
                  <input id="accountActive" type="checkbox" name="accountActive" className="featureSelectedCheckbox" checked={this.state.accountActive} onChange={this.changeActiveStatus}/>
                  <br/>
                  <br/>
                  <font color="#c62828">Account Type: </font>
                  <select className="projectSelect" id="accountType" name="accountType" onChange={this.handleChange} value={this.state.accountType}>
                    <option value="utility">Utility</option>
                    <option value="contractor">Contractor/Excavator</option>
                    <option value="locator">Utility Locator</option>
                    <option value="consultant">Consultant/Engineering</option>
                    <option value="individual">Individual/Homeowner</option>
                  </select>
                  <br/>
                  <br/>
                  {this.state.accountType == "utility" &&
                    <div>
                      <font color="#c62828">Account Alias:</font>
                      <br/>
                      <input type="text" name="accountAlias" className="companyInput" onChange={this.handleChange} value={this.state.accountAlias} />
                      <br/>
                      <font color="#c62828">Esri Service URL:</font>
                      <br/>
                      <input type="text" name="esriServiceURL" className="companyInput" onChange={this.handleChange} value={this.state.esriServiceURL} />
                      <br/>
                      <font color="#c62828">Esri Analysis URL:</font>
                      <br/>
                      <input type="text" name="esriAnalysisURL" className="companyInput" onChange={this.handleChange} value={this.state.esriAnalysisURL} />
                    </div>
                  }
                  <font color="#c62828">Number of Licenses:</font>
                  <br/>
                  <input type="text" name="accountLicenses" className="companyInput" onChange={this.handleChange} value={this.state.accountLicenses} />
                  <br/>
                  <font color="#c62828">Country:*</font>
                  <br/>
                  <select className="projectSelect" name="accountCountry" id="accountCountry" value={this.state.accountCountry} onChange={this.handleChange}>
                      <option value=""></option>
                      <option value="Afganistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bonaire">Bonaire</option>
                      <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Canary Islands">Canary Islands</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Channel Islands">Channel Islands</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos Island">Cocos Island</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote DIvoire">Cote DIvoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curaco">Curacao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="East Timor">East Timor</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands">Falkland Islands</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Ter">French Southern Ter</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Great Britain">Great Britain</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="India">India</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea North">Korea North</option>
                      <option value="Korea Sout">Korea South</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedonia">Macedonia</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Midway Islands">Midway Islands</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Nambia">Nambia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherland Antilles">Netherland Antilles</option>
                      <option value="Netherlands">Netherlands (Holland, Europe)</option>
                      <option value="Nevis">Nevis</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau Island">Palau Island</option>
                      <option value="Palestine">Palestine</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Phillipines">Philippines</option>
                      <option value="Pitcairn Island">Pitcairn Island</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Republic of Montenegro">Republic of Montenegro</option>
                      <option value="Republic of Serbia">Republic of Serbia</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="St Barthelemy">St Barthelemy</option>
                      <option value="St Eustatius">St Eustatius</option>
                      <option value="St Helena">St Helena</option>
                      <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                      <option value="St Lucia">St Lucia</option>
                      <option value="St Maarten">St Maarten</option>
                      <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                      <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                      <option value="Saipan">Saipan</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Samoa American">Samoa American</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Tahiti">Tahiti</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Erimates">United Arab Emirates</option>
                      <option value="United States of America">United States of America</option>
                      <option value="Uraguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City State">Vatican City State</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                      <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                      <option value="Wake Island">Wake Island</option>
                      <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zaire">Zaire</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <br/>
                  {this.state.accountCountry == "United States of America" ? 
                    <div>
                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State:*</font>
                        <br/>
                        <select className="projectSelect" name="accountState" id="accountState" value={this.state.accountState} onChange={this.handleChange}>
                            <option value=""> </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <br/>
                    </div>
                    :
                    <div>
                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State/Province/Region:*</font>
                        <br/>
                        <input type="text" value={this.state.accountState} name="accountState" id="accountState" className="companyInput" placeholder="State/Province/Region" onChange={this.handleChange}/>
                        <br/>
                    </div>
                  }
                  <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} City/Town/Village:*</font>
                  <br/>
                  <input type="text" value={this.state.accountCity} id="accountCity" name="accountCity" className="companyInput" placeholder="City/Town/Village..." onChange={this.handleChange}/>
                  <br/>
                  <font color="#c62828">Company Address:</font>
                  <br/>
                  <input type="text" name="address1" className="companyInput" onChange={this.handleChange} value={this.state.address1} />
                  <br/>
                  <font color="#c62828">Address Cont:</font>
                  <br/>
                  <input type="text" name="address2" className="companyInput" onChange={this.handleChange} value={this.state.address2} />
                  <br/>
                  <b>ACCOUNT PERMISSIONS</b>
                </div>

                <div className="userRoleWrapper" >
                  <input type="checkbox" name="Select All" checked={this.state.selectAllBoxes}  onChange={this.selectAll} className="featureSelectedCheckbox"/>
                  <b>Select All</b>
					      </div>
                {this.state.allPermissions.map(permission => {
                  return(
                    <div className="userRoleWrapper" key={permission}>
                    <input id={permission} type="checkbox" name={permission} checked={this.state.accountPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                    {permission}
                    </div>
                  );
                })}
              </div>
            </div>
          :
            <div>
              <div className="username" name="userName">
                {this.state.accountName}
              </div>
              <div className="accountUpdate">
                {value.accountPermissions.includes("user:super") &&
                  <Button variant="primary" onClick={this.showUpdate} >Change</Button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default AccountAdminPreview;