import React from 'react';
import './EditSessionFilter.css';

class EditSessionFilter extends React.Component{

  render(){
    return (
    	<div className="projectsFilterWrapper">
      		<div className="editSessionId">
          Session ID
          </div>
          <div className="editSessionName">
          Session Name
          </div>
          <div className="editSessionCreator">
          Creator
          </div>
          <div className="editSessionDate">
          Session Created
          </div>
      </div>
    );
  }
}

export default EditSessionFilter;