import React from 'react';
import { Redirect } from 'react-router-dom';

export const Context = React.createContext({});

export class ConfigContext extends React.Component {
	
	constructor() {
		super();
		this.state = {
			username: "",
			isLoggedIn: false,
			role: "",
			companyName: "",
			accountType: "",
			jwt: "",
			permissions: [],
			userPermissions: [],
			accountPermissions: []
		};
		this.updateUsername = this.updateUsername.bind(this);
		this.updateUserRole = this.updateUserRole.bind(this);
		this.updateCompanyName = this.updateCompanyName.bind(this);
		this.updateAccountType = this.updateAccountType.bind(this);
		this.updateAccountPermissions = this.updateAccountPermissions.bind(this);
		this.updateUserPermissions = this.updateUserPermissions.bind(this);
		this.updateJWT = this.updateJWT.bind(this);
		this.logout = this.logout.bind(this);
	}

	updateUsername(name){
		this.setState({username: name});
	}

	updateUserRole(role){
		const rules = {
		  guest: {
		    static: ["posts:list", "home-page:visit"]
		  },
		  user: {
		    static: [
		      "projects:view",
		      "project:view",
		      "communitymap:view",
		      "profile:update"
		    ]
		  },
		  creator: {
		    static: [
		      "projects:view",
		      "project:view",
		      "project:create",
		      "project:delete",
		      "requests:view",
		      "request:view",
		      "request:update",
		      "editsessions:view",
		      "editsession:view",
		      "communitymap:view",
		      "profile:update"
		    ]
		  },
		  admin: {
		    static: [
		      "projects:view",
		      "project:view",
		      "project:create",
		      "project:delete",
		      "requests:view",
		      "request:view",
		      "request:update",
		      "editsessions:view",
		      "editsession:view",
		      "updatesessions:view",
		      "udpatesession:view",
		      "communitymap:view",
		      "profile:update",
		      "account:update",
		      "mapping:update",
		      "users:view",
		      "user:update"
		    ]
		  }
		}

		const permissions = rules[role];
		const staticPermissions = permissions.static;
		this.setState({role: role, permissions: staticPermissions});
	}

	updateUserPermissions(permissions){
		this.setState({userPermissions: permissions});
	}

	updateAccountPermissions(permissions){
		this.setState({accountPermissions: permissions});
	}

	updateCompanyName(companyName){
		this.setState({companyName: companyName});
	}

	updateAccountType(accountType){
		this.setState({accountType: accountType});
	}

	updateJWT(jwt){
		this.setState({jwt: jwt});
	}

	logout(){
		this.setState({username:"",isLoggedIn: false,role:"",companyName:"",jwt:""});
		return (<Redirect to='/login' />);
	}

	render() {
		const { username, companyName, role, jwt, permissions, userPermissions, accountPermissions, accountType} = this.state;
		return (
			<Context.Provider value={{userPermissions: userPermissions, accountPermissions: accountPermissions, permissions: permissions, username: username, role: role, jwt: jwt, updateUsername: this.updateUsername, companyName: companyName, updateUserRole: this.updateUserRole, updateCompanyname: this.updateCompanyName, updateJWT:this.updateJWT, logout:this.logout, updateUserPermissions: this.updateUserPermissions, updateAccountPermissions: this.updateAccountPermissions, updateAccountType: this.updateAccountType, accountType: accountType}}>
				{this.props.children}
			</Context.Provider>
		);
	}
}