import React from 'react';
import ReactDOM from 'react-dom';
import './SessionFeature.css';

class SessionFeature extends React.Component{

  constructor(props) {
      super(props);
      this.state = {
        show: false,
        checked: false,
        attributes: {"featureId":null,"attributes":[]},
        attributesStandard: [],
        originalAttributes: {"featureId":null,"attributes":[]},
        originalAttributesStandard: []
      }
      this.showAttributes = this.showAttributes.bind(this);
      this.updateAttribute = this.updateAttribute.bind(this);
      this.updateChecked = this.updateChecked.bind(this);
  }

  componentDidMount() {
    var attributes = this.props.featureAttributes;
    var attributeKeys = Object.keys(attributes[0]);
    var attributeArray = [];
    for(var i2=0; i2<attributeKeys.length;i2++){
      var keyName = attributeKeys[i2];
      var value = attributes[0][keyName];
      var arrayItem = {"field":keyName,"value":value};
      attributeArray.push(arrayItem);
    }
    var attributeItem = {"featureId":this.props.featureId,"attributes":attributeArray};
    this.setState({attributes: attributeItem, attributesStandard: this.props.featureAttributes});
  }

  showAttributes(e){
    var elementName = e.target.getAttribute('name') + "Attributes";
    let element = document.getElementById(elementName);
    if(this.state.show == false){
      ReactDOM.findDOMNode(element).style.display = "block";
      this.setState({show: true});
    }else{
      ReactDOM.findDOMNode(element).style.display = "none";
      this.setState({show: false});
    }
  }

  updateAttribute(e){
    var fieldName = e.target.id;
    var oldAttributes = this.state.attributes;
    var oldAttributesStandard = this.state.attributesStandard;
    oldAttributesStandard[0][fieldName] = e.target.value;
    var numAttributes = oldAttributes.attributes.length;
    for(var i=0; i<numAttributes; i++){
      if(oldAttributes.attributes[i].field == fieldName){
        oldAttributes.attributes[i].value = e.target.value;
      }
    }
    this.setState({attributes: oldAttributes, attributesStandard: oldAttributesStandard});
    this.props.updateAttribute(oldAttributesStandard);
  }

  updateChecked(e){
    if(this.state.checked == false){
      this.setState({checked: true});
      this.props.updateSelected(this.props.featureId);
    }else{
      this.setState({checked: false});
      this.props.updateSelected(this.props.featureId);
    }
  }

  render(){
    var featureStatusImg = "";
    var featureAttributeWrapper = this.props.featureId + "Attributes";
    if(this.props.featureStatus == "0"){
      featureStatusImg = '/images/inprogressBtn.png';
    }else if(this.props.featureStatus == "1"){
      featureStatusImg = '/images/completeProjectBtn.png';
    }else if(this.props.featureStatus == "2"){
      featureStatusImg = '/images/cancelProjectBtn.png';
    }
    return (
      <div>
        <div className={this.state.checked ? 'featureWrapperChecked': "featureWrapper"}>
          <div className="featureSelected">
            <input type="checkbox" className="featureSelectedCheckbox" onChange={this.updateChecked} checked={this.state.checked} />
          </div>
          <div className="featureStatus">
            <img src={featureStatusImg} alt="featureStatus"/>
          </div>
          <div className="featureId">
            {this.props.featureType} {this.props.featureId}
          </div>
          <div className="featureCarrot" name={this.props.featureId} onClick={this.showAttributes}>
            {(this.state.show == false) ?
              <img src="/images/downcarrotblack.png" name={this.props.featureId} onClick={this.showAttributes} alt="downCarrot"/>
              :
              <img src="/images/upcarrotblack.png" name={this.props.featureId} onClick={this.showAttributes} alt="upCarrot"/>
            }
          </div> 
            {(this.props.featureEndLng == "") ? 
              <div className="featureCoordinatesPoint">
                <b>Lng: </b>{this.props.featureStartLng} <b>Lat: </b>{this.props.featureStartLat}
              </div>
              :
              <div className="featureCoordinatesLine">
                <b>Start Lng: </b>{this.props.featureStartLng} <b>Start Lat: </b>{this.props.featureStartLat}
                <br/>
                <b>End Lng: </b>{this.props.featureEndLng} <b>End Lat: </b>{this.props.featureEndLat}
              </div>
            }
          <div className="featureAttributes" id={featureAttributeWrapper}>
            <div className="attributesWrapper">
              <b><font size="3">ATTRIBUTES:</font></b>
              {this.state.attributes.attributes.map(attribute => {
                var key = attribute.field + this.props.featureId;
                return(
                  <div className="attributeWrapper" key={key}>
                    <div className="attributeFieldWrapper">
                      <b>{attribute.field}</b>
                    </div>
                    <div className="attributeValueWrapper">
                      <input type="text" name={attribute.field} id={attribute.field} className="companyInput" value={attribute.value} onChange={this.updateAttribute}/>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>     
        </div>
      </div>
    );
  }
}

export default SessionFeature;