import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './Pricing.css';

class Pricing extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        numDamages: "",
        costDamages: "",
        totalDamageCost: 0,
        damageReduction: 20,
        yearlyDamageSavings: 0
    }
    this.focusContractors = React.createRef();
    this.focusUtilities = React.createRef();
    this.focusUtilityLocators = React.createRef();
    this.focusArchitects = React.createRef();
    this.focusEngineers = React.createRef();
    this.focusHomeowners = React.createRef();
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleCalculatorChange = this.handleCalculatorChange.bind(this);
    this.recalculateUtilityCost = this.recalculateUtilityCost.bind(this);
  }

  recalculateUtilityCost(){
    if(this.state.numDamages > 0 && this.state.costDamages > 0){
      var costDamages = parseFloat(this.state.numDamages * this.state.costDamages).toFixed(0);
      var damageReduction = this.state.damageReduction/100;
      var yearlyDamageSavings = parseFloat(costDamages*damageReduction).toFixed(0);
      this.setState({totalDamageCost: costDamages, yearlyDamageSavings: yearlyDamageSavings});
    }else{
      this.setState({totalDamageCost: 0});
    }
  }

  handleCalculatorChange(event){
    this.setState({[event.target.id]:event.target.value},this.recalculateUtilityCost);
  }

  handleOnClick(event){
    if(event.target.id == "focusContractors"){
      this.focusContractors.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusUtilities"){
      this.focusUtilities.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusUtilityLocators"){
      this.focusUtilityLocators.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusArchitects"){
      this.focusArchitects.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusEngineers"){
      this.focusEngineers.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusHomeowners"){
      this.focusHomeowners.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="pageTitle">
          PRICING
        </div>
        <div className="pageDescription">
          <div className="howitworksIndustryWrapper" onClick={this.handleOnClick} id="focusUtilities">
            <div className="industryWrapper" id="focusUtilities">
              <div className="industryWrapperTitle" id="focusUtilities">
                Utilities
              </div>
              <div className="industryWrapperImg" id="focusUtilities">
                <img src="/images/utilities.png" alt="Utilities" id="focusUtilities"/>
              </div>
              <div className="industryWrapperNextImg" id="focusUtilities">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusUtilities"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusUtilityLocators">
              <div className="industryWrapperTitle" id="focusUtilityLocators">
                Utility Locators, Contractors and Engineers
              </div>
              <div className="industryWrapperImg" id="focusUtilityLocators">
                <img src="/images/utilitylocator.png" alt="Utilities" id="focusUtilityLocators"/>
              </div>
              <div className="industryWrapperNextImg" id="focusUtilityLocators">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusUtilityLocators"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusHomeowners">
              <div className="industryWrapperTitle" id="focusHomeowners">
                Homeowners
              </div>
              <div className="industryWrapperImg" id="focusHomeowners">
                <img src="/images/homeowners.png" alt="Utilities" id="focusHomeowners"/>
              </div>
              <div className="industryWrapperNextImg" id="focusHomeowners">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusHomeowners"/>
              </div>
            </div>
          </div>
          <div className="pricingDetailWrapper" ref={this.focusUtilities}>
            <div className="pricingDetailTitle">
              Utilities
            </div>
            <div className="pricingDetailDescription">
              Increase safety, increase efficiency and decrease damages to your assets by taking advantage of the ARUtility augmented reality asset management and visualization solution. Collaborate with neighboring utilities and easily share asset information with others performing locating and excavation activities within your service territory.
              <br/>
              <br/>
              All subscriptions include an unlimited number of licenses. Pricing varies based on the number of utilities being served and the number of endpoints/meters. Discounts provided based on size and subscription term. Please contact us to provide some additional information.
            </div>
            <div className="pricingCalculatorUtilities">
              <font size="+2"><b>Calculate your savings</b></font>
              <br/>
              This calculation is only an estimation based on our research. Numbers may vary for your case.
              <div className="calculatorInput">
                <div className="calculatorValueWrapper">
                  <label htmlFor="numDamages"><b>Estimated # of Damages Per Year:</b></label>
                  <br/>
                  <input id="numDamages" type="number" min="0" step="1" name="name" value={this.state.numDamages} onChange={this.handleCalculatorChange}/>
                  <div className="calculatorOperator">
                    X
                  </div>
                </div>
                <div className="calculatorValueWrapper">
                  <label htmlFor="costDamages"><b>Estimated Cost Per Damage:</b></label>
                  <br/>
                  <input id="costDamages" type="number" min="0" step="1" name="name" value={this.state.costDamages} onChange={this.handleCalculatorChange}/>
                  <div className="calculatorOperator">
                    =
                  </div>
                </div>
                <div className="calculatorValueWrapper">
                  <label htmlFor="totalDamageCost"><b>Yearly Damage Costs:</b></label>
                  <br/>
                  <input id="totalDamageCost" type="number" min="0.00" step="0.01" value={this.state.totalDamageCost} name="name" readOnly/>
                </div>
                <div className="calculatorValueWrapper2">
                  <label htmlFor="totalDamageCost"><b>Yearly Damage Costs:</b></label>
                  <br/>
                  <input id="totalDamageCost" type="number" min="0.00" step="0.01" value={this.state.totalDamageCost} name="name" readOnly/>
                  <div className="calculatorOperator">
                    X
                  </div>
                </div>
                <div className="calculatorValueWrapper">
                  <label htmlFor="damageReduction"><b>Damage Reduction (%):</b></label>
                  <br/>
                  <input id="damageReduction" type="number" min="0" step="10" max="100" value={this.state.damageReduction} onChange={this.handleCalculatorChange}/>
                  <div className="calculatorOperator">
                    =
                  </div>
                </div>
                <div className="calculatorValueWrapper">
                  <label htmlFor="yearlyDamageSavings"><b>Yearly Savings:</b></label>
                  <br/>
                  <input id="yearlyDamageSavings" type="number" min="0.00" step="0.01" value={this.state.yearlyDamageSavings} name="name" readOnly/>
                </div>
              </div>
            </div>
            <div className="pricingDetailImage">
              <div className="pricingOptions">
                <div className="pricingOption">
                  <div className="pricingOptionTitle">
                    1 Year Subscription
                  </div>
                  <div className="pricingOptionDescription">
                    $/meter*
                    <br/>
                    <font size="+1">*contact for more info</font>
                  </div>
                </div>
                <div className="pricingOption">
                  <div className="pricingOptionTitle">
                    2 Year Subscription
                  </div>
                  <div className="pricingOptionDescription">
                    $/meter*
                    <br/>
                    <font size="+1">*contact for more info</font>
                  </div>
                </div>
                <div className="pricingOption">
                  <div className="pricingOptionTitle">
                    3 Year Subscription
                  </div>
                  <div className="pricingOptionDescription">
                    $/meter*
                    <br/>
                    <font size="+1">*contact for more info</font>
                  </div>
                </div>
              </div>
              <img src="/images/arutility_screenshot_2.png" alt="handholdingphone" />
            </div>
            <div className="pricingOptionFeaturesWrapper">
              <div className="pricingOptionFeaturesTitle">
                Your Subscription Includes
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/infinity.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    UNLIMITED PROJECTS
                  </div>
                  Create an unlimited number of projects and respond to an unlimited number of requests. Projects allow you to request information from other utilities to view during design and construction, increasing safety and efficiency for your workforce.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/globe.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    ESRI INTEGRATION
                  </div>
                  Quickly and easily connect to your Esri ArcGIS Online or Enterprise account. This allows you to easily share your asset information with others on a project by project basis. This also allows you to visualize your ArcGIS assets in augmented reality.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/kml.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    KML FILE SUPPORT
                  </div>
                  Create projects by simply uploading KML files to your web portal. Quickly map your KML file layers to ARUtility layers and visualize your assets in augmented reality.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/editsession.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    EDIT SESSIONS
                  </div>
                  Edit sessions allow you to update existing assets and perform record drawings in augmented reality. Quickly and easily perform record drawings from the safety of the sidewalk.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/externalgps.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    EXTERNAL GPS SUPPORT
                  </div>
                  ARUtility can be paired with any external GPS unit to provide even more accuracy to your augmented reality visualizations. Accuracy varies by manufacturer and model. Some manufacturers can provide real-time cm accuracy.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/lidar.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    LIDAR SUPPORT
                  </div>
                  ARUtility always stays on top of the latest technology. We now support LiDar, as provided on the new iPad Pro 2020, for even more accurate tracking, occlusion and visualization.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/unlimitedusers.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    UNLIMITED LICENSES
                  </div>
                  Your subscription includes an unlimited number of licenses to ensure all of your workforce can take full advantage of the benefits ARUtility provides. Subscription fees are based on the number of utilities being served and the number of customers being served.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/remoteassist.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    REMOTE ASSISTANCE
                  </div>
                  Increase efficiency, more clearly communicate with field staff and reduce truck rolls with ARUtility's remote assistance. Remote assistance functionality is built directly into the ARUtility application, allowing you to take full advantage at no additional cost.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/collaborate.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    COLLABORATION
                  </div>
                  ARUtility's collaboration platform is unlike anything else. Create projects, request asset information from other utilities, see projects in your territory and more through our collaboration platform. The power of AR increases exponentially when you can visualize every asset, not just your own.
                </div>
              </div>
            </div>
          </div>
          <div className="pricingDetailWrapper" ref={this.focusUtilityLocators}>
            <div className="pricingDetailTitle">
              Utility Locators, Contractors and Engineers
            </div>
            <div className="pricingDetailDescription">
              Increase safety, increase efficiency and decrease damages to assets by taking advantage of the ARUtility augmented reality asset management and visualization solution. Avoid dangerous and costly damages to underground assets by maintaining a constant visual of where things are located while performing excavation. Placing ARUtility in the hands of operators, inspectors and field crews can significantly reduce the likelihood of damage. Digital markings never get removed during construction or inclement weather. Take advantage of the collaboration tool ahead of construction and quickly and easily gain access to all underground and above ground assets prior to digging.
              <br/>
              <br/>
              Visualize underground assets prior to locating and get a lay of the land. Using the built in measuring tool you can quickly and efficiently take measurements to better understand where assets are located. 2D maps and paper plans make it difficult to understand where you are in reference to an assets actual location in the world. Augmented reality allows you to visualize assets where they actually exist in the world as they are displayed around the user.
            </div>
            <div className="pricingDetailImage">
              <div className="pricingOptions">
                <div className="pricingOption">
                  <div className="pricingOptionTitle">
                    Monthly Subscription
                  </div>
                  <div className="pricingOptionDescription">
                    $100/mo*
                    <br/>
                    <font size="+1">*billed monthly</font>
                  </div>
                </div>
                <div className="pricingOption">
                  <div className="pricingOptionTitle">
                    Quarterly Subscription
                  </div>
                  <div className="pricingOptionDescription">
                    $80/mo*
                    <br/>
                    <font size="+1">*billed quarterly</font>
                  </div>
                </div>
                <div className="pricingOption">
                  <div className="pricingOptionTitle">
                    Yearly Subscription
                  </div>
                  <div className="pricingOptionDescription">
                    $60/mo*
                    <br/>
                    <font size="+1">*billed yearly</font>
                  </div>
                </div>
              </div>
              <img src="/images/locatingscreenshot.png" alt="handholdingphone" />
            </div>
            <div className="pricingOptionFeaturesWrapper">
              <div className="pricingOptionFeaturesTitle">
                Your Subscription Includes
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/infinity.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    UNLIMITED PROJECTS
                  </div>
                  Create an unlimited number of projects and respond to an unlimited number of requests. Projects allow you to request information from other utilities to view during design and construction, increasing safety and efficiency for your workforce.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/globe.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    ESRI INTEGRATION
                  </div>
                  Quickly and easily connect to your Esri ArcGIS Online or Enterprise account. This allows you to easily share your asset information with others on a project by project basis. This also allows you to visualize your ArcGIS assets in augmented reality.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/kml.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    KML FILE SUPPORT
                  </div>
                  Create projects by simply uploading KML files to your web portal. Quickly map your KML file layers to ARUtility layers and visualize your assets in augmented reality.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/editsession.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    EDIT SESSIONS
                  </div>
                  Edit sessions allow you to update existing assets and perform record drawings in augmented reality. Quickly and easily perform record drawings from the safety of the sidewalk.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/externalgps.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    EXTERNAL GPS SUPPORT
                  </div>
                  ARUtility can be paired with any external GPS unit to provide even more accuracy to your augmented reality visualizations. Accuracy varies by manufacturer and model. Some manufacturers can provide real-time cm accuracy.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/lidar.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    LIDAR SUPPORT
                  </div>
                  ARUtility always stays on top of the latest technology. We now support LiDar, as provided on the new iPad Pro 2020, for even more accurate tracking, occlusion and visualization.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/remoteassist.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    REMOTE ASSISTANCE
                  </div>
                  Increase efficiency, more clearly communicate with field staff and reduce truck rolls with ARUtility's remote assistance. Remote assistance functionality is built directly into the ARUtility application, allowing you to take full advantage at no additional cost.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/collaborate.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    COLLABORATION
                  </div>
                  ARUtility's collaboration platform is unlike anything else. Create projects, request asset information from other utilities, see projects in your territory and more through our collaboration platform. The power of AR increases exponentially when you can visualize every asset, not just your own.
                </div>
              </div>
            </div>
          </div>
          <div className="pricingDetailWrapper" ref={this.focusHomeowners}>
            <div className="pricingDetailTitle">
              Homeowners
            </div>
            <div className="pricingDetailDescription">
                Coming soon...
                <br/>
                <br/>
                Add an additional layer of safety when performing excavation on your property. Quickly and easily request utility information from all of your utilities and then visualize assets in augmented reality before ever breaking ground. Users must still comply with their local laws regarding requirements to contact the one-call system. ARUtility is not a replacement for the one-call system but is instead an additional layer of safety.
            </div>
            <div className="pricingDetailImage">
              <img src="/images/homeownerscreenshot.png" alt="handholdingphone" />
            </div>
            <div className="pricingOptionFeaturesWrapper">
              <div className="pricingOptionFeaturesTitle">
                Your Subscription Includes
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/infinity.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    UNLIMITED PROJECTS
                  </div>
                  Create an unlimited number of projects. Projects allow you to request information from utilities to view during design and construction, increasing safety and efficiency for your at home projects.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/externalgps.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    EXTERNAL GPS SUPPORT
                  </div>
                  ARUtility can be paired with any external GPS unit to provide even more accuracy to your augmented reality visualizations. Accuracy varies by manufacturer and model. Some manufacturers can provide real-time cm accuracy.
                </div>
              </div>
              <div className="pricingOptionFeatureSingle">
                <div className="pricingOptionFeatureImage">
                  <img src="/images/lidar.png" alt="Unlimited" />
                </div>
                <div className="pricingOptionFeatureDescription">
                  <div className="pricingOptionDescriptionTitle">
                    LIDAR SUPPORT
                  </div>
                  ARUtility always stays on top of the latest technology. We now support LiDar, as provided on the new iPad Pro 2020, for even more accurate tracking, occlusion and visualization.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Pricing;