import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Request from '../request/Request';
import {Context} from "../configcontext/ConfigContext";
import RequestsFilter from '../requestsfilter/RequestsFilter';
import RequestsHeader from '../requestsheader/RequestsHeader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './WOProjects.css';

class WOProjects extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    requests: [],
		    filteredRequests: [],
		    searching: false,
		    loading: true,
		    searchString: "",
		    statusSearchString: "",
		    utilitySearchString: "",
		    featureServices: [],
			esriFeatureServices: [],
			surveys: [],
			response: [],
			questionsResponse: [],
			answersResponse: [],
			questions: [],
			answers: [],
		    mapping: [],
		    modalBody: "",
			modalHeader: "",
			show: true,
			selectedQuestion: "",
			selectedQuestionID: "",
			selectedSurvey: "",
			selectedSurveyID: "",
			selectedCondition: "===",
			selectedAnswer: "",
			assignmentTypes: [],
			workforceResponse: [],
			showModal: false,
			showConfirmation: false
		}
		this.updateSearch = this.updateSearch.bind(this);
		this.updateSearchStatus	= this.updateSearchStatus.bind(this);
		this.updateSearchUtility = this.updateSearchUtility.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateSearchFull = this.updateSearchFull.bind(this);
		this.deleteLayer = this.deleteLayer.bind(this);
    	this.confirmDelete = this.confirmDelete.bind(this);
    	this.surveyChange = this.surveyChange.bind(this);
    	this.questionChange = this.questionChange.bind(this);
    	this.conditionChange = this.conditionChange.bind(this);
    	this.createFlow = this.createFlow.bind(this);
    	this.answerChange = this.answerChange.bind(this);
    	this.workforceChange = this.workforceChange.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	  	this.setState({loading: true});
		axios.post('https://www.arutility.com/api/v5/esri/surveys/read.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } })
			.then(res => {
				let surveys = res.data.results.map(survey => {
					return { name: survey.title, id: survey.id };
				});
				this.setState({ surveys: [{ name: '' }].concat(surveys), response: res.data.results});
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
	    axios.post('https://www.arutility.com/api/v5/esri/esriServices/readWorkforce.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } })
		.then(res => {
			this.setState({loading: false});
			console.log(res.data.services);
			let featureServices = res.data.services.filter((service) => service.name.toLowerCase().includes("workforce")).map(featureService => {
				//MAKE A CALL TO ESRI ENDPOINT https://services9.arcgis.com/mxyowWsFz4PZyvnS/ArcGIS/rest/services/workforce_11e9c0f7a5134b24ad3f6bdfeab870c3/FeatureServer/info/itemInfo?f=pjson&token= and get the title back for a more user friendly naming
				return { name: featureService.name, title: featureService.label, url: featureService.url };
			});
			this.setState({ featureServices: [{ name: '', title: '', url: '' }].concat(featureServices), workforceResponse: res.data.services, loading: false });
		}).catch(error => {
			if (error.response) {
				if (error.response.status == 401) {
					this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
				} else {
					this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
				}
			} else if (error) {
				this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
			} else {
				this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
			}
		});
	    /*axios.post('https://www.arutility.com/api/v5/esri/featureService/readAll.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } }).then(res => {
			this.setState({mapping: res.data.message});
			}).catch(error => {
				if (error.response) {
					if (error.response.status == 401) {
						this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
					} else {
						this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
					}
				} else if (error) {
					this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
				} else {
					this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
				}
			});*/
  	}

  	createFlow(){
  		alert("Create Flow");
  	}

  	updateSearch(searchString){
  		this.setState({searchString: searchString}, this.updateSearchFull);
		/*if(searchString != ""){
		  	const searchResults = this.state.requests.filter(request => 
		  		request.name.toLowerCase().includes(searchString.toLowerCase()) ||
		  		request.project_city.toLowerCase().includes(searchString.toLowerCase()) ||
		  		request.project_state.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}*/
	}

	updateSearchStatus(searchString){
		this.setState({statusSearchString: searchString}, this.updateSearchFull);
		/*if(searchString != ""){
			const searchResults = this.state.requests.filter(request => 
				request.response_code.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}*/
  	}

  	updateSearchUtility(searchString){
  		this.setState({utilitySearchString: searchString}, this.updateSearchFull);
		/*if(searchString != ""){
			const searchResults = this.state.requests.filter(request => 
				request.featureType.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}*/
  	}

  	updateSearchFull(){
  		var searchResults = JSON.parse(JSON.stringify(this.state.requests));
  		if(this.state.searchString != ""){
  			//console.log(searchResults);
		  	const searchResultsFiltered = searchResults.filter(request => 
		  		request.name.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		request.project_city.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		request.project_state.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		request.ticket_id.toLowerCase().includes(this.state.searchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.statusSearchString != ""){
			const searchResultsFiltered = searchResults.filter(request => 
				request.response_code.toLowerCase().includes(this.state.statusSearchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.utilitySearchString != ""){
			const searchResultsFiltered =searchResults.filter(request => 
				request.featureType.toLowerCase().includes(this.state.utilitySearchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.searchString != "" || this.state.statusSearchString != "" || this.state.utilitySearchString != ""){
			this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}
  	}

  	surveyChange(e) {
  		let value = this.context;
	   //this.props.updateFeatureService(e.target.value, this.state.feature_layer_map_index);
	    this.setState({selectedSurvey: e.target.value, loading: true});
	    var numSurveys = this.state.surveys.length;
	    for (var i = 0; i < numSurveys; i++) {
	      if (this.state.surveys[i].id == e.target.value){
	      	console.log(this.state.surveys[i]);
	      	axios.post('https://www.arutility.com/api/v5/esri/surveys/readOne.php', {id: this.state.surveys[i].id}, { headers: { Authorization: 'Bearer ' + value.jwt } })
			.then(res => {
				let questions = res.data.questions.map(question => {
					return { name: question.label, id: question.id };
				});
				this.setState({ questions: [{ name: '' }].concat(questions), questionsResponse: res.data.questions, loading: false });
		    }).catch(error => {
		        if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
		    });
	      }
	  }
	}

	questionChange(e) {
  		//let value = this.context;
	   //this.props.updateFeatureService(e.target.value, this.state.feature_layer_map_index);
	    this.setState({selectedQuestion: e.target.value});
	    var numQuestions = this.state.questionsResponse.length;
	    for(var i=0; i < numQuestions; i++){
	    	if(e.target.value === this.state.questionsResponse[i].id){
	    		this.setState({answers: this.state.questionsResponse[i].choices.items, selectedAnswer: this.state.questionsResponse[i].choices.items[0].value});
	    	}
	    }
	}

	conditionChange(e){
		this.setState({selectedCondition: e.target.value});
	}

	answerChange(e){
		let value = this.context;
		this.setState({selectedAnswer: e.target.value});
	}

	workforceChange(e){
		let value = this.context;
		this.setState({assignmentTypes: []});
		var numFeatureServices = this.state.workforceResponse.length;
		for(var i=0; i<numFeatureServices; i++){
			if(this.state.workforceResponse[i].name === e.target.value){
				var numTables = this.state.workforceResponse[i].tables.length;
				var url = this.state.workforceResponse[i].url;
				var index = 0;
				for(var i2=0; i2<numTables; i2++){
					if(this.state.workforceResponse[i].tables[i2].name === "Assignment Types"){
						index = this.state.workforceResponse[i].tables[i2].id;
						//Now pass this information to the new endpoint and query that feature service for the assignment types
						this.setState({loading: true});
						axios.post('https://www.arutility.com/api/v5/esri/esriServices/readOneWorkforce.php', {url: url, index: index}, { headers: { Authorization: 'Bearer ' + value.jwt } })
						.then(res => {
							/*console.log(res.data.services);
							let featureServices = res.data.services.filter((service) => service.name.toLowerCase().includes("workforce")).map(featureService => {
								//MAKE A CALL TO ESRI ENDPOINT https://services9.arcgis.com/mxyowWsFz4PZyvnS/ArcGIS/rest/services/workforce_11e9c0f7a5134b24ad3f6bdfeab870c3/FeatureServer/info/itemInfo?f=pjson&token= and get the title back for a more user friendly naming
								return { name: featureService.name, title: featureService.label, url: featureService.url };
							});*/
							this.setState({ assignmentTypes: [{attributes:{description: ""}}].concat(res.data.features), loading: false });
						}).catch(error => {
							if (error.response) {
								if (error.response.status == 401) {
									this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
								} else {
									this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
								}
							} else if (error) {
								this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
							} else {
								this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
							}
						});
					}
				}
			}
		}
	}

  	showModal() {
		this.setState({ showConfirmation: true });
	}

	closeModal() {
		this.setState({ showConfirmation: false });
	}

	deleteLayer(){
	    //this.props.deleteLayer(this.state.feature_layer_map_index);
	    this.setState({showConfirmation: false});
	  }

	confirmDelete(){
	    this.setState({showConfirmation: true});
	}

  	render(){
	  	const {loading} = this.state;
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	//if(value.userPermissions.includes("requests:view")){
			    return (
			    	<div className="mainWrapper">
				    	<div className="mainWindow">
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<div className="plainBody">
								<div className="layerOptionWrapper" data-map_id={this.props.feature_layer_map_index} data-feature_service_type_id={this.props.feature_service_type_id} data-feature_layer_type_id={this.props.feature_layer_type_id} data-feature_type={this.props.feature_type}>
						            <Modal show={this.state.showConfirmation} onHide={this.closeModal}>
						              <Modal.Header closeButton>
						                <Modal.Title>{this.state.modalHeader}</Modal.Title>
						              </Modal.Header>
						              <Modal.Body>{this.state.modalBody}</Modal.Body>
						              <Modal.Footer>
						                <Button variant="danger" onClick={this.deleteLayer}>
						                  Delete
						                </Button>
						                <Button variant="secondary" onClick={this.closeModal}>
						                  Cancel
						                </Button>
						              </Modal.Footer>
						            </Modal>
						            <div className="layerHeader" >
						              Create New Automated Work Order
						            </div>
						            <div className="layerSelection" style={{display: this.state.show ? "inline-block" : "none"}}>
						              Generate work order based on response from the following survey:
						              <select id="survey_name" onChange={this.surveyChange} className="featureServiceName"  value={this.state.selectedSurvey}>
						              	{this.state.surveys.map((survey, index) => <option key={survey.name + index} value={survey.id}>{survey.name}</option>)}
						              </select>
						              IF response to question:
						              <select id="question" onChange={this.questionChange} className="featureServiceName"  value={this.state.selectedQuestion}>
						                {this.state.questions.map((question, index) => <option key={question.name + index} value={question.id}>{question.name}</option>)}
						              </select>
						              <select id="condition" onChange={this.conditionChange} className="featureServiceName"  value={this.state.selectedCondition}>
						                <option value="===">=</option>
						                <option value="contains">Contains</option>
						              </select>
						              <select id="answers" onChange={this.answerChange} className="featureServiceName"  value={this.state.selectedAnswer}>
						                {this.state.answers.map((answer, index) => <option key={answer.label + index} value={answer.value}>{answer.label}</option>)}
						              </select>
						              Create a Work Order on the Following Workforce Project:
						              <select id="workforceprojects" onChange={this.workforceChange} className="featureServiceName"  value={this.state.selectedWorkforce}>
						                {this.state.featureServices.map((featureService, index) => <option key={featureService.name + index} value={featureService.name}>{featureService.title}</option>)}
						              </select>
						              Create the Following Assignment Type:
						              <select id="assignmentTypes" onChange={this.assignmentChange} className="featureServiceName"  value={this.state.selectedAssignment}>
						                {this.state.assignmentTypes.map((assignmentType, index) => <option key={assignmentType.attributes.description + index} value={assignmentType.attributes.description}>{assignmentType.attributes.description}</option>)}
						              </select>
						              <Button variant="primary" onClick={this.createFlow}>
						                  CREATE
						              </Button>
						            </div>
						          </div>
							</div>
				       	</div>
			       	</div>
			    );
			//}else{
				//return(
					//<Route>
						//<Redirect to="/login" />
					//</Route>
				//);
			//}
	  	}
  	}
}

export default WOProjects;