import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NewsArticle.css';

class NewsArticle extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        newsArticle: [],
        loading: false,
        modalBody: "",
        modalHeader: "",
        show: false
    }
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  componentDidMount(){
    this.setState({loading: true});
    axios.post('https://www.arutility.com/api/v4/news/readOne.php', {id: this.props.match.params.id})
    .then(res => {
      this.setState({newsArticle: res.data, loading: false});
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  render(){
    const {show} = this.state;
    return (
      <div>
        <MainHeader />
        {this.state.loading && 
          <LoadingSpinner />
        }
        <Modal show={show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="pageTitle">
          {this.state.newsArticle.title}
        </div>
        <div className="newsArticleWrapper" dangerouslySetInnerHTML={{__html: this.state.newsArticle.body}}>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default NewsArticle;