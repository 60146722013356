import React from 'react';
import axios from 'axios';
import {Link, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import EsriUpdateSessionMap2 from '../esriupdatesessionmap2/EsriUpdateSessionMap2';
import SessionFeature from '../sessionfeature/SessionFeature';
import UpdateSessionFeature from '../updatesessionfeature/UpdateSessionFeature';
import './EditSessionView2.css';

class EditSessionView2 extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    sessionId: "",
		    sessionName: "",
		    addedFeatures: [],
		    movedFeatures: [],
		    selectedFeatures: [],
		    selectedFeaturesMoved: [],
		    selectedFeature: "",
		    unselectedFeature: "",
		    graphics: [],
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.handleApproveAllClick = this.handleApproveAllClick.bind(this);
		this.handleApproveSelectedClick = this.handleApproveSelectedClick.bind(this);
		this.handleDenyAllClick = this.handleDenyAllClick.bind(this);
		this.handleDenySelectedClick = this.handleDenySelectedClick.bind(this);
		this.updateAddedFeatureAttribute = this.updateAddedFeatureAttribute.bind(this);
		this.updateMovedFeatureAttribute = this.updateMovedFeatureAttribute.bind(this);
		this.updateSelected = this.updateSelected.bind(this);
		this.updateSelectedMoved = this.updateSelectedMoved.bind(this);
		this.updateGraphics = this.updateGraphics.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    var id = this.props.match.params.id;
	    this.setState({sessionId: id});
	    axios.post('https://www.arutility.com/api/v4/features/readOneEditSession.php',{id: id},{headers: {Authorization: 'Bearer ' + value.jwt}})
		.then(res => {
	        	this.setState({addedFeatures: res.data.addedFeatures, movedFeatures: res.data.movedFeatures, sessionName: res.data.sessionId, loading: false});
	     }).catch(error => {
	       	if (error.response) {
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.message, modalHeader: "Error", show: true});
			} else if (error.request) {
			    // The request was made but no response was received
			    this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			} else {
			    // Something happened in setting up the request that triggered an Error
			    this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			}
	    });
  	}

  	handleApproveAllClick(){
	  	let value = this.context;
	  	this.setState({loading: true});
	  	if(this.state.addedFeatures.length > 0){
		  	axios.post('https://www.arutility.com/api/v4/features/update.php',{session_id: this.props.match.params.id, response_code: 1},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        //NOW CHECK IF ANY MOVED FEATURES NEED UPDATING
		        if(this.state.movedFeatures.length > 0){
					axios.post('https://www.arutility.com/api/v4/features/updateMoved.php',{session_id: this.props.match.params.id, response_code: 1},{headers: {Authorization: 'Bearer ' + value.jwt}})
			      	.then(res => {
			        	this.setState({loading: false, modalBody: "Features successfully approved", modalHeader: "Success", show: true });
			    	}).catch(error => {
			        	if (error.response) {
				        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
					    } else if (error.request) {
					      // The request was made but no response was received
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
					    } else {
					      // Something happened in setting up the request that triggered an Error
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
					    }
			    	});
				}else{
					this.setState({loading: false, modalBody: "Features successfully approved", modalHeader: "Success", show: true });
				}
		    }).catch(error => {
		        if (error.response) {
		        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
		    });
		}else if(this.state.movedFeatures.length > 0){
			axios.post('https://www.arutility.com/api/v4/features/updateMoved.php',{session_id: this.props.match.params.id, response_code: 1},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      	.then(res => {
	        	this.setState({loading: false, modalBody: "Features successfully approved", modalHeader: "Success", show: true });
	    	}).catch(error => {
	        	if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
	    	});
		}else{
			this.setState({loading: false, modalBody: "Sorry, no features are present for this edit session", modalHeader: "Error", show: true });
		}
  	}

  	handleApproveSelectedClick(){
  		let value = this.context;
  		this.setState({loading: true});
	  	if(this.state.selectedFeatures.length > 0){
		  	var featureInfo = [];
		  	var numSelectedFeatures = this.state.selectedFeatures.length;
		  	var numFeatures = this.state.addedFeatures.length;
		  	for(var i=0; i<numSelectedFeatures; i++){
		  		for(var i2=0; i2<numFeatures; i2++){
		  			if(this.state.addedFeatures[i2].feature_id == this.state.selectedFeatures[i]){
		  				var attributes = this.state.addedFeatures[i2].attributes;
		  				var attributeKeys = Object.keys(attributes[0]);
					    var attributeArray = [];
					    for(var i3=0; i3<attributeKeys.length;i3++){
					      var keyName = attributeKeys[i3];
					      var fieldValue = attributes[0][keyName];
					      var arrayItem = {"field":keyName,"value":fieldValue};
					      attributeArray.push(arrayItem);
					    }
		  				var featureItem = {"feature_id": this.state.selectedFeatures[i],"attributes":attributeArray};
		  				featureInfo.push(featureItem);
		  			}
		  		}
		  	}
		  	axios.post('https://www.arutility.com/api/v4/features/updateSelected.php',{session_id: this.props.match.params.id, response_code: 1, selected_features: this.state.selectedFeatures, feature_info: featureInfo},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        //NOW SEE IF THERE ARE MOVED FEATURES THAT WERE SELECTED
		        if(this.state.selectedFeaturesMoved.length > 0){
					var featureInfo = [];
				  	var numSelectedFeatures = this.state.selectedFeaturesMoved.length;
				  	var numFeatures = this.state.movedFeatures.length;
				  	for(var i=0; i<numSelectedFeatures; i++){
				  		for(var i2=0; i2<numFeatures; i2++){
				  			if(this.state.movedFeatures[i2].feature_id == this.state.selectedFeaturesMoved[i]){
				  				var attributes = this.state.movedFeatures[i2].attributes;
				  				var attributeKeys = Object.keys(attributes[0]);
							    var attributeArray = [];
							    for(var i3=0; i3<attributeKeys.length;i3++){
							      	var keyName = attributeKeys[i3];
							      	var fieldValue = attributes[0][keyName];
							      	var arrayItem = {"field":keyName,"value":fieldValue};
							      	attributeArray.push(arrayItem);
							    }
				  				var featureItem = {"feature_id": this.state.selectedFeaturesMoved[i],"attributes":attributeArray};
				  				featureInfo.push(featureItem);
				  			}
				  		}
				  	}
				  	this.setState({loading: true});
				  	axios.post('https://www.arutility.com/api/v4/features/updateSelectedMoved.php',{session_id: this.props.match.params.id, response_code: 1, selected_features: this.state.selectedFeaturesMoved, feature_info: featureInfo},{headers: {Authorization: 'Bearer ' + value.jwt}})
				    .then(res => {
				        this.setState({loading: false, modalBody: "Records successfully approved", modalHeader: "Success", show: true });
				    }).catch(error => {
				    	if (error.response) {
				        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
					    } else if (error.request) {
					      // The request was made but no response was received
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
					    } else {
					      // Something happened in setting up the request that triggered an Error
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
					    }
				    });
				}else{
					this.setState({loading: false, modalBody: "Records successfully approved", modalHeader: "Success", show: true });
				}
		    }).catch(error => {
		        if(error.response){
			      	if(error.response.status == 401){
			        	this.setState({modalBody: error.response.data.message, modalHeader: "Error", loading: false, show: true});
			      	}else{
			        	this.setState({modalBody: "Sorry, it appears something went wrong. Please try again.", modalHeader: "Error", show: true, loading: false});
			      	}
			    }else if(error){
			    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false})
			    }else{
			    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false})
			    }
		    });
		}else if(this.state.selectedFeaturesMoved.length > 0){
			var featureInfo = [];
		  	var numSelectedFeatures = this.state.selectedFeaturesMoved.length;
		  	var numFeatures = this.state.movedFeatures.length;
		  	for(var i=0; i<numSelectedFeatures; i++){
		  		for(var i2=0; i2<numFeatures; i2++){
		  			if(this.state.movedFeatures[i2].feature_id == this.state.selectedFeaturesMoved[i]){
		  				var attributes = this.state.movedFeatures[i2].attributes;
		  				var attributeKeys = Object.keys(attributes[0]);
					    var attributeArray = [];
					    for(var i3=0; i3<attributeKeys.length;i3++){
					      	var keyName = attributeKeys[i3];
					      	var fieldValue = attributes[0][keyName];
					      	var arrayItem = {"field":keyName,"value":fieldValue};
					      	attributeArray.push(arrayItem);
					    }
		  				var featureItem = {"feature_id": this.state.selectedFeaturesMoved[i],"attributes":attributeArray};
		  				featureInfo.push(featureItem);
		  			}
		  		}
		  	}
		  	this.setState({loading: true});
		  	axios.post('https://www.arutility.com/api/v4/features/updateSelectedMoved.php',{session_id: this.props.match.params.id, response_code: 1, selected_features: this.state.selectedFeaturesMoved, feature_info: featureInfo},{headers: {Authorization: 'Bearer ' + value.jwt}})
		    .then(res => {
		        this.setState({loading: false, modalBody: "Records successfully approved", modalHeader: "Success", show: true });
		    }).catch(error => {
		    	if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
		    });
		}else{
			this.setState({modalBody: "Sorry, no features were selected", modalHeader: "Error", loading: false, show: true});
		}
  	}

  	handleDenyAllClick(){
	  	let value = this.context;
	  	this.setState({loading: true});
	  	if(this.state.addedFeatures.length > 0){
		  	axios.post('https://www.arutility.com/api/v4/features/update.php',{session_id: this.props.match.params.id, response_code: 2},{headers: {Authorization: 'Bearer ' + value.jwt}})
		    .then(res => {
		        //NOW CHECK IF THERE ARE ANY MOVED FEATURES
		        if(this.state.movedFeatures.length > 0){
					axios.post('https://www.arutility.com/api/v4/features/updateMoved.php',{session_id: this.props.match.params.id, response_code: 2},{headers: {Authorization: 'Bearer ' + value.jwt}})
			      	.then(res => {
			        	this.setState({loading: false, modalBody: "Features successfully denied", modalHeader: "Success", show: true });
			    	}).catch(error => {
			        	if (error.response) {
				        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
					    } else if (error.request) {
					      // The request was made but no response was received
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
					    } else {
					      // Something happened in setting up the request that triggered an Error
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
					    }
			    	});
				}else{
					this.setState({loading: false, modalBody: "Features successfully denied", modalHeader: "Success", show: true });
				}
		    }).catch(error => {
		        if (error.response) {
		        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
		    });
		}else if(this.state.movedFeatures.length > 0){
			axios.post('https://www.arutility.com/api/v4/features/updateMoved.php',{session_id: this.props.match.params.id, response_code: 2},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      	.then(res => {
	        	this.setState({loading: false, modalBody: "Features successfully denied", modalHeader: "Success", show: true });
	    	}).catch(error => {
	        	if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
	    	});
		}else{
			this.setState({loading: false, modalBody: "Sorry, no features are present for this edit session", modalHeader: "Error", show: true });
		}
  	}

  	handleDenySelectedClick(){
  		let value = this.context;
  		this.setState({loading: true});
	  	if(this.state.selectedFeatures.length > 0){
		  	axios.post('https://www.arutility.com/api/v4/features/updateSelected.php',{session_id: this.props.match.params.id, response_code: 2, selected_features: this.state.selectedFeatures},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        if(this.state.selectedFeaturesMoved.length > 0){
				  	axios.post('https://www.arutility.com/api/v4/features/updateSelectedMoved.php',{session_id: this.props.match.params.id, response_code: 2, selected_features: this.state.selectedFeaturesMoved},{headers: {Authorization: 'Bearer ' + value.jwt}})
				    .then(res => {
				        this.setState({loading: false, modalBody: "Features successfully denied", modalHeader: "Success", show: true });
				    }).catch(error => {
				    	if (error.response) {
				        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
					    } else if (error.request) {
					      // The request was made but no response was received
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
					    } else {
					      // Something happened in setting up the request that triggered an Error
					        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
					    }
				    });
				}else{
					this.setState({loading: false, modalBody: "Features successfully denied", modalHeader: "Success", show: true });
				}
		    }).catch(error => {
		        if(error.response){
			      	if(error.response.status == 401){
			        	this.setState({modalBody: error.response.data.message, modalHeader: "Error", loading: false, show: true});
			      	}else{
			        	this.setState({modalBody: "Sorry, it appears something went wrong. Please try again.", modalHeader: "Error", show: true, loading: false});
			      	}
			    }else if(error){
			    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false})
			    }else{
			    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false})
			    }
		    });
		}else if(this.state.selectedFeaturesMoved.length > 0){
		  	axios.post('https://www.arutility.com/api/v4/features/updateSelectedMoved.php',{session_id: this.props.match.params.id, response_code: 2, selected_features: this.state.selectedFeaturesMoved},{headers: {Authorization: 'Bearer ' + value.jwt}})
		    .then(res => {
		        this.setState({loading: false, modalBody: "Features successfully denied", modalHeader: "Success", show: true });
		    }).catch(error => {
		    	if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
		    });	
		}else{
			this.setState({modalBody: "Sorry, no features were selected", modalHeader: "Error", loading: false, show: true});
		}
  	}

  	updateAddedFeatureAttribute(attributes){
	  	var featuresArray = this.state.addedFeatures;
	  	var numFeatures = featuresArray.length;
	  	for(var i=0; i<numFeatures; i++){
	  		if(attributes.featureId == featuresArray[i].feature_id){
	  			featuresArray[i].attributes = attributes.attributes;
	  		}
	  	}
	  	this.setState({addedFeatures: featuresArray});
  	}

  	updateMovedFeatureAttribute(attributes){
	  	var featuresArray = this.state.movedFeatures;
	  	var numFeatures = featuresArray.length;
	  	for(var i=0; i<numFeatures; i++){
	  		if(attributes.featureId == featuresArray[i].feature_id){
	  			featuresArray[i].attributes = attributes.attributes;
	  		}
	  	}
	  	this.setState({movedFeatures: featuresArray});
  	}

  	updateSelected(featureId){
	  	if(this.state.selectedFeatures.indexOf(featureId) < 0){
	  		var selectFeaturesArray = this.state.selectedFeatures;
	  		selectFeaturesArray.push(featureId);
	  		this.setState({selectedFeatures: selectFeaturesArray, selectedFeature: featureId, unselectedFeature: ""});
	  	}else{
	  		var selectFeaturesArray = this.state.selectedFeatures;
	  		var index = this.state.selectedFeatures.indexOf(featureId);
	  		selectFeaturesArray.splice(index,1);
	  		this.setState({selectedFeatures: selectFeaturesArray, selectedFeature: "", unselectedFeature: featureId});
	  	}
  	}

  	updateSelectedMoved(featureId){
	  	if(this.state.selectedFeaturesMoved.indexOf(featureId) < 0){
	  		var selectFeaturesArray = this.state.selectedFeaturesMoved;
	  		selectFeaturesArray.push(featureId);
	  		this.setState({selectedFeaturesMoved: selectFeaturesArray, selectedFeature: featureId, unselectedFeature: ""});
	  	}else{
	  		var selectFeaturesArray = this.state.selectedFeaturesMoved;
	  		var index = this.state.selectedFeaturesMoved.indexOf(featureId);
	  		selectFeaturesArray.splice(index,1);
	  		this.setState({selectedFeaturesMoved: selectFeaturesArray, selectedFeature: "", unselectedFeature: featureId});
	  	}
	  	console.log(this.state.selectedFeaturesMoved);
  	}

  	updateGraphics(graphics){
  		this.setState({graphics: graphics});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	const {show} = this.state;
	  	let value = this.context;
	    if (value.jwt == "") {
	      	return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("editsession:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
						      	<LoadingSpinner />
						    }
						    <Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      <div className="editSessionHeader">
					      	<div className="editSessionClose">
					      		<Link to="/session/edits">
						      		CLOSE
						      	</Link>
					      	</div>
					      	<div className="editSessionTitle">
					      		SESSION: {this.state.sessionName}
					      	</div>
					      </div>
					      <div className="editSessionResponses">
					      	<Button variant="success" onClick={this.handleApproveAllClick} >Approve All</Button>
					      	<Button variant="success" onClick={this.handleApproveSelectedClick} >Approve Selected</Button>
					      	<Button variant="danger" onClick={this.handleDenyAllClick} >Deny All</Button>
					      	<Button variant="danger" onClick={this.handleDenySelectedClick} >DenySelected</Button>
					      </div>
					      <div className="requestViewWrapper">
					 		<EsriUpdateSessionMap2 sessionId={this.props.match.params.id} updateGraphics={this.updateGraphics} selectedFeature={this.state.selectedFeature} unselectedFeature={this.state.unselectedFeature}/>
					      </div>
					      <div className="addedFeaturesWrapper">
					      	ADDED ASSETS:
					      	{this.state.addedFeatures.length > 0 ?
					      		(<div>
							      	{this.state.addedFeatures.map(feature => {
							      		var startLng = "";
							      		var startLat = "";
							      		var endLng = "";
							      		var endLat = "";
							      		if(feature.feature_type == "line"){
								            var featurePaths = feature.paths[0][0];
								            startLng = featurePaths[0][0];
								            startLat = featurePaths[0][1];
								            endLng = featurePaths[1][0];
								            endLat = featurePaths[1][1];
								        }
								        if(feature.feature_type == "point"){
								        	var featurePoints = feature.points[0];
								            startLng = featurePoints.x;
								            startLat = featurePoints.y;
								        }
							      		return(
							      			<SessionFeature key={feature.feature_id} featureStatus={feature.status} featureId={feature.feature_id} featureType={feature.feature_layer_name} featureStartLng={startLng} featureStartLat={startLat} featureEndLng={endLng} featureEndLat={endLat} featureAttributes={feature.attributes} updateAttribute={this.updateAddedFeatureAttribute} updateSelected={this.updateSelected}/>
							      		);
						       		})}
					       		</div>)
					       		:
					       		(
					       		<div className="noAssets">
				      				There were no new assets added as part of this edit session
				      			</div>
				      			)
					       	}
					      </div>
					      <div className="addedFeaturesWrapper">
					      	UPDATED ASSETS:
					      	{this.state.movedFeatures.length > 0? 
						      	(<div>
							      	{this.state.movedFeatures.map(feature => {
							      		var startLng = "";
							      		var startLat = "";
							      		var endLng = "";
							      		var endLat = "";
							      		if(feature.feature_type == "line"){
								            var featurePaths = feature.paths[0][0];
								            startLng = featurePaths[0][0];
								            startLat = featurePaths[0][1];
								            endLng = featurePaths[1][0];
								            endLat = featurePaths[1][1];
								        }
								        if(feature.feature_type == "point"){
								        	var featurePoints = feature.points[0];
								            startLng = featurePoints.x;
								            startLat = featurePoints.y;
								        }
							      		return(
							      			<UpdateSessionFeature key={feature.feature_id} featureStatus={feature.status} featureId={feature.feature_id} featureType={feature.feature_layer_name} featureStartLng={startLng} featureStartLat={startLat} featureEndLng={endLng} featureEndLat={endLat} featureAttributes={feature.attributes} originalAttributes={feature.originalAttributes} updateAttribute={this.updateMovedFeatureAttribute} updateSelected={this.updateSelectedMoved}/>
							      		);
						       		})}
						       	</div>)
						       	:
						       	(
					       		<div className="noAssets">
				      				There were no moved assets as part of this edit session
				      			</div>
				      			)
						     }
					      </div>
					    </div>
				    </div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}
		}
  	}
}

export default EditSessionView2;