import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import { Modal, Button } from 'react-bootstrap';
import './AdminLogin.css';

class AdminLogin extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      show: false,
      modalBody: "",
      modalHeader: "",
      loading: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {

  }

  handleSubmit(){
    this.setState({loading: true});
    if(this.state.username != "" && this.state.password != ""){
      axios.post('https://www.arutility.com/api/v4/users/adminlogin.php',{username: this.state.username,password:this.state.password})
        .then(res => {
            this.setState({loading: false});
            window.location.href=res.data.url;
        }).catch(error => {
          if (error.response) {
              this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
          } else if (error.request) {
            // The request was made but no response was received
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
          } else {
            // Something happened in setting up the request that triggered an Error
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
          }
        });
    }else{
      this.setState({loading: false, modalBody: "Sorry, it appears you are missing required information", modalHeader: "Error", show: true});
    }
  }

  handleChange(e) {
    this.setState({[e.target.id]: e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  render(){
    const{show} = this.state;
    return (
      <div className="mainWrapper">
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
      	<div className="pageTitle">
        LOGIN
        </div>
        <div className="loginFormWrapper">
          <div className="loginAuth0">
            <input type="text" id="username" name="username" placeholder="Email..." required="" value={this.state.username} onChange={this.handleChange}/>
            <br/>
            <input type="password" id="password" name="password" placeholder="Password..." required="" value={this.state.password} onChange={this.handleChange}/>
            <br/>
            <button type="submit" value="Submit" onClick={this.handleSubmit}>LOGIN</button>
            <br/>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default AdminLogin;