import React from 'react';
import axios from 'axios';
import { loadModules } from 'esri-loader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import './EsriEditSessionMap.css';

class EsriEditSessionMap extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			sessionId: "",
			sessionFeatures: [],
			loading: true,
			modalBody: "",
			modalHeader: "",
			show: false
		}
		this.mapRef = React.createRef();
		this.updateSelectedGraphic = this.updateSelectedGraphic.bind(this);
		this.updateUnselectedGraphic = this.updateUnselectedGraphic.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    this.setState({sessionId: this.props.sessionId});
	    loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer]) => {
		    this.map = new ArcGISMap({
		        basemap: 'streets'
		    });

		    this.view = new MapView({
		        container: this.mapRef.current,
		        map: this.map,
		        center: [-98.579532, 39.828577],
		        zoom: 5
		    });

		    this.toggle = new BasemapToggle({
				view: this.view,  // view with map that uses "streets" basemap
				nextBasemap: "satellite"  // Allows for toggling to "hybrid" basemap
		    });

		    this.view.ui.add(this.toggle, "top-right");

		    axios.post('https://www.arutility.com/api/v4/features/readOne.php',{id: this.props.sessionId},{headers: {Authorization: 'Bearer ' + value.jwt}})
			.then(res => {
		        this.setState({sessionFeatures: res.data.features});
		        this.layer = new GraphicsLayer({
				  	graphics: []
				});
				this.graphics = [];
		        {res.data.features.map(feature => {
		          	if(feature.attributes != ""){
		                var attributes = feature.attributes;
		                var attributeKeys = Object.keys(attributes[0]);
		                var attributeText = "";
		                for(var i2=0; i2<attributeKeys.length;i2++){
		                    var keyName = attributeKeys[i2];
		                    var value = attributes[0][keyName];
		                    if(i2==0){
		                        attributeText = attributeText + "<b>" + keyName + "</b>: " + value;
		                    }else{
		                        attributeText = attributeText + "</br>" + "<b>" + keyName + "</b>: " + value;
		                    }
		                }
		            }else{
		                attributeText = "";
		            }
		          	if(feature.feature_type == "line"){
			            var featurePaths = feature.paths[0][0];
			            var numPaths = featurePaths.length;
			            var polylineCoords = [];
			            for(var i=0; i<numPaths; i++){
			                var polylineCoord = [];
			                polylineCoord.push(featurePaths[0]);
			                polylineCoord.push(featurePaths[1]);
			                polylineCoords.push(polylineCoord);
			            }

			            this.polyline = {
							type: "polyline", // autocasts as new Polyline()
							paths: polylineCoords
						};

						this.lineSymbol = {
						    type: "simple-line", // autocasts as new SimpleLineSymbol()
						    width: 2
						};

			            if(feature.feature_service_type == "water"){
			                this.lineSymbol.color = [0,0,255,1];
			            }
			            if(feature.feature_service_type == "electric"){
			                this.lineSymbol.color = [255,0,0,1];
			            }
			            if(feature.feature_service_type == "gas"){
			                this.lineSymbol.color = [255,255,0,1];
			            }
			            if(feature.feature_service_type == "storm"){
			                this.lineSymbol.color = [0,255,0,1];
			            }
			            if(feature.feature_service_type == "fiber"){
			                this.lineSymbol.color = [128,0,128,1];
			            }
			            if(feature.feature_service_type == "telecom"){
			                this.lineSymbol.color = [255,165,0,1];
			            }
			            if(feature.feature_service_type == "sanitary"){
			            	this.lineSymbol.color = [165,42,42,1];
			            }
			            if(feature.feature_service_type == "steam"){
			            	this.lineSymbol.color = [0,0,255,1];
			            }
			            this.lineAtt = {"assetId":feature.feature_id,"selected":false,"originalColor":this.lineSymbol.color};

					  	this.polylineGraphic = new Graphic({
					    	geometry: this.polyline, // Add the geometry created in step 4
					    	symbol: this.lineSymbol, // Add the symbol created in step 5
					    	attributes: this.lineAtt, // Add the attributes created in step 6
					    	popupTemplate: {
						      title: feature.feature_layer_name + " " + feature.feature_id,
						      content: attributeText
						    }
					  	});
					  	this.graphics.push(this.polylineGraphic);
					  	this.layer.graphics.add(this.polylineGraphic);
			        }else if(feature.feature_type == "point"){
			            var featurePoints = feature.points[0];
			            var circleCoords = [];
			            var circleCoord = [];
			            circleCoord.push(featurePoints.x);
			            circleCoord.push(featurePoints.y);
			            circleCoords.push(circleCoord);
			            this.point = {
							type: "point", // autocasts as new Polyline()
							longitude: featurePoints.x,
							latitude: featurePoints.y
						};
						this.pointSymbol = {
						    type: "simple-marker", // autocasts as new SimpleLineSymbol()
						    radius: 0.75
						};
			            if(feature.feature_service_type == "water"){
			                this.pointSymbol.color = [0,0,255,1];
			            }
			            if(feature.feature_service_type == "electric"){
			                this.pointSymbol.color = [255,0,0,1];
			            }
			            if(feature.feature_service_type == "gas"){
			                this.pointSymbol.color = [255,255,0,1];
			            }
			            if(feature.feature_service_type == "storm"){
			                this.pointSymbol.color = [0,255,0,1];
			            }
			            if(feature.feature_service_type == "fiber"){
			                this.pointSymbol.color = [128,0,128,1];
			            }
			            if(feature.feature_service_type == "telecom"){
			                this.pointSymbol.color = [255,165,0,1];
			            }
			            if(feature.feature_service_type == "sanitary"){
			            	this.pointSymbol.color = [165,42,42,1];
			            }
			            if(feature.feature_service_type == "steam"){
			            	this.pointSymbol.color = [0,0,255,1];
			            }
			            this.pointAtt = {"assetId":feature.feature_id,"selected":false,"originalColor":this.pointSymbol.color};
			            this.pointGraphic = new Graphic({
						  	geometry: this.point,
						  	symbol: this.pointSymbol,
						  	attributes: this.pointAtt, // Add the attributes created in step 6
					    	popupTemplate: {
						      title: feature.feature_layer_name + " " + feature.feature_id,
						      content: attributeText
						    }
						});
			            this.graphics.push(this.pointGraphic);
					  	this.layer.graphics.add(this.pointGraphic);
			        }
		        })}
				this.setState({loading: false});
				this.map.add(this.layer);
				this.view.goTo(this.graphics);
				this.props.updateGraphics(this.graphics);
		    }).catch(error => {
	        	if (error.response) {
		        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			    }
	      	});
	    });
  	}

  	updateSelectedGraphic(featureId){
	  	loadModules(['esri/Color','esri/Graphic'], { css: true })
	    .then(([Color,Graphic]) => {
		  	var numGraphics = this.layer.graphics.length;
		  	for(var i=0; i<numGraphics; i++){
		  		if(this.layer.graphics.items[i].attributes.assetId == featureId){
		  			if(this.layer.graphics.items[i].geometry.type == "polyline"){
		  				this.lineSymbol = {
						    type: "simple-line", // autocasts as new SimpleLineSymbol()
						    width: 2
						};
			  			this.lineSymbol.color = [255,0,0,1];
			  			this.layer.graphics.items[i].symbol = this.lineSymbol;
			  		}else if(this.layer.graphics.items[i].geometry.type == "point"){
			  			this.pointSymbol = {
						    type: "simple-marker", // autocasts as new SimpleLineSymbol()
						    radius: 0.75
						};
						this.pointSymbol.color = [255,0,0,1];
						this.layer.graphics.items[i].symbol = this.pointSymbol;
			  		}
		  		}
		  	}
		})
  	}

  	updateUnselectedGraphic(featureId){
	  	loadModules(['esri/Color','esri/Graphic'], { css: true })
	    .then(([Color,Graphic]) => {
		  	var numGraphics = this.layer.graphics.length;
		  	for(var i=0; i<numGraphics; i++){
		  		if(this.layer.graphics.items[i].attributes.assetId == featureId){
		  			if(this.layer.graphics.items[i].geometry.type == "polyline"){
		  				this.lineSymbol = {
						    type: "simple-line", // autocasts as new SimpleLineSymbol()
						    width: 2
						};
			  			this.lineSymbol.color = this.layer.graphics.items[i].attributes.originalColor;
			  			this.layer.graphics.items[i].symbol = this.lineSymbol;
			  		}else if(this.layer.graphics.items[i].geometry.type == "point"){
			  			this.pointSymbol = {
						    type: "simple-marker", // autocasts as new SimpleLineSymbol()
						    radius: 0.75
						};
						this.pointSymbol.color = this.layer.graphics.items[i].attributes.originalColor;
						this.layer.graphics.items[i].symbol = this.pointSymbol;
			  		}
		  		}
		  	}
		})
  	}

  	componentWillUnmount() {
    	if (this.view) {
     		// destroy the map view
      		this.view.container = null;
    	}
  	}

  	render(){
	  	if(this.props.selectedFeature != ""){
	  		this.updateSelectedGraphic(this.props.selectedFeature);
	  	}
	  	if(this.props.unselectedFeature != ""){
	  		this.updateUnselectedGraphic(this.props.unselectedFeature);
	  	}
	  	return(
	  		<div className="editSessionMapWrapper">
	  			{this.state.loading &&
		  			<LoadingSpinner />
		  		}
			  	<div className="map" id="map" ref={this.mapRef}>
			      
			  	</div>
			</div>
	  	);
  	}
}

export default EsriEditSessionMap;