import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './Home.css';

class Home extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        newsArticles: [],
        newsImage: "",
        newsTitle: "",
        newsBody: "",
        newsDate: ""
    }
  }
  
  componentDidMount(){
    this.setState({loading: true});
    axios.get('https://www.arutility.com/api/v4/news/read.php')
    .then(res => {
      this.setState({newsArticles: res.data, loading: false});
      //console.log(res.data);
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="mainVideoWrapper">
          <div className="heroContent">
            <font size="+4"><b>ARUTILITY</b></font>
            <br/>
            <br/>
            <font size="+2"><i>A new reality for construction</i></font>
            <br/>
            <font size="+1">See how you can increase safety and efficiency and reduce damages to underground assets</font>
            <br/>
            <br/>
            <Link to="/contact">
              <button className="heroButton" name="freeTrial">Request a FREE trial</button>
            </Link>
            <br/>
            <br/>
            <Link to="/howitworks">
              <button className="heroButton" name="freeTrial">Explore more about ARUtility</button>
            </Link>
          </div>
          <video preload="auto" id="video" autoPlay={true} muted={true} loop={true}>
            <source src="/images/ARUtilityHome1.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="mainPageExplanation">
          <font size="6"><b>WHAT IS ARUTILITY?</b></font>
          <p>
          ARUtility is the leading augmented reality utility locating, asset management and BIM visualization application that aims to reduce underground damages, make excavation more cost effective, aid Engineers in identifying conflicts before they happen, and assist utilities with asset management. Now utilities can see where their assets are located in the ground in real time
          </p>
          <Link to="/howitworks">
            <button className="learnMoreBtn">LEARN MORE</button>
          </Link>
        </div>
        <div className="mainPageSteps">
          <div className="mainPageStep1">
            <b><font color="#c62828">DECREASE DAMAGES</font></b>
            <br/><br/>
            See where utilities are located and avoid damages before they happen. Underground damages cost direct stakeholders an estimated $1.5billion in 2016. Not only are damages an expensive problem, but they can also be fatal. ARUtility can help reduce damages to underground utilities.
          </div>
          <div className="mainPageStep">
            <b><font color="#c62828">ENGINEERING DESIGN</font></b>
            <br/><br/>
            See proposed utilities in the field before construction starts. Identify potential conflicts in the field that may have been missed. Save time and money by identifying problems before they become a conflict during construction.
          </div>
          <div className="mainPageStep3">
            <b><font color="#c62828">ASSET MANAGEMENT</font></b>
            <br/><br/>
            Update your assets in the field. Simply click on a utility to bring up the asset information. A direct link to ESRI or other sources allows you to see utility attributes at any time. Mistakes can be corrected by field crews immediately after being identified.
          </div>
        </div>
        <div className="mainPageNewsWrapper">
          ARUTILITY IN THE NEWS
          <div className="newsClipWrapper">
            {this.state.newsArticles.length > 0 &&
              <Link to={"news/article/" + this.state.newsArticles[0].id}>
                <div>
                  <div className="newsImage">
                    <img src={this.state.newsArticles[0].image} alt="News Image"/>
                  </div>
                  <div className="newsContent">
                    <div className="newsTitle">
                      {this.state.newsArticles[0].title}
                    </div>
                    <div className="newsDate">
                      {this.state.newsArticles[0].date}
                    </div>
                    <div className="newsText" dangerouslySetInnerHTML={{__html:this.state.newsArticles[0].body.substring(0,400) + "..."}}>
                      
                    </div>
                  </div>
                </div>
              </Link>
            }
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Home;