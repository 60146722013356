import React, { Component} from 'react';
import { Context } from "../configcontext/ConfigContext.js";
import axios from 'axios';
import {Table,Button, Modal} from 'react-bootstrap'
import './FeatureFields.css'
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';

class FeatureFields extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            feature_layer_types: [],
            feature_field_types: [],
            feature_fields: [],

            edit: false,
            modalBody: "",
            modalHeader: "",
            show: false,
            loading: true,

            new_feature_field: {
                id: "",
                feature_layer_type_id: "",
                feature_field_type_id: "",
            }

        }
    }

    componentDidMount() {
        // read feature service types from database
        let value = this.context;
        this.readFeatureLayerTypes(value.jwt);
        this.readFeatureFieldTypes(value.jwt);
        this.readFeatureFields(value.jwt);
        this.setState({loading:false})
    }

    readFeatureLayerTypes = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/testing/v5/accounts/admin/readFeatureLayerTypes.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let flt_array = await res.data.FeatureLayerTypes;
            this.setState({ feature_layer_types: [...flt_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature layers. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }
    readFeatureFieldTypes = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/testing/v5/accounts/admin/readFeatureFieldTypes.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let fft_array = await res.data.FeatureFieldTypes;
            this.setState({ feature_field_types: [...fft_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }
    readFeatureFields = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/testing/v5/accounts/admin/readFeatureFields.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let ffs_array = await res.data.FeatureFields;
            ffs_array.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);
            this.setState({ feature_fields: [...ffs_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }


    handleSubmit = (event) => {
        // submit new feature type
        let new_feature_field = this.state.new_feature_field;
        let value = this.context;
        //check for duplicate
        for(let i = 0;i< this.state.feature_fields.length;i++){
            if(this.state.feature_fields[i].feature_field_type_id === new_feature_field.feature_field_type_id &&
                this.state.feature_fields[i].feature_layer_type_id === new_feature_field.feature_layer_type_id){
                event.preventDefault();
                alert("You already have this feature field mapped up!")
                return 

            }
        }
        this.setState({loading:true})
        if(this.state.edit && new_feature_field.id !== ""){
            axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/editFeatureField.php', { post: new_feature_field }, { headers: { Authorization: 'Bearer ' + value.jwt } })
        	.then(res => {
                this.setState({ loading: false, modalBody: "Feature Field successfully updated", modalHeader: "Success", show: true });
                this.readFeatureFields(value.jwt);

        	}).catch(error => {
        		if (error.response) {
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
        		} else if (error.request) {
        			// The request was made but no response was received
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
        		} else {
        			// Something happened in setting up the request that triggered an Error
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
        		}
            });
            this.setState({ edit: false })
        }else{
        axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/addFeatureField.php', { post: new_feature_field }, { headers: { Authorization: 'Bearer ' + value.jwt } })
        	.then(res => {
                this.setState({ loading: false, modalBody: "Feature Field successfully added", modalHeader: "Success", show: true });
                this.readFeatureFields(value.jwt);

        	}).catch(error => {
        		if (error.response) {
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
        		} else if (error.request) {
        			// The request was made but no response was received
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
        		} else {
        			// Something happened in setting up the request that triggered an Error
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
        		}
            });
        }
        //console.log(new_feature_field)
        
        this.setState({
            new_feature_field: {
                id: "",
                feature_layer_type_id: "",
                feature_field_type_id: "",
            }
        })
        event.preventDefault();
    }

    handleDelete = (event, id) => {
        // delete feature layer type
        let value = this.context;
        this.setState({loading:true})
        
        if (id) {
            axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/deleteFeatureField.php', { deleteID: id }, { headers: { Authorization: 'Bearer ' + value.jwt } })
                .then(res => {
                    this.setState({ loading: false, modalBody: "Feature Field successfully deleted.", modalHeader: "Success", show: true });
                    this.readFeatureFields(value.jwt);
                }).catch(error => {
                    if (error.response) {
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
                    }
                });
        }
        event.preventDefault();
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState((prevState) => ({
            new_feature_field: {                   // object that we want to update
                ...prevState.new_feature_field,    // keep all other key-value pairs
                [name]: value      // update the value of specific key
            }
        }))
    }

    handleData = () => {
        this.setState({ edit: false,loading:false });
        this.setState({
            new_feature_field: {
                id: "",
                feature_layer_type_id: "",
                feature_field_type_id: "",
            }
        })
    }
    handleEdit = (e,feature_field)=>{
        this.setState({
            new_feature_field: {
                id: feature_field.id,
                feature_layer_type_id: feature_field.feature_layer_type_id,
                feature_field_type_id: feature_field.feature_field_type_id
            }
        }
        )
        this.setState({ edit: true });
    }
    closeModal=()=>{
    	this.setState({show: false});
  	}
    render() {
        let value = this.context;
        const { loading, show } = this.state;
        //console.log(this.state)
        return (
            
                <div className="moduleDetailWrapper">
                    {this.state.loading &&
							<LoadingSpinner />
							}
							<Modal show={show} onHide={()=>this.closeModal()}>
								<Modal.Header closeButton>
									<Modal.Title>{this.state.modalHeader}</Modal.Title>
								</Modal.Header>
								<Modal.Body>{this.state.modalBody}</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={()=>this.closeModal()}>
										Close
					              </Button>
								</Modal.Footer>
							</Modal>
                            <div className="adminPageTitle">
                                MANAGE FEATURE FIELDS
                            </div>
                            <div className={`${this.state.edit ? "col-75" : "col-100" }`} >
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ID</th>
                                        <th>Feature Layer Type</th>
                                        <th>Feature Field Type</th>
                                        <th>Editing</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.state.feature_fields.map((feature_field,index)=>{
                                        return( 
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{feature_field.id}</td>
                                                    <td>{feature_field.feature_layer_label}</td>
                                                    <td>{feature_field.feature_field_name}</td>
                                                    <td><Button variant="secondary" onClick={(e)=>this.handleEdit(e,feature_field)}>Edit</Button>{" "}
                                                    <Button variant="danger" onClick={(e)=>{if(window.confirm('Are you sure you want to delete?')){
                                                                                                    this.handleDelete(e,feature_field.id) }
                                                                                            else{
                                                                                               e.preventDefault()
                                                                                            }
                                                    }}>Delete</Button></td>
                                                </tr>   
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            <button  className = {"cancel-btn"} onClick={(e)=>(this.setState({edit:true}))}> Add New Feature Field Type</button>
                        </div>
                        {this.state.edit ? <div className="col-25">
                            <form onSubmit={this.handleSubmit}>
                                <div>
                                    <label >
                                        Feature Layer Type:
                                    </label>
                                    <select style={{ float: "right" }} required type="option" name="feature_layer_type_id" onChange={(event) => this.handleChange(event)} value={this.state.new_feature_field.feature_layer_type_id} >
                                        <option></option>
                                        {this.state.feature_layer_types.map((field) =>
                                            <option key={field.name} value={field.id}>{field.label}</option>)}
                                    </select>
                                </div>
                                <div>
                                    <label>
                                        Feature Field Type:
                                    </label>
                                    <select style={{ float: "right" }} required type="option" name="feature_field_type_id" onChange={(event) => this.handleChange(event)} value={this.state.new_feature_field.feature_field_type_id}>
                                        <option></option>
                                        {this.state.feature_field_types.map((field) =>
                                            <option key={field.name} value={field.id}>{field.name}</option>)}
                                        
                                    </select>
                                </div>
                                <div>
                                    <input type="submit" value="Submit" />
                                </div>
                                </form>
                                <div>
                                    <button className = {"cancel-btn"} onClick={this.handleData}>Cancel</button>
                                </div>
                           
                        </div> : null}
                </div>
            
        )
    }
}
export default FeatureFields