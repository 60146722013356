import React from 'react';
import axios from 'axios';
import {NavLink, Route, Link} from 'react-router-dom';
import {Redirect} from 'react-router';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import User from "../user/User";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminConsole.css';
import AdminUserCount from '../adminusercount/AdminUserCount';
import AdminAccountCount from '../adminaccountcount/AdminAccountCount';
import AdminAccountModule from '../adminaccountmodule/AdminAccountModule';
import AdminUserModule from '../adminusermodule/AdminUserModule';
import AdminNewsModule from '../adminnewsmodule/AdminNewsModule';
import AdminCreateAccount from '../admincreateaccount/AdminCreateAccount';
import AdminViewAccounts from '../adminviewaccounts/AdminViewAccounts';
import AdminViewUsers from '../adminviewusers/AdminViewUsers';
import AdminCreateUser from '../admincreateuser/AdminCreateUser';
import AdminCreateNews from '../admincreatenews/AdminCreateNews';
import AdminFeaturesModule from '../adminfeaturesmodule/AdminFeaturesModule.js'
import { ResponsiveLine } from '@nivo/line';
import FeatureServiceTypes from '../featureservicetypes/FeatureServiceTypes';
import FeatureFieldTypes from '../featurefieldtypes/FeatureFieldTypes';
import FeatureLayerTypes from '../featurelayertypes/FeatureLayerTypes';
import FeatureFields from '../featurefields/FeatureFields';

class AdminConsole extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    modalBody: "",
		    modalHeader: "",
		    managingUsers: false,
		    managingAccounts: false,
			managingNews: false,
			managingFeatures:false,
		    showUserData: false,
		    showAccountData: false,
		    createUser: false,
		    viewUsers: false,
		    createAccount: false,
		    viewAccounts: true,
			createNews: false,
			viewFeatureServiceTypes:false,
			viewFeatureLayerTypes:false,
			viewFeatureFieldTypes:false,
			viewFeatureFields:false,
		    menuOptions: ["managingAccounts","managingUsers","showUserData","showAccountData","managingNews","managingFeatures"],
		    actions: ["createUser","viewUsers","createAccount","viewAccounts", "createNews","viewFeatureServiceTypes","viewFeatureLayerTypes","viewFeatureFieldTypes","viewFeatureFields"],
		    show: false,
		    data: [{"id": "Users","data":[{"x":"January","y":3},{"x":"February","y":5},{"x":"March","y":7},{"x":"April","y":10}]},{"id": "Accounts","data":[{"x":"January","y":1},{"x":"February","y":2},{"x":"March","y":2},{"x":"April","y":4}]}]
		}
		this.updateModules = this.updateModules.bind(this);
		this.updateModule = this.updateModule.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	updateModules(moduleBoolText){
		if(this.state[moduleBoolText] == true){
			this.setState({[moduleBoolText]:false});
			var numOptions = this.state.menuOptions.length;
			var options = this.state.menuOptions;
			for(var i=0; i<numOptions; i++){
				if(options[i] != moduleBoolText){
					this.setState({[options[i]]:false});
				}
			}
		}else{
			this.setState({[moduleBoolText]:true});
			var numOptions = this.state.menuOptions.length;
			var options = this.state.menuOptions;
			for(var i=0; i<numOptions; i++){
				if(options[i] != moduleBoolText){
					this.setState({[options[i]]:false});
				}
			}
		}
	}

	updateModule(e){
		if(this.state[e.target.id] == false){
			this.setState({[e.target.id]: true});
			var numActions = this.state.actions.length;
			var actions = this.state.actions;
			for(var i=0; i<numActions; i++){
				if(actions[i] != e.target.id){
					this.setState({[actions[i]]:false});
				}
			}
		}else{
		}
	}

  	componentDidMount(){
  		this.setState({loading: false});
  	}

  	showModal(){
   		this.setState({show: true});
  	} 

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {loading, show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
	    	if(value.userPermissions.includes("user:super")){
			    return ( 
			   		<div className="mainWrapper">
			    		<div className="mainWindow">
				   			{this.state.loading &&
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              	<Button variant="secondary" onClick={this.closeModal}>
					                	Close
					              	</Button>
					            </Modal.Footer>
					        </Modal>
					        <div className="mainModuleWrapper">
					      		<AdminUserCount updateModules={this.updateModules} moduleBoolText="showUserData" selected={this.state.showUserData}/>
					      		<AdminAccountCount updateModules={this.updateModules} moduleBoolText="showAccountData" selected={this.state.showAccountData}/>
					      		<AdminUserModule updateModules={this.updateModules} moduleBoolText="managingUsers" imgURL="/images/profileDark.png" footer="Manage Users" selected={this.state.managingUsers}/>
					      		<AdminAccountModule updateModules={this.updateModules} moduleBoolText="managingAccounts" imgURL="/images/account.png" footer="Manage Accounts" selected={this.state.managingAccounts}/>
					    		<AdminNewsModule updateModules={this.updateModules} moduleBoolText="managingNews" imgURL="/images/account.png" footer="Manage News" selected={this.state.managingNews}/>
								<AdminFeaturesModule updateModules={this.updateModules} moduleBoolText="managingFeatures" imgURL="/images/layers.png" footer="Manage Features" selected={this.state.managingFeatures}/>

					    	</div>
					    	{(this.state.managingAccounts == false && this.state.managingUsers == false && this.state.showUserData == false && this.state.showAccountData == false && this.state.managingNews == false && this.state.managingFeatures== false) &&
					    		<div className="chartWrapper">
							    	<ResponsiveLine
								        data={this.state.data}
								        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
								        xScale={{ type: 'point' }}
								        yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
								        axisTop={null}
								        axisRight={null}
								        axisBottom={{
								            orient: 'bottom',
								            tickSize: 5,
								            tickPadding: 5,
								            tickRotation: 0,
								            legend: 'Month',
								            legendOffset: 36,
								            legendPosition: 'middle'
								        }}
								        axisLeft={{
								            orient: 'left',
								            tickSize: 5,
								            tickPadding: 5,
								            tickRotation: 0,
								            legend: 'Count',
								            legendOffset: -40,
								            legendPosition: 'middle'
								        }}
								        colors={{ scheme: 'set1' }}
								        lineWidth={4}
		        						pointSize={11}
								        pointColor={{ theme: 'background' }}
								        pointBorderWidth={2}
								        pointBorderColor={{ from: 'serieColor' }}
								        pointLabel="y"
								        pointLabelYOffset={-12}
								        enableArea={true}
								        areaBaselineValue={0}
								        useMesh={true}
								        legends={[
								            {
								                anchor: 'bottom-right',
								                direction: 'column',
								                justify: false,
								                translateX: 100,
								                translateY: 0,
								                itemsSpacing: 0,
								                itemDirection: 'left-to-right',
								                itemWidth: 80,
								                itemHeight: 20,
								                itemOpacity: 0.75,
								                symbolSize: 12,
								                symbolShape: 'circle',
								                symbolBorderColor: 'rgba(0, 0, 0, .5)',
								                effects: [
								                    {
								                        on: 'hover',
								                        style: {
								                            itemBackground: 'rgba(0, 0, 0, .03)',
								                            itemOpacity: 1
								                        }
								                    }
								                ]
								            }
								        ]}
								    />
								</div>
					    	}
					    	{(this.state.managingUsers || this.state.managingAccounts || this.state.managingNews || this.state.managingFeatures) &&
					    		<div>
						   			<div className="moduleMenu">
						   				{this.state.managingUsers &&
						   					<div>
						   						<Button variant="primary" id="viewUsers" className="addUserBtn" onClick={this.updateModule}>View All Users</Button>
						   						<Button variant="primary" id="createUser" className="addUserBtn" onClick={this.updateModule}>+ Add User</Button>
						   					</div>
						   				}
						   				{this.state.managingAccounts &&
						   					<div>
						   						<Button variant="primary" id="viewAccounts" className="addUserBtn" onClick={this.updateModule}>View All Accounts</Button>
						   						<Button variant="primary" id="createAccount" className="addUserBtn" onClick={this.updateModule}>+ Add Account</Button>
						   					</div>
						   				}
						   				{this.state.managingNews &&
						   					<div>
						   						<Button variant="primary" id="viewNews" className="addUserBtn" onClick={this.updateModule}>View All News</Button>
						   						<Button variant="primary" id="createNews" className="addUserBtn" onClick={this.updateModule}>+ Add News</Button>
						   					</div>
						   				}
										{this.state.managingFeatures &&
						   					<div>
						   						<Button variant="primary" id="viewFeatureServiceTypes" className="addUserBtn" onClick={this.updateModule}>Manage Features Service Types</Button>
												<Button variant="primary" id="viewFeatureLayerTypes" className="addUserBtn" onClick={this.updateModule}>Manage Features Layer Types</Button>
						   						<Button variant="primary" id="viewFeatureFieldTypes" className="addUserBtn" onClick={this.updateModule}>Manage Features Field Types</Button>
						   						<Button variant="primary" id="viewFeatureFields" className="addUserBtn" onClick={this.updateModule}>Manage Features Fields</Button>
						   					</div>
						   				}
						   			</div>
						   			<div className="moduleSelectionWrapper">
							   			{this.state.managingAccounts &&
							   				<div>
								   				{this.state.createAccount &&
										   			<AdminCreateAccount />
										   		}
										   		{this.state.viewAccounts &&
										   			<AdminViewAccounts />
										   		}
									   		</div>
							   			}
							   			{this.state.managingUsers &&
							   				<div>
							   					{this.state.createUser &&
										   			<AdminCreateUser />
										   		}
										   		{this.state.viewUsers &&
										   			<AdminViewUsers />
										   		}
							   				</div>
							   			}
							   			{this.state.managingNews &&
							   				<div>
							   					{this.state.createNews &&
										   			<AdminCreateNews />
										   		}
							   				</div>
							   			}
										{this.state.managingFeatures &&
											<div>
												{
													this.state.viewFeatureServiceTypes && 
													<FeatureServiceTypes/>
												}
												{
													this.state.viewFeatureLayerTypes &&
													<FeatureLayerTypes/>

												}
												{
													this.state.viewFeatureFieldTypes &&
													<FeatureFieldTypes />
												}
												{
													this.state.viewFeatureFields &&
													<FeatureFields/>
												}
											</div>

										}
							   		</div>
						   		</div>
					   		}
							    
					    </div>
			      	</div>
			    );
			}else{
		       return(
		         	<Route>
		            <Redirect to="/projects" />
		          	</Route>
		       );
		    }
		}	
  	}
}

export default AdminConsole;