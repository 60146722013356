import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Modal, Button,Tooltip, OverlayTrigger} from 'react-bootstrap';
import './License.css';

class License extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        expireDate: "",
        newExpireDate: "",
        licenseId: "",
        status: "",
        autoRenew: false,
        showDeletePrompt: false,
        showRenewPrompt: false,
        updating: false,
        show: false,
        modalBody: "",
        modalHeader: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.showUpdate = this.showUpdate.bind(this);
    this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this);
    this.showRenewConfirmation = this.showRenewConfirmation.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.renewSubscription = this.renewSubscription.bind(this);
    this.cancelUser = this.cancelUser.bind(this);
    this.changeRadio = this.changeRadio.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount(){
    this.setState({licenseId: this.props.licenseId, expireDate: this.props.expireDate, status: this.props.licenseStatus, autoRenew: this.props.autoRenew});
  }

  closeModal(){
    this.setState({show: false});
  }

  closeDeleteModal(){
    this.setState({showDeletePrompt: false, showRenewPrompt: false});
  }

  showDeleteConfirmation(){
    this.setState({showDeletePrompt: true});
  }

  showRenewConfirmation(){
    this.setState({showRenewPrompt: true});
  }

  handleChange(e){
    this.setState({[e.target.name]:e.target.value});
  }

  showUpdate(){
    this.setState({updating: true});
  }

  renewSubscription(){
    let value = this.context;
    this.setState({loading: true, showDeletePrompt: false});
    axios.post('https://www.arutility.com/api/v5/subscriptions/renew.php',{licenseId: this.state.licenseId},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
          this.setState({loading: false, updating: false, status: "active", autoRenew: true, showRenewPrompt: false, show: true, modalBody: "License updated", modalHeader: "Success"});
          this.props.cancelSuccess();
      }).catch(error => {
          if(error.response.status == 400) {
            this.setState({loading: false, updating: false});
            this.props.cancelError(error.response.data.error);
          }else{
            this.setState({loading: false, updating: false});
            this.props.cancelError(error.response.data.error);
          }
    });
  }

  cancelSubscription(){
    let value = this.context;
    this.setState({loading: true, showDeletePrompt: false});
    axios.post('https://www.arutility.com/api/v5/subscriptions/cancel.php',{licenseId: this.state.licenseId},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
          this.setState({loading: false, updating: false, status: "active", autoRenew: false, showDeletePrompt: false, show: true, modalBody: "License will no longer renew. You will still have access until the end of the billing cycle.", modalHeader: "Success"});
          this.props.cancelSuccess();
      }).catch(error => {
          if(error.response.status == 400) {
            this.setState({loading: false, updating: false});
            this.props.cancelError(error.response.data.error);
          }else{
            this.setState({loading: false, updating: false});
            this.props.cancelError(error.response.data.error);
          }
    });
  }

  cancelUser(){
    this.setState({updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone});     
  }

  changeRadio(e){
    const index = this.state.userPermissions.indexOf(e.target.id);
    var updatedUserPermissions = this.state.userPermissions;
    if (index > -1) {
      updatedUserPermissions.splice(index, 1);
    }else{
      updatedUserPermissions.push(e.target.id);
    }
    this.setState({userPermissions: updatedUserPermissions});
  }

  render(){
    let value = this.context;
    var expireDate = new Date(this.props.expireDate);
    var currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    var daysRemaining = Math.floor((expireDate-currentDate)/(1000*60*60*24));
    return (
      <div className="userWrapper">
        <div className="userInfoWrapper">
          <Modal show={this.state.show} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalBody}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {this.state.updating ?  
            <div>
            {this.state.loading && 
              <LoadingSpinner />
            }
            <Modal show={this.state.showDeletePrompt} onHide={this.closeDeleteModal}>
              <Modal.Header closeButton>
                <Modal.Title>CONFIRM SUBSCRIPTION CANCELLATION</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to end this subscription? You can always reinstate this subscription at any time. You will be able to use this subscription until its expiration at which time you will no longer be charged the recurring fee.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeDeleteModal}>
                  Close
                </Button>
                <Button variant="danger" onClick={this.cancelSubscription}>
                  Yes, Please Cancel My Subscription
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showRenewPrompt} onHide={this.closeDeleteModal}>
              <Modal.Header closeButton>
                <Modal.Title>CONFIRM SUBSCRIPTION RENEW</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to renew this subscription?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeDeleteModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.renewSubscription}>
                  Yes, Please Renew My Subscription
                </Button>
              </Modal.Footer>
            </Modal>
              <div className={(daysRemaining < 30) ? "expireDateUrgent" : "expireDate"} name="expireDate">
                {this.state.expireDate}
              </div>
              <div className={(daysRemaining < 30) ? "daysRemainingUrgent" : "daysRemaining"} name="daysRemaining">
                {(daysRemaining < 0) ?
                    <div>
                      Expired
                    </div>
                  :
                    <div>
                      {daysRemaining}
                    </div>
                }
              </div>
              <div className="licenseStatus">
                {this.state.status}
              </div>
              <div className="licenseId">
                {this.state.licenseId}
              </div>
              <div className="licenseRenew">
                {this.state.autoRenew == 1 ?
                  <div>
                    Auto Renew
                  </div>
                  :
                  <div>
                    End at Billing Period
                  </div>
                }
              </div>
              <div className="licenseUpdate">
                <Button variant="secondary" onClick={this.cancelUser} className="licenseUpdateBtn">Cancel</Button>
                {(this.state.status == "active" && this.state.autoRenew == 1) && 
                  <Button variant="danger" onClick={this.showDeleteConfirmation} className="licenseUpdateBtn">Cancel Subscription</Button>
                }
                {(this.state.status == "active" && this.state.autoRenew == 0) &&
                  <Button variant="primary" onClick={this.showRenewConfirmation} className="licenseUpdateBtn">Renew Subscription</Button>
                }
              </div>
            </div>
          :
            <div>
              <div className={(daysRemaining < 30) ? "expireDateUrgent" : "expireDate"} name="expireDate">
                {this.state.expireDate}
              </div>
              <div className={(daysRemaining < 30) ? "daysRemainingUrgent" : "daysRemaining"} name="daysRemaining">
                {(daysRemaining < 0) ?
                    <div>
                      Expired
                    </div>
                  :
                    <div>
                      {daysRemaining}
                    </div>
                }
              </div>
              <div className="licenseStatus">
                {this.state.status}
              </div>
              <div className="licenseId">
                {this.state.licenseId}
              </div>
              <div className="licenseRenew">
                {this.state.autoRenew == 1 ?
                  <div>
                    Auto Renew
                  </div>
                  :
                  <div>
                    End at Billing Period
                  </div>
                }
              </div>
              <div className="licenseUpdate">
                  <Button variant="primary" onClick={this.showUpdate} >Update</Button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default License;