import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import './Demo.css';

class Demo extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
	    	longitude: "",
	    	latitude: "",
	    	show: false,
	    	modalHeader: "",
	    	modalBody: ""
	    }
	    this.LaunchDemoData = this.LaunchDemoData.bind(this);
	    this.updateLng = this.updateLng.bind(this);
	    this.updateLat = this.updateLat.bind(this);
	    this.ChangeLatLng = this.ChangeLatLng.bind(this);
	    this.showModal = this.showModal.bind(this);
	    this.closeModal = this.closeModal.bind(this);
	}

	LaunchDemoData(){
		window.location.href = "arutilityapp://oath/callback?demo=true";
	}

	ChangeLatLng(){
		if(this.state.longitude != "" && this.state.latitude != ""){
			window.location.href = "arutil://launch?Latitude=" + this.state.latitude + "&Longitude=" + this.state.longitude + "&GpsOverride=true";
		}else{
			this.setState({show: true, modalHeader: "Error", modalBody: "Please provide a latitude and longitude"});
		}	
	}

	showModal(){
        this.setState({show: true});
    }

    closeModal(){
        this.setState({show: false});
    }

	updateLng(e){
		this.setState({longitude: e.target.value});
		//alert("Lng Updated to: " + e.target.value);
	}

	updateLat(e){
		this.setState({latitude: e.target.value});
		//alert("Lat Updated to: " + e.target.value);
	}

  	render(){
	  	let value = this.context;
	    return (
	      	<div className="mainWrapper">
	      		<Modal show={this.state.show} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.modalBody}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                         Close
                        </Button>
                    </Modal.Footer>
                </Modal>
	      		<MainHeader />
	      		<div className="pageTitle">
		          	DEMO
		        </div>
		        <div className="pageDescriptionDemo">
		          So, you want to see for yourself how you can merge GIS and Augmented Reality to create a safer and more efficient work site? No problem, we understand!
		          <br/>
		          <br/>
		          Please know that the demo is only a fraction of what ARUtility is capable of. While you'll be able to get a feel for how you can visualize assets in Augmented Reality, you won't have access to the following features:
		          <br/>
		          <br/>
		          <ul>
		          	<li>Remote Assistance</li>
		          	<li>As-Builts</li>
		          	<li>BIM Model Visualization</li>
		          </ul>
		          <strong>STEP 1:</strong>
		          <br/>
		          Download the ARUtility app from the playstore using one of the links below:
		          <br/>
		            <a href="https://play.google.com/store/apps/details?id=com.ARUtility.ARUtility" target="_blank"><img src="/images/googlePlayBlack.png" alt="Download on Google Play" width="200px" /></a>
		            <a href="https://apps.apple.com/gb/app/arutility/id1493590181" target="_blank"><img src="/images/appleStoreBlack.png" alt="Download on Apple App Store" width="200px"/></a>
		          <br/>
		          <br/>
		          <strong>STEP 2:</strong>
		          <br/>
		          Click the button below to launch the app:
		          <br/>
		          <a href="arutilityapp://oauth/callback?demo=true">
		          <Button>
		      			Launch ARUtility Demo
		      		</Button>
		      		</a>
		        </div>
	      		<div className="footerSpace">
		        </div>
		        <MainFooter />
	   		</div>
	    );
  	}
}

export default Demo;