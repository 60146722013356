import React from 'react';
import './UpdateSessionHeader.css';

class UpdateSessionHeader extends React.Component{

  render(){
    return (
    	<div className="projectsHeaderWrapper">
      		<div className="projectsHeaderText">
          Updated Asset Sessions
          </div>
          <div className="projectsHeaderSearch">
            <input type="text" className="projectsHeaderSearch" id="projectsHeaderSearch" placeholder="Search..." />
          </div>
      </div>
    );
  }
}

export default UpdateSessionHeader;