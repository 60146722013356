import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {Router, Route, Switch} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
//import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConfigContext as Provider } from "../configcontext/ConfigContext";
import { Redirect } from 'react-router';
import '../../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from '../home/Home';
import Projects from '../projects/Projects';
import ProjectView from '../projectview/ProjectView';
import ProjectCreate from '../projectcreate/ProjectCreate';
import Header from '../header/Header';
import Leftrail from '../leftrail/Leftrail';
import Login from '../login/Login';
import Authorize from '../authorize/Authorize';
import Requests from '../requests/Requests';
import RequestView from '../requestview/RequestView';
import ProfileSettings from '../profilesettings/ProfileSettings';
import AccountSettings from '../accountsettings/AccountSettings';
import AccountMapping from '../accountmapping/AccountMapping';
import ManageUsers from '../manageusers/ManageUsers';
import CreateUser from '../createuser/CreateUser';
import EditSessions from '../editsessions/EditSessions';
import EditSessionView from '../editsessionview/EditSessionView';
import EditSessionView2 from '../editsessionview2/EditSessionView2';
import UpdateSessions from '../updatesessions/UpdateSessions';
import UpdateSessionView from '../updatesessionview/UpdateSessionView';
import HowItWorks from '../howitworks/HowItWorks';
import AllProjectsMap from '../allprojectsmap/AllProjectsMap';
import About from '../about/About';
import Contact from '../contact/Contact';
import News from '../news/News';
import NewsArticle from '../newsarticle/NewsArticle';
import Privacy from '../privacy/Privacy';
import CreateAccount from '../createaccount/CreateAccount';
import Partners from '../partners/Partners';
import Testimonials from '../testimonials/Testimonials';
import ForgotPassword from '../forgotpassword/ForgotPassword';
import ResetPassword from '../resetpassword/ResetPassword';
import AdminLogin from '../adminlogin/AdminLogin';
import AdminConsole from '../adminconsole/AdminConsole';
import Knowledgebase from '../knowledgebase/Knowledgebase';
import Support from '../support/Support';
import BIMProjects from '../bimprojects/BIMProjects';
import BIMProjectView from '../bimprojectview/BIMProjectView';
import Pricing from '../pricing/Pricing';
import RemoteLaunch from '../remotelaunch/RemoteLaunch'
import RemoteSession from '../remotesession/RemoteSession';
import Demo from '../demo/Demo';
import Careers from '../careers/Careers';
import Solutions from '../solutions/Solutions';
import Billing from '../billing/Billing';
import CreateLicense from '../createlicense/CreateLicense';
import Terms from '../terms/Terms';
import DeviceList from '../devicelist/DeviceList';
import WOProjects from '../woprojects/WOProjects';

// Utility Mapping
//import Mapping from '../utilityMapping/index'; //NEW
import Mapping from '../accountmapping2/AccountMapping2'; //OLD
import AppLaunch from '../applaunch/AppLaunch';

const history = createHistory()
ReactGA.initialize('UA-135500571-1');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    //console.log(location.pathname)
});

class App extends React.Component {

  render() {
    return (
      <div className="wrapper">
	    <Provider>
	      <Router history={history}>
	        <div>
	            <Switch> 
	                <Route exact path="/" component={Home} />
	                <Route exact path='/howitworks' component={HowItWorks}/>
	                <Route exact path='/about' component={About}/>
	                <Route exact path='/contact' component={Contact} />
	                <Route exact path='/news' component={News} />
	                <Route path ='/news/article/:id' component={NewsArticle} />
	                <Route exact path='/privacy' component={Privacy} />
	                <Route path="/login/:accountName?" component={Login} />
	                <Route path="/authorize" component={Authorize} />
	        		<Route exact path="/projects" component={Projects} />
	        		<Route exact path="/bimprojects" component={BIMProjects} />
	        		<Route exact path ="/bimproject/view/:id" component={BIMProjectView}/>
	                <Route exact path="/project/create" component={ProjectCreate} />
	                <Route exact path="/project/view/:id" component={ProjectView} />
	                <Route exact path="/pricing" component={Pricing} />
	                <Route path="/requests" component={Requests} />
	                <Route exact path="/request/view/:id" component={RequestView} />
	                <Route exact path="/profile/settings" component={ProfileSettings} />
	                <Route path="/account/settings" component={AccountSettings} />
	                <Route path="/account/create" component={CreateAccount} />
	                <Route exact path="/account/mapping" component={Mapping} />
	                <Route path="/users/manage" component={ManageUsers} />
	                <Route path="/users/create" component={CreateUser} />
	                <Route exact path="/session/edits" component={EditSessions} />
	                <Route exact path="/community/map" component={AllProjectsMap} />
	                <Route exact path="/session/edits/:id" component={EditSessionView2} />
	                <Route exact path="/session/updates" component={UpdateSessions} />
	                <Route exact path="/session/updates/:id" component={UpdateSessionView} />
					<Route exact path="/applaunch" component={AppLaunch} />
					<Route exact path="/remotelaunch" component={RemoteLaunch} />
	                <Route exact path="/remotesession" component={RemoteSession} />
	                <Route exact path="/demo" component={Demo} />
	                <Route exact path="/careers" component={Careers} />
	                <Route path="/partners" component={Partners} />
	                <Route path="/testimonials" component={Testimonials} />
	                <Route path="/forgotpassword" component={ForgotPassword} />
	                <Route path="/resetpassword" component={ResetPassword} />
	                <Route exact path="/admin/login" component={AdminLogin} />
	                <Route exact path="/admin/console" component={AdminConsole} />
	                <Route path="/knowledgebase" component={Knowledgebase} />
	                <Route path="/support" component={Support} />
	                <Route exact path="/solutions" component={Solutions} />
	                <Route exact path="/billing" component={Billing} />
	                <Route exact path="/license/create" component={CreateLicense} />
	                <Route exact path="/terms" component={Terms} />
	                <Route exact path="/devicelist" component={DeviceList} />
					<Route exact path="/applaunch" component={AppLaunch} />
					<Route exact path="/woprojects" component={WOProjects} />
	                <Route render={() => <Redirect to="/" />} />
	            </Switch>
	            <Header /> 
	            <Leftrail /> 
	          </div>
	      </Router>
	    </Provider>
	  </div>
    )
  }
}

export default App