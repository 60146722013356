import React from 'react';
import {Context} from "../configcontext/ConfigContext";
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Responses.css';

class Responses extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    responses: [],
		    loading: true,
		    projectId: "",
        shapefiles: [],
        requestId: "",
        modalBody: "",
        modalHeader: "",
        show: false
		}
    this.downloadFile = this.downloadFile.bind(this);
    this.closeModal = this.closeModal.bind(this);
	}

  componentDidMount() {
  	let value = this.context;
    this.setState({projectId: this.props.projectId});
    axios.post('https://www.arutility.com/api/v4/requests/readResponses.php',{projectId: this.props.projectId},{headers: {Authorization: 'Bearer ' + value.jwt}})
      .then(res => {
        this.setState({loading: false, responses: res.data});
      }).catch(error => {
        this.setState({loading: false, responses: []});
      });
  }

  closeModal(){
    this.setState({show: false});
  }

  downloadFile(e){
    let value = this.context;
    var fileName = e.target.name;
    const link = document.createElement('a');
    link.href = "https://www.arutility.com/uploads/" + e.target.name;
    link.setAttribute("download", e.target.name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render(){
    return (
    	<div className="allResponseWrapper">
	    	{this.state.loading &&
	    		<LoadingSpinner />
	    	}
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
	    	{this.state.responses.map(response => {
	    		var utilityResponse;
	    		if(response.responseCode == 0){
	    			utilityResponse = "Pending";
	    		}else if(response.responseCode == 1){
	    			utilityResponse = "Approved";
	    		}else if(response.responseCode == 2){
	    			utilityResponse = "Denied";
	    		}
          var shapefileArray = [];
          var attacheFilesArray = [];
          if(response.shapefileURL != null){
            shapefileArray = response.shapefileURL.split(',');
          }
          if(response.attachedFiles != null){
            attacheFilesArray = response.attachedFiles.split(',');
          }
      			return(
      				<div key={response.requestId} className="responseWrapper">
      					<b>{response.companyName} ({response.featureServiceName})</b> - {utilityResponse} 
                <div className="responseFileWrapper">  
                  {shapefileArray.length > 0 &&
                    <div>
                      {shapefileArray.map(url=>{
                        return(
                          <a href={url + "/data"} target="_blank" key={url}>
                            <Button variant="primary" className="shapefileBtn">
                              Shapefile
                            </Button>
                          </a>
                        );
                      })}
                    </div>
                  }
                  {attacheFilesArray.map(file=>{
                    return(
                      <Button key={file} variant="primary" className="shapefileBtn" name={file} onClick={this.downloadFile}>
                        {file}
                      </Button>
                    );
                  })}
                </div>
      					<br/>
      					{response.responseComment}
      				</div>
      			);
       		})}
    	</div>
    );
  }
}

export default Responses;