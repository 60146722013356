import React from 'react';
import './ProjectsFilter.css';

class ProjectsFilter extends React.Component{

  render(){
    return (
    	<div className="projectsFilterWrapper">
      		<div className="projectsFilterStatus">
          Status <img src='/images/sorticon.png' alt="statusFilter"/>
          </div>
          <div className="projectsFilterId">
          Ticket #
          </div>
          <div className="projectsFilterName">
          Project Name <img src='/images/sorticon.png' alt="projectNameFilter"/>
          </div>
          <div className="projectsFilterLocation">
          Location <img src='/images/sorticon.png' alt="locationFilter"/>
          </div>
          <div className="projectsFilterStart">
          Start Date <img src='/images/sorticon.png' alt="startDateFilter"/>
          </div>
          <div className="projectsFilterEnd">
          End Date <img src='/images/sorticon.png' alt="endDateFilter"/>
          </div>
          <div className="projectsFilterResponses">
          Responses
          </div>
      </div>
    );
  }
}

export default ProjectsFilter;