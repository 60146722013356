import React from 'react';
import axios from 'axios';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Modal, Button} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import SingleFileUpload from '../singlefileupload/SingleFileUpload';
//import {OBJModel} from 'react-3d-viewer';
//import ModelViewer from 'react-model-viewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BIMProject.css';

class BIMProject extends React.Component{

    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            projectName: "",
            projectDescription: "",
            uploadedFiles: [],
            objectURL: "",
            materialURL: "",
            object: "",
            material: "",
            thumbnail: "",
            textures: [],
            showingSubmitOptions: false,
            modelLatitude: "",
            modelLongitude: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.fileUploaded = this.fileUploaded.bind(this);
        this.kmlRef = React.createRef();
        this.removeFile = this.removeFile.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.showSubmitOptions = this.showSubmitOptions.bind(this);
        this.hideSubmitOptions = this.hideSubmitOptions.bind(this);
        this.updateMapping = this.updateMapping.bind(this);
    }

    updateMapping(fileName, fileType){
        if(fileType == "object"){
            this.setState({object: fileName});
            if(this.state.material == fileName){
                this.setState({material: ""});
            }
            if(this.state.thumbnail == fileName){
                this.setState({thumbnail: ""});
            }
            if(this.state.textures.includes(fileName)){
                var newArray = this.state.textures;
                for(var i=0; i < newArray.length; i++){
                    if(newArray[i] == fileName){
                        newArray.splice(i,1);
                    }
                }
                this.setState({textures: newArray});
            }
        }else if(fileType == "material"){
            this.setState({material: fileName});
            if(this.state.object == fileName){
                this.setState({object: ""});
            }
            if(this.state.thumbnail == fileName){
                this.setState({thumbnail: ""});
            }
            if(this.state.textures.includes(fileName)){
                var newArray = this.state.textures;
                for(var i=0; i < newArray.length; i++){
                    if(newArray[i] == fileName){
                        newArray.splice(i,1);
                    }
                }
                this.setState({textures: newArray});
            }
        }else if(fileType == "thumbnail"){
            this.setState({thumbnail: fileName});
            if(this.state.material == fileName){
                this.setState({material: ""});
            }
            if(this.state.object == fileName){
                this.setState({object: ""});
            }
            if(this.state.textures.includes(fileName)){
                var newArray = this.state.textures;
                for(var i=0; i < newArray.length; i++){
                    if(newArray[i] == fileName){
                        newArray.splice(i,1);
                    }
                }
                this.setState({textures: newArray});
            }
        }else if(fileType == "texture"){
            if(!this.state.textures.includes(fileName)){
                var oldTextureArr = this.state.textures;
                oldTextureArr.push(fileName);
                this.setState({textures: oldTextureArr});
            }
            if(this.state.material == fileName){
                this.setState({material: ""});
            }
            if(this.state.object == fileName){
                this.setState({object: ""});
            }
            if(this.state.thumnail == fileName){
                this.setState({thumbnail: ""});
            }
        }
    }

    showSubmitOptions(){
        this.setState({showingSubmitOptions: true});
    }

    hideSubmitOptions(){
        this.setState({showingSubmitOptions: false});
    }

    fileUploaded(e){
        var numFiles = e.target.files.length;
        var files = e.target.files;
        var updatedFiles = this.state.uploadedFiles;
        var existingTextureArr = this.state.textures;
        for(var i=0; i<numFiles; i++){
            var filePresent = false;
            var fileName = files[i].name;
            for(var i2=0; i2<this.state.uploadedFiles.length; i2++){
                var existingFileName = this.state.uploadedFiles[i2].name;
                if(existingFileName == fileName){
                    filePresent = true;
                }
            }
            if(filePresent == false){
                updatedFiles.push(e.target.files[i]);
                existingTextureArr.push(fileName);
            }
        }
        this.setState({uploadedFiles: updatedFiles, textures: existingTextureArr});
    }

    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    removeFile(e, fileType){
        var fileName = e;
        var updatedFiles = this.state.uploadedFiles;
        for(var i=0; i<updatedFiles.length; i++){
            var existingFileName = updatedFiles[i].name;
            if(existingFileName == fileName){
                updatedFiles.splice(i,1);
            }
        }
        if(fileType == "object"){
            this.setState({object: ""});
        }else if(fileType == "material"){
            this.setState({material: ""});
        }else if(fileType == "thumbnail"){
            this.setState({thumbnail: ""});
        }else if(fileType == "texture"){
            var newArray = this.state.textures;
            for(var i=0; i < newArray.length; i++){
                if(newArray[i] == fileName){
                    newArray.splice(i,1);
                }
            }
            this.setState({textures: newArray});
        }
        this.setState({uploadedFiles: updatedFiles});
    }

    previewFile(){
        let value = this.context;
        let formData = new FormData();
        if(this.state.uploadedFiles.length > 0){
            for(var i=0; i<this.state.uploadedFiles.length; i++){
                formData.append('file[]', this.state.uploadedFiles[i]);
            }
        }
        //formData.append('jwt', value.jwt);
        this.setState({loading: true});
        axios.post('https://www.arutility.com/api/v4/project/previewModel.php',formData,{headers: {'content-type': 'multipart/form-data', Authorization: 'Bearer ' + value.jwt}})
          .then(res => {
            var objectURL = "https://www.arutility.com/uploads/objFiles/" + res.data.message.object;
            var materialURL = "https://www.arutility.com/uploads/objFiles/" + res.data.message.material;
            this.setState({loading: false, objectURL: objectURL, materialURL: materialURL});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, requestInfo: []});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, requestInfo: []});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true, requestInfo: []});
            }
        });
    }

    handleSubmit(){
        this.setState({loading: true});
        let value = this.context;
        let formData = new FormData();
        //formData.append('jwt', value.jwt);
        if(this.state.uploadedFiles.length > 0 && this.state.projectName != "" && this.state.projectDescription != ""){
            if(this.state.material != "" && this.state.object != ""){
                for(var i=0; i<this.state.uploadedFiles.length; i++){
                    formData.append('file[]', this.state.uploadedFiles[i]);
                }
                //formData.append('objectURL', this.state.objectURL);
                //formData.append('materialURL', this.state.materialURL);
                formData.append('projectName', this.state.projectName);
                formData.append('projectDescription', this.state.projectDescription);
                formData.append('objectName', this.state.object);
                formData.append('materialName', this.state.material);
                formData.append('thumbnailName', this.state.thumbnail);
                formData.append('textures[]', this.state.textures);
                //axios.post('https://www.arutility.com/api/v3/users/login.php',{username: this.state.username,password:this.state.password})
                axios.post('https://www.arutility.com/api/v4/project/createBIM.php',formData,{headers: {'content-type': 'multipart/form-data', Authorization: 'Bearer ' + value.jwt}})
                .then(res => {
                    this.setState({loading: false, modalBody: "Project successfully created.", modalHeader: "Success", show: true});
                }).catch(error => {
                    if (error.response) {
                        this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
                    }
                });
            }else{
                this.setState({loading: false, modalBody: "You must include an object file and material file to upload a model. Please verify both of these file types have been selected before submitting.", modalHeader: "Error", show: true});
            }
        }else{
          this.setState({loading: false, modalBody: "Sorry, it appears you are missing required information. Verify you have added an obj file.", modalHeader: "Error", show: true});
        }
    }

    showModal(){
        this.setState({show: true});
    }

    closeModal(){
        this.setState({show: false});
    }

    componentDidMount() {
        let value = this.context;
        this.setState({loading: false});
    }

    render(){
        let value = this.context;
        const {loading, show, setShow} = this.state;
        if(value.jwt == ""){
          return(
            <Route>
              <Redirect to="/login" />
            </Route>
          );
        }else{
          if(value.userPermissions.includes("projectsbim:create")){
              return (
                <div className="createProjectInfoWrapper">
                    {this.state.loading &&
                        <LoadingSpinner />
                    }
                    <Modal show={show} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.modalHeader}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.modalBody}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="kmluploadwrapper">
                        <form id="uploadKMLProject" encType="multipart/form-data">
                            Drag & drop obj/mtl files and project thumbnail here...
                            <br/>
                            <br/>
                            Or browse files to upload
                            <br/>
                            <br/>
                            <input id="kmlprojectfile" ref={this.kmlRef} name="file" className="kmlFileInput" type="file" onChange={this.fileUploaded}/>
                        </form>
                    </div>
                    {(this.state.uploadedFiles.length > 0) &&
                        <div>
                            Attached Files:
                            <div className="uploadedFilesWrapper">
                                {this.state.uploadedFiles.map(file =>{
                                    return(
                                        <SingleFileUpload key={file.name} fileName={file.name} removeFile={this.removeFile} updateMapping={this.updateMapping} object={this.state.object} material={this.state.material} thumbnail={this.state.thumbnail}/>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    <b>Project Name:</b>
                    <br/>
                    <input type="text" placeholder="e.g. Main St Utility Replacement" className="projectInputField" name="projectName" id="projectName" required="" onChange={this.handleChange} value={this.state.projectName}/>
                    <br/>
                    <b>Project Description:</b>
                    <br/>
                    <textarea placeholder="e.g. Main St Utility Replacement" className="projectInputArea" name="projectDescription" id="projectDescription" required="" onChange={this.handleChange} value={this.state.projectDescription}></textarea>
                    <br/>
                    <b>Origin Latitude:</b>
                    <br/>
                    <input type="number" placeholder="Latitude" className="projectInputField" name="modelLatitude" id="modelLatitude" required="" onChange={this.handleChange} value={this.state.modelLatitude}/>
                    <br/>
                    <b>Origin Longitude:</b>
                    <br/>
                    <input type="number" placeholder="Longitude" className="projectInputField" name="modelLongitude" id="modelLongitude" required="" onChange={this.handleChange} value={this.state.modelLongitude}/>
                    <br/>
                    {(this.state.uploadedFiles.length > 0) ?
                        <button id="submitProjectBtn" className="submitProjectBtn" onClick={this.handleSubmit}>SUBMIT PROJECT</button>
                        :
                        <button id="submitProjectBtn" className="submitProjectInactiveBtn" onMouseEnter={this.showSubmitOptions} onMouseLeave={this.hideSubmitOptions}>SUBMIT PROJECT</button>
                    }
                    {this.state.showingSubmitOptions &&
                        <div className="submitNoticeWrapper">
                            Please attach atleast 1 obj file and an accompanying mtl file where applicable before submitting.
                        </div>
                    }
                </div>
              );
          }else{
            return(
              <Route>
                <Redirect to="/projects" />
              </Route>
            );
          }
        }
    }
}

export default BIMProject;