import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './About.css';

class About extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="pageTitle">
          ABOUT
        </div>
        <div className="pageDescription">
          ARUtility is an augmented reality utility locating solution that allows users to see where utilities are located above and below the ground surface. Our mission is to create a safer work site, a more efficient worksite and help utilities better manage their assets.
          Our team is made of licensed Professional Engineers and developers that have extensive experience working in underground construction and design. We have spent hours in the field performing inspection on projects ranging from sanitary sewer installations, storm sewer installations, water main installations, gas main installations and more. The ARUtility solution was developed from our own needs in the field. Combining our own experiences in the field with our development expertise, we aim to be the number one augmented reality utility locating solution.
          <br/>
          <br/>
          In 2016, underground damages to utilities cost direct stakeholders more than $1.5billion, according the Common Ground Alliance. Reports across the globe continue to show this number increasing. As the infrastructure in the U.S. and around the world continues to deteriorate, communities will see an increase in underground excavation. With increased excavation comes an increase in underground utility hits.
          <br/>
          <br/>
          Damage to underground utilities has a number of negative effects on a community. Residents in the immediate vicinity can go extended periods of time without power, gas, water and electricity. There are social impacts that are hard to put a dollar figure on. Damages can also create unsafe conditions that require evacuations of residents and contractor crews. Some of these damages can even be fatal. Rupturing a high pressure gas main can have fatal consequences for any workers in the immediate area as well as the local residents.
          <br/>
          <br/>
          ARUtility aims to be an added tool in the hands of Engineers, Contractors, Inspectors and Utility Workers to help reduce the likelihood of damages to underground utilities. By being able to see where all utilities are located at all times, unexpected damages can be avoided.
        </div>
        <div className="pageTitle">
          ARUTILITY'S CORE VALUES
        </div>
        <div className="pageDescription">
          At ARUtility we are focused on not just creating great solutions that bring value to your organization. Our team is also focused on creating a diverse work force, providing top customer service and fostering innovation and creativity. Some of our core values include:
          <br/>
          <br/>
          <ul>
              <li>Promote and Encourage a Diverse Workplace</li>
              <li>Commitment to Customer Satisfaction</li>
              <li>Commitment to Innovation and Excellence</li>
              <li>Commitment to Accountability</li>
              <li>Results Oriented</li>
              <li>Integrity</li>
              <li>Teamwork</li>
          </ul>
        </div>
        <div className="pageTitle">
          ARUTILITY LEADERSHIP
        </div>
        <div className="pageDescription">
          <div className="teamWrapper">
            <div className="teamMemberOdd">
              <div className="teamMemberImage">
                <img src="/images/headshot_JoeEastman2.png" alt="Joe Eastman Photo" />
              </div>
              <div className="teamMemberDesc">
                <b><font size="5">Joe Eastman, P.E.</font></b><a href="https://www.linkedin.com/in/j0schm03/" target="_blank"><img width="25px" height="25px" style={{marginBottom:"10px"}} src="/images/linkedinlogo.png" alt="LinkedIn Logo"/></a><br/>
                <i>Founder/CEO</i>
                <br/>
                <br/>
                Joe is a licensed Professional Engineer in the State of Michigan and the Founder of ARUtility. Joe graduated from the University of Michigan with a degree in Civil Engineering. He has over 13 years of experience in the Civil Engineering field that ranges from water main, gas main, electric, storm water, sanitary sewer installations and more. This experience includes many years of field inspection. His skills also include extensive experience with modeling of water and sewer systems and GIS editing. Joe also holds an S1 license which is the highest license that can be obtained through the State of Michigan allowing him to operate any public water system within the state.
                <br/>
                <br/>
                He started ARUtility based on his own wants and needs from years of working on construction projects of all types. In particular, the idea for an augmented reality utility locating application came from one memorable experience. While performing inspection services for a water main installation, he witnessed a contractor who struck an underground gas line. The ground surface had been removed and the contractor lost track of where the utilities were located. The buried line began spewing natural gas and the contractor quickly exited the excavator and ran away to safety. The damage resulted in the evacuation of multiple blocks and cost the contractor tens of thousands of dollars. It was this experience in particular that was the ah-ha moment. ARUtility was born on that day.
                <br/>
              </div>
            </div>
            <div className="teamMemberOdd">
              <div className="teamMemberImage">
                <img src="/images/Alando.png" alt="Joe Eastman Photo" />
              </div>
              <div className="teamMemberDesc">
                <b><font size="5">Alando Chappell</font></b><a href="https://www.linkedin.com/in/alando-chappell-4ab731aa/" target="_blank"><img width="25px" height="25px" style={{marginBottom:"10px"}} src="/images/linkedinlogo.png" alt="LinkedIn Logo"/></a><br/>
                <i>Managing Partner & COO</i>
                <br/>
                <br/>
                Alando brings to the team almost 20 years of experience in the utility industry. He began his career as a Journey Line Worker for over 10 years and gained exposure to field operations through that experience. He quickly moved up into a Management position and was the Manager of Water, Steam & Chilled Water Distribution for a utility in Michigan. Alando holds an S1 license which is the highest license that can be obtained through the State of Michigan allowing him to operate any public water system within the state. Alando also received his B.S. in Organizational Management and brings his excellent management style and techniques to the team.
                <br/>
              </div>
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default About;