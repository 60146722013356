import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import './ProjectsHeader.css';

class ProjectsHeader extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      searchString: ""
    }
    this.updateSearch = this.updateSearch.bind(this);
    this.statusChange = this.statusChange.bind(this);
  }

  updateSearch(e){
    this.setState({searchString: e.target.value});
    this.props.updateSearch(e.target.value);
  }

  statusChange(e){
    this.props.updateSearchStatus(e.target.value);
  }

  render(){
    return (
    	<div className="projectsHeaderWrapper">
      		<div className="projectsHeaderText">
          Projects
          </div>
          <div className="projectsHeaderSearch">
            <label htmlFor="statusSelect">Status:</label>
            <select id="statusSelect" onChange={this.statusChange}>
              <option value=""></option>
              <option value="1">In Progress</option>
              <option value="0">Upcoming</option>
            </select>
            <InputGroup className="projectsHeaderSearchNew" id="projectsHeaderSearchNew">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Search:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder=""
                aria-label=""
                aria-describedby="basic-addon1"
                value={this.state.searchString}
                onChange={this.updateSearch}
              />
            </InputGroup>
          </div>
      </div>
    );
  }
}

export default ProjectsHeader;