import React from 'react';
import axios from 'axios';
import { loadModules } from 'esri-loader';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import './EsriMapRequest.css';

class EsriMapRequest extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			projectId: "",
			requestId: "",
			assets: [],
			loading: true,
			projectBoundary: [],
			colors: [],
			modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.mapRef = React.createRef();
		this.closeModal = this.closeModal.bind(this);
	}

	closeModal(){
		this.setState({show: false});
	}

	showAssets(assets){
		
	}

  	componentDidMount() {
	  	let value = this.context;
	  	axios.post('https://www.arutility.com/api/v5/accounts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	      	if(res.data[0].colors != "" && JSON.parse(res.data[0].colors).length > 0){
	      		var colorsToUpdate = JSON.parse(res.data[0].colors);
	      	}else{
	      		var colorsToUpdate = [];
	      	}
			this.setState({colors: colorsToUpdate});
	    }).catch(error => {
		    //Add some error handling here.
		});
	    this.setState({jwt: value.jwt, projectId: this.props.projectId, requestId: this.props.requestId, projectBoundary: JSON.parse(this.props.projectBoundary)});
		loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer]) => {
	    	var parent = this;
	      	this.map = new ArcGISMap({
	        	basemap: 'streets'
	      	});

	      	this.view = new MapView({
		        container: this.mapRef.current,
		        map: this.map,
		        center: [-98.579532, 39.828577],
		        zoom: 5
	      	});

	      	this.toggle = new BasemapToggle({
			  	view: this.view,  // view with map that uses "streets" basemap
			  	nextBasemap: "satellite"  // Allows for toggling to "hybrid" basemap
	      	});

	      	this.view.ui.add(this.toggle, "top-right");

	      	this.layer = new GraphicsLayer({
		  		graphics: []
		  	});
		  	this.graphics = [];
          	var rings = [];
          	var firstRing = [];
          	var boundaryCoordinates;
          	if(this.state.projectBoundary){
          		boundaryCoordinates = this.state.projectBoundary.coordinates;
      	  	}else{
      	  		this.setState({loading: false, modalBody: "Sorry, it appears something went wrong. If you are not signed in with Esri credentials you will not be able to see the assets being shared.", modalHeader: "Error", show: true});
      	  		return;
      	  	}
          	for(var i=0; i < boundaryCoordinates.length; i++){
	          	var vertex = [];
	          	vertex.push(boundaryCoordinates[i]["longitude"]);
	          	vertex.push(boundaryCoordinates[i]["latitude"]);
	          	firstRing.push(vertex);
          	}
          	rings.push(firstRing);
          	this.polygon = {
          	  	type: "polygon",
			  	hasZ: false,
			  	hasM: false,
			  	rings: rings,
			  	spatialReference: { wkid: 4326 }
		  	};
		  	this.polygonSymbol = {
			  	type: "simple-fill",  // autocasts as new SimpleFillSymbol()
			  	color: [198,40,40,0.5],
			  	style: "solid",
			  	outline: {  // autocasts as new SimpleLineSymbol()
			    	color: "white",
			    	width: 1
			  	}
			};
		  	this.polygonGraphic = new Graphic({
		    	geometry: this.polygon, // Add the geometry created in step 4
		    	symbol: this.polygonSymbol // Add the symbol created in step 5
		  	});
		  	//console.log(this.polygonGraphic);
		  	this.graphics.push(this.polygonGraphic);
		  	this.layer.graphics.add(this.polygonGraphic);
		  	this.map.add(this.layer);
		  	this.view.goTo(this.graphics);
		  	axios.post('https://www.arutility.com/api/v5/project/requestAssets.php',{id:this.props.projectId,request_id:this.props.requestId},{headers: {Authorization: 'Bearer ' + value.jwt}},{timeout: 480000})
		    .then(res => {
		        this.setState({assets: res.data.features});
		        parent.props.updateLoading(false);
			  	/*setTimeout(function(){
	              parent.view.goTo(parent.graphics);
	              parent.props.updateLoading(false);
	            }, 2000);*/
	          	{res.data.features.map(feature => {
		          	if(feature.attributes != ""){
		                var attributes = feature.attributes;
		                var attributeKeys = Object.keys(attributes[0]);
		                var attributeText = "";
		                for(var i2=0; i2<attributeKeys.length;i2++){
		                    var keyName = attributeKeys[i2];
		                    var value = attributes[0][keyName];
		                    if(i2==0){
		                        attributeText = attributeText + "<b>" + keyName + "</b>: " + value;
		                    }else{
		                        attributeText = attributeText + "</br>" + "<b>" + keyName + "</b>: " + value;
		                    }
		                }
		            }else{
		                attributeText = "";
		            }
		          	if(feature.feature_type == "line"){
			            var featurePaths = feature.paths[0][0];
			            if(featurePaths[0][0]){
				            var numPaths = featurePaths.length;
				            var polylineCoords = [];
				            for(var i=0; i<numPaths-1; i++){
				                var polylineCoord = [];
				                polylineCoord.push(featurePaths[i]);
				                polylineCoord.push(featurePaths[i+1]);
				                polylineCoords.push(polylineCoord);
				            }

				            this.polyline = {
								type: "polyline", // autocasts as new Polyline()
								paths: polylineCoords
							};

							this.lineSymbol = {
							    type: "simple-line", // autocasts as new SimpleLineSymbol()
							    width: 2
							};

							var numFeatureServices = this.props.mappingOriginal.length;
							for(var i5=0; i5 < numFeatureServices; i5++){
								if(parseInt(this.props.mappingOriginal[i5]["feature_service_type_id"]) == feature.feature_service_type_id){
									var color = this.props.mappingOriginal[i5]["color"];
									for(var i4=0; i4 < this.state.colors.length; i4++){
					            		if(this.state.colors[i4].id == feature.feature_service_type_id){
					            			color = this.state.colors[i4].color;
					            			break;
					            		}
					            	}
					                this.lineSymbol.color = color;
					                break;
								}
							}

				            /*if(feature.feature_service_type == "water"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 1){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "electric"){
				            	var color = [255,0,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 2){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "gas"){
				            	var color = [255,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 3){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "storm"){
				            	var color = [0,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 4){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "fiber"){
				            	var color = [128,0,128,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 5){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "telecom"){
				            	var color = [255,165,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 6){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "sanitary"){
				            	var color = [165,42,42,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 7){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.lineSymbol.color = color;
				            }else if(feature.feature_service_type == "steam"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 9){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.lineSymbol.color = color;
				            }*/
				            this.lineAtt = attributeText;

						  	this.polylineGraphic = new Graphic({
						    	geometry: this.polyline, // Add the geometry created in step 4
						    	symbol: this.lineSymbol, // Add the symbol created in step 5
						    	attributes: this.lineAtt, // Add the attributes created in step 6
						    	popupTemplate: {
							      title: feature.feature_layer_name,
							      content: attributeText
							    }
						  	});
						  	this.graphics.push(this.polylineGraphic);
						  	this.layer.graphics.add(this.polylineGraphic);
						}
			        }else if(feature.feature_type == "point"){
			            var featurePoints = feature.points[0];
			            if(featurePoints.x){
			            	var circleCoords = [];
				            var circleCoord = [];
				            circleCoord.push(featurePoints.x);
				            circleCoord.push(featurePoints.y);
				            circleCoords.push(circleCoord);
				            this.point = {
								type: "point", // autocasts as new Polyline()
								longitude: featurePoints.x,
								latitude: featurePoints.y
							};
							this.pointSymbol = {
							    type: "simple-marker", // autocasts as new SimpleLineSymbol()
							    radius: 0.75
							};
							var numFeatureServices = this.props.mappingOriginal.length;
							for(var i5=0; i5 < numFeatureServices; i5++){
								if(parseInt(this.props.mappingOriginal[i5]["feature_service_type_id"]) == feature.feature_service_type_id){
									var color = this.props.mappingOriginal[i5]["color"];
									for(var i4=0; i4 < this.state.colors.length; i4++){
					            		if(this.state.colors[i4].id == feature.feature_service_type_id){
					            			color = this.state.colors[i4].color;
					            			break;
					            		}
					            	}
					                this.pointSymbol.color = color;
					                break;
								}
							}
				            /*if(feature.feature_service_type == "water"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 1){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "electric"){
				            	var color = [255,0,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 2){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "gas"){
				            	var color = [255,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 3){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "storm"){
				            	var color = [0,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 4){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "fiber"){
				            	var color = [128,0,128,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 5){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "telecom"){
				            	var color = [255,165,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 6){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "sanitary"){
				            	var color = [165,42,42,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 7){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "steam"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 9){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.pointSymbol.color = color;
				            }else if(feature.feature_service_type == "controlpoint"){
				            	var color = [0,0,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 10){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.pointSymbol.color = color;
				            }*/
				            this.pointAtt = attributeText;
				            this.pointGraphic = new Graphic({
							  	geometry: this.point,
							  	symbol: this.pointSymbol,
							  	attributes: this.pointAtt, // Add the attributes created in step 6
						    	popupTemplate: {
							      title: feature.feature_layer_name,
							      content: attributeText
							    }
							});
				            this.graphics.push(this.pointGraphic);
						  	this.layer.graphics.add(this.pointGraphic);
			            }
			        }
	          	})}
		    }).catch(error => {
		        if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, requestInfo: []});
			    } else if (error.request) {
			      // The request was made but no response was received
			      	console.log("Request error");
			      	console.log(error.request);
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, requestInfo: []});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			      	console.log("Message Error");
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true, requestInfo: []});
			    }
		    });
		});
  	}

  	componentWillUnmount() {
    	if (this.view) {
      	// destroy the map view
      	this.view.container = null;
    	}
  	}

  	render(){
	  	const {loading, show} = this.state;
		return(
			<div className="mapWrapper">
				<Modal show={show} onHide={this.closeModal}>
		            <Modal.Header closeButton>
		              <Modal.Title>{this.state.modalHeader}</Modal.Title>
		            </Modal.Header>
		            <Modal.Body>{this.state.modalBody}</Modal.Body>
		            <Modal.Footer>
		              <Button variant="secondary" onClick={this.closeModal}>
		                Close
		              </Button>
		            </Modal.Footer>
		        </Modal>
				<div className="map" id="map" ref={this.mapRef}>
		  		</div>
			</div>
		);
  	}
}

export default EsriMapRequest;