import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import Project from '../project/Project';
import {Context} from "../configcontext/ConfigContext";
import ProjectsFilter from '../projectsfilter/ProjectsFilter';
import ProjectsHeader from '../projectsheader/ProjectsHeader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './Projects.css';

class Projects extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    projects: [],
		    filteredProjects: [],
		    searchString: "",
		    statusSearchString: "",
		    searching: false,
		    loading: true,
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.updateSearch = this.updateSearch.bind(this);
		this.updateSearchStatus = this.updateSearchStatus.bind(this);
		this.updateSearchFull = this.updateSearchFull.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v4/project/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	        const projects = res.data["records"];
	        this.setState({ projects: projects, loading: false });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
  	}

  	updateSearch(searchString){
  		this.setState({searchString: searchString}, this.updateSearchFull);
  	}

  	updateSearchStatus(searchString){
  		this.setState({statusSearchString: searchString}, this.updateSearchFull);
  	}

  	updateSearchFull(){
  		var searchResults = JSON.parse(JSON.stringify(this.state.projects));
  		if(this.state.searchString != ""){
  			//console.log(searchResults);
		  	const searchResultsFiltered = searchResults.filter(project => 
		  		project.name.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		project.project_city.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		project.project_state.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		project.ticket_id.toLowerCase().includes(this.state.searchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.statusSearchString != ""){
			var currentDate = new Date();
			currentDate.setHours(0,0,0,0);
			if(this.state.statusSearchString == "1"){
				const searchResultsFiltered = searchResults.filter(project => 
					new Date(project.start_date).setTime(new Date(project.start_date).getTime() + Math.abs(new Date(project.start_date).getTimezoneOffset()*60000)) <= currentDate
			  	);
			  	searchResults = searchResultsFiltered;
			}else if(this.state.statusSearchString == "0"){
				const searchResultsFiltered = searchResults.filter(project => 
			  		new Date(project.start_date).setTime(new Date(project.start_date).getTime() + Math.abs(new Date(project.start_date).getTimezoneOffset()*60000)) > currentDate
			  	);
			  	searchResults = searchResultsFiltered;
			}
		}
		if(this.state.searchString != "" || this.state.statusSearchString != ""){
			this.setState({searching: true, filteredProjects: searchResults});
		}else{
			this.setState({searching: false, filteredProjects: []});
		}
  	}

  	render(){
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("projects:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<ProjectsHeader updateSearch={this.updateSearch} updateSearchStatus={this.updateSearchStatus}/>
				      		<ProjectsFilter /> 
				      		{(value.accountPermissions.includes("project:create")) ? (
				      			null
				      		):(
				      			<div className="noProjects">
				      				Upgrade your account, or request a free 30 day trial, by contacting <a href="mailto:sales@arutility.com">sales@arutility.com</a> in order to take advantage of your accounts full capabilities. Project creation is a paid feature that requires an upgrade to your account.
				      			</div>
				      		)}
				      		{(this.state.searching == false) ? (
					      		this.state.projects.filter((project) => project.projectStatus != "0").map(project => {
					      			var end_date = new Date(project.end_date);
					      			end_date.setTime(end_date.getTime() + Math.abs(end_date.getTimezoneOffset()*60000));
					      			var currentDate = new Date();
					      			currentDate.setHours(0,0,0,0);
					      			if(end_date >= currentDate){
						      			var urlPath = "/project/view/" + project.id;
						      			var numPositiveResponses = 0;
						      			for(var i=0; i<project.responses.length; i++){
						      				if(project.responses[i].response_code != 0){
						      					numPositiveResponses = numPositiveResponses + 1;
						      				}
						      			}
						            	return(
							            	<NavLink key={project.id} to={urlPath}>
							            		<Project key={project.id} ticketId={project.ticket_id} projectName={project.name} projectCity={project.project_city} projectState={project.project_state} projectStartDate={project.start_date} projectEndDate={project.end_date} projectRequests={project.responses.length} projectResponses={numPositiveResponses}/>
							            	</NavLink>
					            		);
					            	}
					       		})
					       		)
					       		:
					       		(this.state.filteredProjects.filter((project) => project.projectStatus != "0").map(project => {
					      			var end_date = new Date(project.end_date);
					      			end_date.setTime(end_date.getTime() + Math.abs(end_date.getTimezoneOffset()*60000));
					      			var currentDate = new Date();
					      			currentDate.setHours(0,0,0,0);
					      			if(end_date >= currentDate){
						      			var urlPath = "/project/view/" + project.id;
						      			var numPositiveResponses = 0;
						      			for(var i=0; i<project.responses.length; i++){
						      				if(project.responses[i].response_code != 0){
						      					numPositiveResponses = numPositiveResponses + 1;
						      				}
						      			}
						            	return(
							            	<NavLink key={project.id} to={urlPath}>
							            		<Project key={project.id} ticketId={project.ticket_id} projectName={project.name} projectCity={project.project_city} projectState={project.project_state} projectStartDate={project.start_date} projectEndDate={project.end_date} projectRequests={project.responses.length} projectResponses={numPositiveResponses}/>
							            	</NavLink>
					            		);
					            	}
					       		})
					       		)
					      	}
					    </div>
			       	</div>
			    );
			}else if(value.userPermissions.includes("requests:view")){
				return(
					<Route>
						<Redirect to="/requests" />
					</Route>
				);
			}else{
				{value.logout()}
				return(
					<Route>
						<Redirect to="/login" />
					</Route>
				);
			}
		}
  	}
}

export default Projects;