import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import AccountAdminPreview from "../accountadminpreview/AccountAdminPreview";
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminViewAccounts.css';

class AdminViewAccounts extends React.Component{

  static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            accounts: [],
            filteredAccounts: [],
            allPermissions: [],
            searchingText: "",
            searching: false,
            modalBody: "",
            modalHeader: "",
            show: false
        }
        this.closeModal = this.closeModal.bind(this);
        this.updateAccountError = this.updateAccountError.bind(this);
        this.updateAccountSuccess = this.updateAccountSuccess.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
    }

    componentDidMount(){
        const value = this.context;
        axios.post('https://www.arutility.com/api/v4/accounts/adminReadAll.php',{},{headers: {Authorization: 'Bearer ' + value.jwt, 'Content-Type': 'application/json'}})
        .then(res => {
            const accounts = res.data;
            this.setState({ accounts: accounts, loading: false });
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
        axios.post('https://www.arutility.com/api/v4/accounts/adminReadAllPermissions.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            const allPermissions = res.data[0].permissions;
            var allPermissionsArray = allPermissions.split(',');
            allPermissionsArray.sort();
            this.setState({ allPermissions: allPermissionsArray, loading: false });
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }

    updateSearch(e){
        var searchString = e.target.value;
        if(searchString != ""){
            const searchResults = this.state.accounts.filter(account => 
                account.accountName.toLowerCase().includes(searchString.toLowerCase())
            );
            this.setState({searching: true, searchingText: searchString, filteredAccounts: searchResults});
        }else{
            this.setState({searching: false, searchingText: "", filteredAccounts: []});
        }
    }

    updateAccountSuccess(){
        this.setState({modalBody: "Account successfully updated.", modalHeader: "Success", loading: false, show: true});
    }

    updateAccountError(){
        this.setState({modalBody: "Unable to update Account. Please verify no information was left blank", modalHeader: "Error", loading: false, show: true});
    }

    closeModal(){
        this.setState({show: false});
    }

    render(){
        let value = this.context;
        const {show} = this.state;
        return (
            <div className="moduleDetailWrapper">
              	{this.state.loading &&
                    <LoadingSpinner />
                }
                <Modal show={show} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.state.modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.modalBody}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.closeModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                </Modal>
                <div className="adminPageTitle">
                  VIEW ALL ACCOUNTS
                </div>
                <div className="projectsHeaderSearch">
                    <input type="text" className="projectsHeaderSearch" id="projectsHeaderSearch" placeholder="Search..." value={this.state.searchingText} onChange={this.updateSearch}/>
                </div>
                <div className="adminPageDescription">
                    {(this.state.searching == false) ? (
                        this.state.accounts.map(account => {
                            return(
                                <AccountAdminPreview key={account.accountId} accountId={account.accountId} accountName={account.accountName} accountActive={account.accountActive} accountAlias={account.accountAlias} accountLicenses={account.accountLicenses} accountPermissions={account.accountPermissions} accountType={account.accountType} accountCountry={account.accountCountry} address1={account.address1} address2={account.address2} accountCity={account.city} accountState={account.state} esriServiceURL={account.esriServiceURL} esriAnalysisURL={account.esriAnalysisURL} allPermissions={this.state.allPermissions} updateAccountError={this.updateAccountError} updateAccountSuccess={this.updateAccountSuccess}/>
                            );
                        })
                    )
                    :
                    (
                        this.state.filteredAccounts.map(account => {
                            return(
                                <AccountAdminPreview key={account.accountId} accountId={account.accountId} accountName={account.accountName} accountActive={account.accountActive} accountAlias={account.accountAlias} accountLicenses={account.accountLicenses} accountPermissions={account.accountPermissions} accountType={account.accountType} accountCountry={account.accountCountry} address1={account.address1} address2={account.address2} accountCity={account.city} accountState={account.state} esriServiceURL={account.esriServiceURL} esriAnalysisURL={account.esriAnalysisURL} allPermissions={this.state.allPermissions} updateAccountError={this.updateAccountError} updateAccountSuccess={this.updateAccountSuccess}/>
                            );
                        })
                    )}
                </div>
            </div>
        );
    }
}

export default AdminViewAccounts;