import React from 'react';
import axios from 'axios';
import {Link, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import AdminCreateAccount from '../admincreateaccount/AdminCreateAccount';
import AdminViewAccounts from '../adminviewaccounts/AdminViewAccounts';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminNewsModule.css';

class AdminNewsModule extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
		    loading: true,
		    urlPath: "",
		    imgURL: "",
		    footerText: "",
		    selected: false,
		    actions: ["createNews","viewAll"],
		    createNews: false,
		    viewAll: true,
		    moduleBoolText: "",
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.updateModuleColor = this.updateModuleColor.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	updateModuleColor(e){
		var moduleBoolText = e.target.id;
		this.props.updateModules(moduleBoolText);
		if(this.state.selected){
			this.setState({selected: false});
		}else{
			this.setState({selected: true});
		}
	}

  	componentDidMount() {
  		this.setState({loading: false, urlPath: this.props.urlPath, imgURL: this.props.imgURL, footerText: this.props.footer, moduleBoolText: this.props.moduleBoolText, selected: this.props.selected});
  	}

  	componentDidUpdate(){
  		if(this.props.selected != this.state.selected){
  			this.setState({selected: this.props.selected});
  		}
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
	    	if(value.userPermissions.includes("user:super")){
			    return (
			    	<div className="adminModule">
				   		<div className={this.state.selected ? "adminModuleWrapperSelected" : "adminModuleWrapper" } id={this.state.moduleBoolText} onClick={this.updateModuleColor}>
				   			<div className="adminModuleLarge" id={this.state.moduleBoolText} onClick={this.updateModuleColor}>
				   				<img src={this.state.imgURL} alt="" id={this.state.moduleBoolText} onClick={this.updateModuleColor}/>
				   			</div>
				   			<div className="adminModuleFooter" id={this.state.moduleBoolText} onClick={this.updateModuleColor}>
				   				{this.state.footerText}
				   			</div>
				   		</div>

			   		</div>
			    );
			}else{
		       return null;
		    }
		}
  	}
}

export default AdminNewsModule;