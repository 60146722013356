import React from 'react';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import { Modal, Button } from 'react-bootstrap';
import {Context} from "../configcontext/ConfigContext";
import './AppLaunch.css';

class AppLaunch extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        show: false,
        modalBody: "",
        modalHeader: "",
        storeLinkText: "",
        storeLink: "",
        error:[],
    }
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount(){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //this.setState({storeLinkText: "Please visit the following link to download the app:", storeLink: "https://apps.apple.com/gb/app/arutility/id1493590181"});
    setTimeout(() => { 
      if (/windows phone/i.test(userAgent)) {
          this.setState({show:true, modalHeader: "Error", modalBody: "Sorry, windows devices are not supported"});
      }else if (/android/i.test(userAgent)) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.ARUtility.ARUtility';
      }else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        var urlString = window.location.href;
        var subStringArray = urlString.split('?');
        window.location.href = 'arutilityapp://applaunch?' + subStringArray[1];
        this.setState({storeLinkText: "Please visit the following link to download the app:", storeLink: "https://apps.apple.com/gb/app/arutility/id1493590181"});
      }else{
          this.setState({show:true, modalHeader: "Error", modalBody: "Sorry, we were not able to determine your operating system."});
      } 
    }, 1000)
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  render(){
    let value = this.context;
    return (
      <div>
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
        <div className="pageTitle">
          ARUTILITY APP REDIRECT
        </div>
        <div className="pageDescription">
            Please wait one moment while we try to launch the ARUtility application. If you do not have the app downloaded,
            you will be redirected to the appropriate app store for download or the link will be provided below.          
            <br/>
            <br/>
            {this.state.storeLinkText} <a href={this.state.storeLink}>{this.state.storeLink}</a>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default AppLaunch;