import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button , Card, Container, Row, Col} from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Partners.css';

class Partners extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    const {show} = this.state;
    return (
      <div>
        <MainHeader />
        <Modal show={show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="pageTitle">
          OUR VALUED PARTNERS
        </div>
        <div> {/*className="partnersWrapper"*/} 
        <Container>
          <Row>
            <Col>            
              <Card>
                <Card.Img  src="/images/epnEmergingPartner.png" />
                <Card.Body>
                <a href="http://www.arcgis.com"  target="_blank"><Card.Title>ESRI</Card.Title></a> 
                  <Card.Text>
                  ESRI builds ArcGIS, the world’s most powerful mapping & spatial analytics software.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img src="/images/partners/fieldgo.png" />
                <Card.Body>
                <a href="http://fieldgo.com/" target="_blank"><Card.Title>fieldGO</Card.Title></a> 
                  <Card.Text>
                    fieldGO provides organisations a real-time view of field works and
                   easily integrates with multiple back-end systems at any time.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card >
                <Card.Img  src="/images/partners/centerlinemapping.png" />
                <Card.Body>
                <a href="http://www.centerlinemapping.com/" target="_blank"><Card.Title>Centerline Mapping</Card.Title></a> 
                  <Card.Text>
                  Centerline Mapping is a full-lifecycle Geographic Information System (GIS) consultancy firm.
                   </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>  
              <Card>
                <Card.Img  src="/images/partners/terraflow.png" />
                <Card.Body>
                <a href="https://www.terraflow.ca/" target="_blank"><Card.Title>TerraFlow Geomatics</Card.Title></a> 
                  <Card.Text>
                  TerraFlow Geomatics offers a platform for collection, integration, and visualization of GIS data
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>  
              <Card>
                <Card.Img src="/images/partners/agoraio.png" />
                <Card.Body>
                <a href="https://www.agora.io/" target="_blank"><Card.Title>Agora</Card.Title></a> 
                  <Card.Text>
                  The Real-Time Engagement Platform for meaningful human connections.</Card.Text>
                </Card.Body>
              </Card>
          </Col>
          </Row>
        </Container>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Partners;