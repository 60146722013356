import React , {Component} from 'react';
import { Context } from "../configcontext/ConfigContext.js";
import axios from 'axios';
import {Table,Button, Modal} from 'react-bootstrap';
import './FeatureFieldTypes.css';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';

class FeatureFieldTypes extends Component {

    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            feature_field_types: [],
            edit: false,
            modalBody: "",
            modalHeader: "",
            show: false,
            loading: true,
            new_field: {
                id: "",
                name: "",
            }

        }
    }
    componentDidMount() {
        // read feature service types from database
        let value = this.context;
        this.readFeatureFieldTypes(value.jwt);
        this.setState({loading:false});
    }

    readFeatureFieldTypes = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/testing/v5/accounts/admin/readFeatureFieldTypes.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let fft_array = await res.data.FeatureFieldTypes;
            this.setState({ feature_field_types: [...fft_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature layers. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }
    handleSubmit = (event) => {
        // submit new feature type
        this.setState({loading:true})
        let new_feature_field_type = this.state.new_field;
        let value = this.context;
        if(this.state.edit && new_feature_field_type.id !== ""){
            axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/editFeatureFieldType.php', { post: new_feature_field_type }, { headers: { Authorization: 'Bearer ' + value.jwt } })
        	.then(res => {
                this.setState({ loading: false, modalBody: "Feature Field successfully updated", modalHeader: "Success", show: true });
                this.readFeatureFieldTypes(value.jwt);

        	}).catch(error => {
        		if (error.response) {
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
        		} else if (error.request) {
        			// The request was made but no response was received
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
        		} else {
        			// Something happened in setting up the request that triggered an Error
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
        		}
            });
            this.setState({ edit: false })
        }else{
        axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/addFeatureFieldType.php', { post: new_feature_field_type }, { headers: { Authorization: 'Bearer ' + value.jwt } })
        	.then(res => {
                this.setState({ loading: false, modalBody: "Feature Field successfully added", modalHeader: "Success", show: true });
                this.readFeatureFieldTypes(value.jwt);

        	}).catch(error => {
        		if (error.response) {
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
        		} else if (error.request) {
        			// The request was made but no response was received
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
        		} else {
        			// Something happened in setting up the request that triggered an Error
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
        		}
            });
        }
        //console.log(new_feature_field_type)
        
        this.setState({
            new_field: {
                id: "",
                name: "",
            }
        })
        event.preventDefault();
    }
    handleDelete = (event, id) => {
        // delete feature layer type
        this.setState({loading:true})
        let value = this.context;
        if (id) {
            axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/deleteFeatureFieldType.php', { deleteID: id }, { headers: { Authorization: 'Bearer ' + value.jwt } })
                .then(res => {
                    this.setState({ loading: false, modalBody: "Feature Field successfully deleted.", modalHeader: "Success", show: true });
                    this.readFeatureFieldTypes(value.jwt);

                }).catch(error => {
                    if (error.response) {
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
                    }
                });
        }
        event.preventDefault();
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState((prevState) => ({
            new_field: { 
                ...prevState.new_field,    
                [name]: value      
            }
        }))
    }
    handleData = () => {
        this.setState({ edit: false , loading: false});
        this.setState({
            new_field: {
                id: "",
                name: "",
                
            }
        })
    }

    handleEdit = (e,feature_field)=>{
        this.setState({
            new_field: {                   
                id: feature_field.id ,
                name: feature_field.name ,
            },
        }
        )
        this.setState({ edit: true });
    }

    closeModal=()=>{
    	this.setState({show: false});
  	}
    render(){
        const {loading, show} = this.state;
        return(
            <>
                <div className="moduleDetailWrapper">
                        {this.state.loading && <LoadingSpinner />}
                        <Modal show={show} onHide={()=>this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.modalHeader}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{this.state.modalBody}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={()=>this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="adminPageTitle">
                            MANAGAE FEATURE FIELD TYPES
                        </div>
                        <div className="adminPageDescription">
                            <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>Name</th> 
                                    <th>Editing</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {
                                    this.state.feature_field_types.map((field,index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{field.id}</td>
                                                <td>{field.name}</td>
                                                <td><Button variant="secondary" onClick={(e)=>this.handleEdit(e,field)}>Edit</Button>{" "}
                                                <Button variant="danger" onClick={(e)=>{if(window.confirm('Are you sure you want to delete?')){
                                                                                                    this.handleDelete(e,field.id) }
                                                                                            else{
                                                                                               e.preventDefault()
                                                                                            }
                                                    }}>Delete</Button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            </Table>       
                            <button type="button" className={"cancel-btn"} onClick={(e) => (this.setState({ edit: true }))}> Add New Feature Field Type</button>

                            {this.state.edit ? <form className="form-inline" onSubmit={this.handleSubmit}>
                                <label >
                                    Name:
                                </label>
                                <input style={{ float: "right" }} required type="text" name="name" value={this.state.new_field.name} onChange={(e) => this.handleChange(e)} />
                                <input type="submit" value="Submit" />
                                <button type="button" onClick={this.handleData}>Cancel</button>
                            </form>: null}
                            
                            
                        </div>
                    
                </div>
            </>
        )
    }
}
export default FeatureFieldTypes