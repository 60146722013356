import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "../cardsection/CardSection.js";

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
      this.state = {
        productId: "",
        priceId: "",
        email: "",
        quantity: "1"
    }
    this.updateField = this.updateField.bind(this);
  }

  updateField(e){
    this.setState({[e.target.id]:e.target.value})
  }

  componentDidMount(){
    this.setState({productId: this.props.productId, priceId: this.props.priceId})
  }

  handleSubmit = async event => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {
      //console.log(result.token);
      //console.log(result.token.id);
      this.props.createSubscription(this.state.productId, this.state.quantity, this.state.priceId, result.token.id);
    }
  };

  render() {
    return (
      <div>
        <div className="product-info">
          <h3 className="product-title">{this.props.productTitle}</h3>
          <h4 className="product-price">${this.props.productPrice}/{this.props.frequency}</h4>
          <div className="product-description" dangerouslySetInnerHTML={{__html: this.props.productDescription}}>
          </div>
        </div>
        <form onSubmit={this.handleSubmit} className="stripeForm">
          <CardSection />
          <button disabled={!this.props.stripe} className="btn-pay">
            Purchase Subscription
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} productTitle={props.productTitle} productPrice={props.productPrice} productId={props.productId} priceId={props.priceId} productDescription={props.productDescription} createSubscription={props.createSubscription} frequency={props.frequency}/>
      )}
    </ElementsConsumer>
  );
}