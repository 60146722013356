import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import UserAdminPreview from "../useradminpreview/UserAdminPreview";
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminViewUsers.css';

class AdminViewUsers extends React.Component{

  static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            users: [],
            filteredUsers: [],
            allPermissions: [],
            allAccounts: [],
            searchingText: "",
            searching: false,
            modalBody: "",
            modalHeader: "",
            show: false
        }
        this.closeModal = this.closeModal.bind(this);
        this.updateUserError = this.updateUserError.bind(this);
        this.updateUserSuccess = this.updateUserSuccess.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
    }

    componentDidMount(){
        const value = this.context;
        axios.post('https://www.arutility.com/api/v4/users/adminReadAll.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            const users = res.data;
            this.setState({ users: users, loading: false });
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + JSON.stringify(error.request), modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + JSON.stringify(error), modalHeader: "Error", show: true});
            }
        });
        axios.post('https://www.arutility.com/api/v4/accounts/adminReadAll.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            const accounts = res.data;
            accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1);
            this.setState({allAccounts: accounts, loading: false});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }

    updateSearch(e){
        var searchString = e.target.value;
        if(searchString != ""){
            const searchResults = this.state.users.filter(user => 
                user.username.toLowerCase().includes(searchString.toLowerCase())
            );
            this.setState({searching: true, searchingText: searchString, filteredUsers: searchResults});
        }else{
            this.setState({searching: false, searchingText: "", filteredUsers: []});
        }
    }

    updateUserSuccess(){
        this.setState({modalBody: "User successfully updated.", modalHeader: "Success", loading: false, show: true});
    }

    updateUserError(){
        this.setState({modalBody: "Unable to update User. Please verify no information was left blank", modalHeader: "Error", loading: false, show: true});
    }

    closeModal(){
        this.setState({show: false});
    }

    render(){
        let value = this.context;
        const {show} = this.state;
        return (
            <div className="moduleDetailWrapper">
              	{this.state.loading &&
                    <LoadingSpinner />
                }
                <Modal show={show} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.state.modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.modalBody}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.closeModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                </Modal>
                <div className="adminPageTitle">
                  VIEW ALL USERS
                </div>
                <div className="projectsHeaderSearch">
                    <input type="text" className="projectsHeaderSearch" id="projectsHeaderSearch" placeholder="Search..." value={this.state.searchingText} onChange={this.updateSearch}/>
                </div>
                <div className="adminPageDescription">
                    {(this.state.searching == false) ? (
                        this.state.users.map(user => {
                            return(
                                <UserAdminPreview key={user.userId} userId={user.userId} userName={user.username} userRole={user.userRole} userEmail={user.userEmail} firstName={user.firstName} lastName={user.lastName} userPhone={user.userPhone} activeStatus={user.activeStatus} accountName={user.accountName} accountId={user.accountId} accountPermissions={user.accountPermissions} userPermissions={user.userPermissions} allAccounts={this.state.allAccounts}/>
                            );
                        })
                    )
                    :
                    (
                        this.state.filteredUsers.map(user => {
                            return(
                                <UserAdminPreview key={user.userId}  userId={user.userId} userName={user.username} userRole={user.userRole} userEmail={user.userEmail} firstName={user.firstName} lastName={user.lastName} userPhone={user.userPhone} activeStatus={user.activeStatus} accountName={user.accountName} accountId={user.accountId} accountPermissions={user.accountPermissions} userPermissions={user.userPermissions} allAccounts={this.state.allAccounts}/>
                            );
                        })
                    )}
                </div>
            </div>
        );
    }
}

export default AdminViewUsers;