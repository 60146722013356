import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SingleFileUpload from '../singlefileupload/SingleFileUpload';
import './AdminCreateNews.css';

class AdminCreateNews extends React.Component{

  static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            newsTitle: "",
            editorHtml: "",
            loading: false,
            modalBody: "",
            modalHeader: "",
            uploadedFiles: [],
            show: false
        }
        this.updateForm = this.updateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.fileUploaded = this.fileUploaded.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.kmlRef = React.createRef();
    }

    componentDidMount(){
        const value = this.context;
        this.setState({"loading": true});
        axios.post('https://www.arutility.com/api/v4/accounts/adminReadAll.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            const accounts = res.data;
            accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1);
            this.setState({allAccounts: accounts, loading: false});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }

    removeFile(e){
        var fileName = e.target.id;
        var updatedFiles = this.state.uploadedFiles;
        for(var i=0; i<updatedFiles.length; i++){
            var existingFileName = updatedFiles[i].name;
            if(existingFileName == fileName){
                updatedFiles.splice(i,1);
            }
        }
        this.setState({uploadedFiles: updatedFiles});
    }

    fileUploaded(e){
        var numFiles = e.target.files.length;
        var files = e.target.files;
        var updatedFiles = this.state.uploadedFiles;
        for(var i=0; i<numFiles; i++){
            var filePresent = false;
            var fileName = files[i].name;
            for(var i2=0; i2<this.state.uploadedFiles.length; i2++){
                var existingFileName = this.state.uploadedFiles[i2].name;
                if(existingFileName == fileName){
                    filePresent = true;
                }
            }
            if(filePresent == false){
                updatedFiles.push(e.target.files[i]);
            }
        }
        this.setState({uploadedFiles: updatedFiles});
    }

    handleEditorChange(data){
        this.setState({editorHtml: data});
    }

    changeRadio(e){
      const index = this.state.userPermissions.indexOf(e.target.id);
      var updatedUserPermissions = this.state.userPermissions;
      if (index > -1) {
        updatedUserPermissions.splice(index, 1);
      }else{
        updatedUserPermissions.push(e.target.id);
      }
      this.setState({userPermissions: updatedUserPermissions});
    }

    updateForm(e){
        this.setState({[e.target.id]:e.target.value});
    }

    showModal(){
        this.setState({show: true});
    }

    closeModal(){
        this.setState({show: false});
    }

    sendForm(){
        this.setState({loading: true});
        const value = this.context;
        let formData = new FormData();
        formData.append('file[]', this.state.uploadedFiles[0]);
        //formData.append('jwt', value.jwt);
        formData.append('title', this.state.newsTitle);
        formData.append('body', this.state.editorHtml);
        axios.post('https://www.arutility.com/api/v4/news/adminCreate.php',formData,{headers: {'content-type': 'multipart/form-data', Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }

    submitForm(){
        if(this.state.newsTitle != "" && this.state.editorHtml != "" && this.state.uploadedFiles.length > 0){
            this.sendForm();
        }else{
            this.setState({modalBody: "You must include a title, body text and news image before submitting", modalHeader: "Error", show: true});
        }
    }

    render(){
        let value = this.context;
        return (
            <div className="moduleDetailWrapper">
                {this.state.loading &&
                    <LoadingSpinner />
                }
                <Modal show={this.state.show} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.modalBody}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                         Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="adminPageTitle">
                  CREATE NEWS
                </div>
                <div className="adminPageDescription">
                    <div className="createAccount">
                        <div id="createUserForm">
                            <font color="#c62828">Article Image:</font>
                            <br/>
                            <div className="kmluploadwrapper">
                                <form id="uploadKMLProject" encType="multipart/form-data">
                                    Drag & drop image file here...
                                    <br/>
                                    <br/>
                                    Or browse files to upload
                                    <br/>
                                    <br/>
                                    <input id="kmlprojectfile" ref={this.kmlRef} name="file" className="kmlFileInput" type="file" onChange={this.fileUploaded}/>
                                </form>
                            </div>
                            {(this.state.uploadedFiles.length > 0) &&
                                <div className="uploadedFilesWrapper">
                                    {this.state.uploadedFiles.map((file, i) =>{
                                        return(
                                            <div className="singleFileWrapper" key={i}>
                                                <div className="fileName">
                                                    {file.name}
                                                </div>
                                                <div className="fileDelete" id={file.name} onClick={this.removeFile}>
                                                    <img src="/images/close.png" id={file.name} onClick={this.removeFile} alt="Delete File" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            <font color="#c62828">Title:</font>
                            <br/>
                            <input type="text" id="newsTitle" onChange={this.updateForm} value={this.state.newsTitle} className="companyInput"/>
                            <br/>
                            <br/>
                            <font color="#c62828">News Content:</font>
                            <br/>                           
                            <CKEditor
                                editor={ ClassicEditor }
                                data=""
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.handleEditorChange(data);
                                    //console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                                config={{
                                    
                                    ckfinder:{uploadUrl: "https://www.arutility.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json"}
                                }}
                            />
                            <br/>
                            
                        </div>
                        <br/>
                        <button value="Submit" id="createAccountSubmit" onClick={this.submitForm}>CREATE NEWS</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminCreateNews;