import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import './FeatureLayerWrapperKML.css';

class FeatureLayerWrapperKML extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        feature_service_mapped_name: "",
        feature_layer_mapped_name: "",
        feature_layer_url: "",
        feature_layer_mapped_fields: [],
        feature_layer_mapped_attributes: [],
        esri_feature_services: [],
        feature_layers: [],
        feature_layer_fields: [],
        feature_layer_map_index: "",
        allow_shapefile: false,
        feature_layer_mapped_id: "",
        modalBody: "Are you sure you want to delete this layer? Once you've saved changes this cannot be undone. No changes will be saved until you click \"Save Changes\"",
        modalHeader: "Confirm Delete",
        show: false,
        showConfirmation: false
    }
    this.featureServiceChange = this.featureServiceChange.bind(this);
    this.featureLayerChange = this.featureLayerChange.bind(this);
    this.addLayer = this.addLayer.bind(this);
    this.attributeUpdate = this.attributeUpdate.bind(this);
    this.updateFeatureField = this.updateFeatureField.bind(this);
    this.changeShapefile = this.changeShapefile.bind(this);
    this.deleteLayer = this.deleteLayer.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount(){
    this.setState({feature_layer_mapped_name: this.props.feature_layer_mapped_name, feature_layer_url: this.props.feature_layer_url, feature_layer_mapped_attributes: this.props.attributes, feature_layer_map_index: this.props.feature_layer_map_index, feature_layer_mapped_fields: this.props.feature_layer_mapped_fields, allow_shapefile: this.props.allowShapefile, feature_layer_mapped_id: this.props.feature_layer_mapped_id});
    if(this.props.feature_layer_mapped_name != ""){
      this.setState({show: true});
      /*var numServices = this.props.esri_feature_services.length;
      for (var i = 0; i < numServices; i++) {
        if (this.props.esri_feature_services[i].name == this.props.feature_service_mapped_name) {
          if(this.props.esri_feature_services[i].layers){
            var numLayers = this.props.esri_feature_services[i].layers.length;
            for (var i2 = 0; i2 < numLayers; i2++) {
              if (this.props.esri_feature_services[i].layers[i2].name == this.props.feature_layer_mapped_name) {
                if(this.props.esri_feature_services[i].layers[i2].fields){
                  let fields = this.props.esri_feature_services[i].layers[i2].fields.map(field => {
                    return { name: field.name, type: field.type };
                  });
                  this.setState({ feature_layer_fields: [{ name: '', type: '' }].concat(fields)});
                }
              }
            }
          }
        }
      }*/
      //console.log("Here");
      //this.updateFeatureLayers(this.props.feature_service_mapped_name);
    }else{
      this.setState({show: false});
    }
  }

  componentDidUpdate(){
    if(this.props.feature_layer_mapped_name != this.state.feature_layer_mapped_name || this.props.feature_layer_map_index != this.state.feature_layer_map_index || this.props.feature_layer_map_index != this.state.feature_layer_map_index || this.props.feature_layer_url != this.state.feature_layer_url || this.props.feature_layer_mapped_fields != this.state.feature_layer_mapped_fields || this.props.allowShapefile != this.state.allow_shapefile || this.props.feature_layer_mapped_id != this.state.feature_layer_mapped_id){
      this.setState({feature_layer_mapped_name: this.props.feature_layer_mapped_name, feature_layer_url: this.props.feature_layer_url, feature_layer_mapped_attributes: this.props.attributes, feature_layer_map_index: this.props.feature_layer_map_index, feature_layer_mapped_fields: this.props.feature_layer_mapped_fields, feature_layer_fields: [], allow_shapefile: this.props.allowShapefile, feature_layer_mapped_id: this.props.feature_layer_mapped_id});
      if(this.props.feature_layer_mapped_name != ""){
        this.setState({show: true});
        //alert("Here");
        /*var numServices = this.props.esri_feature_services.length;
        for (var i = 0; i < numServices; i++) {
          if (this.props.esri_feature_services[i].name == this.props.feature_service_mapped_name) {
            if(this.props.esri_feature_services[i].layers){
              var numLayers = this.props.esri_feature_services[i].layers.length;
              for (var i2 = 0; i2 < numLayers; i2++) {
                if (this.props.esri_feature_services[i].layers[i2].name == this.props.feature_layer_mapped_name) {
                  if(this.props.esri_feature_services[i].layers[i2].fields){
                    let fields = this.props.esri_feature_services[i].layers[i2].fields.map(field => {
                      return { name: field.name, type: field.type };
                    });
                    this.setState({ feature_layer_fields: [{ name: '', type: '' }].concat(fields)});
                  }
                }
              }
            }
          }
        }*/
      }else{
        this.setState({show: false});
      }
      //this.updateFeatureLayers(this.props.feature_service_mapped_name);
      //console.log("Feature Layer Updated");
    }
  }

  featureServiceChange(e) {
    this.props.updateFeatureService(e.target.value, this.state.feature_layer_map_index);
    this.setState({feature_service_mapped_name: e.target.value, feature_layer_mapped_name: "", feature_layer_url: "", feature_layer_mapped_attributes: [], feature_layer_fields: [], feature_layer_mapped_fields: []});
    this.updateFeatureLayers(e.target.value);
  }

  featureLayerChange(e){
    this.props.updateFeatureLayer(e.target.value, this.state.feature_layer_map_index);
  }

  updateFeatureField(e){
    console.log(e.target.value + " " + e.target.getAttribute("data-field_type_id"));
    console.log(this.state.feature_layer_map_index);
    this.props.updateFeatureField(this.state.feature_layer_map_index, e.target.getAttribute("data-field_type_id"), e.target.value);
  }

  addLayer(){
    if(this.state.show == false){
      this.setState({show: true});
    }else{
      this.props.addFeatureLayer(this.state.feature_layer_map_index);
      console.log(this.state.feature_layer_map_index);
    }
  }

  attributeUpdate(e){
    var fieldName = e.target.value;
    var attributeArray = JSON.parse(JSON.stringify(this.state.feature_layer_mapped_attributes));
    console.log(attributeArray);
    if (!attributeArray.includes(fieldName)) {
      attributeArray.push(e.target.value);
    } else {
      var index = attributeArray.indexOf(e.target.value);
      if (index > -1) {
        attributeArray.splice(index, 1);
      }
    }
    this.setState({feature_layer_mapped_attributes: attributeArray });
    this.props.updateMappedAttributes(this.state.feature_layer_map_index, attributeArray);
  }

  changeShapefile(){
    var shapefileAllowed = this.state.allow_shapefile;
    if(shapefileAllowed == false){
      shapefileAllowed = true;
    }else{
      shapefileAllowed = false;
    }
    this.props.updateShapefile(this.state.feature_layer_map_index, shapefileAllowed);
  }

  deleteLayer(){
    this.props.deleteLayer(this.state.feature_layer_map_index);
    this.setState({showConfirmation: false});
  }

  closeModal(){
    this.setState({showConfirmation: false});
  }

  confirmDelete(){
    this.setState({showConfirmation: true});
  }

  render(){
    let value = this.context;
    var parent = this;
    //if(numMappedLayers > 0){
      //for(var i = 0; i < numMappedLayers; i++){
        return(
          <div className="layerOptionWrapper" data-map_id={this.props.feature_layer_map_index} data-feature_service_type_id={this.props.feature_service_type_id} data-feature_layer_type_id={this.props.feature_layer_type_id} data-feature_type={this.props.feature_type}>
            <Modal show={this.state.showConfirmation} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.deleteLayer}>
                  Delete
                </Button>
                <Button variant="secondary" onClick={this.closeModal}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="layerHeader" >
              {this.props.label}
              <Button  className="float-right" style={{float: "right", position: "absolute", right: "5px", top: "5px"}} onClick={this.addLayer}>+</Button>
            </div>
            <div className="layerSelection" style={{display: this.state.show ? "inline-block" : "none"}}>
              Feature Layer:
              <select id={this.props.name} onChange={this.featureLayerChange} className="featureLayerName" name={this.props.name + "FeatureLayer"} value={this.state.feature_layer_mapped_name}>
                {this.props.feature_type == "line" &&
                  parent.props.pathLayers.map((featureLayer) => <option key={featureLayer} value={featureLayer}>{featureLayer}</option>)
                }
                {this.props.feature_type == "line" &&
                  parent.props.multiPathLayers.map((featureLayer) => <option key={featureLayer} value={featureLayer}>{featureLayer}</option>)
                }
                {this.props.feature_type == "point" &&
                  parent.props.pointLayers.map((featureLayer) => <option key={featureLayer} value={featureLayer}>{featureLayer}</option>)
                }
                {this.props.feature_type == "polygon" &&
                  parent.props.polygonLayers.map((featureLayer) => <option key={featureLayer} value={featureLayer}>{featureLayer}</option>)
                }
              </select>
              {this.props.feature_fields.sort((a, b) => a.name > b.name ? 1 : -1).map((featureField, index) => 
                  <div key={index}>
                    {featureField.label}
                    <select id={this.props.name} onChange={this.updateFeatureField} className="featureFieldName" name={this.props.name+"Field"} data-field_type_id={featureField.field_type_id} value={parent.state.feature_layer_mapped_fields.filter(field => field.field_type_id.includes(featureField.field_type_id)).map(filteredField => (filteredField.alias))}>
                      {this.state.feature_layer_fields.filter((field) => field.type == "" || field.type == "esriFieldTypeDouble" || field.type == "esriFieldTypeInteger" || field.type == "esriFieldTypeSmallInteger").map((field) => <option key={field.name} value={field.name}>{field.name}</option>)}
                    </select>
                    <br />
                  </div>
                )
              }
              <div className="shapefile">
              Share Shapefile:&nbsp;
              <input id="shapefile" type="checkbox" name="watermainvalveShapefile" className="featureSelectedCheckbox" checked={this.state.allow_shapefile} onChange={this.changeShapefile} />
              </div>
              <div className="selectFields">
                <div className="selectFieldsHeader">
                  <b>Select Attributes To Share:</b>
                </div>
                {this.state.feature_layer_fields.filter((field) => field.name != "").map((field) =>
                  <div className="selectFieldOption" key={field.name}>
                    <input id={field.name} type="checkbox" className="featureSelectedCheckbox" checked={this.state.feature_layer_mapped_attributes.includes(field.name)} value={field.name} onChange={this.attributeUpdate} readOnly /> {field.name}
                  </div>
                )}
              </div>
              <Button variant="danger" onClick={this.confirmDelete}>
                  DELETE
              </Button>
            </div>
          </div>
        );
      //}
    /*}else{
      return (
        <div className="layerOptionWrapper" data-feature_service_type_id={this.props.feature_service_type_id} data-feature_layer_type_id={this.props.feature_layer_type_id} data-feature_type={this.props.feature_type}>
          <div className="layerHeader" >
            {this.props.label}
          </div>
          <div className="layerSelection">
            Feature Service:
            <select id={this.props.name} className="featureServiceName" data-feature_service_type_id={this.props.feature_service_type_id} name={this.props.name + "FeatureService"}>
                {this.props.esri_feature_services.map((featureService, index) => <option key={featureService.name + index} value={featureService.name}>{featureService.name}</option>)}            
            </select>
            Feature Layer:
            <select id={this.props.name} className="featureLayerName" name={this.props.name + "territoryFeatureLayer"}>
              
            </select>
            {this.props.feature_fields.sort((a, b) => a.name > b.name ? 1 : -1).map((featureField, index) => 
                <div key={index}>
                  {featureField.label}
                  <select id={this.props.name} className="featureFieldName" name={this.props.name+"Field"} data-field_type_id={featureField.field_type_id}>
                  </select>
                  <br />
                </div>
              )
            }
          </div>
          <Button  className="float-right" style={{float: 'right'}}>+</Button>
        </div>
      );
    }*/
  }
}

export default FeatureLayerWrapperKML;