import React from 'react';
import './NewsPreview.css';

class NewsPreview extends React.Component{

  render(){
    return (
      <div className="newsPreviewWrapper">
        <div className="newsPreviewTitle">
        {this.props.title}
        </div>
        <div className="newsPreviewImage">
          <img src={this.props.image} alt={this.props.title} />
        </div>
      </div>
    );
  }
}

export default NewsPreview;