import React, { Component, useState, useContext } from 'react';
import { Context } from "../configcontext/ConfigContext.js";
import axios from 'axios';
import {Table,Accordion,Button,Modal} from 'react-bootstrap'
import './FeatureLayerTypes.css'
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';


class FeatureLayerTypes extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            feature_layer_types: [],
            feature_service_types: [],

            edit: false,
            modalBody: "",
            modalHeader: "",
            show: false,
            loading: true,
            new_feature: {
                id: "",
                name: "",
                label: "",
                prefab:"",
                feature_service_type_id: "",
                feature_type: "",
                default_depth: "",
                default_diameter: "",
                default_height: "",
                default_scale: "",
                default_translate: "",
            }

        }
    }

    componentDidMount() {
        // read feature service types from database
        let value = this.context;
        this.readFeatureLayerTypes(value.jwt);
        this.readFeatureServiceTypes(value.jwt);
        this.setState({loading:false})
    }

    readFeatureLayerTypes = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/testing/v5/accounts/admin/readFeatureLayerTypes.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let flt_array = await res.data.FeatureLayerTypes;
            this.setState({ feature_layer_types: [...flt_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature layers. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }
    readFeatureServiceTypes = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/testing/v5/accounts/admin/readFeatureServiceTypes.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let fst_array = await res.data.FeatureServiceTypes;
            console.log(fst_array);
            this.setState({ feature_service_types: [...fst_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }


    handleSubmit = (event) => {
        // submit new feature type
        this.setState({loading:true})
        let new_feature_layer_type = this.state.new_feature;
        let value = this.context;
        if(this.state.edit && new_feature_layer_type.id !== ""){
            axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/editFeatureLayerType.php', { post: new_feature_layer_type }, { headers: { Authorization: 'Bearer ' + value.jwt } })
        	.then(res => {
                this.setState({ loading: false, modalBody: "Feature Layer successfully updated", modalHeader: "Success", show: true });
                this.readFeatureLayerTypes(value.jwt);

        	}).catch(error => {
        		if (error.response) {
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
        		} else if (error.request) {
        			// The request was made but no response was received
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
        		} else {
        			// Something happened in setting up the request that triggered an Error
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
        		}
            });
            this.setState({ edit: false })
        }else{
        axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/addFeatureLayerType.php', { post: new_feature_layer_type }, { headers: { Authorization: 'Bearer ' + value.jwt } })
        	.then(res => {
                this.setState({ loading: false, modalBody: "Feature Layer successfully added", modalHeader: "Success", show: true });
                this.readFeatureLayerTypes(value.jwt);

        	}).catch(error => {
        		if (error.response) {
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
        		} else if (error.request) {
        			// The request was made but no response was received
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
        		} else {
        			// Something happened in setting up the request that triggered an Error
        			this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
        		}
            });
        }
        //console.log(new_feature_layer_type)
        
        this.setState({
            new_feature: {
                id: "",
                name: "",
                label: "",
                prefab:"",
                feature_service_type_id: "",
                feature_type: "",
                default_depth: "",
                default_diameter: "",
                default_height: "",
                default_scale: "",
                default_translate: "",
            }
        })
        event.preventDefault();
    }

    handleDelete = (event, id) => {
        // delete feature layer type
        this.setState({loading:true})
        let value = this.context;
        if (id) {
            axios.post('https://www.arutility.com/api/testing/v5/accounts/admin/deleteFeatureLayerType.php', { deleteID: id }, { headers: { Authorization: 'Bearer ' + value.jwt } })
                .then(res => {
                    this.setState({ loading: false, modalBody: "Feature Layer successfully deleted.", modalHeader: "Success", show: true });
                    this.readFeatureLayerTypes(value.jwt);
                }).catch(error => {
                    if (error.response) {
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
                    }
                });
        }
        event.preventDefault();
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState((prevState) => ({
            new_feature: {                   // object that we want to update
                ...prevState.new_feature,    // keep all other key-value pairs
                [name]: value      // update the value of specific key
            }
        }))
    }

    handleData = () => {
        this.setState({ edit: false});
        this.setState({
            new_feature: {
                id: "",
                name: "",
                label: "",
                prefab:"",
                feature_service_type_id: "",
                feature_type: "",
                default_depth: "",
                default_diameter: "",
                default_height: "",
                default_scale: "",
                default_translate: "",
            }
        })
    }
    handleEdit = (e,feature_layer)=>{
        this.setState({
            new_feature: {                   
                id: feature_layer.id ,
                name: feature_layer.name ,
                label: feature_layer.label,
                feature_service_type_id: feature_layer.feature_service_type_id ,
                feature_type: feature_layer.feature_type,
                prefab:feature_layer.prefab,
                default_depth: feature_layer.default_depth,
                default_diameter: feature_layer.default_diameter,
                default_height: feature_layer.default_height,
                default_scale: feature_layer.default_scale,
                default_translate: feature_layer.default_translate
            },
        }
        )
        this.setState({ edit: true });
    }
    closeModal=()=>{
    	this.setState({show: false});
  	}
    render() {
        let value = this.context;
        const {loading, show} = this.state;

        //console.log(this.state)
        
        return (
            <>
                <div className="moduleDetailWrapper">
                    {this.state.loading &&
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={()=>this.closeModal()}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              	<Button variant="secondary" onClick={()=>this.closeModal()}>
					                	Close
					              	</Button>
					            </Modal.Footer>
					        </Modal>
                            <div className="adminPageTitle">
                                MANAGE FEATURE LAYER TYPES
                            </div>
                            <div className="adminPageDescription">

                         <div className={`${this.state.edit ? "col-75" : "col-100" }`} >
                            <Accordion>
                                {this.state.feature_service_types.map((feature_service,index)=>{
                                    return(
                                    <div key = {index}>
                                        <div className="panel-header">
                                            <Accordion.Toggle eventKey={index}>
                                            
                                            <b>{feature_service.label}</b>
                            
                                            </Accordion.Toggle>
                                        </div>
                                   
                                    <Accordion.Collapse eventKey={index}>
                                        <Table responsive striped bordered hover className={"feature-layer"}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Label</th>
                                                <th>Feature Type</th>
                                                <th>Prefab</th>
                                                <th>Default Depth</th>
                                                <th>Default Diameter</th>
                                                <th>Default Height</th>
                                                <th>Default Translate</th>
                                                <th>Default Scale</th>
                                                <th>Editing</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {
                                                this.state.feature_layer_types.filter((feature)=>feature.feature_service_type_id === feature_service.id).map((feature_layer,index)=>{
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{feature_layer.id}</td>
                                                            <td>{feature_layer.name}</td>
                                                            <td>{feature_layer.label}</td>
                                                            <td>{feature_layer.feature_type}</td>
                                                            <td>{feature_layer.prefab}</td>
                                                            <td>{feature_layer.default_depth}</td>
                                                            <td>{feature_layer.default_diameter}</td>
                                                            <td>{feature_layer.default_height}</td>
                                                            <td>{feature_layer.default_translate}</td>
                                                            <td>{feature_layer.default_scale}</td>
                                                            <td><Button variant="secondary" onClick={(e)=>this.handleEdit(e,feature_layer)}>Edit</Button>{" "}
                                                            <Button variant="danger" onClick={(e)=>{if(window.confirm('Are you sure you want to delete?')){
                                                                                                    this.handleDelete(e,feature_layer.id)   }
                                                                                            else{
                                                                                               e.preventDefault()
                                                                                            }
                                                    }}>Delete</Button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    </Accordion.Collapse>
                                </div>
                            )})}
                            </Accordion>
                            <button type="button" className={"cancel-btn"} onClick={(e)=>(this.setState({edit:true}))}> Add New Feature Layer Type</button>

                        </div>


                        {this.state.edit ? 
                            <div className="col-25">
                            <form onSubmit={this.handleSubmit}>
                                <div>
                                    <label >
                                        Name:
                                    </label>
                                    <input style={{ float: "right" }} required type="text" name="name" value={this.state.new_feature.name} onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label>
                                        Label:
                                    </label>
                                    <input style={{ float: "right" }} required type="text" name="label" value={this.state.new_feature.label} onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label >
                                        Feature Service Type:
                                    </label>
                                    <select style={{ float: "right" }} required type="option" name="feature_service_type_id" onChange={(event) => this.handleChange(event)} value={this.state.new_feature.feature_service_type_id} >
                                        <option></option>
                                        {this.state.feature_service_types.map((field) =>
                                            <option key={field.name} value={field.id}>{field.label}</option>)}
                                    </select>
                                </div>
                                <div>
                                    <label>
                                        Feature Type:
                                    </label>
                                    <select style={{ float: "right" }} required type="option" name="feature_type" onChange={(event) => this.handleChange(event)} value={this.state.new_feature.feature_type}>
                                        <option></option>
                                        <option>line</option>
                                        <option>point</option>
                                    </select>
                                </div>
                                <div>
                                    <label>
                                        Prefab:
                                    </label>
                                    <input style={{ float: "right" }} required type="text" name="prefab" value={this.state.new_feature.prefab} onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label>
                                        Default Depth:
                                    </label>
                                    <input style={{ float: "right" }} required type="number" name="default_depth" value={this.state.new_feature.default_depth} onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label>
                                        Default Diameter:
                                    </label>
                                    <input style={{ float: "right" }} required type="number" name="default_diameter" value={this.state.new_feature.default_diameter} onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label>
                                        Default Height:
                                    </label>
                                    <input style={{ float: "right" }} required type="number" name="default_height" value={this.state.new_feature.default_height} onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label>
                                        Default Scale:
                                    </label>
                                    <input style={{ float: "right" }} required type="text" name="default_scale" value={this.state.new_feature.default_scale} onChange={(e) => this.handleChange(e)} 
                                    placeholder={"[x,y,z]"}/>
                                </div>
                                <div>
                                    <label>
                                        Default Translate:
                                    </label>
                                    <input style={{ float: "right" }} type="text" name="default_translate" value={this.state.new_feature.default_translate} onChange={(e) => this.handleChange(e)} placeholder={"[x,y,z]"}/>
                                </div>
                                <div>
                                    <input type="submit" value="Submit" />
                                </div>
                                </form>
                                <div>
                                    <button className = {"cancel-btn"} onClick={this.handleData}>Cancel</button>
                                </div>
                            
                        </div> : null}
                    </div>
                </div>
            </>
        )
    }
}
export default FeatureLayerTypes