import React from 'react';
import { loadModules } from 'esri-loader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import './EsriMapCreateProject.css';

class EsriMapCreateProject extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			projectId: "",
			requestId: "",
			assets: [],
			loading: true,
			jwt: "",
			projectBoundary: []
		}
		this.mapRef = React.createRef();
	}

  	componentDidMount() {
  		let value = this.context;
    	// lazy load the required ArcGIS API for JavaScript modules and CSS
    	//var jwt = localStorage.getItem('jwt');
    	this.setState({jwt: value.jwt, loading: false});
    	loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer','esri/views/draw/Draw', 'esri/widgets/Search','esri/widgets/Sketch', 'esri/geometry/Extent','esri/geometry/support/webMercatorUtils'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer, Draw, Search, Sketch, Extent, WebMercatorUtils]) => {
	      	this.map = new ArcGISMap({
		        basemap: 'streets',
		        spatialReference: {
				    wkid: 4326
				}
		    });

		    this.layer = new GraphicsLayer({
			  	graphics: []
			});

	      	this.view = new MapView({
		        container: this.mapRef.current,
		        map: this.map,
		        center: [-98.579532, 39.828577],
		        zoom: 5
		    });

		    /*this.view.extent = new Extent({
			  spatialReference: {
			    wkid: 4326
			  }
			});*/

	      	this.toggle = new BasemapToggle({
			  	view: this.view,  // view with map that uses "streets" basemap
			  	nextBasemap: "satellite",  // Allows for toggling to "hybrid" basemap
			  	index: 0
	      	});

	      	this.search = new Search({
			  	view: this.view,
			  	index: 1
	      	});

	      	this.sketch = new Sketch({
	      		view: this.view,
	      		layer: this.layer,
	      		index: 2,
	      		availableCreateTools: ["polygon"],
	      		creationMode: "single"
	      	});

	      	var parent = this;

	      	// Listen to sketch widget's create event.
			this.sketch.on("create", function(event) {
			  parent.layer.removeAll();
			  if (event.state === "complete") {
			  	this.graphics = [];
			  	this.graphics.push(event.graphic);
				parent.layer.add(event.graphic);
				parent.map.add(parent.layer);
				parent.view.goTo(this.graphics);
				parent.props.coordinateHandler(WebMercatorUtils.webMercatorToGeographic(event.graphic.geometry));
			  }
			});

	      	this.view.ui.add(this.toggle, "top-trailing");
	      	this.view.ui.add(this.search, "top-left");
	      	this.view.ui.add(this.sketch, "top-trailing");
		});
  	}

  	componentWillUnmount() {
    	if (this.view) {
      		// destroy the map view
      		this.view.container = null;
    	}
  	}

  	render(){
	  	const {loading} = this.state;
	  	if(loading){
	  		return(
		  		<div>
		  			<LoadingSpinner />
		  		</div>
		  	);
	  	}else{
			return(
				<div className="mapWrapper">
					<div className="map" id="map" ref={this.mapRef}>
			  		</div>
				</div>
			);
		}
  	}
}

export default EsriMapCreateProject;