import React from 'react';
import './ProjectBIM.css';

class ProjectBIM extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
        thumbURL: "",
        projectName: ""
    }
  }

  componentDidMount(){
    if(this.props.projectThumb == "" || this.props.projectThumb == null){
      this.setState({thumbURL: "https://www.arutility.com/images/nobimpreview.png"});
    }else{
      this.setState({thumbURL: "https://www.arutility.com/uploads/objFiles/" + this.props.projectDirectory + "/" + this.props.projectThumb});
    }
    this.setState({projectName: this.props.projectName});
  }

  render(){
    return (
      <div className="bimPreviewWrapper">
        <div className="bimPreviewImage">
          <img src={this.state.thumbURL} alt={this.state.projectName} />
        </div>
        <div className="bimPreviewTitle">
        {this.state.projectName}
        </div>
      </div>
    );
  }
}

export default ProjectBIM;