import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './Authorize.css';

class Authorize extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
        jwt: "",
        loading: true,
        username: "",
        role: "",
        superUser: false
    }
    this.finishAuthenticating = this.finishAuthenticating.bind(this);
  }

  finishAuthenticating(){
    const value = this.context;
    let search = new URLSearchParams(this.props.location.search);
    if(search.get("username")){
        this.setState({username: search.get("username")});
        value.updateUsername(search.get("username"));
      }
      if(search.get("role")){
        this.setState({role: search.get("role")});
        value.updateUserRole(search.get("role"));
      }else{
        this.setState({role: "guest"});
        value.updateUserRole("guest");
      }
      if(search.get("companyName")){
        value.updateCompanyname(search.get("companyName"));
      }
      if(search.get("accountType")){
        value.updateAccountType(search.get("accountType"));
      }
      this.setState({loading: false});
  }

  componentDidMount(){
    const value = this.context;
    let search = new URLSearchParams(this.props.location.search);
    if(search.get("token")){
      var jwt = search.get("token");
      this.setState({jwt:jwt});
      value.updateJWT(jwt);
      axios.post('https://www.arutility.com/api/v5/users/readPermissions.php',{},{headers: {Authorization: 'Bearer ' + jwt}})
        .then(res => {
          value.updateUserPermissions(res.data[0].userPermissions[0]);
          value.updateAccountPermissions(res.data[0].accountPermissions[0]);
          if(res.data[0].userPermissions[0].includes("user:super")){
            this.setState({superUser: true});
          }
          this.finishAuthenticating();
        }).catch(error => {
          this.setState({jwt:"", loading: false});
      });
    }else{
      this.setState({jwt:jwt, loading: false});
      value.updateJWT("");
    }
  }

  render(){
    const {jwt, loading, superUser} = this.state;
    if(loading){
      return(
        <LoadingSpinner />
      );
    }else{
      if(jwt == "" || !jwt){
        return (
          <Route>
            <Redirect to="/login" />
          </Route>
        );
      }else{
        if(superUser){
          return(
            <div>
              <Route>
                <Redirect to="/admin/console" />
              </Route>
            </div>
          );
        }else{
          return(
            <div>
              <Route>
                <Redirect to="/projects" />
              </Route>
            </div>
          );
        }
      }
    }
  }
}

export default Authorize;