import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        name: "",
        email: "",
        message: "",
        subject: "General",
        loading: false,
        modalBody: "",
        modalHeader: "",
        show: false,
        verified: false
    }
    this.updateForm = this.updateForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.recaptchaVerified = this.recaptchaVerified.bind(this);
    this.recaptchaExpired = this.recaptchaExpired.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  recaptchaExpired(){
    this.setState({verified: false});
  }

  recaptchaVerified(){
    this.setState({verified: true});
  }

  updateForm(e){
    this.setState({[e.target.id]:e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  submitForm(){
    if(this.state.verified != false){
      if(this.state.name != "" && this.state.email != "" && this.state.subject != "" && this.state.message != ""){
        this.setState({loading: true});
        axios.post('https://www.arutility.com/sendcontact.php',{contactName: this.state.name, contactEmail: this.state.email, contactSubject: this.state.subject, contactMessage: this.state.message})
        .then(res => {
          this.setState({loading: false, modalHeader: "Success", modalBody: "Message was successfully received. We will respond within 2 business days.", show: true});
        }).catch(error => {
            if (error.response) {
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.message, modalHeader: "Error", show: true});
          } else if (error.request) {
            // The request was made but no response was received
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
          } else {
            // Something happened in setting up the request that triggered an Error
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
          }
        });
      }else{
        this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
      }
    }else{
      this.setState({loading: false, modalBody: "Sorry, you did not complete the captcha", modalHeader: "Error", show: true});
    }
  }

  render(){
    let value = this.context;
    const {show} = this.state;
    return (
      <div>
        <MainHeader />
        {this.state.loading && 
          <LoadingSpinner />
        }
        <Modal show={show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="pageTitle">
          CONTACT
        </div>
        <div className="pageDescription">
          <form className="contactForm">
            Send us a message and we will respond within 1-2 business days.
            <br/>
            <br/>
            Name:
            <input type="text" placeholder="Name" className="contactInput" id="name" onChange={this.updateForm} value={this.state.name}/>
            <br/>
            Email:
            <input type="text" placeholder="Email" className="contactInput" id="email" onChange={this.updateForm} value={this.state.email} />
            <br/>
            Subject:
            <br/>
            <select id="subject" onChange={this.updateForm} value={this.state.subject}>
              <option value="General">General</option>
                <option value="Media">Media</option>
                <option value="Request Demo">Request Demo</option>
            </select>
            <br/>
            Message Text:
            <textarea id="message" className="contactArea" placeholder="Insert message here" form="contactForm" onChange={this.updateForm} value={this.state.message}></textarea>
            <div className="recaptchaWrapper">
              <ReCAPTCHA
                  sitekey="6LcPxuYUAAAAAPaJX64OnB190ljQCB0kSBalA43X"
                  size="normal"
                  onChange={this.recaptchaVerified}
                  onExpired={this.recaptchaExpired}
              />
            </div>
            <div className="submitContactBtnWrapper">
              <Button variant="primary" onClick={this.submitForm}>
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Contact;