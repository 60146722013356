import React from 'react';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import EsriCommunityMap from '../esricommunitymap/EsriCommunityMap';
import './AllProjectsMap.css';

class AllProjectsMap extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {

		}
	}

  	render(){
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("communitymap:view")){
			    return (
			      	<div className="mainWrapper">
			    		<div className="mainWindow">
					      <div className="projectViewWrapper">
					      	<EsriCommunityMap />
					      </div>
					    </div>
				    </div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}	
		}
  	}
}

export default AllProjectsMap;