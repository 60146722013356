import React from 'react';
import axios from 'axios';
import {Link, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import EsriEditSessionMap from '../esrieditsessionmap/EsriEditSessionMap';
import SessionFeature from '../sessionfeature/SessionFeature';
import './EditSessionView.css';

class EditSessionView extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    sessionId: "",
		    sessionName: "",
		    sessionFeatures: [],
		    selectedFeatures: [],
		    selectedFeature: "",
		    unselectedFeature: "",
		    graphics: [],
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.handleApproveAllClick = this.handleApproveAllClick.bind(this);
		this.handleApproveSelectedClick = this.handleApproveSelectedClick.bind(this);
		this.handleDenyAllClick = this.handleDenyAllClick.bind(this);
		this.handleDenySelectedClick = this.handleDenySelectedClick.bind(this);
		this.updateAttribute = this.updateAttribute.bind(this);
		this.updateSelected = this.updateSelected.bind(this);
		this.updateGraphics = this.updateGraphics.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    var id = this.props.match.params.id;
	    this.setState({sessionId: id});
	    axios.post('https://www.arutility.com/api/v4/features/readOne.php',{id: id},{headers: {Authorization: 'Bearer ' + value.jwt}})
		.then(res => {
	        	this.setState({sessionFeatures: res.data.features, sessionName: res.data.sessionId, loading: false});
	     }).catch(error => {
	       	if (error.response) {
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
			} else if (error.request) {
			    // The request was made but no response was received
			    this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
			} else {
			    // Something happened in setting up the request that triggered an Error
			    this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
			}
	    });
  	}

  	handleApproveAllClick(){
	  	let value = this.context;
	  	this.setState({loading: true});
	  	axios.post('https://www.arutility.com/api/v4/features/update.php',{session_id: this.props.match.params.id, response_code: 1},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	        this.setState({loading: false, modalBody: "Features successfully approved", modalHeader: "Success", show: true });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
  	}

  	handleApproveSelectedClick(){
	  	if(this.state.selectedFeatures.length > 0){
		  	let value = this.context;
		  	var featureInfo = [];
		  	var numSelectedFeatures = this.state.selectedFeatures.length;
		  	var numFeatures = this.state.sessionFeatures.length;
		  	for(var i=0; i<numSelectedFeatures; i++){
		  		for(var i2=0; i2<numFeatures; i2++){
		  			if(this.state.sessionFeatures[i2].feature_id == this.state.selectedFeatures[i]){
		  				var attributes = this.state.sessionFeatures[i2].attributes;
		  				var attributeKeys = Object.keys(attributes[0]);
					    var attributeArray = [];
					    for(var i3=0; i3<attributeKeys.length;i3++){
					      var keyName = attributeKeys[i3];
					      var fieldValue = attributes[0][keyName];
					      var arrayItem = {"field":keyName,"value":fieldValue};
					      attributeArray.push(arrayItem);
					    }
		  				var featureItem = {"feature_id": this.state.selectedFeatures[i],"attributes":attributeArray};
		  				featureInfo.push(featureItem);
		  			}
		  		}
		  	}
		  	this.setState({loading: true});
		  	axios.post('https://www.arutility.com/api/v4/features/updateSelected.php',{session_id: this.props.match.params.id, response_code: 1, selected_features: this.state.selectedFeatures, feature_info: featureInfo},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        this.setState({loading: false, modalBody: "Records successfully approved", modalHeader: "Success", show: true });
		    }).catch(error => {
		        if(error.response){
			      	if(error.response.status == 401){
			        	this.setState({modalBody: "Sorry, you are not authorized to perform this action.", modalHeader: "Error", loading: false, show: true});
			      	}else{
			        	this.setState({modalBody: "Sorry, it appears something went wrong. Please try again.", modalHeader: "Error", show: true, loading: false});
			      	}
			    }else if(error){
			    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false})
			    }else{
			    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false})
			    }
		    });
		}else{
			this.setState({modalBody: "Sorry, no features were selected", modalHeader: "Error", loading: false, show: true});
		}
  	}

  	handleDenyAllClick(){
	  	let value = this.context;
	  	this.setState({loading: true});
	  	axios.post('https://www.arutility.com/api/v4/features/update.php',{session_id: this.props.match.params.id, response_code: 2},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	        this.setState({loading: false, modalBody: "Features successfully denied.", modalHeader: "Success", show: true });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
  	}

  	handleDenySelectedClick(){
	  	if(this.state.selectedFeatures.length > 0){
		  	let value = this.context;
		  	this.setState({loading: true});
		  	axios.post('https://www.arutility.com/api/v4/features/updateSelected.php',{session_id: this.props.match.params.id, response_code: 2, selected_features: this.state.selectedFeatures},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        this.setState({loading: false, modalBody: "Records successfully denied", modalHeader: "Success", show: true });
		    }).catch(error => {
		        if(error.response){
			      	if(error.response.status == 401){
			        	this.setState({modalBody: "Sorry, you are not authorized to perform this action.", modalHeader: "Error", loading: false, show: true});
			      	}else{
			        	this.setState({modalBody: "Sorry, it appears something went wrong. Please try again.", modalHeader: "Error", show: true, loading: false});
			      	}
			    }else if(error){
			    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false})
			    }else{
			    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false})
			    }
		    });
		}else{
			this.setState({modalBody: "Sorry, no features were selected", modalHeader: "Error", loading: false, show: true});
		}
  	}

  	updateAttribute(attributes){
	  	var featuresArray = this.state.sessionFeatures;
	  	var numFeatures = featuresArray.length;
	  	for(var i=0; i<numFeatures; i++){
	  		if(attributes.featureId == featuresArray[i].feature_id){
	  			featuresArray[i].attributes = attributes.attributes;
	  		}
	  	}
	  	this.setState({sessionFeatures: featuresArray});
  	}

  	updateSelected(featureId){
	  	if(this.state.selectedFeatures.indexOf(featureId) < 0){
	  		var selectFeaturesArray = this.state.selectedFeatures;
	  		selectFeaturesArray.push(featureId);
	  		this.setState({selectedFeatures: selectFeaturesArray, selectedFeature: featureId, unselectedFeature: ""});
	  	}else{
	  		var selectFeaturesArray = this.state.selectedFeatures;
	  		var index = this.state.selectedFeatures.indexOf(featureId);
	  		selectFeaturesArray.splice(index,1);
	  		this.setState({selectedFeatures: selectFeaturesArray, selectedFeature: "", unselectedFeature: featureId});
	  	}
  	}

  	updateGraphics(graphics){
  		this.setState({graphics: graphics});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	const {show} = this.state;
	  	let value = this.context;
	    if (value.jwt == "") {
	      	return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("editsession:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
						      	<LoadingSpinner />
						    }
						    <Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      <div className="editSessionHeader">
					      	<div className="editSessionClose">
					      		<Link to="/session/edits">
						      		CLOSE
						      	</Link>
					      	</div>
					      	<div className="editSessionTitle">
					      		SESSION: {this.state.sessionName}
					      	</div>
					      </div>
					      <div className="editSessionResponses">
					      	<Button variant="success" onClick={this.handleApproveAllClick} >Approve All</Button>
					      	<Button variant="success" onClick={this.handleApproveSelectedClick} >Approve Selected</Button>
					      	<Button variant="danger" onClick={this.handleDenyAllClick} >Deny All</Button>
					      	<Button variant="danger" onClick={this.handleDenySelectedClick} >DenySelected</Button>
					      </div>
					      <div className="requestViewWrapper">
					 		<EsriEditSessionMap sessionId={this.props.match.params.id} updateGraphics={this.updateGraphics} selectedFeature={this.state.selectedFeature} unselectedFeature={this.state.unselectedFeature}/>
					      </div>
					      <div className="addedFeaturesWrapper">
					      	ADDED ASSETS:
					      	{this.state.sessionFeatures.map(feature => {
					      		var startLng = "";
					      		var startLat = "";
					      		var endLng = "";
					      		var endLat = "";
					      		if(feature.feature_type == "line"){
						            var featurePaths = feature.paths[0][0];
						            startLng = featurePaths[0][0];
						            startLat = featurePaths[0][1];
						            endLng = featurePaths[1][0];
						            endLat = featurePaths[1][1];
						        }
						        if(feature.feature_type == "point"){
						        	var featurePoints = feature.points[0];
						            startLng = featurePoints.x;
						            startLat = featurePoints.y;
						        }
					      		return(
					      			<SessionFeature key={feature.feature_id} featureStatus={feature.status} featureId={feature.feature_id} featureType={feature.feature_layer_name} featureStartLng={startLng} featureStartLat={startLat} featureEndLng={endLng} featureEndLat={endLat} featureAttributes={feature.attributes} updateAttribute={this.updateAttribute} updateSelected={this.updateSelected}/>
					      		);
				       		})}
					      </div>
					    </div>
				    </div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}
		}
  	}
}

export default EditSessionView;