import React from 'react';
import axios from 'axios';
import {Link, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import {OBJModel} from 'react-3d-viewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BIMProjectView.css';

class BIMProjectView extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    jwt: "",
		    loading: true,
		    projectId: "",
		    projectName: "",
		    objectFile: "",
		    materialFile: "",
		    projectDescription: "",
		   	modelLatitude: 0.0,
		   	modelLongitude: 0.0,
		   	projectThumb: "",
		   	modalHeader: "",
		   	modalBody: "",
		   	show: false,
		   	deleteVerify: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateProject = this.updateProject.bind(this);
		this.deleteProject = this.deleteProject.bind(this);
		this.deleteConfirm = this.deleteConfirm.bind(this);
	}

  	componentDidMount() {
	  	let value = this.context;
	    //var jwt = localStorage.getItem("jwt");
	    var id = this.props.match.params.id;
	    this.setState({loading: true, projectId: id});
	    //this.setState({jwt: jwt});
	    axios.post('https://www.arutility.com/api/v4/project/readOneBIM.php',{id: id},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	        const projectInfo = res.data;
	        var objectFile = "https://www.arutility.com/uploads/objFiles/" + projectInfo.projectDirectory + "/" + projectInfo.objectFile;
	        var materialFile = "https://www.arutility.com/uploads/objFiles/"  + projectInfo.projectDirectory + "/" + projectInfo.materialFile;
	        if(projectInfo.projectThumb == null || projectInfo.projectThumb == ""){
	        	var projectThumb = "https://www.arutility.com/images/nobimpreview.png";
	        }else{
	        	var projectThumb = "https://www.arutility.com/uploads/objFiles/"  + projectInfo.projectDirectory + "/" + projectInfo.projectThumb;
	        }
	        this.setState({projectName: projectInfo.projectName, projectDescription: projectInfo.projectDescription, objectFile: objectFile, materialFile: materialFile, modelLatitude: projectInfo.modelLatitude, modelLongitude: projectInfo.modelLongitude, projectThumb: projectThumb, loading: false});
	    }).catch(error => {
	    	this.setState({loading: false});
		});
  	}

  	updateProject(){
  		let value = this.context;
	    this.setState({loading: true});
	    axios.post('https://www.arutility.com/api/v4/project/updateBIM.php',{id: this.state.projectId, projectName: this.state.projectName, projectDescription: this.state.projectDescription, longitude: this.state.modelLongitude, latitude: this.state.modelLatitude},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	        this.setState({loading: false, modalBody: "Project successfully updated", modalHeader: "Success", show: true});
	    }).catch(error => {
	    	if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
		});
  	}

  	deleteProject(){
  		this.setState({deleteVerify: true, modalHeader: "Confirmation", modalBody: "Are you sure you want to delete this project? This action cannot be undone.", show: true});
  	}

  	deleteConfirm(){
	    let value = this.context;
	    this.setState({loading: true, show: false});
	    axios.post('https://www.arutility.com/api/v4/project/deleteBIM.php',{id: this.state.projectId},{headers: {Authorization: 'Bearer ' + value.jwt}})
	        .then(res => {
	          	this.setState({loading: false, show: true, modalHeader: "Success", modalBody: "Project successfully deleted.", deleteVerify: false});
	          	this.props.history.push("/bimprojects");
	      }).catch(error => {
	          if (error.response) {
	            this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, deleteVerify: false});
	        } else if (error.request) {
	          // The request was made but no response was received
	            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, deleteVerify: false});
	        } else {
	          // Something happened in setting up the request that triggered an Error
	            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error, modalHeader: "Error", show: true, deleteVerify: false});
	        }
	    });
	}

  	handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    closeModal(){
        this.setState({show: false, deleteVerify: false});
    }

  	render(){
	  	let value = this.context;
	  	const {objectFile} = this.state;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("projectsbim:view")){
			    return (
			      	<div className="mainWrapper">
			    		<div className="mainWindow">
					      	{this.state.loading &&
					      		<LoadingSpinner />
					      	}
					      	<Modal show={this.state.show} onHide={this.closeModal}>
		                    <Modal.Header closeButton>
		                      <Modal.Title>{this.state.modalHeader}</Modal.Title>
		                    </Modal.Header>
		                    <Modal.Body>{this.state.modalBody}</Modal.Body>
		                    <Modal.Footer>
	                      	{this.state.deleteVerify &&
			                  <Button variant="danger" onClick={this.deleteConfirm}>
			                    Delete
			                  </Button>
			                }
			                  <Button variant="secondary" onClick={this.closeModal}>
			                    Close
			                  </Button> 
			                </Modal.Footer>
		                </Modal>
					      	<div className="projectViewWrapper">
					      		<div className="projectViewClose">
						      		<Link to="/bimprojects">
						      			CLOSE
						      		</Link>
					      		</div>
					      		<div className="projectViewTitle">
					      			{this.state.projectName} 
					      		</div>
					      		<div className="projectViewDesc">
					      			<div className="bimProjectViewWrapper">
					      				<div className="bimProjectThumb">
					      					<img src={this.state.projectThumb} alt="Project Thumbnail" />
					      				</div>
					      				<div className="bimProjectInfo">
					      					<b>Project Name:</b>
						                    <br/>
						                    <input type="text" placeholder="e.g. New Hospital" className="projectInputField" name="projectName" id="projectName" required="" onChange={this.handleChange} value={this.state.projectName}/>
						                    <br/>
						                    <b>Project Description:</b>
						                    <br/>
						                    <textarea placeholder="e.g. Brand new hospital construction" className="projectInputArea" name="projectDescription" id="projectDescription" required="" onChange={this.handleChange} value={this.state.projectDescription}></textarea>
					      					<br/>
					      					<b>Model Latitude:</b>
					      					<br/>
						                    <input type="number" placeholder="Latitude of model origin at 0,0,0" className="projectInputField" name="modelLatitude" id="modelLatitude" onChange={this.handleChange} value={this.state.modelLatitude}/>
						                    <br/>
						                    <b>Model Longitude:</b>
						                    <br/>
						                    <input type="number" placeholder="Longitude of model origin at 0,0,0" className="projectInputField" name="modelLongitude" id="modelLongitude" onChange={this.handleChange} value={this.state.modelLongitude}/>
					      					<div className="bimPreviewButtons">
					      						{value.userPermissions.includes("project:update") && 
					      							<div>
					      								<Button variant="primary" onClick={this.updateProject}>Update</Button>
					      								<Button variant="danger" onClick={this.deleteProject}>Delete</Button>
					      							</div>
					      						}
					      					</div>
					      				</div>
					      			</div>
					      			<div className="modelPreviewWrapper">
					      				<OBJModel src={objectFile} texPath="" />
					      			</div>
					      		</div>
				      		</div>
			      		</div>
			   		</div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}	
		}
  	}
}

export default BIMProjectView;