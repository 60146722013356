import React from 'react';
import axios from 'axios';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProfileSettings.css';

class ProfileSettings extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    jwt: "",
		    loading: true,
		    emailPrefs: [],
		    mapping: [],
		    emailAddress: "",
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.changeRadio = this.changeRadio.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v4/users/reademailprefs.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	      	this.setState({emailPrefs: res.data.email_notifications, emailAddress: res.data.email_address});
	    }).catch(error => {
		    if (error.response) {
	        	if (error.response.status == 401) {
					this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
				} else {
					this.setState({ modalBody: "Sorry, we were unable to retrieve your account preferences. Please try again.", modalHeader: "Error", show: true, loading: false });
				}
		    } else if (error) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
		});
	    //NOW GET ALL FEATURE SERVICE TYPES
		axios.post('https://www.arutility.com/api/v5/esri/featureService/readAll.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } }).then(res => {
			this.setState({mapping: res.data.message, loading: false});
		}).catch(error => {
			if (error.response) {
				if (error.response.status == 401) {
					this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
				} else {
					this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
				}
			} else if (error) {
				this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
			} else {
				this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
			}
		});
  	}

  	changeRadio(e){
	  	if(this.state.emailPrefs.includes(e.target.value)){
	  		var emailPrefCopy = JSON.parse(JSON.stringify(this.state.emailPrefs));
	  		emailPrefCopy.splice(this.state.emailPrefs.indexOf(e.target.value,0),1);
	  		this.setState({emailPrefs: emailPrefCopy});
	  	}else{
	  		var emailPrefCopy = JSON.parse(JSON.stringify(this.state.emailPrefs));
	  		emailPrefCopy.push(e.target.value);
	  		this.setState({emailPrefs: emailPrefCopy});
	  	}
  	}

  	handleSubmit(){
	  	let value = this.context;
	  	this.setState({loading: true});
	    var requestArray = [];
	    for(var i=0; i<this.state.mapping.length; i++){
	    	var serviceChecked = this.state.emailPrefs.includes(this.state.mapping[i].feature_service_type_id);
	    	var objectString = {"feature_service_type_id":this.state.mapping[i].feature_service_type_id, "checked": serviceChecked};
	    	requestArray.push(objectString);
	    }
	  	axios.post('https://www.arutility.com/api/v4/users/updateemailprefs.php',{feature_service_types: requestArray, email_address: this.state.emailAddress},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      	.then(res => {
	       		this.setState({modalBody: "Profile updated successfully", modalHeader: "Success", show: true, loading: false});
	    	}).catch(error => {
	      	if(error.response.status == 401) {
	        	this.setState({authorized: false, loading: false});
	      	}else{
	        	this.setState({modalBody: "Sorry, we were unable to update your profile. Please try again.", modalHeader: "Error", show: true, loading: false});
	      	}
	  	});
  	}

  	handleChange(e){
  		this.setState({emailAddress: e.target.value});
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
		    return (
		   		<div className="mainWrapper">
			    	<div className="mainWindow">
			   			{this.state.loading &&
			   				<LoadingSpinner />
			   			}	
			   			<Modal show={show} onHide={this.closeModal}>
				            <Modal.Header closeButton>
				              <Modal.Title>{this.state.modalHeader}</Modal.Title>
				            </Modal.Header>
				            <Modal.Body>{this.state.modalBody}</Modal.Body>
				            <Modal.Footer>
				              <Button variant="secondary" onClick={this.closeModal}>
				                Close
				              </Button>
				            </Modal.Footer>
				        </Modal>
				      	<div className="pageHeader">
				      	EMAIL NOTIFICATIONS
				      	</div>
				      	<div className="profileBody">
				      		Choose the feature services you would like to receive notifications for when new requests arrive
					      	<br/>
					      	<br/>
				            <font color="#c62828">Email Address:</font>
				            <br/>
				            <input type="text" name="emailAddress" id="emailAddress" className="companyInput" value={this.state.emailAddress} onChange={this.handleChange} />
				            <br/>
				            <br/>
				            {this.state.mapping.filter((featureService) => featureService.feature_service_type_id != "8").sort(function(a, b){if(a.sorting < b.sorting) return -1; if(a.sorting > b.sorting) return 1; if(a.sorting == b.sorting){if(a.name > b.name) return 1; if(a.name < b.name) return -1;}}).map((featureService, index) => 
								<div key={featureService.feature_service_type_id}>
									<input type="checkbox" name={featureService.name} id={featureService.name} checked={this.state.emailPrefs.includes(featureService.feature_service_type_id)} onChange={this.changeRadio} value={featureService.feature_service_type_id} className="featureSelectedCheckbox"/> {featureService.label}
									<br/>
								</div>
								)
							}
				        	<button id="submitProfileSettings" className="submitProjectBtn" onClick={this.handleSubmit} >SAVE CHANGES</button>
				      	</div>
				    </div>
		      	</div>
		    );
		}
  	}
}

export default ProfileSettings;