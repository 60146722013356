import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import DeviceHeader from '../deviceheader/DeviceHeader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import Device from '../device/Device';
import './DeviceList.css';

class DeviceList extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
        devices: [],
        filteredDevices: [],
        searchString: "",
        searching: false,
        loading: true,
        modalBody: "",
        modalHeader: "",
        show: false
    }
    this.updateSearch = this.updateSearch.bind(this);
  }

  componentDidMount() {
    //let value = this.context;
    axios.post('https://www.arutility.com/api/v5/devices/read.php',{})
    .then(res => {
        const devices = res.data;
        devices.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer));
        this.setState({ devices: devices, loading: false });
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  updateSearch(searchString){
    if(searchString != ""){
        const searchResults = this.state.devices.filter(device => 
          device.manufacturer.toLowerCase().includes(searchString.toLowerCase()) ||
          device.model_name.toLowerCase().includes(searchString.toLowerCase())
        );
        this.setState({searching: true, filteredDevices: searchResults});
    }else{
      this.setState({searching: false, filteredDevices: []});
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        {this.state.loading &&
          <LoadingSpinner />
        }
        <DeviceHeader updateSearch={this.updateSearch}/>
        <div className="devicesExplanation">
        Below is a list of supported android devices. You can use the search tool to quickly look for your device.
        <br/>
        <br/>
        Any iOS device running iOS 11 and contains an A9 chip, at a minimum, can support the ARUtility application.
        </div>
        <div className="devicesWrapper">
          {(this.state.searching == false) ? 
            (this.state.devices.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer)).map(device => {
                return(
                  <Device key={device.id} deviceName={device.model_name} deviceManufacturer={device.manufacturer} deviceCode={device.model_code}imgURL={device.img_url} updateSearch={this.updateSearch}/>
                );
              })
            )
            :
            (this.state.filteredDevices.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer)).map(device => {
                return(
                  <Device key={device.id} deviceName={device.model_name} deviceManufacturer={device.manufacturer} deviceCode={device.model_code} imgURL={device.img_url} updateSearch={this.updateSearch}/>
                );
              })
            )
          }
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default DeviceList;