import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom'
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { SketchPicker } from 'react-color';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AccountSettings.css';

class AccountSettings extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    jwt: "",
		    loading: true,
		    authorized: false,
		    accountType: "",
		    companyName: "",
		    address1: "",
		    address2: "",
		    accountCity: "",
		    accountState: "",
		    accountCountry: "",
		    serviceURL: "",
		    analysisURL: "",
		    catalogType: "agol",
		    clientId: "",
		    clientSecret: "",
		    esriUsername: "",
		    esriPassword: "",
		    services: [],
		    colorMapping: [],
		    backgroundColor: "#000000",
		    featureServiceColorToChange: "",
		    showColorPicker: false,
		    modalBody: "",
		    modalHeader: "",
		    receive_requests: false,
		    show: false,
		    showCreate: true,
		    featureType: "",
		    conditionNum: "",
		    featureUnit: "",
		    approvalConditions: [],
		    searchString: "",
		    searchResults: [],
		    approvedOrgs: []
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dropdownChange = this.dropdownChange.bind(this);
		this.updateColor = this.updateColor.bind(this);
		this.rgb2hex = this.rgb2hex.bind(this);
		this.approveColorPicker = this.approveColorPicker.bind(this);
		this.closeColorPicker = this.closeColorPicker.bind(this);
		this.handleColorChangeComplete = this.handleColorChangeComplete.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.changeRequests = this.changeRequests.bind(this);
		this.createCondition = this.createCondition.bind(this);
		this.removeCondition = this.removeCondition.bind(this);
		this.updateSearch = this.updateSearch.bind(this);
		this.addOrg = this.addOrg.bind(this);
		this.removeOrg = this.removeOrg.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	  	axios.post('https://www.arutility.com/api/v5/accounts/readServices.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
			this.setState({services: res.data});
	    	axios.post('https://www.arutility.com/api/v5/accounts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		      	if(res.data[0].colors != "" && JSON.parse(res.data[0].colors).length > 0){
		      		var colorsToUpdate = JSON.parse(res.data[0].colors);
		      		var servicesArray = this.state.services;
		      		for(var i = 0; i < colorsToUpdate.length; i++){
		      			var serviceId = colorsToUpdate[i].id;
		      			for(var i2 = 0; i2 < servicesArray.length; i2++){
		      				if(servicesArray[i2].id == serviceId){
		      					servicesArray[i2].color = colorsToUpdate[i].color;
		      				}
		      			}
		      		}
		      	}else{
		      		var servicesArray = this.state.services;
		      	}
				this.setState({loading: false, companyName: res.data[0].company_name, accountCountry: res.data[0].company_country, accountType: res.data[0].account_type, address1: res.data[0].company_address, address2: res.data[0].company_address2, accountCity: res.data[0].company_city, accountState: res.data[0].company_state, analysisURL: res.data[0].analysis_url, serviceURL: res.data[0].service_url, clientId: res.data[0].app_client_id, clientSecret: res.data[0].app_client_secret, catalogType: res.data[0].login_type, services: servicesArray, receive_requests: parseInt(res.data[0].receive_requests), esriUsername: res.data[0].esri_username, esriPassword: res.data[0].esri_pass, approvalConditions: res.data[0].approval_conditions, approvedOrgs: res.data[0].approved_orgs});
		    	if(res.data[0].login_type == null){
		    		this.setState({catalogType: "agol"});
		    	}
		    	if(res.data[0].service_url == null){
		    		this.setState({serviceURL: ""});
		    	}
		    	if(res.data[0].analysis_url == null){
		    		this.setState({analysisURL: ""});
		    	}
		    	if(res.data[0].app_client_secret == null){
					this.setState({clientSecret: ""});
		    	}
		    	if(res.data[0].app_client_id == null){
					this.setState({clientId: ""});
		    	}
		    }).catch(error => {
			    if (error.response) {
		            this.setState({loading: false});
		        } else if (error.request) {
		            // The request was made but no response was received
		            this.setState({loading: false});
		        } else {
		            // Something happened in setting up the request that triggered an Error
		            this.setState({loading: false});
		        }
			});
	    }).catch(error => {
		    if (error.response) {
	            this.setState({loading: false});
	        } else if (error.request) {
	            // The request was made but no response was received
	            this.setState({loading: false});
	        } else {
	            // Something happened in setting up the request that triggered an Error
	            this.setState({loading: false});
	        }
		});
  	}

  	handleChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	changeRequests(e){
  		if(this.state.receive_requests){
  			this.setState({receive_requests:false});
  		}else{
			this.setState({receive_requests:true});
  		}
  	}

  	dropdownChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	handleSubmit(){
	  	let value = this.context;
	  	this.setState({loading: true});
	  	if(this.state.accountType == "utility"){
	  		if(this.state.companyName != "" && this.state.accountCountry != "" && this.state.address1 != "" && this.state.accountCity != "" && this.state.accountState != "" && this.state.serviceURL != "" && this.state.analysisURL != ""){
	  			if(this.state.catalogType != "agol"){
	  				if(this.state.clientId != "" && this.state.clientSecret != ""){
	  					//do nothing, all good
	  				}else{
	  					this.setState({modalBody: "ArcGIS Enterpirse users must provide a client ID and client secret", modalHeader: "Error", show: true, loading: false});
	  					return;
	  				}
	  			}
	  		}else{
	  			this.setState({modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true, loading: false});
	  			return;
	  		}
	  	}else if(this.state.accountType != "individual"){
	  		if(this.state.companyName != "" && this.state.accountCountry != "" && this.state.address1 != "" && this.state.accountCity != "" && this.state.accountState != ""){
	  			//do nothing, all good
	  		}else{
	  			this.setState({modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true, loading: false});
	  			return;
	  		}
	  	}else{
	  		if(this.state.accountCountry != "" && this.state.address1 != "" && this.state.accountCity != "" && this.state.accountState != ""){
	  			//do nothing, all good
	  		}else{
	  			this.setState({modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true, loading: false});
	  			return;
	  		}
	  	}
	  	axios.post('https://www.arutility.com/api/v5/accounts/update.php',{company_name: this.state.companyName, company_country: this.state.accountCountry, company_address: this.state.address1, company_address2: this.state.address2, company_city: this.state.accountCity, company_state: this.state.accountState, esri_service_url: this.state.serviceURL, esri_analysis_url: this.state.analysisURL, account_auth_method: this.state.catalogType, account_app_client_id: this.state.clientId, account_app_client_secret: this.state.clientSecret, colors: this.state.services, receive_requests: this.state.receive_requests, esri_username: this.state.esriUsername, esri_pass: this.state.esriPassword, approval_conditions: this.state.approvalConditions, approved_orgs: this.state.approvedOrgs},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      	.then(res => {
	       		this.setState({modalBody: "Account updated successfully", modalHeader: "Success", show: true, loading: false});
	    	}).catch(error => {
	      	if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
	  	});
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	handleColorChangeComplete(color, event){
  		this.setState({backgroundColor: color.hex});
  	}

  	approveColorPicker(){
  		var newServiceArray = this.state.services;
  		for(var i=0; i < newServiceArray.length; i++){
  			if(parseInt(this.state.featureServiceColorToChange) == newServiceArray[i].id){
  				newServiceArray[i].color = this.state.backgroundColor;
  			}
  		}
  		this.setState({showColorPicker: false, services: newServiceArray});
  	}

  	closeColorPicker(){
  		this.setState({showColorPicker: false});
  	}

  	rgb2hex(rgb) {
	    if (/^#[0-9A-F]{6}$/i.test(rgb)) return rgb;

	    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
	    function hex(x) {
	        return ("0" + parseInt(x).toString(16)).slice(-2);
	    }
	    return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
	}

  	updateColor(e){
  		this.setState({showColorPicker: true, backgroundColor: this.rgb2hex(e.currentTarget.style.backgroundColor), featureServiceColorToChange: e.target.getAttribute('data-feature_service_type_id')});
  	}

  	createCondition(){
  		var conditionFound = false;
  		if(this.state.featureType == "point"){
  			if(this.state.conditionNum != ""){
		  		for(var i=0; i < this.state.approvalConditions.length; i++){
		  			if(this.state.approvalConditions[i]["feature_type"] == this.state.featureType){
		  				conditionFound = true;
		  				this.setState({modalHeader: "Error", modalBody: "There is already a condition created for this feature type", show: true});
		  			}
		  		}
		  		if(conditionFound == false){
		  			var conditionString = {"feature_type": this.state.featureType, "feature_units": this.state.featureUnit, "feature_num": this.state.conditionNum};
		  			var conditionsNew = JSON.parse(JSON.stringify(this.state.approvalConditions));
		  			conditionsNew.splice(0, 0, conditionString);
		  			//console.log(conditionsNew);
		  			this.setState({approvalConditions: conditionsNew});
		  		}
		  	}else{
		  		this.setState({modalHeader: "Error", modalBody: "One or more required fields was not provided", show: true});
		  	}
  		}else{
  			if(this.state.featureType != "" && this.state.featureUnit != "" && this.state.conditionNum != ""){
		  		for(var i=0; i < this.state.approvalConditions.length; i++){
		  			if(this.state.approvalConditions[i]["feature_type"] == this.state.featureType){
		  				conditionFound = true;
		  				this.setState({modalHeader: "Error", modalBody: "There is already a condition created for this feature type", show: true});
		  			}
		  		}
		  		if(conditionFound == false){
		  			var conditionString = {"feature_type": this.state.featureType, "feature_units": this.state.featureUnit, "feature_num": this.state.conditionNum};
		  			var conditionsNew = JSON.parse(JSON.stringify(this.state.approvalConditions));
		  			conditionsNew.splice(0, 0, conditionString);
		  			this.setState({approvalConditions: conditionsNew});
		  		}
		  	}else{
		  		this.setState({modalHeader: "Error", modalBody: "One or more required fields was not provided", show: true});
		  	}
  		}
  	}

  	removeCondition(e){
  		var conditionsNew = JSON.parse(JSON.stringify(this.state.approvalConditions));
  		for(var i=conditionsNew.length-1; i >= 0; i--){
  			if(conditionsNew[i].feature_type == e.target.name){
  				conditionsNew.splice(i,1);
  			}
  		}
  		this.setState({approvalConditions: conditionsNew});
  	}

  	updateSearch(e){
  		let value = this.context;
  		console.log(e.target.value);
  		this.setState({searchString:e.target.value});
  		axios.post('https://www.arutility.com/api/v5/accounts/readSearch.php',{search_string: e.target.value},{headers: {Authorization: 'Bearer ' + value.jwt}})
      	.then(res => {
       		this.setState({searchResults: res.data});
       		console.log(res.data);
    	}).catch(error => {
	      	if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
	  	});
  	}

  	addOrg(e){
  		//console.log(e.target.id);
  		var orgsNew = JSON.parse(JSON.stringify(this.state.approvedOrgs));
  		var newOrg = {"name":e.target.name, "id": e.target.id};
		orgsNew.splice(0, 0, newOrg);
  		this.setState({approvedOrgs: orgsNew});
  	}

  	removeOrg(e){
  		var orgsNew = JSON.parse(JSON.stringify(this.state.approvedOrgs));
  		for(var i=orgsNew.length-1; i >= 0; i--){
  			if(orgsNew[i].id == e.target.id){
  				orgsNew.splice(i,1);
  			}
  		}
  		this.setState({approvedOrgs: orgsNew});
  	}

  	render(){
	  	let value = this.context;
	  	const {show} = this.state;
	  	const filteredSearch = this.state.searchResults.filter(org => !this.state.approvedOrgs.map(itemY => { return itemY.name;}).includes(org.name));
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
	    	if(value.userPermissions.includes("account:update")){
			    return (
			   		<div className="mainWrapper">
				    	<div className="mainWindow">
				   			{this.state.loading && 
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					        {this.state.showColorPicker &&
					        	<div className="colorPickerFullWrapper">
						        	<div className="colorPickerWrapper">
						        		<SketchPicker
						        			color={this.state.backgroundColor}
	        								onChangeComplete={ this.handleColorChangeComplete }
	        							/>
	        							<Button variant="primary" onClick={this.approveColorPicker}>
						        			Approve
						        		</Button>
						        		&nbsp;&nbsp;
						        		<Button variant="primary" onClick={this.closeColorPicker}>
						        			Cancel
						        		</Button>
						        	</div>
						        </div>
					        }
					      	<div className="pageHeader">
					      	BASIC ACCOUNT SETTINGS
					      	</div>
					      	<div className="profileBody">
						      	<form id="companyInfo">
						            {this.state.accountType != "individual" &&
			                        	<div>
			            	            	<font color="#c62828">Company Name:*</font>
			            		            <br/>
			            		            <input type="text" value={this.state.companyName} id="companyName" className="companyInput" name="companyName" onChange={this.handleChange} />
			            		            <br/>
			            		            <br/>
			            		        </div>
			                    	}
			                        <font color="#c62828">Country:*</font>
			                        <br/>
			                        <select className="projectSelect" id="accountCountry" name="accountCountry" value={this.state.accountCountry} onChange={this.handleChange}>
			                            <option value=""></option>
			                            <option value="Afganistan">Afghanistan</option>
			                            <option value="Albania">Albania</option>
			                            <option value="Algeria">Algeria</option>
			                            <option value="American Samoa">American Samoa</option>
			                            <option value="Andorra">Andorra</option>
			                            <option value="Angola">Angola</option>
			                            <option value="Anguilla">Anguilla</option>
			                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
			                            <option value="Argentina">Argentina</option>
			                            <option value="Armenia">Armenia</option>
			                            <option value="Aruba">Aruba</option>
			                            <option value="Australia">Australia</option>
			                            <option value="Austria">Austria</option>
			                            <option value="Azerbaijan">Azerbaijan</option>
			                            <option value="Bahamas">Bahamas</option>
			                            <option value="Bahrain">Bahrain</option>
			                            <option value="Bangladesh">Bangladesh</option>
			                            <option value="Barbados">Barbados</option>
			                            <option value="Belarus">Belarus</option>
			                            <option value="Belgium">Belgium</option>
			                            <option value="Belize">Belize</option>
			                            <option value="Benin">Benin</option>
			                            <option value="Bermuda">Bermuda</option>
			                            <option value="Bhutan">Bhutan</option>
			                            <option value="Bolivia">Bolivia</option>
			                            <option value="Bonaire">Bonaire</option>
			                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
			                            <option value="Botswana">Botswana</option>
			                            <option value="Brazil">Brazil</option>
			                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
			                            <option value="Brunei">Brunei</option>
			                            <option value="Bulgaria">Bulgaria</option>
			                            <option value="Burkina Faso">Burkina Faso</option>
			                            <option value="Burundi">Burundi</option>
			                            <option value="Cambodia">Cambodia</option>
			                            <option value="Cameroon">Cameroon</option>
			                            <option value="Canada">Canada</option>
			                            <option value="Canary Islands">Canary Islands</option>
			                            <option value="Cape Verde">Cape Verde</option>
			                            <option value="Cayman Islands">Cayman Islands</option>
			                            <option value="Central African Republic">Central African Republic</option>
			                            <option value="Chad">Chad</option>
			                            <option value="Channel Islands">Channel Islands</option>
			                            <option value="Chile">Chile</option>
			                            <option value="China">China</option>
			                            <option value="Christmas Island">Christmas Island</option>
			                            <option value="Cocos Island">Cocos Island</option>
			                            <option value="Colombia">Colombia</option>
			                            <option value="Comoros">Comoros</option>
			                            <option value="Congo">Congo</option>
			                            <option value="Cook Islands">Cook Islands</option>
			                            <option value="Costa Rica">Costa Rica</option>
			                            <option value="Cote DIvoire">Cote DIvoire</option>
			                            <option value="Croatia">Croatia</option>
			                            <option value="Cuba">Cuba</option>
			                            <option value="Curaco">Curacao</option>
			                            <option value="Cyprus">Cyprus</option>
			                            <option value="Czech Republic">Czech Republic</option>
			                            <option value="Denmark">Denmark</option>
			                            <option value="Djibouti">Djibouti</option>
			                            <option value="Dominica">Dominica</option>
			                            <option value="Dominican Republic">Dominican Republic</option>
			                            <option value="East Timor">East Timor</option>
			                            <option value="Ecuador">Ecuador</option>
			                            <option value="Egypt">Egypt</option>
			                            <option value="El Salvador">El Salvador</option>
			                            <option value="Equatorial Guinea">Equatorial Guinea</option>
			                            <option value="Eritrea">Eritrea</option>
			                            <option value="Estonia">Estonia</option>
			                            <option value="Ethiopia">Ethiopia</option>
			                            <option value="Falkland Islands">Falkland Islands</option>
			                            <option value="Faroe Islands">Faroe Islands</option>
			                            <option value="Fiji">Fiji</option>
			                            <option value="Finland">Finland</option>
			                            <option value="France">France</option>
			                            <option value="French Guiana">French Guiana</option>
			                            <option value="French Polynesia">French Polynesia</option>
			                            <option value="French Southern Ter">French Southern Ter</option>
			                            <option value="Gabon">Gabon</option>
			                            <option value="Gambia">Gambia</option>
			                            <option value="Georgia">Georgia</option>
			                            <option value="Germany">Germany</option>
			                            <option value="Ghana">Ghana</option>
			                            <option value="Gibraltar">Gibraltar</option>
			                            <option value="Great Britain">Great Britain</option>
			                            <option value="Greece">Greece</option>
			                            <option value="Greenland">Greenland</option>
			                            <option value="Grenada">Grenada</option>
			                            <option value="Guadeloupe">Guadeloupe</option>
			                            <option value="Guam">Guam</option>
			                            <option value="Guatemala">Guatemala</option>
			                            <option value="Guinea">Guinea</option>
			                            <option value="Guyana">Guyana</option>
			                            <option value="Haiti">Haiti</option>
			                            <option value="Hawaii">Hawaii</option>
			                            <option value="Honduras">Honduras</option>
			                            <option value="Hong Kong">Hong Kong</option>
			                            <option value="Hungary">Hungary</option>
			                            <option value="Iceland">Iceland</option>
			                            <option value="Indonesia">Indonesia</option>
			                            <option value="India">India</option>
			                            <option value="Iran">Iran</option>
			                            <option value="Iraq">Iraq</option>
			                            <option value="Ireland">Ireland</option>
			                            <option value="Isle of Man">Isle of Man</option>
			                            <option value="Israel">Israel</option>
			                            <option value="Italy">Italy</option>
			                            <option value="Jamaica">Jamaica</option>
			                            <option value="Japan">Japan</option>
			                            <option value="Jordan">Jordan</option>
			                            <option value="Kazakhstan">Kazakhstan</option>
			                            <option value="Kenya">Kenya</option>
			                            <option value="Kiribati">Kiribati</option>
			                            <option value="Korea North">Korea North</option>
			                            <option value="Korea Sout">Korea South</option>
			                            <option value="Kuwait">Kuwait</option>
			                            <option value="Kyrgyzstan">Kyrgyzstan</option>
			                            <option value="Laos">Laos</option>
			                            <option value="Latvia">Latvia</option>
			                            <option value="Lebanon">Lebanon</option>
			                            <option value="Lesotho">Lesotho</option>
			                            <option value="Liberia">Liberia</option>
			                            <option value="Libya">Libya</option>
			                            <option value="Liechtenstein">Liechtenstein</option>
			                            <option value="Lithuania">Lithuania</option>
			                            <option value="Luxembourg">Luxembourg</option>
			                            <option value="Macau">Macau</option>
			                            <option value="Macedonia">Macedonia</option>
			                            <option value="Madagascar">Madagascar</option>
			                            <option value="Malaysia">Malaysia</option>
			                            <option value="Malawi">Malawi</option>
			                            <option value="Maldives">Maldives</option>
			                            <option value="Mali">Mali</option>
			                            <option value="Malta">Malta</option>
			                            <option value="Marshall Islands">Marshall Islands</option>
			                            <option value="Martinique">Martinique</option>
			                            <option value="Mauritania">Mauritania</option>
			                            <option value="Mauritius">Mauritius</option>
			                            <option value="Mayotte">Mayotte</option>
			                            <option value="Mexico">Mexico</option>
			                            <option value="Midway Islands">Midway Islands</option>
			                            <option value="Moldova">Moldova</option>
			                            <option value="Monaco">Monaco</option>
			                            <option value="Mongolia">Mongolia</option>
			                            <option value="Montserrat">Montserrat</option>
			                            <option value="Morocco">Morocco</option>
			                            <option value="Mozambique">Mozambique</option>
			                            <option value="Myanmar">Myanmar</option>
			                            <option value="Nambia">Nambia</option>
			                            <option value="Nauru">Nauru</option>
			                            <option value="Nepal">Nepal</option>
			                            <option value="Netherland Antilles">Netherland Antilles</option>
			                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
			                            <option value="Nevis">Nevis</option>
			                            <option value="New Caledonia">New Caledonia</option>
			                            <option value="New Zealand">New Zealand</option>
			                            <option value="Nicaragua">Nicaragua</option>
			                            <option value="Niger">Niger</option>
			                            <option value="Nigeria">Nigeria</option>
			                            <option value="Niue">Niue</option>
			                            <option value="Norfolk Island">Norfolk Island</option>
			                            <option value="Norway">Norway</option>
			                            <option value="Oman">Oman</option>
			                            <option value="Pakistan">Pakistan</option>
			                            <option value="Palau Island">Palau Island</option>
			                            <option value="Palestine">Palestine</option>
			                            <option value="Panama">Panama</option>
			                            <option value="Papua New Guinea">Papua New Guinea</option>
			                            <option value="Paraguay">Paraguay</option>
			                            <option value="Peru">Peru</option>
			                            <option value="Phillipines">Philippines</option>
			                            <option value="Pitcairn Island">Pitcairn Island</option>
			                            <option value="Poland">Poland</option>
			                            <option value="Portugal">Portugal</option>
			                            <option value="Puerto Rico">Puerto Rico</option>
			                            <option value="Qatar">Qatar</option>
			                            <option value="Republic of Montenegro">Republic of Montenegro</option>
			                            <option value="Republic of Serbia">Republic of Serbia</option>
			                            <option value="Reunion">Reunion</option>
			                            <option value="Romania">Romania</option>
			                            <option value="Russia">Russia</option>
			                            <option value="Rwanda">Rwanda</option>
			                            <option value="St Barthelemy">St Barthelemy</option>
			                            <option value="St Eustatius">St Eustatius</option>
			                            <option value="St Helena">St Helena</option>
			                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
			                            <option value="St Lucia">St Lucia</option>
			                            <option value="St Maarten">St Maarten</option>
			                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
			                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
			                            <option value="Saipan">Saipan</option>
			                            <option value="Samoa">Samoa</option>
			                            <option value="Samoa American">Samoa American</option>
			                            <option value="San Marino">San Marino</option>
			                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
			                            <option value="Saudi Arabia">Saudi Arabia</option>
			                            <option value="Senegal">Senegal</option>
			                            <option value="Seychelles">Seychelles</option>
			                            <option value="Sierra Leone">Sierra Leone</option>
			                            <option value="Singapore">Singapore</option>
			                            <option value="Slovakia">Slovakia</option>
			                            <option value="Slovenia">Slovenia</option>
			                            <option value="Solomon Islands">Solomon Islands</option>
			                            <option value="Somalia">Somalia</option>
			                            <option value="South Africa">South Africa</option>
			                            <option value="Spain">Spain</option>
			                            <option value="Sri Lanka">Sri Lanka</option>
			                            <option value="Sudan">Sudan</option>
			                            <option value="Suriname">Suriname</option>
			                            <option value="Swaziland">Swaziland</option>
			                            <option value="Sweden">Sweden</option>
			                            <option value="Switzerland">Switzerland</option>
			                            <option value="Syria">Syria</option>
			                            <option value="Tahiti">Tahiti</option>
			                            <option value="Taiwan">Taiwan</option>
			                            <option value="Tajikistan">Tajikistan</option>
			                            <option value="Tanzania">Tanzania</option>
			                            <option value="Thailand">Thailand</option>
			                            <option value="Togo">Togo</option>
			                            <option value="Tokelau">Tokelau</option>
			                            <option value="Tonga">Tonga</option>
			                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
			                            <option value="Tunisia">Tunisia</option>
			                            <option value="Turkey">Turkey</option>
			                            <option value="Turkmenistan">Turkmenistan</option>
			                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
			                            <option value="Tuvalu">Tuvalu</option>
			                            <option value="Uganda">Uganda</option>
			                            <option value="United Kingdom">United Kingdom</option>
			                            <option value="Ukraine">Ukraine</option>
			                            <option value="United Arab Erimates">United Arab Emirates</option>
			                            <option value="United States of America">United States of America</option>
			                            <option value="Uraguay">Uruguay</option>
			                            <option value="Uzbekistan">Uzbekistan</option>
			                            <option value="Vanuatu">Vanuatu</option>
			                            <option value="Vatican City State">Vatican City State</option>
			                            <option value="Venezuela">Venezuela</option>
			                            <option value="Vietnam">Vietnam</option>
			                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
			                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
			                            <option value="Wake Island">Wake Island</option>
			                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
			                            <option value="Yemen">Yemen</option>
			                            <option value="Zaire">Zaire</option>
			                            <option value="Zambia">Zambia</option>
			                            <option value="Zimbabwe">Zimbabwe</option>
			                        </select>
			                        <br/>
			                        <br/>
			                        {this.state.accountCountry == "United States of America" ? 
			                            <div>
			                                <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State:*</font>
			                                <br/>
			                                <select className="projectSelect" id="accountState" name="accountState" value={this.state.accountState} onChange={this.handleChange} >
			                                    <option value=""> </option>
			                                    <option value="AL">Alabama</option>
			                                    <option value="AK">Alaska</option>
			                                    <option value="AZ">Arizona</option>
			                                    <option value="AR">Arkansas</option>
			                                    <option value="CA">California</option>
			                                    <option value="CO">Colorado</option>
			                                    <option value="CT">Connecticut</option>
			                                    <option value="DE">Delaware</option>
			                                    <option value="DC">District Of Columbia</option>
			                                    <option value="FL">Florida</option>
			                                    <option value="GA">Georgia</option>
			                                    <option value="HI">Hawaii</option>
			                                    <option value="ID">Idaho</option>
			                                    <option value="IL">Illinois</option>
			                                    <option value="IN">Indiana</option>
			                                    <option value="IA">Iowa</option>
			                                    <option value="KS">Kansas</option>
			                                    <option value="KY">Kentucky</option>
			                                    <option value="LA">Louisiana</option>
			                                    <option value="ME">Maine</option>
			                                    <option value="MD">Maryland</option>
			                                    <option value="MA">Massachusetts</option>
			                                    <option value="MI">Michigan</option>
			                                    <option value="MN">Minnesota</option>
			                                    <option value="MS">Mississippi</option>
			                                    <option value="MO">Missouri</option>
			                                    <option value="MT">Montana</option>
			                                    <option value="NE">Nebraska</option>
			                                    <option value="NV">Nevada</option>
			                                    <option value="NH">New Hampshire</option>
			                                    <option value="NJ">New Jersey</option>
			                                    <option value="NM">New Mexico</option>
			                                    <option value="NY">New York</option>
			                                    <option value="NC">North Carolina</option>
			                                    <option value="ND">North Dakota</option>
			                                    <option value="OH">Ohio</option>
			                                    <option value="OK">Oklahoma</option>
			                                    <option value="OR">Oregon</option>
			                                    <option value="PA">Pennsylvania</option>
			                                    <option value="RI">Rhode Island</option>
			                                    <option value="SC">South Carolina</option>
			                                    <option value="SD">South Dakota</option>
			                                    <option value="TN">Tennessee</option>
			                                    <option value="TX">Texas</option>
			                                    <option value="UT">Utah</option>
			                                    <option value="VT">Vermont</option>
			                                    <option value="VA">Virginia</option>
			                                    <option value="WA">Washington</option>
			                                    <option value="WV">West Virginia</option>
			                                    <option value="WI">Wisconsin</option>
			                                    <option value="WY">Wyoming</option>
			                                </select>
			                                <br/>
			                                <br/>
			                            </div>
			                            :
			                            <div>
			                                <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State/Province/Region:*</font>
			                                <br/>
			                                <input type="text" value={this.state.accountState} id="accountState" name="accountState" className="companyInput" placeholder="State/Province/Region" onChange={this.handleChange}/>
			                                <br/>
			                                <br/>
			                            </div>
			                        }
			                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} City/Town/Village:*</font>
			                        <br/>
			                        <input type="text" value={this.state.accountCity} id="accountCity" name="accountCity" className="companyInput" placeholder="Lansing..." onChange={this.handleChange}/>
			                        <br/>
			                        <br/>
			                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} Address:*</font>
			                        <br/>
			                        <input type="text" value={this.state.address1} id="address1" name="address1" className="companyInput" placeholder="123 Main St..." onChange={this.handleChange}/>
			                        <br/>
			                        <br/>
			                        <font color="#c62828">Address Cont:</font>
			                        <br/>
			                        <input type="text" value={this.state.address2} id="address2" name="address2" className="companyInput" placeholder="Apt 1..." onChange={this.handleChange}/>
			                        <br/>
			                        <br/>
			                        {(this.state.accountType == "utility" || this.state.accountType == "consultant") &&
			                        	(value.userPermissions.includes("mapping:update")) &&
					    	            	<div>
					    			            <font color="#c62828">
					    			                <label htmlFor="esriServiceURL">Esri Service URL:*</label>
					    			            </font>
					    			            <br/>
					    			            <input type="text" value={this.state.serviceURL} id="serviceURL" name="serviceURL" className="projectInputField" placeholder="https://services8.arcgis.com/xxxxxxxxxxxxxxxx/ArcGIS/rest/services" onChange={this.handleChange}/>
					    			            <br/>
					    			            <br/>
					                            <font color="#c62828">
					                                <label htmlFor="esriAnalysisURL">Esri Analysis URL:*</label>
					                            </font>
					                            <br/>
					                            <input type="text" value={this.state.analysisURL} id="esriAnalysisURL" name="analysisURL" className="projectInputField" placeholder="https://analysis8.arcgis.com/arcgis/rest/services" onChange={this.handleChange}/>
					                            <br/>
					                            <br/>
					                            <font color="#c62828">
					                                <label htmlFor="esriUsername">Esri Username:</label>
					                            </font>
					                            <br/>
					                            <input type="text" value={this.state.esriUsername} id="esriUsername" name="esriUsername" className="projectInputField" placeholder="Esri Username..." onChange={this.handleChange}/>
					                            <br/>
					                            <br/>
					                            <font color="#c62828">
					                                <label htmlFor="esriUsername">Esri Password:</label>
					                            </font>
					                            <br/>
					                            <input type="password" value={this.state.esriPassword} id="esriPassword" name="esriPassword" className="projectInputField" placeholder="Esri Password..." onChange={this.handleChange}/>
					                            <br/>
					                            <br/>
					    			            <select className="projectSelect" name="catalogType" id="account_auth_method" onChange={this.handleChange} value={this.state.catalogType}>
									                <option value="agol">ArcGIS Online</option>
									                <option value="enterprise">ArcGIS Enterprise</option>
									            </select>
									            <br/>
					    			            {this.state.catalogType == "agol" ? 
					    			           		<br/>
					    			           		:
					    			           		(
					    			           			<div>
					    						            <br/>
					    						            <input type="text" value={this.state.clientId} id="clientId" name="clientId" className="projectInputField" placeholder="App Client Id..." onChange={this.handleChange}/>
					    						            <br/>
					    						            <br/>
					    						            <input type="text" value={this.state.clientSecret} id="clientSecret" name="clientSecret" className="projectInputField" placeholder="App Client Secret..." onChange={this.handleChange}/>
					    						            <br/>
					    						        </div>
					    					        )
					    			           	}
					    			           	Receive Requests From Outside Parties: <input id="receiveRequests" type="checkbox" name="receiveRequests" className="featureSelectedCheckbox" checked={this.state.receive_requests} onChange={this.changeRequests} />
					    		            	<br/>
					    		            	<br/>
					    		            </div> 
			    	                }
			    	            </form>
			    	        </div>
	    	                <div className="pageHeader">
						      	AUTOMATIC REQUEST CONDITIONS
						    </div>
						    <div className="profileBody">
	    	                	<div className="layerSelection" style={{display: this.state.showCreate ? "inline-block" : "none"}}>
					              IF feature type is a:
					              <select id="feature_type" name="featureType" onChange={this.handleChange} className="featureServiceName"  value={this.state.featureType}>
					              	<option value=""></option>
					              	<option value="point">Point</option>
					              	<option value="line">Line</option>
					              	<option value="polygon">Project Polygon</option>
					              </select>

					              {this.state.featureType == "point" &&
					              	(
					              		<div>AND number of point features is less than:</div>
					              	)}
					              {this.state.featureType == "line" &&
					              	(
					              		<div>AND total length of line features is less than:</div>
					              	)}
					              {this.state.featureType == "polygon" &&
					              	(
					              		<div>AND total project area is less than:</div>
					              	)}
					              	{this.state.featureType != "" && (
					              		<input value={this.state.conditionNum} className="featureServiceNameInput" onChange={event => this.setState({conditionNum: event.target.value.replace(/\D/,'')})}/>
					              	)}
					              	{(this.state.featureType != "" && this.state.featureType != "point") && (
				              			<select id="feature_units" name="featureUnit" onChange={this.handleChange} className="featureServiceName10"  value={this.state.featureUnit}>
							              	<option value=""></option>
							              	<option value="ft">ft</option>
							              	<option value="yards">yd</option>
							              	<option value="km">km</option>
							              	<option value="miles">miles</option>
							             </select>
					              	)}
					              <div className="fullWidth">
						              <Button variant="primary" onClick={this.createCondition}>
						                  CREATE
						              </Button>
					              </div>
					              <div className="pageHeader">
							      	Approved Conditions
							      </div>
					              <div>
					              	{this.state.approvalConditions.map(condition => {
					              		return(
						              		<div className="projectWrapper">
						              			<b>Feature Type:</b> {condition.feature_type} &nbsp;&nbsp;&nbsp; <b>Condition:</b> {condition.feature_num} {condition.feature_units}
						              			<div className="conditionDelete">
						              				<Button variant="danger" name={condition.feature_type} onClick={this.removeCondition}>Delete</Button>
						              			</div>
						              		</div>
					              		)
					              	})}
					              </div>
					            </div>
					        </div>
					        <div className="pageHeader">
						      	APPROVED AUTOMATIC REQUESTS
						    </div>
						    <div className="profileBody">
						    	<div className="fullWidth">
							    	Find organizations that you would like to automatically approve requests for: 
							    	<br/>
							    	<InputGroup className="projectsHeaderSearchNew" id="projectsHeaderSearchNew">
						              <InputGroup.Prepend>
						                <InputGroup.Text id="basic-addon1">Search:</InputGroup.Text>
						              </InputGroup.Prepend>
						              <FormControl
						                placeholder=""
						                aria-label=""
						                aria-describedby="basic-addon1"
						                value={this.state.searchString}
						                onChange={this.updateSearch}
						              />
						            </InputGroup>
						            <div className="pageHeader">
								      	Search Results
								    </div>
								    <div>
								    	{filteredSearch.length > 0 ? 
						              		(
						              			filteredSearch.map(org => {
						              				return(
							              				<div className="projectWrapper">
									              			{org.name}
									              			<div className="conditionDelete">
									              				<Button variant="primary" name={org.name} id={org.id} onClick={this.addOrg}>Add</Button>
									              			</div>
								              			</div>
								              		)
						              			})
						              		)
						              		:
						              		(
						              			<div className="fullWidth">
						              				No Results
						              			</div>
						              		)
						              	}
						            </div>
						            <div className="pageHeader">
								      	Approved Organizations
								    </div>
								    <div>
						              	{this.state.approvedOrgs.map(org => {
						              		return(
							              		<div className="projectWrapper">
							              			{org.name}
							              			<div className="conditionDelete">
							              				<Button variant="danger" name={org.name} id={org.id} onClick={this.removeOrg}>Delete</Button>
							              			</div>
							              		</div>
						              		)
						              	})}
						            </div>
								</div>
					        </div>
					        <div className="pageHeader">
						      	FEATURE SERVICE COLOR SETTINGS
						    </div>
							<div className="profileBody">
		    	                <div className="featureServiceColors">
		    	                	<div className="selectFieldsHeader">
		    	                		<b>Select Feature Colors:</b>
		    	                	</div>
		    	                	{this.state.services.map(service => {
		    	                		return(
			    	                		<div key={service.id} className="featureServiceColor">
			    	                			<div className="featureServiceColorImgWrapper" data-feature_service_type_id={service.id} style={{backgroundColor: service.color}} onClick={this.updateColor}>

			    	                			</div>
			    	                			{service.label}
			    	                		</div>
		    	                		);
		    	                	})}
		    	                </div>
					        	<button id="submitAccountSettings" className="submitProjectBtn" onClick={this.handleSubmit} >SAVE CHANGES</button>
					      	</div>
					    </div>
			      	</div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}
		}
  	}
}

export default AccountSettings;