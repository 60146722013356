import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import './RequestsHeader.css';

class RequestsHeader extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      utilityType: "",
      mapping: []
    }
    this.updateSearch = this.updateSearch.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.utilityChange = this.utilityChange.bind(this);
  }

  componentDidMount(){
      this.setState({mapping: this.props.mapping, utilityType: "water"});
  }

  componentDidUpdate(){
    if(this.props.mapping != this.state.mapping){
      this.setState({mapping: this.props.mapping});
    }
  }

  updateSearch(e){
    this.setState({searchString: e.target.value});
    this.props.updateSearch(e.target.value);
  }

  statusChange(e){
    this.props.updateSearchStatus(e.target.value);
  }

  utilityChange(e){
    this.setState({utilityType: e.target.value});
    this.props.updateSearchUtility(e.target.value);
  }

  render(){
    return (
    	<div className="requestsHeaderWrapper">
      		<div className="requestsHeaderText">
          Requests
          </div>
          <div className="requestsHeaderSearch">
            <label htmlFor="statusSelect">Utility Type:</label>
            <select id="utilitySelect" onChange={this.utilityChange} value={this.state.utilityType}>
              <option value=""></option>
              {this.state.mapping.filter((featureService) => featureService.feature_service_type_id != 8).sort(function(a, b){if(a.sorting < b.sorting) return -1; if(a.sorting > b.sorting) return 1; if(a.sorting == b.sorting){if(a.name > b.name) return 1; if(a.name < b.name) return -1;}}).map((featureService, index) => 
                  <option key={index} value={featureService.label}>{featureService.label}</option>
              )}
            </select>
            <label htmlFor="statusSelect">Status:</label>
            <select id="statusSelect" onChange={this.statusChange}>
              <option value=""></option>
              <option value="0">Pending</option>
              <option value="1">Approved</option>
              <option value="2">Denied</option>
            </select>
            <InputGroup className="projectsHeaderSearchNew" id="projectsHeaderSearchNew">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Search:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder=""
                aria-label=""
                aria-describedby="basic-addon1"
                value={this.state.searchString}
                onChange={this.updateSearch}
              />
            </InputGroup>
          </div>
      </div>
    );
  }
}

export default RequestsHeader;