import React from 'react';
import './Request.css';

class Request extends React.Component{

  render(){
    var end_date = new Date(this.props.requestEndDate);
    var start_date = new Date(this.props.requestStartDate);
    var current_date = new Date();
    var request_image = "";
    if(end_date > current_date && start_date > current_date){
      request_image = '/images/upcomingProjectBtn.png';
    }else if(start_date < current_date && end_date > current_date){
      request_image = '/images/inprogressBtn.png';
    }else{
      request_image = '/images/upcomingProjectBtn.png';
    }
    return (
      <div className="requestWrapper">
        <div className="requestStatus">
          <img src={request_image} alt="requestStatus"/>
        </div>
        <div className="requestId">
          {this.props.ticketId}
        </div>
        <div className="requestName">
          {this.props.requestName}
        </div>
        <div className="requestLocation">
          {this.props.requestCity}, {this.props.requestState}
        </div>
        <div className="requestUtilityType">
          {this.props.requestUtilityType}
        </div>
        <div className="requestStartDate">
          {this.props.requestStartDate}
        </div>
        <div className="requestEndDate">
          {this.props.requestEndDate}
        </div>
        <div className="requestStatus">
          {this.props.requestStatus}
        </div>
      </div>
    );
  }
}

export default Request;