import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import './ResetPassword.css';

class ResetPassword extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password1: "",
      password2: "",
      token: "",
      show: false,
      modalBody: "",
      modalHeader: "",
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let search = new URLSearchParams(this.props.location.search);
    if(search.get("t")){
        this.setState({token: search.get("t")});
      }
    if(search.get("act")){
        this.setState({email: search.get("act")});
    }
    axios.post('https://www.arutility.com/api/v4/users/validate_password_token.php',{token: search.get("t")})
    .then(res => {
      this.setState({loading: false});
    }).catch(error => {
      window.location.href="https://www.arutility.com/login";
    });
  }

  handleSubmit(e){
    e.preventDefault();
    this.setState({loading: true});
    if(this.state.email != "" && this.state.password1 != "" && this.state.password2 != ""){
      if(this.state.password1 == this.state.password2){
        axios.post('https://www.arutility.com/api/v4/users/resetpassword.php',{email: this.state.email, password: this.state.password1, token: this.state.token})
          .then(res => {
            this.setState({loading: false, modalBody: res.data.message, modalHeader: "Success", show: true});
          }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
          });
        }else{
          this.setState({loading: false, modalBody: "Sorry, it looks like the passwords don't match. Please try again.", modalHeader: "Error", show: true});
        }
    }else{
      this.setState({loading: false, modalBody: "Sorry, it looks like you're missing some information", modalHeader: "Error", show: true});
    }
  }

  handleChange(e) {
    this.setState({[e.target.id]: e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  render(){
    const{show} = this.state;
    return (
      <div className="mainWrapper">
        {this.state.loading &&
          <LoadingSpinner />
        }
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
      	<div className="pageTitle">
        RESET PASSWORD
        </div>
        <div className="loginFormWrapper">
            <div className="resetPasswordForm">
              <div className="resetPasswordFormLabel">
                <font color="#c62828">Email:</font>
              </div>
              <br/>
              <input type="email" id="email" name="email" placeholder="Email..." value={this.state.email} onChange={this.handleChange}/>
              <br/>
              <div className="resetPasswordFormLabel">
                <font color="#c62828">New Password:</font>
              </div>
              <br/>
              <input type="password" id="password1" name="password1" placeholder="New Password..." value={this.state.password1} onChange={this.handleChange}/>
              <br/>
              <div className="resetPasswordFormLabel">
                <font color="#c62828">Verify Password:</font>
              </div>
              <br/>
              <input type="password" id="password2" name="password2" placeholder="Verify Password..." value={this.state.password2} onChange={this.handleChange}/>
              <br/>
              <button value="Submit" onClick={this.handleSubmit}>RESET PASSWORD</button>
              <br/>
              <a href="https://www.arutility.com/login">
                Back to Login
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/account/create">
                Register New Account
              </Link>
            </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default ResetPassword;