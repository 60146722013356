import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Elements, CardElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CheckoutFormSignup from "../checkoutformsignup/CheckoutFormSignup.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateAccount.css';
import ReCAPTCHA from "react-google-recaptcha";

//USE THIS FOR LIVE MODE
const stripePromise = loadStripe("pk_live_51HXnpwKTDVl8ERQLlJ39XoEVTz1NsgdYmnnAtaMb5gpkrGM782INHV5nOKogPImI88IkOOsloOvNHyXFdy9GK6Pj00xrvEkMHq");

//USE THIS FOR TEST MODE
//const stripePromise = loadStripe("pk_test_51HXnpwKTDVl8ERQLho9C0bVWdVFt6c5dAZkFTInXWrtnZbRPEO8aHm1h25ys51t0HvYfuG0edhBzY2p9dPzx0uDw00dDSFxdQ9");

class CreateAccount extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        accountType: "utility",
        alias: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companyAddr1: "",
        companyAddr2: "",
        companyCity: "",
        companyState: "",
        companyCountry: "",
        companyPhone: "",
        adminEmail: "",
        adminUsername: "",
        password1: "",
        password2: "",
        referral: "",
        esriServiceURL: "",
        esriAnalysisURL: "",
        authMethod: "agol",
        esriClientSecret: "",
        esriClientID: "",
        engineerEsri: false,
        verified: false,
        loading: false,
        showMailing: false,
        firstName_mail: "",
        lastName_mail: "",
        companyName_mail: "",
        companyAddr1_mail: "",
        companyAddr2_mail: "",
        companyCity_mail: "",
        companyState_mail: "",
        companyCountry_mail: "",
        companyPhone_mail: "",
        companyZip_mail: "",
        modalBody: "",
        modalHeader: "",
        productId: "",
        priceId: "",
        quantity: "",
        tokenId: "",
        testing: false,
        show: false
    }
    this.updateForm = this.updateForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.sendFormSubscription = this.sendFormSubscription.bind(this);
    this.recaptchaVerified = this.recaptchaVerified.bind(this);
    this.recaptchaExpired = this.recaptchaExpired.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitCardError = this.submitCardError.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
    this.changeEsriRadio = this.changeEsriRadio.bind(this);
    this.getMailingAddress = this.getMailingAddress.bind(this);
    this.closeMailing = this.closeMailing.bind(this);
    this.updateMailForm = this.updateMailForm.bind(this);
    this.confirmMailing = this.confirmMailing.bind(this);
  }

  confirmMailing(){
    if(this.state.firstName_mail != "" && this.state.lastName_mail != "" && this.state.companyAddr1_mail != "" && this.state.companyCity_mail != "" && this.state.companyState_mail != "" && this.state.companyCountry_mail != "" && this.state.companyPhone_mail != "" && this.state.companyZip_mail != ""){
        this.setState({showMailing: false});
        this.createSubscription(this.state.productId, this.state.quantity, this.state.priceId, this.state.tokenId);
    }else{
        this.setState({modalBody: "Sorry, it looks like you are missing some required information.", modalHeader: "Error", show: true});
    }
  }

  closeMailing(){
    this.setState({showMailing: false, productId: "", quantity: "", priceId: "", tokenId: ""});
  }

  getMailingAddress(productId, quantity, priceId, tokenId){
    this.setState({showMailing: true, productId: productId, quantity: quantity, priceId: priceId, tokenId: tokenId});
  }

  changeEsriRadio(e){
    if (this.state[e.target.id] == true) {
        this.setState({[e.target.id]:false, esriServiceURL: "", esriAnalysisURL: "", esriClientSecret: "", esriClientID: ""});
    }else{
        this.setState({[e.target.id]:true});
    }
  }

  createSubscription(productId, quantity, priceId, tokenId){
    this.setState({loading: true});
    if(this.state.accountType != "" && this.state.accountType == "utility"){
        this.setState({loading: false, modalBody: "Sorry, utility accounts cannot sign up for these subscription options", modalHeader: "Error", show: true});
    }else if(this.state.accountType != "" && this.state.accountType == "individual"){
        if(this.state.referral != "" && this.state.accountType != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.password1 != "" && this.state.password2 != ""){
            if(this.state.password1 == this.state.password2){
              this.setState({loading: true, alias: ""});
              this.sendFormSubscription(productId, quantity, priceId, tokenId);
            }else{
                this.setState({loading: false, modalBody: "Sorry, passwords must match. Verify password and try again.", modalHeader: "Error", show: true});
            }
        }else{
          this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
        }
    }else if(this.state.accountType == "consultant" && this.state.engineerEsri == true){
        if(this.state.referral != "" && this.state.companyName != "" && this.state.accountType != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.adminUsername != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.esriServiceURL != "" && this.state.esriAnalysisURL != "" && this.state.authMethod != "" && this.state.alias != "" && this.state.companyCountry != "" && this.state.companyState != "" && this.state.companyCity != ""){
            this.sendFormSubscription(productId, quantity, priceId, tokenId);
        }else{
            this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
        }
    }else{
        if(this.state.referral != "" && this.state.companyName != "" && this.state.accountType != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.password1 != "" && this.state.password2 != ""){
            this.setState({loading: true, alias: ""});
            this.sendFormSubscription(productId, quantity, priceId, tokenId);
        }else{
            this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
        }
    }
  }

  sendFormSubscription(productId, quantity, priceId, tokenId){
    axios.post('https://www.arutility.com/api/v4/accounts/create.php',{referral: this.state.referral, accountType: this.state.accountType, firstName: this.state.firstName, lastName: this.state.lastName, companyName: this.state.companyName, companyAddr1: this.state.companyAddr1, companyAddr2: this.state.companyAddr2, companyCity: this.state.companyCity, companyState: this.state.companyState, companyCountry: this.state.companyCountry, adminEmail: this.state.adminEmail, adminUsername: this.state.adminUsername, companyPhone: this.state.companyPhone, esriServiceURL: this.state.esriServiceURL, esriAnalysisURL: this.state.esriAnalysisURL, esriClientID: this.state.esriClientID, esriClientSecret: this.state.esriClientSecret, authMethod: this.state.authMethod, password: this.state.password1, alias: this.state.alias})
    .then(res => {
        this.setState({loading: true});
        var accountId = res.data.accountId;
        var userId = res.data.userId;
        axios.post('https://www.arutility.com/api/v5/subscriptions/accountCreate.php',{productId: productId, priceId: priceId, quantity: quantity, email: this.state.adminEmail, tokenId: tokenId, accountType: this.state.accountType, accountId: accountId, userId: userId, mailingAddr1: this.state.companyAddr1_mail, mailingAddr2: this.state.companyAddr2_mail, mailingState: this.state.companyState_mail, mailingCity: this.state.companyCity_mail, mailingZip: this.state.companyZip_mail, mailingCountry: this.state.companyCountry_mail, mailingFirstName: this.state.firstName_mail, mailingLastName: this.state.lastName_mail, mailingCompany: this.state.companyName_mail, mailingPhone: this.state.companyPhone_mail, userFirstName: this.state.firstName, esriServiceURL: this.state.esriServiceURL, alias: this.state.alias})
        .then(res => {
            this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }).catch(error => {
        if (error.response) {
            this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
        } else if (error.request) {
          // The request was made but no response was received
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
        } else {
          // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
        }
    });
  }

  submitCardError(error){
    this.setState({show: true, modalHeader: "Error", modalBody: error});
  }

  recaptchaExpired(){
    this.setState({verified: false});
  }

  recaptchaVerified(){
    this.setState({verified: true});
  }

  updateForm(e){
    this.setState({[e.target.id]:e.target.value, [e.target.id + "_mail"]: e.target.value});
  }

  updateMailForm(e){
    this.setState({[e.target.id]:e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  sendForm(){
  	axios.post('https://www.arutility.com/api/v4/accounts/create.php',{referral: this.state.referral, accountType: this.state.accountType, firstName: this.state.firstName, lastName: this.state.lastName, companyName: this.state.companyName, companyAddr1: this.state.companyAddr1, companyAddr2: this.state.companyAddr2, companyCity: this.state.companyCity, companyState: this.state.companyState, companyCountry: this.state.companyCountry, adminEmail: this.state.adminEmail, adminUsername: this.state.adminUsername, companyPhone: this.state.companyPhone, esriServiceURL: this.state.esriServiceURL, esriAnalysisURL: this.state.esriAnalysisURL, esriClientID: this.state.esriClientID, esriClientSecret: this.state.esriClientSecret, authMethod: this.state.authMethod, password: this.state.password1, alias: this.state.alias})
	.then(res => {
	    this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
	}).catch(error => {
	    if (error.response) {
	        this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
	    } else if (error.request) {
	      // The request was made but no response was received
	        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
	    } else {
	      // Something happened in setting up the request that triggered an Error
	        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
	    }
	});
  }

  submitForm(){
    if(this.state.verified){
      	if(this.state.accountType != "" && this.state.accountType == "utility"){
    	    if(this.state.referral != "" && this.state.companyName != "" && this.state.accountType != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.adminUsername != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.esriServiceURL != "" && this.state.esriAnalysisURL != "" && this.state.authMethod != "" && this.state.alias != "" && this.state.companyCountry != "" && this.state.companyState != "" && this.state.companyCity != ""){
    	      this.setState({loading: true});
    	      this.sendForm();	      
    	    }else{
    	      this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
    	    }
    	}else if(this.state.accountType != "" && this.state.accountType == "individual"){
    		if(this.state.referral != "" && this.state.accountType != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.password1 != "" && this.state.password2 != ""){
    			if(this.state.password1 == this.state.password2){
    		      this.setState({loading: true, alias: ""});
    		      this.sendForm();
    		    }else{
    		    	this.setState({loading: false, modalBody: "Sorry, passwords must match. Verify password and try again.", modalHeader: "Error", show: true});
    		    }
    		}else{
    	      this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
    	    }
    	}else{
    		if(this.state.referral != "" && this.state.companyName != "" && this.state.accountType != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.password1 != "" && this.state.password2 != ""){
    			this.setState({loading: true, alias: ""});
    			this.sendForm();
    		}else{
    			this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
    		}
    	}
    }else{
        this.setState({loading: false, modalBody: "Sorry, you did not complete the captcha", modalHeader: "Error", show: true});
    }
  }

  render(){
    const {show} = this.state;
    return (
      <div>
      	{this.state.loading &&
            <LoadingSpinner />
        }
        <MainHeader />
        <Modal show={show} onHide={this.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalBody}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
            </Modal.Footer>
        </Modal>
        {this.state.showMailing &&
            <div className="mailingBackground">
                <div className="mailingWrapper">
                    <div className="mailingClose" onClick={this.closeMailing}>
                        <img src="/images/close.png" />
                    </div>
                    <div className="mailingHeader">
                        CONFIRM MAILING ADDRESS
                    </div>
                    <div className="mailingInfo">
                        <font color="#c62828">First Name:*</font>
                        <br/>
                        <input type="text" value={this.state.firstName_mail} id="firstName_mail" className="companyInput" onChange={this.updateMailForm} />
                        <br/>
                        <br/>
                        <font color="#c62828">Last Name:*</font>
                        <br/>
                        <input type="text" value={this.state.lastName_mail} id="lastName_mail" className="companyInput" onChange={this.updateMailForm} />
                        <br/>
                        <br/>
                        <font color="#c62828">Contact Number:*</font>
                        <br/>
                        <input type="tel" value={this.state.companyPhone_mail} id="companyPhone_mail" className="companyInput" onChange={this.updateMailForm} />
                        <br/>
                        <br/>
                        {this.state.accountType != "individual" &&
                            <div>
                                <font color="#c62828">Company Name:</font>
                                <br/>
                                <input type="text" value={this.state.companyName_mail} id="companyName_mail" className="companyInput" onChange={this.updateMailForm} />
                                <br/>
                                <br/>
                            </div>
                        }
                        <font color="#c62828">Country:*</font>
                        <br/>
                        <select className="projectSelect" id="companyCountry_mail" value={this.state.companyCountry_mail} onChange={this.updateMailForm}>
                            <option value=""></option>
                            <option value="Afganistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire">Bonaire</option>
                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Canary Islands">Canary Islands</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Channel Islands">Channel Islands</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos Island">Cocos Island</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote DIvoire">Cote DIvoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaco">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Ter">French Southern Ter</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Great Britain">Great Britain</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="India">India</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea North">Korea North</option>
                            <option value="Korea Sout">Korea South</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Midway Islands">Midway Islands</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Nambia">Nambia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherland Antilles">Netherland Antilles</option>
                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                            <option value="Nevis">Nevis</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau Island">Palau Island</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Phillipines">Philippines</option>
                            <option value="Pitcairn Island">Pitcairn Island</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                            <option value="Republic of Serbia">Republic of Serbia</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="St Barthelemy">St Barthelemy</option>
                            <option value="St Eustatius">St Eustatius</option>
                            <option value="St Helena">St Helena</option>
                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                            <option value="St Lucia">St Lucia</option>
                            <option value="St Maarten">St Maarten</option>
                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                            <option value="Saipan">Saipan</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa American">Samoa American</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tahiti">Tahiti</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Erimates">United Arab Emirates</option>
                            <option value="United States of America">United States of America</option>
                            <option value="Uraguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State">Vatican City State</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                        <br/>
                        <br/>
                        {this.state.companyCountry == "United States of America" ? 
                            <div>
                                <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State:*</font>
                                <br/>
                                <select className="projectSelect" id="companyState_mail" value={this.state.companyState_mail} onChange={this.updateMailForm}>
                                    <option value=""> </option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                                <br/>
                                <br/>
                            </div>
                            :
                            <div>
                                <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State/Province/Region:*</font>
                                <br/>
                                <input type="text" value={this.state.companyState_mail} id="companyState_mail" className="companyInput" placeholder="State/Province/Region" onChange={this.updateMailForm}/>
                                <br/>
                                <br/>
                            </div>
                        }
                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} City/Town/Village:*</font>
                        <br/>
                        <input type="text" value={this.state.companyCity_mail} id="companyCity_mail" className="companyInput" placeholder="Lansing..." onChange={this.updateMailForm}/>
                        <br/>
                        <br/>
                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} Address:*</font>
                        <br/>
                        <input type="text" value={this.state.companyAddr1_mail} id="companyAddr1_mail" className="companyInput" placeholder="123 Main St..." onChange={this.updateMailForm}/>
                        <br/>
                        <br/>
                        <font color="#c62828">Address Cont:</font>
                        <br/>
                        <input type="text" value={this.state.companyAddr2_mail} id="companyAddr2_mail" className="companyInput" placeholder="Apt 1..." onChange={this.updateMailForm}/>
                        <br/>
                        <br/>
                        <font color="#c62828">Zipcode:</font>
                        <br/>
                        <input type="text" value={this.state.companyZip_mail} id="companyZip_mail" className="companyInput" onChange={this.updateMailForm}/>
                        <br/>
                        <br/>
                    </div>
                    <div className="mailingApprove">
                        <button value="Submit" id="mailSubmit" onClick={this.confirmMailing}>Confirm Mailing Address and Proceed With Purchase</button>
                    </div>
                </div>
            </div>
        }
        <div className="pageTitle">
          CREATE ACCOUNT
        </div>
        <div className="pageDescription">
            <div className="createAccount">
      	        <font color="#c62828">Account Type:*</font>
                <br/>
                <select className="projectSelect" id="accountType" value={this.state.accountType} onChange={this.updateForm}>
                	<option value="utility">Utility</option>
                	<option value="contractor">Contractor/Excavator</option>
                	<option value="locator">Utility Locator</option>
                	<option value="consultant">Consultant/Engineering</option>
                	<option value="individual">Individual/Homeowner</option>
                </select> 
                <div className="accountTypeInfo">
                	<img src="/images/info.png" alt="More Info"/>
                	<div className="infoPopup">
                	{this.state.accountType == "utility" &&
                		<div>
                			Utility accounts can sign up for ARUtility for FREE. A free account allows your utility to respond to requests at no cost. You can share your information with other users to ensure the safety of your assets. To unlock all features of your utility account, please contact sales@arutility.com to receive pricing information.
                		</div>
                	}
                	{this.state.accountType == "contractor" &&
                		<div>
                			Contractor/Excavator accounts are limited to those performing construction activities that do not own any utility assets. Examples are landscaping contractors, underground excavators, builders and more.
                		</div>
                	}
                	{this.state.accountType == "locator" &&
                		<div>
                			Locators can create projects and request information from utilities for stake and locate functions. This account type is intended for stake and locate contract services. If you are a utility locator working for a public or private utility, please sign up for a utility account.
                		</div>
                	}
                	{this.state.accountType == "consultant" &&
                		<div>
                			Consulting/Engineering firms can create projects and request information from utilities. Make more informed design decisions, easily get access to utility information for engineering design and increase safety during construction by maintaining a constant visual of where assets are located during construction.
                		</div>
                	}
                	{this.state.accountType == "individual" &&
                		<div>
                			Individual accounts are reserved for homeowners performing work on their property. Individuals can create projects and request information from utilities and visualize assets in augmented reality.
                		</div>
                	}
                	</div>
                </div>
                <br/>
                <br/>
                {this.state.accountType == "consultant" && 
                    <div>
                        <input id="engineerEsri" type="checkbox" name="engineerEsri" checked={this.state.engineerEsri} onChange={this.changeEsriRadio} className="featureSelectedCheckbox"/> Connect Esri Account?
                        <br/>
                        <br/>
                    </div>
                }
                {(this.state.accountType == "utility" || this.state.engineerEsri == true) &&
                    <div>
                        <font color="#c62828">Company Alias:*</font>  
                        <br/>
                        <input type="text" maxLength="13" value={this.state.alias} id="alias" className="companyInput" onChange={this.updateForm} />
                        <br/>
                        <b>Your login URL will be: </b><font color="blue">www.arutility.com/login/{this.state.alias}</font>
                        <br/>
                        Please save this URL to sign in upon successful account creation.
                        <br/> 
                        <br/>
                    </div>
                }
                <font color="#c62828">First Name:*</font>
                <br/>
                <input type="text" value={this.state.firstName} id="firstName" className="companyInput" onChange={this.updateForm} />
                <br/>
                <br/>
                <font color="#c62828">Last Name:*</font>
                <br/>
                <input type="text" value={this.state.lastName} id="lastName" className="companyInput" onChange={this.updateForm} />
                <br/>
                <br/>
                <font color="#c62828">Contact Number:*</font>
                <br/>
                <input type="tel" value={this.state.companyPhone} id="companyPhone" className="companyInput" onChange={this.updateForm} />
                <br/>
                <br/>
                {this.state.accountType != "individual" &&
                	<div>
    	            	<font color="#c62828">Company Name:*</font>
    		            <br/>
    		            <input type="text" value={this.state.companyName} id="companyName" className="companyInput" onChange={this.updateForm} />
    		            <br/>
    		            <br/>
    		        </div>
            	}
                <font color="#c62828">Country:*</font>
                <br/>
                <select className="projectSelect" id="companyCountry" value={this.state.companyCountry} onChange={this.updateForm}>
                    <option value=""></option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">French Southern Ter</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">Netherland Antilles</option>
                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                    <option value="Republic of Serbia">Republic of Serbia</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">United Arab Emirates</option>
                    <option value="United States of America">United States of America</option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">Vatican City State</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <br/>
                <br/>
                {this.state.companyCountry == "United States of America" ? 
                    <div>
                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State:*</font>
                        <br/>
                        <select className="projectSelect" id="companyState" value={this.state.companyState} onChange={this.updateForm}>
                            <option value=""> </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <br/>
                        <br/>
                    </div>
                    :
                    <div>
                        <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} State/Province/Region:*</font>
                        <br/>
                        <input type="text" value={this.state.companyState} id="companyState" className="companyInput" placeholder="State/Province/Region" onChange={this.updateForm}/>
                        <br/>
                        <br/>
                    </div>
                }
                <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} City/Town/Village:*</font>
                <br/>
                <input type="text" value={this.state.companyCity} id="companyCity" className="companyInput" placeholder="Lansing..." onChange={this.updateForm}/>
                <br/>
                <br/>
                <font color="#c62828">{this.state.accountType != "individual" ? ("Company") : ("")} Address:*</font>
                <br/>
                <input type="text" value={this.state.companyAddr1} id="companyAddr1" className="companyInput" placeholder="123 Main St..." onChange={this.updateForm}/>
                <br/>
                <br/>
                <font color="#c62828">Address Cont:</font>
                <br/>
                <input type="text" value={this.state.companyAddr2} id="companyAddr2" className="companyInput" placeholder="Apt 1..." onChange={this.updateForm}/>
                <br/>
                <br/>
                <font color="#c62828">Admin Email:*</font>
                <br/>
                <input type="text" value={this.state.adminEmail} id="adminEmail" className="companyInput" onChange={this.updateForm}/>
                <br/>
                <br/>
                {(this.state.accountType == "utility" || this.state.engineerEsri == true) ? 
                	(
    	            	<div>
                            <font color="#c62828">Admin Esri Username:*</font>
                            <br/>
                            <input type="text" value={this.state.adminUsername} id="adminUsername" className="companyInput" onChange={this.updateForm}/>
                            <br/>
                            <br/>
    			            <font color="#c62828">
    			                <label htmlFor="esriServiceURL">Esri Service URL:*</label>
    			            </font>
    			            <br/>
    			            <input type="text" value={this.state.esriServiceURL} id="esriServiceURL" className="projectInputField" placeholder="https://services8.arcgis.com/xxxxxxxxxxxxxxxx/ArcGIS/rest/services" onChange={this.updateForm}/>
    			            <br/>
    			            <br/>
                            <font color="#c62828">
                                <label htmlFor="esriAnalysisURL">Esri Analysis URL:*</label>
                            </font>
                            <br/>
                            <input type="text" value={this.state.esriAnalysisURL} id="esriAnalysisURL" className="projectInputField" placeholder="https://analysis8.arcgis.com/arcgis/rest/services" onChange={this.updateForm}/>
                            <br/>
                            <br/>
    			            <select className="projectSelect" id="authMethod" value={this.state.authMethod} onChange={this.updateForm}>
    			                <option value="agol">ArcGIS Online</option>
    			                <option value="enterprise">ArcGIS Enterprise/Portal</option>
    			            </select>
    			            <br/>
    			            {this.state.authMethod == "agol" ? 
    			           		<br/>
    			           		:
    			           		(
    			           			<div>
    						            <br/>
    						            <input type="text" value={this.state.esriClientID} id="esriClientID" className="projectInputField" placeholder="App Client Id..." onChange={this.updateForm}/>
    						            <br/>
    						            <br/>
    						            <input type="text" value={this.state.esriClientSecret} id="esriClientSecret" className="projectInputField" placeholder="App Client Secret..." onChange={this.updateForm}/>
    						            <br/>
    						            <br/>
    					            </div>
    					        )
    			           	}
    		            </div>
    	            )
            	:
            		(
            			<div>
    		        		<font color="#c62828">Password:*</font>
    			            <br/>
    			            <input type="password" value={this.state.password1} id="password1" className="companyInput" onChange={this.updateForm}/>
    			            <br/>
    			            <br/>
    			            <font color="#c62828">Verify Password:*</font>
    			            <br/>
    			            <input type="password" value={this.state.password2} id="password2" className="companyInput" onChange={this.updateForm}/>
    			            <br/>
    			            <br/>
    			        </div>
    			    )
	        	}
                <font color="#c62828">How did you hear about us?*</font>
                <br/>
                <select className="projectSelect" id="referral" value={this.state.referral} onChange={this.updateForm}>
                    <option value=""> </option>
                    <option value="centerline">Centerline Mapping</option>
                    <option value="fieldgo">FieldGO</option>
                    <option value="esri">Esri</option>
                    <option value="none">No Referral</option>
                    <option value="other">Other</option>
                </select>
                <br/>
                <br/>
                {this.state.accountType != "utility" ?
                    <div className="subscriptionOptions">
                        <div className="subscriptionGroup">
                            <div className="subscriptionGroupTitle">
                                <font size="+3">BUNDLES</font> 
                            </div>
                            <div className="subscriptionWrapperLarge">
                              <Elements stripe={stripePromise}>
                                <CheckoutFormSignup submitError={this.submitCardError} productTitle="YEARLY PRO" productPrice="720" frequency="year" productId={this.state.testing ? "prod_I9ft32uaN1g09Q,prod_IKsLcCPoKn5zyZ" : "prod_I9fueHX9AZQk0O,prod_IKsIpqRCUcdBzV"} priceId={this.state.testing ? "price_1HZMY0KTDVl8ERQLGovkgwL5,price_1HkCaPKTDVl8ERQL8EfDtACQ" : "price_1HZMZ1KTDVl8ERQL5IAx6ee6,price_1HkCYFKTDVl8ERQLvT4JWTmP"} productDescription='<img src="/images/arrowGold.jpg" /><ul><li>Billed Yearly at $720/year</li><li>Eos Arrow Gold RTK GPS Unit</li><li>14 day FREE trial</li><li>Total due covers the cost of the Arrow Gold RTK unit + shipping and will be billed immediately</li></ul>' requiresMailingAddress="true" getMailingAddress={this.getMailingAddress} totalDue="9,000"/>
                              </Elements>
                            </div>
                            <div className="subscriptionWrapperLarge">
                              <Elements stripe={stripePromise}>
                                <CheckoutFormSignup submitError={this.submitCardError} productTitle="QUARTERLY PRO" productPrice="240" frequency="3 months" productId={this.state.testing ? "prod_I9ftGk9XB62hXE,prod_IKsLcCPoKn5zyZ" : "prod_I9fuBPek6HxSoI,prod_IKsIpqRCUcdBzV"} priceId={this.state.testing ? "price_1HZMXXKTDVl8ERQLAMQRr2Op,price_1HkCaPKTDVl8ERQL8EfDtACQ" : "price_1HZMZ5KTDVl8ERQLztE2KOOs,price_1HkCYFKTDVl8ERQLvT4JWTmP"} productDescription='<img src="/images/arrowGold.jpg" /><ul><li>Billed Quarterly at $240 every 3 months</li><li>Eos Arrow Gold RTK GPS Unit</li><li>14 day FREE trial</li><li>Total due covers the cost of the Arrow Gold RTK unit + shipping and will be billed immediately</li></ul>' requiresMailingAddress="true" getMailingAddress={this.getMailingAddress} totalDue="9,000"/>
                              </Elements>
                            </div>
                            <div className="subscriptionWrapperLarge">
                              <Elements stripe={stripePromise}>
                                <CheckoutFormSignup submitError={this.submitCardError} productTitle="MONTHLY PRO" productPrice="100" frequency="month" productId={this.state.testing ? "prod_I9fsZ22KOI3UN0,prod_IKsLcCPoKn5zyZ" : "prod_I9fuNQ6kkw3J34,prod_IKsIpqRCUcdBzV"} priceId={this.state.testing ? "price_1HZMX6KTDVl8ERQLprR6Xmds,price_1HkCaPKTDVl8ERQL8EfDtACQ" : "price_1HZMZ9KTDVl8ERQLsFtofLqY,price_1HkCYFKTDVl8ERQLvT4JWTmP"} productDescription='<img src="/images/arrowGold.jpg" /><ul><li>Billed Monthly at $100/month</li><li>Eos Arrow Gold RTK GPS Unit</li><li>14 day FREE trial</li><li>Total due covers the cost of the Arrow Gold RTK unit + shipping and will be billed immediately</li></ul>' requiresMailingAddress="true" getMailingAddress={this.getMailingAddress} totalDue="9,000"/>
                              </Elements>
                            </div>
                        </div>
                        <div className="subscriptionGroup">
                            <div className="subscriptionGroupTitle">
                                <font size="+3">LICENSE ONLY</font> 
                            </div>
                            <div className="subscriptionWrapper">
                              <Elements stripe={stripePromise}>
                                <CheckoutFormSignup submitError={this.submitCardError} productTitle="YEARLY" productPrice="720" frequency="year" productId={this.state.testing ? "prod_I9ft32uaN1g09Q" : "prod_I9fueHX9AZQk0O"} priceId={this.state.testing ? "price_1HZMY0KTDVl8ERQLGovkgwL5" : "price_1HZMZ1KTDVl8ERQL5IAx6ee6"} productDescription="<ul><li>Billed Yearly at $720/year</li><li>14 day FREE trial</li></ul>" createSubscription={this.createSubscription} totalDue="0"/>
                              </Elements>
                            </div>
                            <div className="subscriptionWrapper">
                              <Elements stripe={stripePromise}>
                                <CheckoutFormSignup submitError={this.submitCardError} productTitle="QUARTERLY" productPrice="240" frequency="3 months" productId={this.state.testing ? "prod_I9ftGk9XB62hXE" : "prod_I9fuBPek6HxSoI"} priceId={this.state.testing ? "price_1HZMXXKTDVl8ERQLAMQRr2Op" : "price_1HZMZ5KTDVl8ERQLztE2KOOs"} productDescription="<ul><li>Billed Quarterly at $240 every 3 months</li><li>14 day FREE trial</li></ul>" createSubscription={this.createSubscription} totalDue="0"/>
                              </Elements>
                            </div>
                            <div className="subscriptionWrapper">
                              <Elements stripe={stripePromise}>
                                <CheckoutFormSignup submitError={this.submitCardError} productTitle="MONTHLY" productPrice="100" frequency="month" productId={this.state.testing ? "prod_I9fsZ22KOI3UN0" : "prod_I9fuNQ6kkw3J34"} priceId={this.state.testing ? "price_1HZMX6KTDVl8ERQLprR6Xmds" : "price_1HZMZ9KTDVl8ERQLsFtofLqY"} productDescription="<ul><li>Billed Monthly at $100/month</li><li>14 day FREE trial</li></ul>" createSubscription={this.createSubscription} totalDue="0"/>
                              </Elements>
                            </div>
                        </div>
                        <img src="/images/Powered by Stripe - black.png" className="stripeLogo"/>
                    </div>
                    :
                    <div>
                        <div className="recaptchaContainer">
                            <ReCAPTCHA
                                sitekey="6LcPxuYUAAAAAPaJX64OnB190ljQCB0kSBalA43X"
                                size="normal"
                                onChange={this.recaptchaVerified}
                                onExpired={this.recaptchaExpired}
                            />
                        </div>
                        <br/>
                        <button value="Submit" id="createAccountSubmit" onClick={this.submitForm}>Create Account</button>
                    </div>
                }
            </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default CreateAccount;