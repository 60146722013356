import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './HowItWorks.css';

class HowItWorks extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
    this.focusContractors = React.createRef();
    this.focusUtilities = React.createRef();
    this.focusUtilityLocators = React.createRef();
    this.focusArchitects = React.createRef();
    this.focusEngineers = React.createRef();
    this.focusHomeowners = React.createRef();
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event){
    if(event.target.id == "focusContractors"){
      this.focusContractors.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusUtilities"){
      this.focusUtilities.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusUtilityLocators"){
      this.focusUtilityLocators.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusArchitects"){
      this.focusArchitects.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusEngineers"){
      this.focusEngineers.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusHomeowners"){
      this.focusHomeowners.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="pageTitle">
          HOW IT WORKS
        </div>
        <div className="pageDescription">
          <video preload="auto" className="howItWorksVideo" autoPlay={true} muted={true} loop={true} controls={true}>
            <source src="images/ARUtilityHome1.mp4" type="video/mp4" />
          </video>
          <div className="howitworksExplanation">
            <font size="6"><b>WHAT IS ARUTILITY?</b></font>
            <p>
            ARUtility is the leading augmented reality utility locating, asset management and BIM visualization application that aims to reduce underground damages, make excavation more cost effective, aid Engineers in identifying conflicts before they happen, and assist utilities with asset management. Now utilities can see where their assets are located in the ground in real time
            </p>
          </div>
          <div className="howitworksIndustryWrapper" onClick={this.handleOnClick} id="focusUtilities">
            <div className="industryWrapper" id="focusUtilities">
              <div className="industryWrapperTitle" id="focusUtilities">
                Utilities
              </div>
              <div className="industryWrapperImg" id="focusUtilities">
                <img src="/images/utilities.png" alt="Utilities" id="focusUtilities"/>
              </div>
              <div className="industryWrapperNextImg" id="focusUtilities">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusUtilities"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusUtilityLocators">
              <div className="industryWrapperTitle" id="focusUtilityLocators">
                Utility Locators
              </div>
              <div className="industryWrapperImg" id="focusUtilityLocators">
                <img src="/images/utilitylocator.png" alt="Utilities" id="focusUtilityLocators"/>
              </div>
              <div className="industryWrapperNextImg" id="focusUtilityLocators">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusUtilityLocators"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusContractors">
              <div className="industryWrapperTitle" id="focusContractors">
                Contractors
              </div>
              <div className="industryWrapperImg" id="focusContractors">
                <img src="/images/excavators.png" alt="Utilities" id="focusContractors"/>
              </div>
              <div className="industryWrapperNextImg" id="focusContractors">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusContractors"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusArchitects">
              <div className="industryWrapperTitle" id="focusArchitects">
                Architects
              </div>
              <div className="industryWrapperImg" id="focusArchitects">
                <img src="/images/architects.png" alt="Utilities" id="focusArchitects"/>
              </div>
              <div className="industryWrapperNextImg" id="focusArchitects">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusArchitects"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusEngineers">
              <div className="industryWrapperTitle" id="focusEngineers">
                Engineers
              </div>
              <div className="industryWrapperImg" id="focusEngineers">
                <img src="/images/engineers.png" alt="Utilities" id="focusEngineers"/>
              </div>
              <div className="industryWrapperNextImg" id="focusEngineers">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusEngineers"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusHomeowners">
              <div className="industryWrapperTitle" id="focusHomeowners">
                Homeowners
              </div>
              <div className="industryWrapperImg" id="focusHomeowners">
                <img src="/images/homeowners.png" alt="Utilities" id="focusHomeowners"/>
              </div>
              <div className="industryWrapperNextImg" id="focusHomeowners">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusHomeowners"/>
              </div>
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusUtilities}>
            <div className="industryDetailTitle">
              Utilities
            </div>
            <div className="industryDetailDescription">
              Underground and above ground assets come to life with ARUtility. Unlike typical 2D maps, assets are displayed in 3D in their actual geographic locations. It's like giving you x-ray vision, right in the palm of your hands. Maintaining a constant visual of where assets are located at all times can ensure you avoid damages to assets during construction. Make more informed design decisions by identifying conflicts in the field prior to breaking ground, avoiding costly damages, costly change orders and more clearly communicating design decisions. Our collaborative platform allows you to share your utility information with anyone at anytime. Decrease the likelihood of others damaging your assets by easily sharing your asset information on a project with one click.
            </div>
            <div className="industryDetailImage">
              <img src="/images/arutility_screenshot_2.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusUtilityLocators}>
            <div className="industryDetailTitle">
              Utility Locators
            </div>
            <div className="industryDetailDescription">
              Visualize underground assets prior to locating and get a lay of the land. Using the built in measuring tool you can quickly and efficiently take measurements to better understand where assets are located. 2D maps and paper plans make it difficult to understand where you are in reference to an assets actual location in the world. Augmented reality allows you to visualize assets where they actually exist in the world as they are displayed around the user.
            </div>
            <div className="industryDetailImage">
              <img src="/images/locatingscreenshot.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusContractors}>
            <div className="industryDetailTitle">
              Contractors
            </div>
            <div className="industryDetailDescription">
              Avoid dangerous and costly damages to underground assets by maintaining a constant visual of where things are located while performing excavation. Placing ARUtility in the hands of operators, inspectors and field crews can significantly reduce the likelihood of damage. Digital markings never get removed during construction or inclement weather. Take advantage of the collaboration tool ahead of construction and quickly and easily gain access to all underground and above ground assets prior to digging.
            </div>
            <div className="industryDetailImage">
              <img src="/images/contractors.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusArchitects}>
            <div className="industryDetailTitle">
              Architects
            </div>
            <div className="industryDetailDescription">
              ARUtility's simple to use interface allows you to upload your 3D models in minutes. Any 3D model can be saved to your profile on ARUtility and loaded into augmented reality view on the fly! Visualize new construction before ever breaking ground. Combined with our utility locating application you can identify conflicts with existing utilities and plan new utility locations and hookups. No other augmented reality solution combines BIM visualizations with asset visualizations to allow a full picture of a construction site. See how new construction will look once complete, all before breaking ground.
            </div>
            <div className="industryDetailImage">
              <img src="/images/bimscreenshot.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusEngineers}>
            <div className="industryDetailTitle">
              Engineers & Consultants
            </div>
            <div className="industryDetailDescription">
              ARUtility's collaborative platform allows you to request asset information from all utilities on a project site. Visualize all assets in one location and then take those visualizations to another level by viewing assets in augmented reality on your mobile device. Make more informed design decisions and increase efficiency and safety throughout the entire lifecycle of your project. Upload KML files for even more accuracy. Perform record drawings by simply pointing your device at an assets real-world location, whether that's line features or point features. Snapping tools are included to maintain network connectivity. No other augmented reality solution has such a large and robust suite of tools to allow you to perform record drawings for all assets in augmented reality!
            </div>
            <div className="industryDetailImage">
              <img src="/images/measurescreenshot.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusHomeowners}>
            <div className="industryDetailTitle">
              Homeowners
            </div>
            <div className="industryDetailDescription">
              Homeowners can now easily and efficiently request utility information when performing excavation on their property! Imagine the increase in safety by providing homeowners with another tool that allows them to visualize assets in augmented reality. Users can simply create a project around their property and utilities can share that information with one simple click, or deny that request if they so choose. Homeowners can then verify staking flags and paint prior to excavation.
            </div>
            <div className="industryDetailImage">
              <img src="/images/homeownerscreenshot.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="pricingOptionFeaturesWrapper">
            <div className="pricingOptionFeaturesTitle">
              KEY FEATURES
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/infinity.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  UNLIMITED PROJECTS
                </div>
                Create an unlimited number of projects and respond to an unlimited number of requests. Projects allow you to request information from other utilities to view during design and construction, increasing safety and efficiency for your workforce.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/globe.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  ESRI INTEGRATION
                </div>
                Quickly and easily connect to your Esri ArcGIS Online or Enterprise account. This allows you to easily share your asset information with others on a project by project basis. This also allows you to visualize your ArcGIS assets in augmented reality.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/kml.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  KML FILE SUPPORT
                </div>
                Create projects by simply uploading KML files to your web portal. Quickly map your KML file layers to ARUtility layers and visualize your assets in augmented reality.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/editsession.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  EDIT SESSIONS
                </div>
                Edit sessions allow you to update existing assets and perform record drawings in augmented reality. Quickly and easily perform record drawings from the safety of the sidewalk.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/externalgps.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  EXTERNAL GPS SUPPORT
                </div>
                ARUtility can be paired with any external GPS unit to provide even more accuracy to your augmented reality visualizations. Accuracy varies by manufacturer and model. Some manufacturers can provide real-time cm accuracy.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/lidar.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  LIDAR SUPPORT
                </div>
                ARUtility always stays on top of the latest technology. We now support LiDar, as provided on the new iPad Pro 2020, for even more accurate tracking, occlusion and visualization.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/unlimitedusers.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  UNLIMITED LICENSES
                </div>
                Your subscription includes an unlimited number of licenses to ensure all of your workforce can take full advantage of the benefits ARUtility provides. Subscription fees are based on the number of utilities being served and the number of customers being served.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/remoteassist.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  REMOTE ASSISTANCE
                </div>
                Increase efficiency, more clearly communicate with field staff and reduce truck rolls with ARUtility's remote assistance. Remote assistance functionality is built directly into the ARUtility application, allowing you to take full advantage at no additional cost.
              </div>
            </div>
            <div className="pricingOptionFeatureSingle">
              <div className="pricingOptionFeatureImage">
                <img src="/images/collaborate.png" alt="Unlimited" />
              </div>
              <div className="pricingOptionFeatureDescription">
                <div className="pricingOptionDescriptionTitle">
                  COLLABORATION
                </div>
                ARUtility's collaboration platform is unlike anything else. Create projects, request asset information from other utilities, see projects in your territory and more through our collaboration platform. The power of AR increases exponentially when you can visualize every asset, not just your own.
              </div>
            </div>
          </div>         
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default HowItWorks;