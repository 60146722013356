import React from 'react';
import UserMenuOption from '../usermenuoption/UserMenuOption';
import {Context} from "../configcontext/ConfigContext";
import ReactDOM from 'react-dom'
import './UserMenu.css';

class UserMenu extends React.Component{

  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    }
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({showMenu: this.props.showMenu});
  }

  handleClick(){
    let element = document.getElementById("userMenuWrapper");
    if(this.state.showMenu == false){
      this.setState({showMenu: true});
      this.props.showMenuFunction(true);
      ReactDOM.findDOMNode(element).style.display = "block";
    }else{
      this.setState({showMenu: false});
      this.props.showMenuFunction(false);
      ReactDOM.findDOMNode(element).style.display = "none";
    }
  }

  render(){
    let value = this.context;
      return(
        <div className="userMenuWrapper" id="userMenuWrapper" onClick={this.handleClick}>
          {value.userPermissions.includes("profile:update") &&
            <UserMenuOption goto="/profile/settings" text="Profile Settings"/>
          }
          {value.userPermissions.includes("account:update") &&
            <UserMenuOption goto="/account/settings" text="Account Settings"/>
          }
          {value.userPermissions.includes("mapping:update") &&
            <UserMenuOption goto="/account/mapping" text="Utility Mapping"/>
          }
          {value.userPermissions.includes("users:view") &&
            <UserMenuOption goto="/users/manage" text="Manage Users"/>
          }
          <UserMenuOption goto="/knowledgebase" text="Knowledge Base"/>
          <UserMenuOption goto="/support" text="Support Center" />
        </div>
      );
  }
}

export default UserMenu;