import React from 'react';
import {NavLink} from 'react-router-dom';
import {Context} from "../configcontext/ConfigContext";
import './Leftrail.css';

class Leftrail extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      showFullMenu: false
    }
    this.showFullMenu = this.showFullMenu.bind(this);
    this.hideFullMenu = this.hideFullMenu.bind(this);
  }

  showFullMenu(){
    this.setState({showFullMenu: true});
  }

  hideFullMenu(){
    this.setState({showFullMenu: false});
  }

  render(){
    let value = this.context;
    if (value.jwt == "") {
      return null;
    } else {
        return (
        <div className={this.state.showFullMenu ? "fullLeftRail":"leftRail"} onMouseEnter={this.showFullMenu} onMouseLeave={this.hideFullMenu}>
          <div className="leftRailMenu">
            {value.userPermissions.includes("projects:view") &&
              <NavLink to="/projects">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/location.png'} alt="showProjects"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Projects
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink>
            }
            {value.userPermissions.includes("requests:view") &&
              <NavLink activeClassName="active" to="/requests">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/requests2.png'} alt="showRequests"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Requests
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            }
            {value.userPermissions.includes("projectsbim:view") &&
              <NavLink activeClassName="active" to="/bimprojects">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/bimbtn.png'} alt="showBIMProjects"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      BIM Models
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink>
            }
            {(value.userPermissions.includes("project:create") || value.userPermissions.includes("projectsbim:create"))  &&
              <NavLink activeClassName="active" to="/project/create">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/addproject.png'} alt="createProject"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Create Project
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink>
            }
            {value.userPermissions.includes("editsessions:view") &&
              <div>
                <NavLink activeClassName="active" to="/session/edits">
                  <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                    <img src={'/images/featureEditImg.png'} alt="editSessions"/>
                    {this.state.showFullMenu ?
                      <div className="menuOptionText">
                        Edit Sessions
                      </div>
                      :
                      ""
                    }
                  </div>
                </NavLink>
              </div>
            }
            {value.userPermissions.includes("remote:view") &&
              <div>
                <NavLink activeClassName="active" to="/remotesession">
                  <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                    <img src={'/images/chatbtn.png'} alt="remoteSession"/>
                    {this.state.showFullMenu ?
                      <div className="menuOptionText">
                        Remote Assistance
                      </div>
                      :
                      ""
                    }
                  </div>
                </NavLink>
              </div>
            }
            <NavLink to="/woprojects">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/womanagement.png'} alt="Manage Work Orders"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Work Order Management
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            {value.userPermissions.includes("communitymap:view") &&
              <NavLink activeClassName="active" to="/community/map">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/projectMap.png'} alt="projectMap"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Community Map
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink>
            }
            {value.userPermissions.includes("billing:update") &&
              <NavLink to="/billing">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/billing.png'} alt="Manage Billing"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Manage Billing
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            }
            <div className={this.state.showFullMenu ? "logoutLeftMenuFull":"logoutLeftMenuSmall"} onClick={() => {value.logout()}}>
              <div className="childActive">
                <img src={'/images/logout.png'} alt="Web Remote Assistance"/>
                {this.state.showFullMenu ?
                  <div className="menuOptionText">
                    Logout
                  </div>
                  :
                  ""
                }
              </div>
            </div> 
          </div>
        </div>
      );
    }
  }
}

export default Leftrail;