import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Link} from 'react-router-dom';
import {Context} from "../configcontext/ConfigContext";
import './Careers.css';

class Careers extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="pageTitle">
          CAREER OPPORTUNITIES
        </div>
        <div className="pageDescription">
          <div className="howitworksExplanation">
            ARUtility is an exciting young company that is leading the way in augmented reality solutions for the utility
            industry (water, electric, gas, fiber, sewer and more)! We are quickly growing and gaining global attention
            which has resulted in a need to grow our team. We are an investor backed startup that has already
            gained significant traction. We were recently named an Emerging Business Partner with the worlds
            leading Geographic Information System provider, Esri. We are looking for individuals who are looking for the
            opportunity to be creative, bring to life new ideas and have a lot of fun while doing it!
            <br/>
            <br/>
            The following positions are currently available:
            <br/>
            <br/>
            No jobs are currently posted.
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Careers;