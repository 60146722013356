import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import {Context} from "../configcontext/ConfigContext";
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from 'agora-rtm-sdk';
import Immutable from 'immutable';
import './RemoteSession.css';

const rtm = {
  client: null,
  token: null,
  channel: null
}

const rtc = {
  // For the local client
  client: null,
  // For the local audio and video tracks
  localAudioTrack: null,
  localVideoTrack: null,
};

const options = {
  // Pass your app ID here
  appId: "43a5724c325149289a2db17fce9c63e0",
  // Set the channel name
  //channel: "test",
  // Pass a token if your project enables the App Certificate
  token: null,
};

const remoteConfig = {
  fit: "cover",
  mirror: false
}

class RemoteSession extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        chatName: "",
        chatPassword: "",
        show: false,
        modalBody: "",
        modalHeader: "",
        lines: new Immutable.List(),
        localUID: "",
        remoteUID: "",
        remoteUsername: "",
        remoteVideoWidth: "",
        remoteVideoHeight: "",
        aspectRatio: 0,
        showDrawMenu: false,
        showColorMenu: false,
        showShapeMenu: false,
        showNoteMenu: false,
        tool: "line",
        thickness: "0.005",
        color: "FF0000",
        shape: "aura",
        uid: "",
        noteText: "",
        drawing: false,
        localAudioMuted: false,
        remoteAudioMuted: false,
        startX: "",
        startY: "",
        lineCoords: [],
        loading: false,
        onCall: false,
        callStartTime: "",
        callEndTime: "",
        user: "test"
    }
    const { Map } = require('immutable');
    this.streamRef = React.createRef();
    this.streamWrapperRef = React.createRef();
    this.startBasicCall = this.startBasicCall.bind(this);
    this.leaveCall = this.leaveCall.bind(this);
    this.startVideoCall = this.startVideoCall.bind(this);
    this.updateChat = this.updateChat.bind(this);
    this.sendTestMessage = this.sendTestMessage.bind(this);
    this.showDrawMenu = this.showDrawMenu.bind(this);
    this.showColorMenu = this.showColorMenu.bind(this);
    this.showShapeMenu = this.showShapeMenu.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.endDrawing = this.endDrawing.bind(this);
    this.updateLineCoords = this.updateLineCoords.bind(this);
    this.approveNoteText = this.approveNoteText.bind(this);
    this.cancelNoteText = this.cancelNoteText.bind(this);
    this.selectDrawingTool = this.selectDrawingTool.bind(this);
    this.selectShapeTool = this.selectShapeTool.bind(this);
    this.selectUndoTool = this.selectUndoTool.bind(this);
    this.selectEraseTool = this.selectEraseTool.bind(this);
    this.muteLocalAudio = this.muteLocalAudio.bind(this);
    this.muteRemoteAudio = this.muteRemoteAudio.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
    this.hex2rgb = this.hex2rgb.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async leaveCall() {
    // Destroy the local audio and video tracks
    let value = this.context;
    this.setState({loading: true});
    if(this.state.onCall == true){
      this.setState({callEndTime: Date.now()});
      var callDuration = Math.ceil((Date.now()-this.state.callStartTime)/1000);
      if(rtm.channel){
        rtm.channel.leave();
      }
      if(rtm.client){
        rtm.client.logout();
      }
      if(rtc.localAudioTrack){
        rtc.localAudioTrack.close();
      }
      if(rtc.localVideoTrack){
        rtc.localVideoTrack.close();
      }

      if(rtc.client){
        if(rtc.client.remoteUsers){
          // Traverse all remote users
          rtc.client.remoteUsers.forEach(user => {
            // Destroy the dynamically created DIV container
            const playerContainer = document.getElementById(user.uid);
            playerContainer && playerContainer.remove();
          });
        }
        await rtc.client.leave();
      }  
      /*axios.post('https://www.arremoteassistance.com/api/v1/calls/create.php',{duration: callDuration, callStartedBy: this.state.remoteUsername, callReceivedBy: value.username},{headers: {Authorization: 'Bearer ' + value.jwt}})       
      .then(res => {
        //DO NOTHING
      }).catch(error => {
        if (error.response) {
            this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
        } else if (error.request) {
          // The request was made but no response was received
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
        } else {
          // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
        }
      });*/
      this.setState({loading: false, show: true, remoteUsername: "", remoteUID: "", modalHeader: "Success", modalBody: "You have successfully ended your remote session", onCall: false, remoteVideoWidth: 0, remoteVideoHeight: 0, aspectRatio: 0});
    }else{
      this.setState({loading: false, show: true, modalHeader: "Error", modalBody: "You are not currently on a call"});
    }
  }

  async startBasicCall() { 
    let value = this.context;
    this.setState({loading: true, callStartTime: Date.now()});
    console.log(Date.now());
    this.streamWrapperRef.current.scrollIntoView({ 
      behavior: "smooth", 
      block: "nearest"
    });
    this.setState({onCall: true});
    rtm.client = AgoraRTM.createInstance(options.appId);
    rtm.channel = rtm.client.createChannel(this.state.chatName);
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
    //rtc.client.setEncryptionMode("aes-256-xts");
    if(this.state.chatPassword != ""){
      rtc.client.setEncryptionConfig("aes-128-xts",this.state.chatPassword);
    }
    const uid = await rtc.client.join(options.appId, this.state.chatName, options.token, null);
    await rtm.client.login({ token: rtm.token, uid: uid.toString() }).then(() => {
      console.log('AgoraRTM client login success');
      rtm.channel.join().then(() => {
        console.log("Joined channel success");
        //let value = this.context;
        //var message = "{\"feature\":\"userverified\",\"uid\":\"" + this.state.uid + "\",\"name\":\"" + value.username + "\"}";
        /*rtm.channel.sendMessage({ text: message}).then(() => {
          console.log("Sent Message!");
        }).catch(error => {
          console.log("Message send error!");
        });*/
        this.setState({loading: false, show: true, modalHeader: "Success", modalBody: "You may now begin your remote session"});
      }).catch(error => {
        console.log("Joined channel error");
        this.setState({loading: false, show: true, modalHeader: "Error", modalBody: "There was an error while joining the chat. Please try again"});
      });
    }).catch(err => {
      console.log('AgoraRTM client login failure', err);
      this.setState({loading: false, show: true, modalHeader: "Error", modalBody: "There was an error while joining the chat. Please try again: " + err});
    });
    this.state.uid = uid;
    // Create an audio track from the audio captured by a microphone
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera
    //rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // Publish the local audio and video tracks to the channel
    //await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    await rtc.client.publish([rtc.localAudioTrack]);
    //await rtc.client.publish([rtc.localAudioTrack, null]);

    var remoteUserList = rtc.client.remoteUsers;
    if(remoteUserList.length > 0){
      var userIdNum = remoteUserList[0].uid;
      if(userIdNum != this.state.remoteUID){
        var message = '{"feature":"verifyuser","uid":"' + userIdNum + '","app":"web"}';
        rtm.channel.sendMessage({ text: message}).then(() => {
          console.log("Sent Message!");
        }).catch(error => {
          console.log("Message send error!");
        });
      }
      this.setState({remoteUID: remoteUserList[0].uid});
      //console.log("Media Type:" + mediaType);
      // Subscribe to a remote user
      console.log("subscribe success");
      if(remoteUserList[0]._audio_added_ == true){
        await rtc.client.subscribe(remoteUserList[0], "audio");
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = remoteUserList[0].audioTrack;
        // Play the audio track. Do not need to pass any DOM element
        if(this.state.muteRemoteAudio == false){
          remoteAudioTrack.play();
        }
      }
      if(remoteUserList[0]._video_added_ == true){
        await rtc.client.subscribe(remoteUserList[0], "video");
        var streamParentDiv = document.getElementById("remoteStreamWrapper");
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = remoteUserList[0].videoTrack;
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const playerContainer = document.createElement("div");
        // Specify the ID of the DIV container. You can use the `uid` of the remote user.
        playerContainer.id = remoteUserList[0].uid;
        playerContainer.style.width = "100%";
        playerContainer.style.height = "100%";
        streamParentDiv.appendChild(playerContainer);
        //document.body.append(playerContainer);

        // Play the remote audio and video tracks
        // SDK dynamically creates a player in the container for playing the remote video track
        remoteVideoTrack.play(playerContainer, remoteConfig);
        remoteVideoTrack.on("first-frame-decoded", user => {
          var parent = this;
          setTimeout(function () {
            var videoStats = rtc.client.getRemoteVideoStats();
            //console.log(videoStats[userIdNum]);
            var aspectRatio = videoStats[userIdNum].receiveResolutionHeight/videoStats[userIdNum].receiveResolutionWidth;
            parent.setState({remoteVideoHeight: videoStats[userIdNum].receiveResolutionHeight, remoteVideoWidth: videoStats[userIdNum].receiveResolutionWidth, aspectRatio: aspectRatio});
          }, 2000);
        });
      }
    }

    rtc.client.on("user-published", async (user, mediaType) => {
      console.log("User " + user.uid + " published media");
      var userIdNum = user.uid;
      if(userIdNum != this.state.remoteUID){
        var message = '{"feature":"verifyuser","uid":"' + userIdNum + '","app":"web"}';
        rtm.channel.sendMessage({ text: message}).then(() => {
          console.log("Sent Message!");
        }).catch(error => {
          console.log("Message send error!");
        });
      }
      this.setState({remoteUID: user.uid});
      //console.log("Media Type:" + mediaType);
      // Subscribe to a remote user
      await rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");

      // || mediaType === "all"
      if (mediaType === "video" || mediaType === "all") {
        var streamParentDiv = document.getElementById("remoteStreamWrapper");
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const playerContainer = document.createElement("div");
        // Specify the ID of the DIV container. You can use the `uid` of the remote user.
        playerContainer.id = user.uid;
        playerContainer.style.width = "100%";
        playerContainer.style.height = "100%";
        streamParentDiv.appendChild(playerContainer);
        //document.body.append(playerContainer);

        // Play the remote audio and video tracks
        // SDK dynamically creates a player in the container for playing the remote video track
        remoteVideoTrack.play(playerContainer, remoteConfig);
        remoteVideoTrack.on("first-frame-decoded", user => {
          var parent = this;
          setTimeout(function () {
            var videoStats = rtc.client.getRemoteVideoStats();
            //console.log(videoStats[userIdNum]);
            var aspectRatio = videoStats[userIdNum].receiveResolutionHeight/videoStats[userIdNum].receiveResolutionWidth;
            parent.setState({remoteVideoHeight: videoStats[userIdNum].receiveResolutionHeight, remoteVideoWidth: videoStats[userIdNum].receiveResolutionWidth, aspectRatio: aspectRatio});
          }, 2000);
        });
        //console.log(videoStats[userIdNum]);
        //console.log("Height: " + videoStats[userIdNum].receiveResolutionHeight);
      }

      // || mediaType === "all"
      if (mediaType === "audio" || mediaType === "all") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. Do not need to pass any DOM element
        if(this.state.muteRemoteAudio == false){
          remoteAudioTrack.play();
        }
      }
    });

    rtc.client.on("user-unpublished", async (user, mediaType) =>{
      // Get the dynamically created DIV container
      const playerContainer = document.getElementById(user.uid);
      if (mediaType === "video" || mediaType === "all") {
        if(playerContainer){
          // Destroy the container
          playerContainer.remove();
        }
        this.setState({loading: false, show: true, modalHeader: "Success", modalBody: this.state.remoteUsername + " has left the chat"});
      }else{
        this.setState({loading: false, show: true, modalHeader: "Success", modalBody: this.state.remoteUsername + " has muted their audio"});
      }
    });

    rtc.client.on("crypt-error", error =>{
      this.setState({loading: false, show: true, modalHeader: "Error", modalBody: error});
    });

    rtm.client.on('ConnectionStateChange', (newState, reason) => {
      console.log('on connection state changed to ' + newState + ' reason: ' + reason);
    });

    //handle receiving message
    rtm.channel.on('ChannelMessage', ({ text }, senderId) => { // text: text of the received channel message; senderId: user ID of the sender.
      
      console.log("Message Received: " + text);
      const msg = JSON.parse(text);
      console.log(msg);
      if(msg["feature"] == "userverified"){
        if(this.state.remoteUID == msg["uid"] && this.state.remoteUsername == ""){
            this.setState({loading: false, show: true, modalHeader: "Success", remoteUsername: msg["name"], modalBody: msg["name"] + " has joined the chat"});
        }
        console.log("User Verified");
      }else if(msg["feature"] == "verifyuser"){
        if(this.state.uid == msg["uid"]){
            var message = '{"feature":"userverified","uid":"' + this.state.uid + '","name":"' + value.username + '"}';
            rtm.channel.sendMessage({ text: message}).then(() => {
              console.log("Sent Message!");
            }).catch(error => {
              console.log("Message send error!");
            });
        }else{
            //DO NOTHING, NOT LOOKING FOR OUR USER NAME
           //Debug.Log("Do nothing, not our uid");
        }
      }
    });
  }

  showDrawMenu(){
    if(this.state.showDrawMenu == true){
      this.setState({showDrawMenu: false, showColorMenu: false, showShapeMenu: false, showNoteMenu: false});
    }else{
      this.setState({showDrawMenu: true, showColorMenu: false, showShapeMenu: false, showNoteMenu: false});
    }
  }

  showColorMenu(){
    if(this.state.showColorMenu == true){
      this.setState({showDrawMenu: false, showColorMenu: false, showShapeMenu: false, showNoteMenu: false});
    }else{
      this.setState({showDrawMenu: false, showColorMenu: true, showShapeMenu: false, showNoteMenu: false});
    }
  }

  showShapeMenu(){
    if(this.state.showShapeMenu == true){
      this.setState({showDrawMenu: false, showColorMenu: false, showShapeMenu: false, showNoteMenu: false});
    }else{
      this.setState({showDrawMenu: false, showColorMenu: false, showShapeMenu: true, showNoteMenu: false});
    }
  }

  updateColor(e){
    this.setState({color: e.target.getAttribute('data-color'), showColorMenu: false});
  }

  updateNote(e){
    this.setState({noteText: e.target.value});
  }

  approveNoteText(){
    this.setState({showNoteMenu: false});
  }

  cancelNoteText(){
    this.setState({noteText: "", tool: "", shape: "", showNoteMenu: false});
  }

  muteLocalAudio(){
    if(this.state.localAudioMuted == true){
      this.setState({localAudioMuted: false});
      if(rtc.localAudioTrack){
        rtc.localAudioTrack.setVolume(10);
      }
    }else{
      this.setState({localAudioMuted: true});
      if(rtc.localAudioTrack){
        rtc.localAudioTrack.setVolume(0);
      }
    }
  }

  muteRemoteAudio(){
    if(this.state.remoteAudioMuted == true){
      this.setState({remoteAudioMuted: false});
      if(rtc.client){
        for(var i=0; i < rtc.client.remoteUsers.length; i++){
          if(rtc.client.remoteUsers[i].audioTrack){
            rtc.client.remoteUsers[i].audioTrack.play();
          }
        }
      }
    }else{
      this.setState({remoteAudioMuted: true});
      if(rtc.client){
        for(var i=0; i < rtc.client.remoteUsers.length; i++){
          if(rtc.client.remoteUsers[i].audioTrack){
            rtc.client.remoteUsers[i].audioTrack.stop();
          }
        }
      }
    }
  }

  selectDrawingTool(e){
    var thickness = e.target.getAttribute('data-thickness');
    this.setState({showDrawMenu: false, thickness: thickness, tool: "line", noteText: ""});
  }

  selectUndoTool(){
    this.setState({tool: "undo", noteText: "", showDrawMenu: false, showColorMenu: false, showShapeMenu: false, showNoteMenu: false});
    if(rtm.channel){
      var message = '{"feature":"undo"}';
      rtm.channel.sendMessage({ text: message}).then(() => {
        console.log("Sent Message!");
      }).catch(error => {
        console.log("Message send error!");
      });
    }
  }

  selectEraseTool(){
    this.setState({tool: "erase", noteText: "", showDrawMenu: false, showColorMenu: false, showShapeMenu: false, showNoteMenu: false});
    if(rtm.channel){
      var message = '{"feature":"erase"}';
      rtm.channel.sendMessage({ text: message}).then(() => {
        console.log("Sent Message!");
      }).catch(error => {
        console.log("Message send error!");
      });
    }
  }

  selectShapeTool(e){
    this.setState({showShapeMenu: false, tool: "shape", shape: e.target.getAttribute('data-tool'), noteText: ""});
    if(e.target.getAttribute('data-tool') == "note"){
      this.setState({showNoteMenu: true});
    }else{
      this.setState({showNoteMenu: false});
    }
  }

  sendTestMessage(){
    if(rtm.channel){
      rtm.channel.sendMessage({ text: '{"feature":"shape","shape":"aura","text":"","array":{"x":-0.0384615384615385,"y":0.0468502892388238}}' }).then(() => {
        console.log("Sent Message!");
      }).catch(error => {
        console.log("Message send error!");
      });
    }
  }

  endDrawing(){
    if(this.state.tool == "line"){
      this.setState({drawing: false});
      alert("End Drawing");
    }
  }

  updateLineCoords(e){
    if(this.state.drawing == true){
      var halfWidth = this.streamRef.current.offsetWidth/2;
      var halfHeight = this.streamRef.current.offsetHeight/2;
      //console.log("Width: " + this.streamRef.current.offsetWidth + " HalfWidth: " + this.streamRef.current.offsetWidth/2);
      //console.log("Native Event Offset X: " + e.nativeEvent.offsetX);
      var currentPosX = (e.nativeEvent.offsetX-halfWidth)/this.streamRef.current.offsetWidth;
      var currentPosY = -(e.nativeEvent.offsetY-halfHeight)/(this.streamRef.current.offsetWidth * this.state.aspectRatio);
      //console.log("Offset X: " + e.nativeEvent.offsetX + " Half Width: " + halfWidth + " Offset Width: " + this.streamRef.current.offsetWidth);
      //console.log("Offset Y: " + e.nativeEvent.offsetY + " Half Height: " + halfHeight + "Offset Height: " + this.streamRef.current.offsetHeight);
      var deltaX = currentPosX - this.state.startX;
      var deltaY = currentPosY - this.state.startY;
      var magnitude = Math.sqrt(deltaX*deltaX+deltaY*deltaY);
      const point = this.relativeCoordinatesForEvent(e);
        this.setState(prevState => ({
          lines: prevState.lines.updateIn([prevState.lines.size - 1], line => line.push(point))
        }));
      if(magnitude > 0.05){
        var lineCoords = this.state.lineCoords;
        var coord = {"x":currentPosX,"y":currentPosY};
        lineCoords.push(coord);
        this.setState({lineCoords: lineCoords, startX: currentPosX, startY: currentPosY});
      }
    }
  }

  relativeCoordinatesForEvent(mouseEvent) {
    const boundingRect = this.streamRef.current.getBoundingClientRect();
    console.log("Left:" + boundingRect.left);
    console.log("Client X: " + mouseEvent.clientX);
    return new Immutable.Map({
      x: mouseEvent.clientX - boundingRect.left,
      y: mouseEvent.clientY - boundingRect.top,
    });
  }

  getCoordinates(e){
    if(this.state.tool == "shape"){
      if(this.state.shape == "aura" || this.state.shape == "arrowUp" || this.state.shape == "arrowDown" || this.state.shape == "arrowLeft" || this.state.shape == "arrowRight"){
        if(rtm.channel){
          var halfWidth = this.streamRef.current.offsetWidth/2;
          var halfHeight = this.streamRef.current.offsetHeight/2;
          var posX = (e.nativeEvent.offsetX-halfWidth)/this.streamRef.current.offsetWidth;
          var posY = -(e.nativeEvent.offsetY-halfHeight)/(this.streamRef.current.offsetWidth * this.state.aspectRatio);
          var message = '{"feature":"shape","shape":"' + this.state.shape + '","text":"","array":{"x":'+ posX +',"y":'+ posY + '}}';
          rtm.channel.sendMessage({ text: message}).then(() => {
            console.log("Sent Message!");
          }).catch(error => {
            console.log("Message send error!");
          });
        }
      }else if(this.state.shape == "note"){
        if(this.state.showNoteMenu == false){
          if(this.state.noteText != ""){
            if(rtm.channel){
              var halfWidth = this.streamRef.current.offsetWidth/2;
              var halfHeight = this.streamRef.current.offsetHeight/2;
              var posX = (e.nativeEvent.offsetX-halfWidth)/this.streamRef.current.offsetWidth;
              var posY = -(e.nativeEvent.offsetY-halfHeight)/(this.streamRef.current.offsetWidth * this.state.aspectRatio);
              var message = '{"feature":"shape","shape":"note","text":"' + this.state.noteText + '","array":{"x":'+ posX +',"y":'+ posY + '}}';
              rtm.channel.sendMessage({ text: message}).then(() => {
                console.log("Sent Message!");
              }).catch(error => {
                console.log("Message send error!");
              });
            }
          }else{
            this.setState({show: true, modalHeader: "Error", modalBody: "Sorry, you have not entered any text in the note field"});
          }
        }
      }
    }else if(this.state.tool == "line"){
      var halfWidth = this.streamRef.current.offsetWidth/2;
      var halfHeight = this.streamRef.current.offsetHeight/2;
      var posX = (e.nativeEvent.offsetX-halfWidth)/this.streamRef.current.offsetWidth;
      var posY = -(e.nativeEvent.offsetY-halfHeight)/(this.streamRef.current.offsetWidth * this.state.aspectRatio);
      var coord = {"x":posX, "y":posY};
      if(this.state.drawing == false){
        var lineCoords = [];
        lineCoords.push(coord);
        this.setState({drawing: true, startX: posX, startY: posY, lineCoords: lineCoords});
        const point = this.relativeCoordinatesForEvent(e);
        this.setState(prevState => ({
          lines: prevState.lines.push(new Immutable.List([point]))
        }));
      }else{
        this.setState({drawing: false, lines: new Immutable.List()});
        var colorArray = this.hex2rgb(this.state.color);
        var message = '{"feature":"line","width":"' + this.state.thickness + '","color":{"r":' + colorArray[0] + ',"g":' + colorArray[1] + ',"b":' + colorArray[2] + '},"array":' + JSON.stringify(this.state.lineCoords) + '}';
        if(rtm.channel){
          rtm.channel.sendMessage({ text: message}).then(() => {
            console.log("Sent Message!");
          }).catch(error => {
            console.log("Message send error!");
          });
        }
      }
    }
  }

  updateChat(e){
    this.setState({[e.target.id]:e.target.value});
  }

  componentDidMount(){
    var userId = Math.floor((Math.random() * 10000) + 1);
    this.setState({localUID: userId.toString()});
  }

  componentWillUnmount() {
    (async () => {
        await this.leaveCall();
    })();
  }

  startVideoCall(){
    if(this.state.chatName != ""){
      (async () => {
        await this.startBasicCall();
      })();
    }else{
      this.setState({show: true, modalHeader: "Error", modalBody: "Sorry, you must enter a chat name"});
    }
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  hex2rgb(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }else {
        var colorArray = [];
        colorArray.push(r/255);
        colorArray.push(g/255);
        colorArray.push(b/255);
        return colorArray;
        //return [r + ", " + g + ", " + b];
    }
  }

  render(){
    let value = this.context;
    if(value.jwt == ""){
        return(
          <Route>
            <Redirect to="/login" />
          </Route>
        );
    }else{
      if(value.userPermissions.includes("remote:view")){
        return (
          <div className="mainWrapper">
            <div className="mainWindow">
              {this.state.loading && 
                <LoadingSpinner />
              }
              <Modal show={this.state.show} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.modalBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>
                     Close
                    </Button>
                </Modal.Footer>
              </Modal>
              <div className="projectsHeaderWrapper">
                <div className="projectsHeaderText">
                  REMOTE ASSISTANCE
                </div>
              </div>
              <div className="createProjectInfoWrapper">
                <div className="channelPropertyWrapper">
                Chat Name: <input type="text" name="chatName" id="chatName" className="companyInput" placeholder="Chat Name..." value={this.state.chatName} onChange={this.updateChat} /> 
                <br/>
                <br/>
                Chat Password: <input type="text" name="chatPassword" id="chatPassword" className="companyInput" placeholder="Chat Password (optional)..." value={this.state.chatPassword} onChange={this.updateChat} /> 
                <br/>
                <br/>
                <Button variant="primary" onClick={this.startVideoCall}>
                  Start Chat
                </Button>
                &nbsp;&nbsp;
                <Button variant="danger" onClick={this.leaveCall}>
                  End Chat
                </Button>
                </div>
                <div className="remoteStream" id="remoteStream" ref={this.streamWrapperRef}>
                  {this.state.showNoteMenu && 
                    <div className="noteMenuWrapper">
                      <textarea placeholder="Add note here..." className="remoteNote" onChange={this.updateNote} value={this.state.noteText}></textarea>
                      <div className="noteTextBtnWrapper">
                        <Button variant="primary" onClick={this.approveNoteText}>Approve</Button> &nbsp;&nbsp;<Button variant="danger" onClick={this.cancelNoteText}>Cancel</Button>
                      </div>
                    </div>
                  }
                  <div className="leftMenuWrapper">
                    <div className="freeDrawMainBtn">
                      <img src="/images/freeDraw.png" alt="freeDraw" onClick={this.showDrawMenu}/>
                      {this.state.showDrawMenu &&
                        <div className="drawMenuWrapper">
                          <div className="drawThicknessOption">
                            <img src="/images/thickness1.png" alt="thickness1" data-thickness="0.005" onClick={this.selectDrawingTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/thickness2.png" alt="thickness2" data-thickness="0.009" onClick={this.selectDrawingTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/thickness3.png" alt="thickness3" data-thickness="0.014" onClick={this.selectDrawingTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/thickness4.png" alt="thickness4" data-thickness="0.019" onClick={this.selectDrawingTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/thickness5.png" alt="thickness5" data-thickness="0.024" onClick={this.selectDrawingTool}/>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="freeDrawMainBtn">
                      <img src="/images/shapeTool.png" alt="freeDraw" onClick={this.showShapeMenu}/>
                      {this.state.showShapeMenu &&
                        <div className="shapeMenuWrapper">
                          <div className="drawThicknessOption">
                            <img src="/images/aura.png" alt="aura" data-tool="aura" onClick={this.selectShapeTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/note.png" alt="note" data-tool="note" onClick={this.selectShapeTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/arrowUp.png" alt="arrowUp" data-tool="arrowUp" onClick={this.selectShapeTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/arrowDown.png" alt="arrowDown" data-tool="arrowDown" onClick={this.selectShapeTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/arrowLeft.png" alt="arrowLeft" data-tool="arrowLeft" onClick={this.selectShapeTool}/>
                          </div>
                          <div className="drawThicknessOption">
                            <img src="/images/arrowRight.png" alt="arrowRight" data-tool="arrowRight" onClick={this.selectShapeTool}/>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="paletteBtn" style={{backgroundColor: this.state.color}}>
                      <img src="/images/colorPalette.png" alt="freeDraw" onClick={this.showColorMenu}/>
                    </div>
                    {this.state.showColorMenu &&
                      <div className="colorMenuWrapper">
                        <div className="colorRed" data-color="#FF0000" data-color_r="" data-color_g="" data-color_b="" onClick={this.updateColor}>
                        </div>
                        <div className="colorGreen" data-color="#00C001" onClick={this.updateColor}>
                        </div>
                        <div className="colorBlue" data-color="#001DC0" onClick={this.updateColor}>
                        </div>
                        <div className="colorMagenta" data-color="#C00092" onClick={this.updateColor}>
                        </div>
                        <div className="colorYellow" data-color="#E8F12C" onClick={this.updateColor}>
                        </div>
                        <div className="colorOrange" data-color="#E08312" onClick={this.updateColor}>
                        </div>
                      </div>
                    }
                    <div className="freeDrawMainBtn">
                      <img src="/images/eraser.png" alt="freeDraw" onClick={this.selectEraseTool}/>
                    </div>
                    <div className="freeDrawMainBtn">
                      <img src="/images/undo.png" alt="freeDraw" onClick={this.selectUndoTool}/>
                    </div>
                    <div className="freeDrawMainBtn">
                      {this.state.remoteAudioMuted ?
                        <img src="/images/volumeOff.png" alt="freeDraw" onClick={this.muteRemoteAudio}/>
                        :
                        <img src="/images/volumeOn.png" alt="freeDraw" onClick={this.muteRemoteAudio}/>
                      }
                    </div>
                    <div className="freeDrawMainBtn">
                      {this.state.localAudioMuted ?
                        <img src="/images/micOff.png" alt="freeDraw" onClick={this.muteLocalAudio}/>
                        :
                        <img src="/images/micOn.png" alt="freeDraw" onClick={this.muteLocalAudio}/>
                      }
                    </div>
                  </div>
                  <div className="remoteStreamWrapper" id="remoteStreamWrapper" onClick={this.getCoordinates} ref={this.streamRef} onMouseMove={this.updateLineCoords}>
                  <Drawing lines={this.state.lines} color={this.state.color} thickness={this.state.thickness}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }else{
         return(
            <Route>
              <Redirect to="/dashboard" />
            </Route>
         );
      }
    }
  }
}

function Drawing({ lines, color, thickness }) {
  return (
    <svg className="drawing">
      {lines.map((line, index) => (
        <DrawingLine key={index} line={line} color={color} thickness={thickness}/>
      ))}
    </svg>
  );
}

function DrawingLine({ line, color, thickness }) {
  //console.log(thickness);
  var strokeWidth = (thickness/0.005)*4;
  //console.log(strokeWidth);
  const pathData = "M " +
    line
      .map(p => {
        return `${p.get('x')} ${p.get('y')}`;
      })
      .join(" L ");

  return <path className="path" style={{stroke: color, strokeWidth: strokeWidth}} d={pathData} />;
}

export default RemoteSession;