import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import ProjectsHeader from '../projectsheader/ProjectsHeader';
import VideoPreview from '../videopreview/VideoPreview';
import './Knowledgebase.css';

class Knowledgebase extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			searching: false,
			searchingText: ""	    
	    }
	    this.updateSearch = this.updateSearch.bind(this);
	}

  	componentDidMount() {
	  	/*let value = this.context;
	    //var jwt = localStorage.getItem("jwt");
	    var id = this.props.match.params.id;
	    this.setState({loading: true, projectId: id});
	    //this.setState({jwt: jwt});
	    axios.post('https://www.arutility.com/api/v4/project/readOne.php',{jwt: value.jwt, id: id})
	      .then(res => {
	        const projectInfo = res.data;
	        var projectBoundary;
	        if(res.data.boundaries != ""){
	        	projectBoundary = JSON.parse(res.data.boundaries);
	        	projectBoundary = projectBoundary.coordinates;
	        }else{
	        	projectBoundary = [];
	        }
	        this.setState({authorized: true, projectInfo: projectInfo, projectName: projectInfo.name, companyName: projectInfo.companyName, address1: projectInfo.address1, address2: projectInfo.address2, companyCity: projectInfo.companyCity, companyState: projectInfo.companyState, projectDescription: projectInfo.description, projectStartDate: projectInfo.start, projectEndDate: projectInfo.end, creatorFirstName: projectInfo.firstName, creatorLastName: projectInfo.lastName, phoneNum: projectInfo.userPhone, email: projectInfo.userEmail, loading: false, projectBoundary: projectBoundary });
	    }).catch(error => {
	    	this.setState({loading: false});
		});*/
  	}

  	updateSearch(e){
        var searchString = e.target.value;
        if(searchString != ""){
            /*const searchResults = this.state.accounts.filter(account => 
                account.accountName.toLowerCase().includes(searchString.toLowerCase())
            );*/
            this.setState({searching: true, searchingText: searchString});
        }else{
            this.setState({searching: false, searchingText: ""});
        }
    }

  	render(){
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
		    return (
		      	<div className="mainWrapper">
		    		<div className="mainWindow">
				      	{this.state.loading &&
				      		<LoadingSpinner />
				      	}
				      	<div className="projectViewWrapper">
					      	<div className="projectViewTitle">
				      			Knowledgebase 
				      		</div>
				      		<div className="projectsHeaderSearch">
			                    <input type="text" className="projectsHeaderSearch" id="projectsHeaderSearch" placeholder="Search..." value={this.state.searchingText} onChange={this.updateSearch}/>
			                </div>
					      	<div className="videoViewWrapper">
					      		<VideoPreview title="Service Territory Mapping" youtubeURL="https://www.youtube.com/embed/Dh43yLmcamQ" youtubeThumb="https://img.youtube.com/vi/Dh43yLmcamQ/0.jpg"/>
					      		<VideoPreview title="Project Workflow" youtubeURL="https://youtube.com/embed/dWmYuNDKkEI" youtubeThumb="https://img.youtube.com/vi/dWmYuNDKkEI/0.jpg"/>
					      		<VideoPreview title="Asset/Esri Mapping" youtubeURL="https://youtube.com/embed/GjYxH7ocIY0" youtubeThumb="https://img.youtube.com/vi/GjYxH7ocIY0/0.jpg"/>
				      		</div>
				      	</div>
		      		</div>
		   		</div>
		    );
		}
  	}
}

export default Knowledgebase;