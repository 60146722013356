import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import axios from 'axios';
import Request from '../request/Request';
import {Context} from "../configcontext/ConfigContext";
import RequestsFilter from '../requestsfilter/RequestsFilter';
import RequestsHeader from '../requestsheader/RequestsHeader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './Requests.css';

class Requests extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    requests: [],
		    filteredRequests: [],
		    searching: false,
		    loading: true,
		    searchString: "",
		    statusSearchString: "",
		    utilitySearchString: "",
		    mapping: [],
		    modalBody: "",
			modalHeader: "",
			show: false
		}
		this.updateSearch = this.updateSearch.bind(this);
		this.updateSearchStatus	= this.updateSearchStatus.bind(this);
		this.updateSearchUtility = this.updateSearchUtility.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateSearchFull = this.updateSearchFull.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v5/requests/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	        const requests = res.data["records"];
	        this.setState({requests: requests, loading: false});
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
	    axios.post('https://www.arutility.com/api/v5/esri/featureService/readAll.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } }).then(res => {
			this.setState({mapping: res.data.message});
			}).catch(error => {
				if (error.response) {
					if (error.response.status == 401) {
						this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
					} else {
						this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
					}
				} else if (error) {
					this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
				} else {
					this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
				}
			});
  	}

  	updateSearch(searchString){
  		this.setState({searchString: searchString}, this.updateSearchFull);
		/*if(searchString != ""){
		  	const searchResults = this.state.requests.filter(request => 
		  		request.name.toLowerCase().includes(searchString.toLowerCase()) ||
		  		request.project_city.toLowerCase().includes(searchString.toLowerCase()) ||
		  		request.project_state.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}*/
	}

	updateSearchStatus(searchString){
		this.setState({statusSearchString: searchString}, this.updateSearchFull);
		/*if(searchString != ""){
			const searchResults = this.state.requests.filter(request => 
				request.response_code.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}*/
  	}

  	updateSearchUtility(searchString){
  		this.setState({utilitySearchString: searchString}, this.updateSearchFull);
		/*if(searchString != ""){
			const searchResults = this.state.requests.filter(request => 
				request.featureType.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}*/
  	}

  	updateSearchFull(){
  		var searchResults = JSON.parse(JSON.stringify(this.state.requests));
  		if(this.state.searchString != ""){
  			//console.log(searchResults);
		  	const searchResultsFiltered = searchResults.filter(request => 
		  		request.name.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		request.project_city.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		request.project_state.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
		  		request.ticket_id.toLowerCase().includes(this.state.searchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.statusSearchString != ""){
			const searchResultsFiltered = searchResults.filter(request => 
				request.response_code.toLowerCase().includes(this.state.statusSearchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.utilitySearchString != ""){
			const searchResultsFiltered =searchResults.filter(request => 
				request.featureType.toLowerCase().includes(this.state.utilitySearchString.toLowerCase())
		  	);
		  	searchResults = searchResultsFiltered;
		}
		if(this.state.searchString != "" || this.state.statusSearchString != "" || this.state.utilitySearchString != ""){
			this.setState({searching: true, filteredRequests: searchResults});
		}else{
			this.setState({searching: false, filteredRequests: []});
		}
  	}

  	showModal() {
		this.setState({ show: true });
	}

	closeModal() {
		this.setState({ show: false });
	}

  	render(){
	  	const {loading} = this.state;
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("requests:view")){
			    return (
			    	<div className="mainWrapper">
				    	<div className="mainWindow">
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<RequestsHeader updateSearch={this.updateSearch} updateSearchStatus={this.updateSearchStatus} updateSearchUtility={this.updateSearchUtility} mapping={this.state.mapping}/>
				      		<RequestsFilter />
				      		<div className="allRequestsWrapper">
					      		{(this.state.searching == false) ? (
						      		this.state.requests.map(request => {
						      			if(value.userPermissions.includes("request:" + request.featureType + ":view")){
							      			var end_date = new Date(request.end_date);
							      			var currentDate = new Date();
							      			var responseCode = request.response_code;
							      			var requestStatus = "";
							      			if(responseCode == "2"){
							      				requestStatus = "Denied";
							      			}else if(responseCode == "1"){
							      				requestStatus = "Approved";
							      			}else if(responseCode == "3"){
							      				requestStatus = "Cancelled";
							      			}else{
							      				requestStatus = "Pending";
							      			}
							      			if(end_date > currentDate){
								      			var urlPath = "/request/view/" + request.id
								            	return(
								            	<NavLink key={request.id} to={urlPath}>
								            		<Request key={request.id} requestId={request.id} ticketId={request.ticket_id} projectId={request.project_id} requestName={request.name} requestCity={request.project_city} requestState={request.project_state} requestStartDate={request.start_date} requestEndDate={request.end_date} requestUtilityType={request.featureType} requestStatus={requestStatus} responseComment={request.response_comment}/>
								            	</NavLink>
							            		);
							            	}
							            }
						       		})
						       	)
						       	:
						       	(
						       		this.state.filteredRequests.map(request => {
						       			if(value.userPermissions.includes("request:" + request.featureType + ":view")){
							      			var end_date = new Date(request.end_date);
							      			var currentDate = new Date();
							      			var responseCode = request.response_code;
							      			var requestStatus = "";
							      			if(responseCode == "2"){
							      				requestStatus = "Denied";
							      			}else if(responseCode == "1"){
							      				requestStatus = "Approved";
							      			}else{
							      				requestStatus = "Pending";
							      			}
							      			if(end_date > currentDate){
								      			var urlPath = "/request/view/" + request.id
								            	return(
								            	<NavLink key={request.id} to={urlPath}>
								            		<Request key={request.id} requestId={request.id} ticketId={request.ticket_id} projectId={request.project_id} requestName={request.name} requestCity={request.project_city} requestState={request.project_state} requestStartDate={request.start_date} requestEndDate={request.end_date} requestUtilityType={request.featureType} requestStatus={requestStatus} responseComment={request.response_comment}/>
								            	</NavLink>
							            		);
							            	}
							            }
						       		})
						       	)
						    	}
							</div>
				       	</div>
			       	</div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/login" />
					</Route>
				);
			}
	  	}
  	}
}

export default Requests;