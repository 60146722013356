import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import EsriMapRequest from '../esrimaprequest/EsriMapRequest';
import './RequestView.css';

class RequestView extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    requestInfo: [],
		    requestId: "",
		    jwt: "",
		    loading: true,
		    authorized: false,
		    projectId: "",
		    projectName: "",
		    companyName: "",
		    address1: "",
		    address2: "",
		    companyCity: "",
		    companyState: "",
		    creatorFirstName: "",
		    creatorLastName: "",
		    phoneNum: "",
		    email: "",
		    projectDescription: "",
		    projectStartDate: "",
		    projectEndDate: "",
		    projectBoundary: "",
		    featureType: "",
		    responseComment: "",
		    requestLoaded: false,
		    showingUploadOptions: false,
		    uploadedFiles: [],
		    existingFiles: [],
		    modalBody: "",
		    modalHeader: "",
		    show: false,
		    mappingOriginal: []
		}
		this.handleApproveClick = this.handleApproveClick.bind(this);
		this.handleDenyClick = this.handleDenyClick.bind(this);
		this.showUploadOptions = this.showUploadOptions.bind(this);
		this.hideUploadOptions = this.hideUploadOptions.bind(this);
		this.fileUploaded = this.fileUploaded.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.removeAllFiles = this.removeAllFiles.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateLoading = this.updateLoading.bind(this);
	}

	updateLoading(bool){
		if(!bool){
			this.setState({loading: false});
		}
	}

  	componentDidMount() {
	  	let value = this.context;
	    var id = this.props.match.params.id;
	    this.setState({requestId: id});
	    axios.post('https://www.arutility.com/api/v5/esri/featureService/readAll.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } }).then(res => {
            this.setState({mappingOriginal: res.data.message});
            axios.post('https://www.arutility.com/api/v5/requests/readOne.php',{id: id},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        const requestInfo = res.data;
		        if(res.data.attachedFiles != null){
		        	var fileArray = res.data.attachedFiles.split(',');
		        	this.setState({existingFiles: fileArray});
		        }else{
		        	var fileArray = [];
		        	this.setState({existingFiles: fileArray});
		        }
		        this.setState({authorized: true, requestInfo: requestInfo, requestLoaded: true, projectId: requestInfo.projectId, projectName: requestInfo.projectName, companyName: requestInfo.companyName, address1: requestInfo.address1, address2: requestInfo.address2, companyCity: requestInfo.companyCity, companyState: requestInfo.companyState, projectDescription: requestInfo.projectDescription, projectStartDate: requestInfo.projectStartDate, projectEndDate: requestInfo.projectEndDate, creatorFirstName: requestInfo.firstName, creatorLastName: requestInfo.lastName, phoneNum: requestInfo.userPhone, email: requestInfo.userEmail, featureType: requestInfo.featureType, responseComment:requestInfo.responseComment, existingFiles: fileArray, projectBoundary: requestInfo.projectBoundary});
		    }).catch(error => {
		    	if (error.response) {
		        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, requestInfo: []});
			    } else if (error.request) {
			      // The request was made but no response was received
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, requestInfo: []});
			    } else {
			      // Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true, requestInfo: []});
			    }
		    });
        }).catch(error => {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        });
  	}

  	fileUploaded(e){
  		var numFiles = e.target.files.length;
        var files = e.target.files;
	    var updatedFiles = this.state.uploadedFiles;
	    for(var i=0; i<numFiles; i++){
	    	var filePresent = false;
	    	var fileName = files[i].name;
	    	for(var i2=0; i2<this.state.uploadedFiles.length; i2++){
	    		var existingFileName = this.state.uploadedFiles[i2].name;
	    		if(existingFileName == fileName){
	    			filePresent = true;
	    		}
	    	}
	    	if(filePresent == false){
	    		updatedFiles.push(e.target.files[i]);
	    	}
	    }
	    this.setState({uploadedFiles: updatedFiles});
  	}

  	removeFile(e){
  		var fileName = e.target.id;
  		var updatedFiles = this.state.uploadedFiles;
  		for(var i=0; i<updatedFiles.length; i++){
  			var existingFileName = updatedFiles[i].name;
  			if(existingFileName == fileName){
  				updatedFiles.splice(i,1);
  			}
  		}
  		this.setState({uploadedFiles: updatedFiles});
  	}

  	deleteFile(e){
  		let value = this.context;
  		var fileName = e.target.id;
  		axios.post('https://www.arutility.com/api/v4/requests/deleteOneFile.php',{projectId: this.state.projectId, requestId: this.state.requestId, fileName: e.target.id},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	  		var updatedExistingFiles = this.state.existingFiles;
	  		for(var i=0; i<updatedExistingFiles.length; i++){
	  			var existingFileName = updatedExistingFiles[i];
	  			if(existingFileName == fileName){
	  				updatedExistingFiles.splice(i,1);
	  			}
	  		}
	        this.setState({loading: false, existingFiles: updatedExistingFiles, modalBody: "File Successfully Deleted", modalHeader: "Success", show: true});
	    }).catch(error => {
	    	if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
  	}

  	removeAllFiles(){
  		this.setState({uploadedFiles: []});
  	}

  	handleChange(e){
    	this.setState({[e.target.name]: e.target.value});
  	}

  	showUploadOptions(){
  		this.setState({showingUploadOptions: true});
  	}

  	hideUploadOptions(){
  		this.setState({showingUploadOptions: false});
  	}

  	handleApproveClick(){
	  	let value = this.context;
	  	let formData = new FormData();
	  	if(this.state.uploadedFiles.length > 0){
	  		for(var i=0; i<this.state.uploadedFiles.length; i++){
	  			formData.append('file[]', this.state.uploadedFiles[i]);
	  		}
	  	}
	  	//formData.append('jwt', value.jwt);
	  	formData.append('request_id', this.state.requestId);
	  	formData.append('response_code', 1);
	  	formData.append('response_comment', this.state.responseComment);
	  	//jwt: value.jwt, request_id: this.state.requestId, response_code: 1, response_comment: this.state.responseComment, 
	  	this.setState({loading: true});
	  	axios.post('https://www.arutility.com/api/v5/requests/updateApproved.php',formData,{headers: {'content-type': 'multipart/form-data', Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	        this.setState({loading: false,modalBody: "Request successfully approved", modalHeader: "Success", show: true });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, requestInfo: []});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, requestInfo: []});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true, requestInfo: []});
		    }
	    });
  	}

  	handleDenyClick(){
	  	this.setState({loading: true});
	  	let value = this.context;
	  	axios.post('https://www.arutility.com/api/v4/requests/update.php',{request_id: this.state.requestId, response_code: 2, response_comment: this.state.responseComment},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	        this.setState({loading: false,modalBody: "Request denied", modalHeader: "Success", show: true  });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true, requestInfo: []});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true, requestInfo: []});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true, requestInfo: []});
		    }
	    });
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	const {show} = this.state;
	  	let value = this.context;
	  	var end_date = new Date(this.state.projectEndDate);
	    var start_date = new Date(this.state.projectStartDate);
	    var current_date = new Date();
	    var project_image = "";
	    if(end_date > current_date && start_date > current_date){
	      project_image = '/images/upcomingProjectBtn.png';
	    }else if(start_date < current_date && end_date > current_date){
	      project_image = '/images/inprogressBtn.png';
	    }else{
	      project_image = '/images/upcomingProjectBtn.png';
	    }
	    if (value.jwt == "") {
	      	return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("request:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
						      	<LoadingSpinner />
						    }
						    <Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      	<div className="requestViewWrapper">
					      		<div className="requestViewClose">
						      		<NavLink to="/requests">
						      			CLOSE
						      		</NavLink>
					      		</div>
					      		<div className="requestViewTitle">
					      			<img src={project_image} alt="requestStatus"/> {this.state.projectName} - ({this.state.featureType})
					      		</div>
					      		<div className="requestViewDesc">
					      			{value.permissions.includes("request:update") &&
					      			<div className="requestResponseWrapper">
					      				<div className="requestButtons">
						      				<button type="button" className="approveBtn" value={this.state.requestId} onClick={this.handleApproveClick}>Approve Request</button>
								      		<button type="button" className="denyBtn" value={this.state.requestId} onClick={this.handleDenyClick}>Deny Request</button>
								      		<div className="attachBtnWrapper" onMouseEnter={this.showUploadOptions} onMouseLeave={this.hideUploadOptions}>
								      			<div className="attachImgWrapper">
								      				<img src="/images/attachfile.png" alt="Attach File" className="attachBtn"/>
								      			</div>
								      			{this.state.showingUploadOptions &&
									      			<div className="uploadOptionsWrapper" onMouseEnter={this.showUploadOptions} onMouseLeave={this.hideUploadOptions}>
									      				<input id="requestFile"  name="requestFile" className="fileInput" type="file" onChange={this.fileUploaded} multiple="multiple"/>
									      				<label htmlFor="requestFile">Upload File</label>
									      				<div className="uploadOption" onClick={this.removeAllFiles}>
									      				Remove All Files
									      				</div>
									      			</div>
									      		}
								      		</div>
								      	</div>
								      	{(this.state.uploadedFiles.length > 0 || this.state.existingFiles.length > 0) &&
								      		<div>
								      			Attached Files:
									      		<div className="uploadedFilesWrapper">
									      			{this.state.uploadedFiles.map((file, i) =>{
									      				return(
										      				<div className="singleFileWrapper" key={i}>
										      					<div className="fileName">
										      						{file.name}
										      					</div>
										      					<div className="fileDelete" id={file.name} onClick={this.removeFile}>
										      						<img src="/images/close.png" id={file.name} onClick={this.removeFile} alt="Delete File" />
										      					</div>
										      				</div>
										      			);
									      			})}
									      			{this.state.existingFiles.map(file =>{
									      				return(
										      				<div className="singleFileWrapper" key={file}>
										      					<div className="fileName">
										      						{file}
										      					</div>
										      					<div className="fileDelete" name={file} id={file}>
										      						<img src="/images/close.png" id={file} onClick={this.deleteFile} alt="Delete File" />
										      					</div>
										      				</div>
										      			);
									      			})}
									      		</div>
								      		</div>
								      	}
										<br/>
							      		Response Comment:
							      		<br/>
							      		<textarea rows="6" className="requestResponseText" name="responseComment" value={this.state.responseComment} onChange={this.handleChange}></textarea>
							      		<br/>
							      		<br/>
					      			</div>
					      			}	
							    	<font color="#c62828">Company:</font> {this.state.companyName}
							      	<br/>
							      	<font color="#c62828">Co addr 1:</font> {this.state.address1}
							      	<br/>
							      	<font color="#c62828">Co Addr 2:</font> {this.state.address2}
							      	<br/>
							      	<font color="#c62828">City:</font> {this.state.companyCity}
							      	<br/>
							      	<font color="#c62828">State:</font> {this.state.companyState}
							      	<br/>
							      	<font color="#c62828">Creator:</font> {this.state.creatorFirstName} {this.state.creatorLastName}
							      	<br/>
							      	<font color="#c62828">Phone:</font> {this.state.phoneNum}
							      	<br/>
							      	<font color="#c62828">Email:</font> {this.state.email}
							      	<br/>
							      	<br/>
							      	<font color="#c62828">Description:</font>
							      	<br/>
							      	{this.state.projectDescription}
							      	<br/>
							      	<br/>
							      	<font color="#c62828">Start Date:</font> {this.state.projectStartDate}
							      	<br/>
							      	<font color="#c62828">End Date:</font> {this.state.projectEndDate}
							      	<br/>
					      		</div>
					      		{this.state.requestLoaded &&
					      			<EsriMapRequest requestId={this.state.requestId} projectId={this.state.projectId} updateLoading={this.updateLoading} projectBoundary={this.state.projectBoundary} mappingOriginal={this.state.mappingOriginal}/>
					      		}
					     	</div>
				      	</div>
				    </div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}
		}
  	}
}

export default RequestView;