import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import EsriMap from '../esrimap/EsriMap';
import { Modal, Button } from 'react-bootstrap';
import Responses from '../responses/Responses';
import './ProjectView.css';

class ProjectView extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    projectInfo: [],
		    jwt: "",
		    loading: true,
		    projectId: "",
		    projectName: "",
		    companyName: "",
		    address1: "",
		    address2: "",
		    companyCity: "",
		    companyState: "",
		    creatorFirstName: "",
		    creatorLastName: "",
		    phoneNum: "",
		    email: "",
		    projectDescription: "",
		    projectStartDate: "",
		    projectEndDate: "",
		    projectRequests: [],
		    projectBoundary: null,
		    showConfirmation: false,
		    modalHeader: "Delete Project?",
		    modalBody: "Are you sure you want to delete this project? This action cannot be undone.",
		    projectDeleted: false,
		    mappingOriginal: []
		}
		this.projectLoading = this.projectLoading.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.deleteProject = this.deleteProject.bind(this);
		this.exportKML = this.exportKML.bind(this);
	}

	projectLoading(bool){
		if(!bool){
			this.setState({loading: false});
		}
	}

  	componentDidMount() {
	  	let value = this.context;
	    //var jwt = localStorage.getItem("jwt");
	    var id = this.props.match.params.id;
	    this.setState({loading: true, projectId: id});
	    //this.setState({jwt: jwt});
		axios.post('https://www.arutility.com/api/v5/esri/featureService/readAll.php', {}, { headers: { Authorization: 'Bearer ' + value.jwt } }).then(res => {
            this.setState({mappingOriginal: res.data.message});
            axios.post('https://www.arutility.com/api/v4/project/readOne.php',{id: id},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
		        const projectInfo = res.data;
		        var projectBoundary = null;
		        if(res.data.boundaries != ""){
		        	projectBoundary = JSON.parse(res.data.boundaries);
		        	projectBoundary = projectBoundary.coordinates;
		        }else{
		        	projectBoundary = [];
		        }
		        this.setState({projectInfo: projectInfo, projectName: projectInfo.name, companyName: projectInfo.companyName, address1: projectInfo.address1, address2: projectInfo.address2, companyCity: projectInfo.companyCity, companyState: projectInfo.companyState, projectDescription: projectInfo.description, projectStartDate: projectInfo.start, projectEndDate: projectInfo.end, creatorFirstName: projectInfo.firstName, creatorLastName: projectInfo.lastName, phoneNum: projectInfo.userPhone, email: projectInfo.userEmail, projectBoundary: projectBoundary });
		    }).catch(error => {
		    	this.setState({loading: false});
			});
        }).catch(error => {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        });
  	}

  	deleteProject(){
  		let value = this.context;
  		this.setState({loading: true});
  		axios.post('https://www.arutility.com/api/v4/project/deleteOne.php', {projectId: this.state.projectId}, { headers: { Authorization: 'Bearer ' + value.jwt } })
		.then(res => {
			this.setState({showConfirmation: false, projectDeleted: true, loading: false});
		}).catch(error => {
			if (error.response) {
				if (error.response.status == 401) {
					this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
				} else {
					this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
				}
			} else if (error) {
				this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false });
			} else {
				this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false });
			}
		});
  	}

  	exportKML(){
  		let value = this.context;
  		this.setState({loading: true});
  		axios.post('https://www.arutility.com/api/v5/project/downloadKML.php',{id:this.state.projectId},{headers: {Authorization: 'Bearer ' + value.jwt}})
      	.then(res => {
      		this.setState({show: true, modalHeader: "Success", modalBody: "Your KML file has been generated and sent to your email.", loading: false});
      	}).catch(error => {
	        if (error.response) {
				if (error.response.status == 401) {
					this.setState({ modalBody: "Sorry there was an error: " + error.response.data.message, modalHeader: "Error", show: true, loading: false });
				} else {
					this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services and feature layers from your GIS. Please try again.", modalHeader: "Error", show: true, loading: false });
				}
			} else if (error) {
				this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false });
			} else {
				this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false });
			}
      	});
  	}

  	confirmDelete(){
	   this.setState({showConfirmation: true, modalBody: "Are you sure you want to delete this project? This action cannot be undone.", modalHeader: "Delete Project?"});
	 }

	closeModal(){
	   this.setState({showConfirmation: false, show: false});
	}

  	render(){
	  	let value = this.context;
	  	var end_date = new Date(this.state.projectEndDate);
	    var start_date = new Date(this.state.projectStartDate);
	    var current_date = new Date();
	    var project_image = "";
	    if(end_date > current_date && start_date > current_date){
	      project_image = '/images/upcomingProjectBtn.png';
	    }else if(start_date < current_date && end_date > current_date){
	      project_image = '/images/inprogressBtn.png';
	    }else{
	      project_image = '/images/upcomingProjectBtn.png';
	    }
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else if(this.state.projectDeleted){
	    	return(
				<Route>
					<Redirect to="/projects" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("project:view")){
			    return (
			      	<div className="mainWrapper">
			      		<Modal show={this.state.showConfirmation} onHide={this.closeModal}>
			              <Modal.Header closeButton>
			                <Modal.Title>{this.state.modalHeader}</Modal.Title>
			              </Modal.Header>
			              <Modal.Body>{this.state.modalBody}</Modal.Body>
			              <Modal.Footer>
			                <Button variant="danger" onClick={this.deleteProject}>
			                  Delete
			                </Button>
			                <Button variant="secondary" onClick={this.closeModal}>
			                  Cancel
			                </Button>
			              </Modal.Footer>
			            </Modal>
			            <Modal show={this.state.show} onHide={this.closeModal}>
			              <Modal.Header closeButton>
			                <Modal.Title>{this.state.modalHeader}</Modal.Title>
			              </Modal.Header>
			              <Modal.Body>{this.state.modalBody}</Modal.Body>
			              <Modal.Footer>
			                <Button variant="secondary" onClick={this.closeModal}>
			                  Close
			                </Button>
			              </Modal.Footer>
			            </Modal>
			    		<div className="mainWindow">
					      	{this.state.loading &&
					      		<LoadingSpinner />
					      	}
					      	<div className="projectViewWrapper">
					      		<div className="projectViewClose">
					      			<Button variant="secondary" onClick={this.exportKML}>Export KML</Button>&nbsp;&nbsp;
					      			{value.userPermissions.includes("project:delete") &&
					      				<Button variant="danger" onClick={this.confirmDelete}>Delete</Button>
					      			}
						      		<NavLink to="/projects">
						      			&nbsp;&nbsp;<Button variant="primary">Close</Button>
						      		</NavLink>
					      		</div>
					      		<div className="projectViewTitle">
					      			<img src={project_image} alt="projectStatus"/> {this.state.projectName}
					      		</div>
					      		<div className="projectViewDesc">
						    		<font color="#c62828">Company:</font> {this.state.companyName}
						      		<br/>
						      		<font color="#c62828">Co addr 1:</font> {this.state.address1}
						      		<br/>
						      		<font color="#c62828">Co Addr 2:</font> {this.state.address2}
						      		<br/>
						      		<font color="#c62828">City:</font> {this.state.companyCity}
						      		<br/>
						      		<font color="#c62828">State:</font> {this.state.companyState}
						      		<br/>
						      		<font color="#c62828">Creator:</font> {this.state.creatorFirstName} {this.state.creatorLastName}
						      		<br/>
						      		<font color="#c62828">Phone:</font> {this.state.phoneNum}
						      		<br/>
						      		<font color="#c62828">Email:</font> {this.state.email}
						      		<br/>
						      		<br/>
						      		<font color="#c62828">Description:</font>
						      		<br/>
							      	{this.state.projectDescription}
							      	<br/>
							      	<br/>
							      	<font color="#c62828">Start Date:</font> {this.state.projectStartDate}
							      	<br/>
							      	<font color="#c62828">End Date:</font> {this.state.projectEndDate}
							      	<br/>
							      	<font color="#c62828">Utility Requests:</font>
							      	<br/>
							      	<Responses projectId={this.props.match.params.id}/>
					      		</div>
					      		{this.state.projectBoundary != null && 
					      			<EsriMap projectId={this.props.match.params.id} projectBoundary={this.state.projectBoundary} projectLoading={this.projectLoading} mappingOriginal={this.state.mappingOriginal}/>
					      		}
				      		</div>
			      		</div>
			   		</div>
			    );
			}else{
				return(
					<Route>
						<Redirect to="/projects" />
					</Route>
				);
			}	
		}
  	}
}

export default ProjectView;