import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import EditSession from '../editsession/EditSession';
import {Context} from "../configcontext/ConfigContext";
import UpdateSessionFilter from '../updatesessionfilter/UpdateSessionFilter';
import UpdateSessionHeader from '../updatesessionheader/UpdateSessionHeader';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './UpdateSessions.css';

class UpdateSessions extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    sessions: [],
		    loading: true,
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.showModal = this.showModal.bind(this);
    	this.closeModal = this.closeModal.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v4/features/readMoved.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	      	if(res.status == "204"){
	      		this.setState({sessions: [], loading: false});
	      	}else{
	        	const sessions = res.data["records"];
	        	this.setState({sessions: sessions, loading: false });
	    	}
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false});
		    }
	    });
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
   		this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {show} = this.state;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("updatesessions:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		<Modal show={show} onHide={this.closeModal}>
					          <Modal.Header closeButton>
					            <Modal.Title>{this.state.modalHeader}</Modal.Title>
					          </Modal.Header>
					          <Modal.Body>{this.state.modalBody}</Modal.Body>
					          <Modal.Footer>
					            <Button variant="secondary" onClick={this.closeModal}>
					              Close
					            </Button>
					          </Modal.Footer>
					        </Modal>
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<UpdateSessionHeader />
				      		<UpdateSessionFilter />
				      		{this.state.sessions.map(session => {
				      			if(session.status == "0"){
					      			var urlPath = "/session/updates/" + session.id;
					            	return(
					            	<NavLink key={session.id} to={urlPath}>
					            		<EditSession sessionId={session.session_id} sessionDate={session.creation_date} />
					            	</NavLink>
				            		);
				            	}
				       		})}
				       	</div>
				    </div>
			    );
			}else{
		        return(
		          <Route>
		            <Redirect to="/projects" />
		          </Route>
		        );
		    }
		}
  	}
}

export default UpdateSessions;