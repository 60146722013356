import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import MainHeader from "../mainheader/MainHeader";
import MainFooter from "../mainfooter/MainFooter";
import { Modal, Button } from "react-bootstrap";
import "./Login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      subdomain: "",
      show: false,
      modalBody: "",
      modalHeader: "",
      loading: true,
      utilityAccount: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuth0Submit = this.handleAuth0Submit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkIfEnter = this.checkIfEnter.bind(this);
    this.checkIfEnterAuth0 = this.checkIfEnterAuth0.bind(this);
  }

  componentDidMount() {
    let host = window.location.host;
    //let parts = host.split(".");
    //var subDomain = parts[0];
    var subDomain = this.props.match.params.accountName;
    //if(subDomain == "" || subDomain == "www" || subDomain == "localhost:3000"){
    if (subDomain == "" || subDomain == null) {
      this.setState({ loading: false });
    } else {
      this.setState({
        subdomain: subDomain,
        loading: false,
        utilityAccount: true,
      });
    }
  }

  checkIfEnter(e){
    if(e.key === 'Enter'){
      this.handleSubmit(e);
    }
  }

  checkIfEnterAuth0(e){
    if(e.key === 'Enter'){
      this.handleAuth0Submit(e);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    var email = this.state.username;
    var account = this.state.subdomain;
    this.setState({ loading: true });
    if (account != "" && account != "www") {
      axios.post("https://www.arutility.com/api/v4/users/loginoauth.php", {email, account})
      //axios.post('https://www.arutility.com/api/v4/users/loginoauthdev.php',{email,account})
        .then((res) => {
          if (!res.data.error) {
            this.setState({ loading: false });
            window.open(res.data.message, "_self");
          } else {
            alert(res.data.error);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              loading: false,
              modalBody: error.response.data.error,
              modalHeader: "Error",
              show: true,
            });
          } else if (error.request) {
            // The request was made but no response was received
            this.setState({
              loading: false,
              modalBody: "Sorry, there was an error. " + error.request,
              modalHeader: "Error",
              show: true,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            this.setState({
              loading: false,
              modalBody: "Sorry, there was an error. " + error.message,
              modalHeader: "Error",
              show: true,
            });
          }
        });
    } else {
      this.setState({
        loading: false,
        modalBody: "Sorry, that subdomain does not appear to be correct",
        modalHeader: "Error",
        show: true,
      });
    }
  }

  handleAuth0Submit() {
    if (this.state.username != "" && this.state.password != "") {
      axios.post("https://www.arutility.com/api/v4/users/login.php", {username: this.state.username,password: this.state.password})
      //axios.post('https://www.arutility.com/api/v4/users/loginDev.php',{username: this.state.username,password:this.state.password})
        .then((res) => {
          this.setState({ loading: false });
          window.location.href = res.data.url;
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              loading: false,
              modalBody: error.response.data.error,
              modalHeader: "Error",
              show: true,
            });
          } else if (error.request) {
            // The request was made but no response was received
            this.setState({
              loading: false,
              modalBody: "Sorry, there was an error. " + error.request,
              modalHeader: "Error",
              show: true,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            this.setState({
              loading: false,
              modalBody: "Sorry, there was an error. " + error.message,
              modalHeader: "Error",
              show: true,
            });
          }
        });
    } else {
      this.setState({
        loading: false,
        modalBody: "Sorry, it appears you are missing required information",
        modalHeader: "Error",
        show: true,
      });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  showModal() {
    this.setState({ show: true });
  }

  closeModal() {
    this.setState({ show: false });
  }

  render() {
    const { show } = this.state;
    return (
      <div className="mainWrapper">
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
        <div className="pageTitle">USER LOGIN</div>
        <div className="pageDescription">
          <div className="createAccount">
            <div className="loginFormWrapper">
                {this.state.utilityAccount ? (
                  <div className="loginAuth0">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Username..."
                      required=""
                      value={this.state.username}
                      onChange={this.handleChange}
                      onKeyPress={this.checkIfEnter} 
                    />
                    <br />
                    <button type="submit" value="Submit" onClick={this.handleSubmit}>
                      LOGIN
                    </button>
                    <br />
                    <a href="https://www.arutility.com/login">Standard Login</a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to="/account/create">Register New Account</Link>
                  </div>
                ) : (
                  <div className="loginAuth0">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Email..."
                      required=""
                      value={this.state.username}
                      onChange={this.handleChange}
                      onKeyPress={this.checkIfEnterAuth0}
                    />
                    <br />
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password..."
                      required=""
                      value={this.state.password}
                      onChange={this.handleChange}
                      onKeyPress={this.checkIfEnterAuth0}
                    />
                    <br />
                    <button
                      type="submit"
                      value="Submit"
                      onClick={this.handleAuth0Submit}
                    >
                      LOGIN
                    </button>
                    <br />
                    <Link to="/account/create">Register New Account</Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to="/forgotpassword">Forgot Password</Link>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="footerSpace"></div>
        <MainFooter />
      </div>
    );
  }
}

export default Login;
