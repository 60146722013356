import React from 'react';
import axios from 'axios';
import { loadModules } from 'esri-loader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import './EsriMap.css';

class EsriMap extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			projectId: "",
			assets: [],
			colors: [],
			jwt: "",
			projectBoundary: []
		}
		this.mapRef = React.createRef();
		this.switchToolDistance = this.switchToolDistance.bind(this);
		this.switchToolArea = this.switchToolArea.bind(this);
		this.clearMeasurements = this.clearMeasurements.bind(this);
	}

	// Switch between area and distance measurement
	switchToolDistance() {
	 	this.measurement.activeTool = "distance";
	 	this.measurement.startMeasurement();
	}

	switchToolArea(){
		this.measurement.activeTool = "area";
		this.measurement.startMeasurement();
	}

    clearMeasurements() {
      this.measurement.clear();
    }

  	componentDidMount() {
	  	let value = this.context;
	  	axios.post('https://www.arutility.com/api/v5/accounts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	      	if(res.data[0].colors != "" && JSON.parse(res.data[0].colors).length > 0){
	      		var colorsToUpdate = JSON.parse(res.data[0].colors);
	      	}else{
	      		var colorsToUpdate = [];
	      	}
			this.setState({colors: colorsToUpdate});
	    }).catch(error => {
		    //Add some error handling here.
		});
	    this.setState({jwt: value.jwt, projectId: this.props.projectId, projectBoundary: this.props.projectBoundary});
	    loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer', 'esri/widgets/Measurement'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer, Measurement]) => {
	      	this.map = new ArcGISMap({
	        	basemap: 'streets'
	      	});

	      	this.view = new MapView({
	       		container: this.mapRef.current,
	        	map: this.map,
	        	center: [-98.579532, 39.828577],
	        	zoom: 5
	      	});

	      	this.measurement = new Measurement({
			  	view: this.view,
			  	activeTool: "distance"
		  	});

	      	this.toggle = new BasemapToggle({
			  	view: this.view,  // view with map that uses "streets" basemap
			  	nextBasemap: "satellite"  // Allows for toggling to "hybrid" basemap
	      	});

	      	this.view.ui.add(this.toggle, "top-right");
	      	this.view.ui.add(this.measurement, "top-right");

	      	this.layer = new GraphicsLayer({
		  		graphics: []
		  	});
		  	this.graphics = [];
          	var rings = [];
          	var firstRing = [];
          	var boundaryCoordinates = this.props.projectBoundary;
          	for(var i=0; i < boundaryCoordinates.length; i++){
	          	var vertex = [];
	          	vertex.push(boundaryCoordinates[i]["longitude"]);
	          	vertex.push(boundaryCoordinates[i]["latitude"]);
	          	firstRing.push(vertex);
          	}
          	rings.push(firstRing);
          	this.polygon = {
          	  	type: "polygon",
			  	hasZ: false,
			  	hasM: false,
			  	rings: rings,
			  	spatialReference: { wkid: 4326 }
		  	};
		  	this.polygonSymbol = {
			  	type: "simple-fill",  // autocasts as new SimpleFillSymbol()
			  	color: [198,40,40,0.5],
			  	style: "solid",
			  	outline: {  // autocasts as new SimpleLineSymbol()
			    	color: "white",
			    	width: 1
			  	}
			};
		  	this.polygonGraphic = new Graphic({
		    	geometry: this.polygon, // Add the geometry created in step 4
		    	symbol: this.polygonSymbol, // Add the symbol created in step 5
		  	});
		  	this.graphics.push(this.polygonGraphic);
		  	this.layer.graphics.add(this.polygonGraphic);
		  	this.map.add(this.layer);
		  	this.view.goTo(this.graphics);

	      	axios.post('https://www.arutility.com/api/v5/project/previewAssets.php',{id:this.props.projectId},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      	.then(res => {
	          	this.setState({assets: res.data});
	          	//console.log(res.data);
	          	//{res.data.features.map(feature => {
	          	//console.log("Num Features: " + res.data.length);
	          	//{res.data.map(feature => {
	          	for(var i=0; i < res.data.length; i++){
	          		var attributeText = "";
		          	if(res.data[i].attributes.length >0){
		                var attributes = JSON.parse(res.data[i].attributes);
		                var attributeKeys = Object.keys(attributes);
		                attributeText = "";
		                for(var i2=0; i2<attributeKeys.length;i2++){
		                    var keyName = attributeKeys[i2];
		                    var value = attributes[keyName];
		                    if(i2==0){
		                        attributeText = attributeText + "<b>" + keyName + "</b>: " + value;
		                    }else{
		                        attributeText = attributeText + "</br>" + "<b>" + keyName + "</b>: " + value;
		                    }
		                }
		            }else{
		                attributeText = "";
		            }
		          	if(res.data[i].feature_type == "line"){
		          		var featurePaths = JSON.parse(res.data[i].geometry);
		          		//console.log(featurePaths);
		          		if(featurePaths[0][0]){
		          		//if(feature.paths[0][0]){
				            //var featurePaths = feature.geometry[0][0];
				            var numPaths = featurePaths[0].length;
				            var polylineCoords = [];
				            for(var i3=0; i3<numPaths; i3++){
				                var polylineCoord = [];
				                polylineCoord.push(featurePaths[0][i3][0]);
				                polylineCoord.push(featurePaths[0][i3][1]);
				                polylineCoords.push(polylineCoord);
				            }

				            this.polyline = {
								type: "polyline", // autocasts as new Polyline()
								paths: polylineCoords
							};

							this.lineSymbol = {
							    type: "simple-line", // autocasts as new SimpleLineSymbol()
							    width: 2
							};

							var numFeatureServices = this.props.mappingOriginal.length;
							for(var i5=0; i5 < numFeatureServices; i5++){
								if(parseInt(this.props.mappingOriginal[i5]["feature_service_type_id"]) == res.data[i].feature_service_type_id){
									var color = this.props.mappingOriginal[i5]["color"];
									for(var i4=0; i4 < this.state.colors.length; i4++){
					            		if(this.state.colors[i4].id == res.data[i].feature_service_type_id){
					            			color = this.state.colors[i4].color;
					            			break;
					            		}
					            	}
					                this.lineSymbol.color = color;
					                break;
								}
							}

				            /*if(res.data[i].feature_service_type == "water"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 1){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "electric"){
				            	var color = [255,0,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 2){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "gas"){
				            	var color = [255,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 3){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "storm"){
				            	var color = [0,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 4){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "fiber"){
				            	var color = [128,0,128,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 5){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "telecom"){
				            	var color = [255,165,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 6){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "sanitary"){
				            	var color = [165,42,42,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 7){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.lineSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "steam"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 9){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.lineSymbol.color = color;
				            }*/
				            this.lineAtt = attributeText;

						  	this.polylineGraphic = new Graphic({
						    	geometry: this.polyline, // Add the geometry created in step 4
						    	symbol: this.lineSymbol, // Add the symbol created in step 5
						    	attributes: this.lineAtt, // Add the attributes created in step 6
						    	popupTemplate: {
							      title: res.data[i].feature_layer_name + " " + res.data[i].feature_id,
							      content: attributeText
							    }
						  	});
						  	this.graphics.push(this.polylineGraphic);
						  	this.layer.graphics.add(this.polylineGraphic);
						}
			        }else if(res.data[i].feature_type == "point"){
			        	//if(feature.points[0]){
			        	if(res.data[i].geometry){
				            var featurePoints = JSON.parse(res.data[i].geometry);
				            var circleCoords = [];
				            var circleCoord = [];
				            circleCoord.push(featurePoints.x);
				            circleCoord.push(featurePoints.y);
				            circleCoords.push(circleCoord);
				            this.point = {
								type: "point", // autocasts as new Polyline()
								longitude: featurePoints.x,
								latitude: featurePoints.y
							};
							this.pointSymbol = {
							    type: "simple-marker", // autocasts as new SimpleLineSymbol()
							    radius: 0.75
							};

							var numFeatureServices = this.props.mappingOriginal.length;
							for(var i5=0; i5 < numFeatureServices; i5++){
								if(parseInt(this.props.mappingOriginal[i5]["feature_service_type_id"]) == res.data[i].feature_service_type_id){
									var color = this.props.mappingOriginal[i5]["color"];
									for(var i4=0; i4 < this.state.colors.length; i4++){
					            		if(this.state.colors[i4].id == res.data[i].feature_service_type_id){
					            			color = this.state.colors[i4].color;
					            			break;
					            		}
					            	}
					                this.pointSymbol.color = color;
					                break;
								}
							}
				            /*if(res.data[i].feature_service_type == "water"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 1){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "electric"){
				            	var color = [255,0,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 2){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "gas"){
				            	var color = [255,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 3){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "storm"){
				            	var color = [0,255,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 4){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "fiber"){
				            	var color = [128,0,128,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 5){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "telecom"){
				            	var color = [255,165,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 6){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				                this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "sanitary"){
				            	var color = [165,42,42,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 7){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "steam"){
				            	var color = [0,0,255,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 9){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.pointSymbol.color = color;
				            }else if(res.data[i].feature_service_type == "controlpoint"){
				            	var color = [0,0,0,1];
				            	for(var i4=0; i4 < this.state.colors.length; i4++){
				            		if(this.state.colors[i4].id == 10){
				            			color = this.state.colors[i4].color;
				            			break;
				            		}
				            	}
				            	this.pointSymbol.color = color;
				            }*/
				            this.pointAtt = attributeText;
				            this.pointGraphic = new Graphic({
							  	geometry: this.point,
							  	symbol: this.pointSymbol,
							  	attributes: this.pointAtt, // Add the attributes created in step 6
						    	popupTemplate: {
							      title: res.data[i].feature_layer_name + " " + res.data[i].feature_id,
							      content: attributeText
							    }
							});
				            this.graphics.push(this.pointGraphic);
						  	this.layer.graphics.add(this.pointGraphic);
						}
			        }
	          	//})}
				}
			  	this.view.goTo(this.graphics);
			  	this.props.projectLoading(false);
	      	}).catch(error => {
		        if (error.response) {
		        	
			    } else if (error.request) {
		      		// The request was made but no response was received
		        	
		    	} else {
		      		// Something happened in setting up the request that triggered an Error
		        	
		    	}
	      	});
	    });
  	}

  	componentWillUnmount() {
    	if (this.view) {
      		// destroy the map view
      		this.view.container = null;
    	}
  	}

  	render(){
	  	return(
	  		<div className="mapWrapper">
	  			{this.state.loading &&
		      		<LoadingSpinner />
		      	}
			  <div className="map" id="map" ref={this.mapRef}>
			      
			  </div>
			  <div id="toolbarDiv" className="esri-component esri-widget">
		      <button
		        id="distance"
		        className="esri-widget--button esri-interactive esri-icon-measure-line"
		        title="Distance Measurement Tool"
		        onClick={this.switchToolDistance}
		      ></button>
		      <button
		        id="area"
		        className="esri-widget--button esri-interactive esri-icon-measure-area"
		        title="Area Measurement Tool"
		        onClick={this.switchToolArea}
		      ></button>
		      <button
		        id="clear"
		        className="esri-widget--button esri-interactive esri-icon-trash"
		        title="Clear Measurements"
		        onClick={this.clearMeasurements}
		      ></button>
		    </div>
			</div>
	  	);
  	}
}

export default EsriMap;