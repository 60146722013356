import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Testimonials.css';

class Testimonials extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        name: "",
        email: "",
        message: "",
        subject: "General",
        loading: false,
        modalBody: "",
        modalHeader: "",
        show: false
    }
    this.updateForm = this.updateForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  updateForm(e){
    this.setState({[e.target.id]:e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  submitForm(){
    if(this.state.name != "" && this.state.email != "" && this.state.subject != "" && this.state.message != ""){
      this.setState({loading: true});
      axios.post('https://www.arutility.com/sendcontact.php',{contactName: this.state.name, contactEmail: this.state.email, contactSubject: this.state.subject, contactMessage: this.state.message})
      .then(res => {
        this.setState({loading: false, modalHeader: "Success", modalBody: "Message was successfully received. We will respond within 2 business days.", show: true});
      }).catch(error => {
          if (error.response) {
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
        } else if (error.request) {
          // The request was made but no response was received
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
        } else {
          // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
        }
      });
    }else{
      alert(JSON.stringify(this.state));
      this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
    }
  }

  render(){
    const {show} = this.state;
    return (
      <div>
        <MainHeader />
        <Modal show={show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="pageTitle">
          TESTIMONIALS
        </div>
        <div className="pageDescription">
          Coming soon...
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Testimonials;