import React, { Component, useState, useContext } from 'react';
import { Context } from "../configcontext/ConfigContext.js";
import axios from 'axios';
import { SketchPicker } from 'react-color';
import { Table, Button, Modal } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';


class FeatureServiceTypes extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            feature_service_types: [],

            edit: false,
            modalBody: "",
            modalHeader: "",
            show: false,
            loading: true,
            new_feature_service: {
                id: "",
                name: "",
                label: "",
                color: "",
            }

        }
    }

    componentDidMount() {
        // read feature service types from database
        let value = this.context;
        this.readFeatureServiceTypes(value.jwt)
        this.setState({ loading: false })
    }

    readFeatureServiceTypes = async (jwt) => {
        try {
            const config = {
                method: 'get',
                url: 'https://www.arutility.com/api/v5/accounts/admin/readFeatureServiceTypes.php',
                headers: { Authorization: 'Bearer ' + jwt }
            }
            let res = await axios(config);
            let fst_array = await res.data.FeatureServiceTypes;
            this.setState({ feature_service_types: [...fst_array] })
        } catch (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ modalBody: "Sorry, we were unable to retrieve your feature services. Please try again.", modalHeader: "Error", show: true, loading: false });
                }
            } else if (error) {
                this.setState({ modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", show: true, loading: false })
            } else {
                this.setState({ modalBody: "Sorry there was an error: " + error, modalHeader: "Error", show: true, loading: false })
            }
        }
    }

    handleDelete = (event, id) => {
        // delete feature layer type
        this.setState({ loading: true })
        let value = this.context;
        if (id) {
            axios.post('https://www.arutility.com/api/v5/accounts/admin/deleteFeatureServiceType.php', { deleteID: id }, { headers: { Authorization: 'Bearer ' + value.jwt } })
                .then(res => {
                    this.setState({ loading: false, modalBody: "Feature Service successfully deleted.", modalHeader: "Success", show: true });
                    this.readFeatureServiceTypes(value.jwt);
                }).catch(error => {
                    if (error.response) {
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
                    }
                });
        }
        event.preventDefault();
    }


    handleSubmit = (event) => {
        // submit new feature service type
        this.setState({ loading: true })
        let new_feature_service_type = this.state.new_feature_service;
        let value = this.context;
        if (this.state.edit && new_feature_service_type.id !== "") {
            axios.post('https://www.arutility.com/api/v5/accounts/admin/editFeatureServiceType.php', { post: new_feature_service_type }, { headers: { Authorization: 'Bearer ' + value.jwt } })
                .then(res => {
                    this.setState({ loading: false, modalBody: "Feature Service successfully updated", modalHeader: "Success", show: true });
                    this.readFeatureServiceTypes(value.jwt);

                }).catch(error => {
                    if (error.response) {
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
                    }
                });
            this.setState({ edit: false })
        } else {
            axios.post('https://www.arutility.com/api/v5/accounts/admin/addFeatureServiceType.php', { post: new_feature_service_type }, { headers: { Authorization: 'Bearer ' + value.jwt } })
                .then(res => {
                    this.setState({ loading: false, modalBody: "Feature Service successfully added", modalHeader: "Success", show: true });
                    this.readFeatureServiceTypes(value.jwt);

                }).catch(error => {
                    if (error.response) {
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true });
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setState({ loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true });
                    }
                });
        } 
        //console.log(new_feature_service_type)

        this.setState({
            new_feature_service: {
                id: "",
                name: "",
                label: "",
                color: ""
            }
        })
        event.preventDefault();
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState((prevState) => ({
            new_feature_service: {
                ...prevState.new_feature_service,
                [name]: value
            }
        }))
    }

    handleData = () => {
        this.setState({ edit: false });
        this.setState({
            new_feature_service: {
                id: "",
                name: "",
                label: "",
                color: ""
            }
        })
    }

    handleEdit = (e, feature_service) => {
        this.setState({
            new_feature_service: {
                id: feature_service.id,
                name: feature_service.name,
                label: feature_service.label,
                color: feature_service.color
            },
        }
        )
        this.setState({ edit: true });
    }
    handleChangeComplete = (color) => {
        this.setState((prevState) => ({
            new_feature_service: {
                ...prevState.new_feature_service,
                "color": color.hex
            }
        }))
    }
    closeModal = () => {
        this.setState({ show: false });
    }


    render() {
        let value = this.context;
        const { loading, show } = this.state;

        //console.log(this.state)
        return (
            <>
                <div className="moduleDetailWrapper">
                    {this.state.loading && <LoadingSpinner />}
                    <Modal show={show} onHide={() => this.closeModal()}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.modalHeader}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.modalBody}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.closeModal()}>
                                Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="adminPageTitle">
                        MANAGE FEATURE SERVICE TYPES
                </div>
                    <div className="adminPageDescription">

                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Label</th>
                                    <th>Color</th>
                                    <th>Editing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.feature_service_types.map((feature_service, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{feature_service.id}</td>
                                            <td>{feature_service.name}</td>
                                            <td>{feature_service.label}</td>
                                            <td>
                                                <div className="featureServiceColorImgWrapper" style={{ backgroundColor: feature_service.color }}>
                                                </div>
                                            </td>
                                            <td><Button variant="secondary" onClick={(e) => this.handleEdit(e, feature_service)}>Edit</Button>{" "}
                                                <Button variant="danger" onClick={(e) => {if(window.confirm('Are you sure you want to delete?')){
                                                                                            this.handleDelete(e, feature_service.id)
                                                                                            }
                                                                                            else{
                                                                                               e.preventDefault()
                                                                                            }
                                                    }}>Delete</Button></td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                        <button type="button" className={"cancel-btn"} onClick={(e) => (this.setState({ edit: true }))}> Add New Feature Service Type</button>

                        {this.state.edit ? <div className={"form-inline"}>
                            <form onSubmit={this.handleSubmit}>
                                <div>
                                    <label>
                                        Name:
                                    </label>
                                    <input type="text" name="name" value={this.state.new_feature_service.name} onChange={this.handleChange} required />
                                </div>
                                <div>
                                    <label>
                                        Label:
                                    </label>
                                    <input type="text" name="label" value={this.state.new_feature_service.label} onChange={this.handleChange} required />
                                </div>
                                <div>
                                    <label>
                                        Color:
                                     </label>
                                    <SketchPicker color={this.state.new_feature_service.color} onChangeComplete={this.handleChangeComplete} />
                                </div>
                                <div>
                                    <input type="submit" value="Submit" />
                                </div>
                                <button className={"cancel-btn"} onClick={this.handleData}>Cancel</button>
                            </form>
                        </div>:null}
                    </div>
                </div>
            </>
        )
    }
}
export default FeatureServiceTypes