import React from 'react';
import {Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import StandardProject from '../standardproject/StandardProject';
import KMLProject2 from '../kmlproject2/KMLProject2';
import BIMProject from '../bimproject/BIMProject';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProjectCreate.css';

class ProjectCreate extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: true,
        standardProject: false,
        kmlProject: false,
        bimProject: false,
        projectTypes: "standardProject,kmlProject,bimProject",
        show: false,
        modalBody: "",
        modalHeader: ""
    }
    this.changeProjectType = this.changeProjectType.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  changeProjectType(e){
    var projectTypeArr = this.state.projectTypes.split(',');
    for(var i=0; i < projectTypeArr.length; i++){
      if(e.target.id != projectTypeArr[i]){
        this.setState({[projectTypeArr[i]]:false});
      }else{
        this.setState({[e.target.id]:true});
      }
    }
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  componentDidMount() {
    let value = this.context;
    if(value.userPermissions.includes("project:create")){
      this.setState({standardProject:true});
    }else if(value.userPermissions.includes("projectsbim:create")){
      this.setState({standardProject: false, bimProject: true});
    }
    this.setState({loading: false});
  }

  render(){
    let value = this.context;
    const {show} = this.state;
    if(value.jwt == ""){
      return(
        <Route>
          <Redirect to="/login" />
        </Route>
      );
    }else{
      if(value.userPermissions.includes("project:create") || value.userPermissions.includes("projectsbim:create")){
          return (
            <div className="mainWrapper">
                <div className="mainWindow">
                <div className="createProjectWrapper">
                  {this.state.loading &&
                    <LoadingSpinner />
                  }
                  <Modal show={show} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.state.modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.modalBody}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.closeModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <div className="createProjectHeader">
                    {value.userPermissions.includes("project:create") &&
                      <div className={this.state.standardProject ? "projectButtonSelected" : "projectButton"} id="standardProject" onClick={this.changeProjectType}>
                        Create Project
                      </div>
                    }
                    {value.userPermissions.includes("project:create") &&
                      <div className={this.state.kmlProject ? "projectButtonSelected" : "projectButton"} id="kmlProject" onClick={this.changeProjectType}>
                        Upload KML
                      </div>
                    }
                    {value.userPermissions.includes("projectsbim:create") &&
                      <div className={this.state.bimProject ? "projectButtonSelected" : "projectButton"} id="bimProject" onClick={this.changeProjectType}>
                        BIM Model
                      </div>
                    }
                  </div>
                  {this.state.standardProject &&
                      <StandardProject />
                  }
                  {this.state.kmlProject &&
                      <KMLProject2 />
                  }
                  {this.state.bimProject &&
                      <BIMProject />
                  }
                </div>
              </div>
            </div>
          );
      }else{
        return(
          <Route>
            <Redirect to="/projects" />
          </Route>
        );
      }
    }
  }
}

export default ProjectCreate;