import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import {Context} from "../configcontext/ConfigContext";
import {Elements, CardElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CheckoutForm from "../checkoutform/CheckoutForm.js";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
//import { PayPalButton } from "react-paypal-button-v2";
import './CreateLicense.css';

//USE THIS FOR LIVE MODE
const stripePromise = loadStripe("pk_live_51HXnpwKTDVl8ERQLlJ39XoEVTz1NsgdYmnnAtaMb5gpkrGM782INHV5nOKogPImI88IkOOsloOvNHyXFdy9GK6Pj00xrvEkMHq");

//USE THIS FOR TEST MODE
//const stripePromise = loadStripe("pk_test_51HXnpwKTDVl8ERQLho9C0bVWdVFt6c5dAZkFTInXWrtnZbRPEO8aHm1h25ys51t0HvYfuG0edhBzY2p9dPzx0uDw00dDSFxdQ9");

class CreateLicense extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        modalHeader: "",
        modalBody: "",
        testing: false,
        show: false
    }
    this.createSubscription = this.createSubscription.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  componentDidMount(){
    //console.log(this.context);
  }

  createSubscription(productId, quantity, priceId, tokenId){
    let value = this.context;
    this.setState({loading: true});
    axios.post('https://www.arutility.com/api/v5/subscriptions/create.php',{productId: productId, priceId: priceId, quantity: quantity, email: value.username, tokenId: tokenId},{headers: {Authorization: 'Bearer ' + value.jwt}})
    .then(res => {
        this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
    }).catch(error => {
        if (error.response) {
            this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
        } else if (error.request) {
          // The request was made but no response was received
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
        } else {
          // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
        }
    });
  }

  render(){
    let value = this.context;
    if(value.jwt == ""){
        return(
          <Route>
            <Redirect to="/login" />
          </Route>
        );
    }else{
      if(value.userPermissions.includes("profile:update")){
        return (
          <div className="mainWrapper">
            <div className="mainWindow">
              {this.state.loading && 
                <LoadingSpinner />
              }
              <Modal show={this.state.show} onHide={this.closeModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>{this.state.modalHeader}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{this.state.modalBody}</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>
                      Close
                    </Button>
                  </Modal.Footer>
              </Modal>
              <div className="pageHeader">
                ADD LICENSE
              </div>
              <div className="plainBody">
                {value.accountType != "utility" ?
                  <div>
                    <div className="subscriptionWrapper">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm productTitle="MONTHLY SUBSCRIPTION" productPrice="100" frequency="month" productId={this.state.testing ? "prod_I9LDU2WFYSqYYR" : "prod_I9JHDSSmrafeFI"} priceId={this.state.testing ? "price_1HZ2WoKTDVl8ERQLkz6sCkCa" : "price_1HZ0fDKTDVl8ERQLyylK6MrZ"} productDescription="<ul><li>Billed Monthly</li><li>$100/month</li></ul>" createSubscription={this.createSubscription}/>
                      </Elements>
                    </div>
                    <div className="subscriptionWrapper">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm productTitle="QUARTERLY SUBSCRIPTION" productPrice="240" frequency="3 months" productId={this.state.testing ? "prod_I9LD6NDPFtNndA" : "prod_I9JJ9ZH4pCJJXq"} priceId={this.state.testing ? "price_1HZ2XGKTDVl8ERQLfH5nQzDI" : "price_1HZ0gOKTDVl8ERQLeAWWLS2I"} productDescription="<ul><li>Billed Quarterly</li><li>$240 every 3 months</li></ul>" createSubscription={this.createSubscription}/>
                      </Elements>
                    </div>
                    <div className="subscriptionWrapper">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm productTitle="YEARLY SUBSCRIPTION" productPrice="720" frequency="year" productId={this.state.testing ? "prod_I9LEEzq5eoHCLs" : "prod_I9JthPKsCfqxfI"} priceId={this.state.testing ? "price_1HZ2XgKTDVl8ERQL5X2Bzg6L" : "price_1HZ1G2KTDVl8ERQLJSNQFC44"} productDescription="<ul><li>Billed Yearly</li><li>$720/year</li></ul>" createSubscription={this.createSubscription}/>
                      </Elements>
                    </div>
                  </div>
                  :
                  <div>
                    <div className="subscriptionWrapper">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm productTitle="MONTHLY SUBSCRIPTION" productPrice="100" frequency="month" productId={this.state.testing ? "prod_I9LDU2WFYSqYYR" : "prod_I9JHDSSmrafeFI"} priceId={this.state.testing ? "price_1HZ2WoKTDVl8ERQLkz6sCkCa" : "price_1HZ0fDKTDVl8ERQLyylK6MrZ"} productDescription="<ul><li>Billed Monthly</li><li>$100/month</li></ul>" createSubscription={this.createSubscription}/>
                      </Elements>
                    </div>
                    <div className="subscriptionWrapper">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm productTitle="QUARTERLY SUBSCRIPTION" productPrice="240" frequency="3 months" productId={this.state.testing ? "prod_I9LD6NDPFtNndA" : "prod_I9JJ9ZH4pCJJXq"} priceId={this.state.testing ? "price_1HZ2XGKTDVl8ERQLfH5nQzDI" : "price_1HZ0gOKTDVl8ERQLeAWWLS2I"} productDescription="<ul><li>Billed Quarterly</li><li>$240 every 3 months</li></ul>" createSubscription={this.createSubscription}/>
                      </Elements>
                    </div>
                    <div className="subscriptionWrapper">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm productTitle="YEARLY SUBSCRIPTION" productPrice="720" frequency="year" productId={this.state.testing ? "prod_I9LEEzq5eoHCLs" : "prod_I9JthPKsCfqxfI"} priceId={this.state.testing ? "price_1HZ2XgKTDVl8ERQL5X2Bzg6L" : "price_1HZ1G2KTDVl8ERQLJSNQFC44"} productDescription="<ul><li>Billed Yearly</li><li>$720/year</li></ul>" createSubscription={this.createSubscription}/>
                      </Elements>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        );
    }else{
         return(
            <Route>
              <Redirect to="/dashboard" />
            </Route>
         );
      }
  }
  }
}

export default CreateLicense;