import React from 'react';
import axios from 'axios';
import { loadModules } from 'esri-loader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import './EsriCommunityMap.css';

class EsriCommunityMap extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			projects: []
		}
		this.mapRef = React.createRef();
	}

  	componentDidMount() {
	  	let value = this.context;
	  	loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/Graphic', 'esri/layers/GraphicsLayer'], { css: true })
	    .then(([ArcGISMap, MapView, BasemapToggle, Graphic, GraphicsLayer]) => {
	    	this.map = new ArcGISMap({
	        	basemap: 'streets'
	      	});

	      	this.view = new MapView({
		        container: this.mapRef.current,
		        map: this.map,
		        center: [-98.579532, 39.828577],
		        zoom: 5
	      	});

	      	this.toggle = new BasemapToggle({
			  	view: this.view,  // view with map that uses "streets" basemap
			  	nextBasemap: "satellite"  // Allows for toggling to "hybrid" basemap
	      	});

	      	this.view.ui.add(this.toggle, "top-right");
		  	axios.post('https://www.arutility.com/api/v4/project/readAllInTerritory.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
		    .then(res => {
	      		this.setState({projects: res.data.records});
	      		this.layer = new GraphicsLayer({
				  	graphics: []
				 });
				this.graphics = [];
				{res.data.records.map(project => {
			        var rings = [];
			        var firstRing = [];
			        var attributeText = "<b>Project Name: </b>" + project.name + "<br/><b>Start Date: </b>" + project.start_date + "<br/><b>End Date: </b>" + project.end_date + "<br/><b>Company Name: </b>" + project.company_name + "<br/><br/><b>Project Description: </b>" + project.project_description;
			        var projectBoundary = JSON.parse(project.project_polygon);
			        var boundaryCoordinates = projectBoundary.coordinates;
			        for(var i=0; i < boundaryCoordinates.length; i++){
			          	var vertex = [];
			          	vertex.push(boundaryCoordinates[i]["longitude"]);
			          	vertex.push(boundaryCoordinates[i]["latitude"]);
			          	firstRing.push(vertex);
			        }
			        rings.push(firstRing);
			        this.polygon = {
			          	type: "polygon",
						hasZ: false,
						hasM: false,
						rings: rings,
						spatialReference: { wkid: 4326 }
					};
					this.polygonSymbol = {
						type: "simple-fill",  // autocasts as new SimpleFillSymbol()
						color: [198,40,40,0.5],
						style: "solid",
						outline: {  // autocasts as new SimpleLineSymbol()
						    color: "white",
						    width: 1
						 }
					};
					this.polygonGraphic = new Graphic({
					    geometry: this.polygon, // Add the geometry created in step 4
				    	symbol: this.polygonSymbol, // Add the symbol created in step 5
				    	attributes: attributeText,
				    	popupTemplate: {
					      title: '<font size="4"><b>Project Details</b></font>',
					      content: attributeText
					    }
					});
					this.graphics.push(this.polygonGraphic);
					this.layer.graphics.add(this.polygonGraphic);
		        })}
				this.map.add(this.layer);
				this.view.goTo(this.graphics);
				this.setState({loading: false});
		    }).catch(error => {
		        if (error.response) {
		        	this.setState({loading: false, projects: []});
			    } else if (error.request) {
			    	// The request was made but no response was received
			        this.setState({loading: false, projects: []});
			    } else {
			      	// Something happened in setting up the request that triggered an Error
			        this.setState({loading: false, projects: []});
			    }
		    });
		});
  	}

  	componentWillUnmount() {
    	if (this.view) {
      		// destroy the map view
      		this.view.container = null;
    	}
  	}

  	render(){
	  	return(
	  		<div className="communityMapWrapper">
		  		{this.state.loading &&
		  			<LoadingSpinner />
		  		}
				  <div className="map" id="map" ref={this.mapRef}>
				      
				  </div>
			</div>
	  	);
  	}
}

export default EsriCommunityMap;