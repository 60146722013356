import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Button} from 'react-bootstrap';
import FeatureLayerWrapper from '../featurelayerwrapper/FeatureLayerWrapper';
import './FeatureServiceWrapper.css';

class FeatureServiceWrapper extends React.Component{

    static contextType = Context;

    constructor(props) {
      super(props);
        this.state = {
          hide: false
      }
      this.handleDropdown = this.handleDropdown.bind(this);
      this.updateFeatureService = this.updateFeatureService.bind(this);
      this.updateFeatureLayer = this.updateFeatureLayer.bind(this);
      this.updateMappedAttributes = this.updateMappedAttributes.bind(this);
      this.addFeatureLayer = this.addFeatureLayer.bind(this);
      this.updateFeatureField = this.updateFeatureField.bind(this);
      this.updateShapefile = this.updateShapefile.bind(this);
      this.deleteLayer = this.deleteLayer.bind(this);
    }

    componentDidMount(){
      
    }

    handleDropdown(e) {
      var elementName = e.target.id + "Wrapper";
      let element = document.getElementById(elementName);
      let elementImage = document.getElementById(this.props.name + "Dropdown");
      if (ReactDOM.findDOMNode(element).style.display == "none") {
        ReactDOM.findDOMNode(element).style.display = "block";
        this.setState({ [e.target.id]: true, hide: true});
      } else {
        ReactDOM.findDOMNode(element).style.display = "none";
        this.setState({ [e.target.id]: false, hide: false});
      }
    }

    updateFeatureService(feature_service_name, feature_layer_map_index){
      this.props.updateFeatureService(feature_service_name, feature_layer_map_index);
      //alert(feature_service_name + " " + id);
    }

    updateFeatureLayer(feature_layer_name, feature_layer_map_index, layer_url){
      this.props.updateFeatureLayer(feature_layer_name, feature_layer_map_index, layer_url);
      //alert(feature_service_name + " " + id);
    }

    updateMappedAttributes(feature_layer_map_index, mapped_attributes){
      this.props.updateMappedAttributes(feature_layer_map_index, mapped_attributes);
    }

    addFeatureLayer(feature_layer_map_index){
      this.props.addFeatureLayer(feature_layer_map_index);
    }

    updateFeatureField(feature_layer_map_index, field_type_id, field_alias){
      this.props.updateFeatureField(feature_layer_map_index, field_type_id, field_alias);
    }

    updateShapefile(feature_layer_map_index, allowShapefile){
      this.props.updateShapefile(feature_layer_map_index, allowShapefile);
    }

    deleteLayer(feature_layer_map_index){
      this.props.deleteLayer(feature_layer_map_index);
    }

  render(){
    let value = this.context;
    return (
      <div className="featureServiceWrapper">
        <div className="selectionHeader" id={this.props.name + "Dropdown"} onClick={this.handleDropdown}>
          {this.props.label}
          <img id={this.props.name + "Dropdown"} className={this.state.hide == true ? "downCarrotHide" : "downCarrotShow"} src="/images/downcarrotblack.png" onClick={this.handleDropdown} />
        </div>
        <div id="layerOptionWrapperGeneral" className="featureServiceDropdownWrapper" id={this.props.name + "DropdownWrapper"} style={{display:"none"}}>
          {this.props.feature_layers.sort(function(a,b){if(a.name < b.name) return -1; if(a.name > b.name) return 1; if(a.name == b.name){if(a.sorting_index > b.sorting_index) return 1; if(a.sorting_index < b.sorting_index) return -1;}}).map((featureLayer, index) => 
              <FeatureLayerWrapper key={index} label={featureLayer.label} name={featureLayer.name} feature_service_type_id={this.props.feature_service_type_id} feature_fields={featureLayer.fields} feature_layer_type_id={featureLayer.feature_layer_type_id} feature_type={featureLayer.feature_type} feature_layer_mapped_name={featureLayer.feature_layer_name ? featureLayer.feature_layer_name : ""} feature_service_mapped_name={featureLayer.feature_service_name ? featureLayer.feature_service_name: ""} esri_feature_services={this.props.esri_feature_services} attributes={featureLayer.attributes} updateFeatureService={this.updateFeatureService} updateFeatureLayer={this.updateFeatureLayer} feature_layer_map_index={featureLayer.feature_layer_map_index} feature_layer_mapped_fields={featureLayer.feature_layer_mapped_fields} updateMappedAttributes={this.updateMappedAttributes} addFeatureLayer={this.addFeatureLayer} updateFeatureField={this.updateFeatureField} updateShapefile={this.updateShapefile} allowShapefile={featureLayer.shapefile} feature_layer_mapped_id={featureLayer.feature_layer_mapped_id} deleteLayer={this.deleteLayer}/>
            )
          }
        </div>
      </div>
    );
  }
}

export default FeatureServiceWrapper;