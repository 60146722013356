import React from 'react';
import './Project.css';

class Project extends React.Component{

  render(){
    var end_date = new Date(this.props.projectEndDate);
    end_date.setTime(end_date.getTime() + Math.abs(end_date.getTimezoneOffset()*60000));
    var start_date = new Date(this.props.projectStartDate);
    start_date.setTime(start_date.getTime() + Math.abs(start_date.getTimezoneOffset()*60000));
    var current_date = new Date();
    current_date.setHours(0,0,0,0);
    var project_image = "";
    if(end_date > current_date && start_date > current_date){
      project_image = '/images/upcomingProjectBtn.png';
    }else if(start_date <= current_date && end_date >= current_date){
      project_image = '/images/inprogressBtn.png';
    }else{
      project_image = '/images/upcomingProjectBtn.png';
    }
    return (
      <div className="projectWrapper">
        <div className="projectStatus">
          <img src={project_image} alt="projectStatus"/>
        </div>
        <div className="ticketId">
          {this.props.ticketId}
        </div>
        <div className="projectName">
          {this.props.projectName}
        </div>
        <div className="projectLocation">
          {this.props.projectCity}, {this.props.projectState}
        </div>
        <div className="projectStartDate">
          {this.props.projectStartDate}
        </div>
        <div className="projectEndDate">
          {this.props.projectEndDate}
        </div>
        <div className="projectResponses">
          {this.props.projectResponses}/{this.props.projectRequests}
        </div>
      </div>
    );
  }
}

export default Project;