import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router';
import {Route, Link} from 'react-router-dom';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateUser.css';

class CreateUser extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    authorized: false,
		    userName: "",
		    userEmail: "",
		    userPhone: "",
		    userRole: "Admin",
		    userPassword1: "",
		    userPassword2: "",
		    modalBody: "",
		    userType: "",
		    modalHeader: "",
		    licensePermissions: [],
		    show: false,
		    accountPermissions: [],
			userPermissions: [],
			selectAllBoxes: false,
			
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendForm = this.sendForm.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.changeRadio = this.changeRadio.bind(this);
		this.selectAll = this.selectAll.bind(this);
	}

	componentDidMount(){
		let value = this.context;
		var accountPermissionsArray;
	    if(value.accountPermissions != null && value.accountPermissions != ""){
	        accountPermissionsArray = value.accountPermissions.split(',');
	    }else{
	        accountPermissionsArray = [];
	    }
	    accountPermissionsArray.sort();
	    axios.post('https://www.arutility.com/api/v5/users/readLicensePermissions.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
			this.setState({loading: false, accountPermissions: accountPermissionsArray, licensePermissions: res.data});
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({authorized: false, loading: false});
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve users. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true})
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true})
		    }
		});


	}

  	handleChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	changeRadio(e){
	  const index = this.state.userPermissions.indexOf(e.target.id);
      var updatedUserPermissions = this.state.userPermissions;
      if (index > -1) {
        updatedUserPermissions.splice(index, 1);
      }else{
        updatedUserPermissions.push(e.target.id);
      }
	  this.setState({userPermissions: updatedUserPermissions});
	  // Check Condition for Select All Boxes
		if(this.state.userPermissions.length === this.state.accountPermissions.length){
			this.setState({selectAllBoxes: true})
		}else{
			this.setState({selectAllBoxes: false})
		}
	}
	  
	  // To select all checkboxes
	  selectAll(e){
		  if(this.state.selectAllBoxes){
			  this.setState({selectAllBoxes: false, userPermissions:[]});
		  }else{
			this.setState({selectAllBoxes: true, userPermissions: [...this.state.accountPermissions]});
		  }
	  }

  	submitForm(){
  		let value = this.context;
  		this.setState({loading: true});
  		axios.post('https://www.arutility.com/api/v4/users/create.php',{username: this.state.userName, userRole: this.state.userRole, userPermissions: this.state.userPermissions, userEmail: this.state.userEmail, userPhone: this.state.userPhone, userPassword: this.state.userPassword1},{headers: {Authorization: 'Bearer ' + value.jwt}})
      		.then(res => {
       			this.setState({modalBody: "User Successfully Created", modalHeader: "Success", show: true, loading: false});
    		}).catch(error => {
      			if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: error, modalHeader: "Error", show: true});
		    }
  		});
  	}

  	sendForm(){
  		/*if(this.state.userType == "arcgis" && this.state.userName != ""){
  			if(this.state.userRole != "" && this.state.userPermissions != "" && this.state.userEmail != "" && this.state.userPhone != ""){
  				this.submitForm();
  			}else{
  				this.setState({ modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
  			}
	    }else if(this.state.userType == "email" && this.state.userEmail != ""){
	    	if(this.state.userRole != "" && this.state.userPermissions != "" && this.state.userPhone != "" && this.state.userPassword1){
  				this.submitForm();
  			}else{
  				this.setState({ modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
  			}
	    }else if(this.state.userType == ""){

	    }else{
	    	this.setState({modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
	    }*/
  	}

  	handleSubmit(){
  		let value = this.context;
  		if(value.accountType == "utility"){
  			if(this.state.userType != ""){
	  			if(this.state.userType == "arcgis" && this.state.userName != ""){
		  			if(this.state.userRole != "" && this.state.userPermissions != "" && this.state.userEmail != "" && this.state.userPhone != ""){
		  				this.submitForm();
		  			}else{
		  				this.setState({ modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
		  			}
			    }else if(this.state.userType == "email" && this.state.userEmail != ""){
			    	if(this.state.userRole != "" && this.state.userPermissions != "" && this.state.userPhone != "" && this.state.userPassword1){
		  				this.submitForm();
		  			}else{
		  				this.setState({ modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
		  			}
			    }
			}else{
				this.setState({modalBody: "Sorry, you must select a user type to create a new user.", modalHeader: "Error", show: true});
			}
  		}else{
  			if(this.state.userRole != "" && this.state.userEmail != "" && this.state.userPhone != "" && this.state.userPassword1 != "" && this.state.userPassword2 != ""){
  				if(this.state.userPassword1 == this.state.userPassword2){
  					this.submitForm();
  				}else{
  					this.setState({modalBody: "Sorry, it looks like your passwords don't match. Please try again.", modalHeader: "Error", show: true, loading: false});
  				}
		    }else{
		    	this.setState({modalBody: "Sorry, it looks like you're missing some information. Please try again.", modalHeader: "Error", show: true, loading: false});
		    }
  		}
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
		 let value = this.context;
	  	const {loading, authorized, show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
		    if(value.userPermissions.includes("user:create")){
			    return (
			   		<div className="mainWrapper">
			    		<div className="mainWindow">
				   			{this.state.loading && 
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      	<div className="pageHeader">
					      	CREATE NEW USER
					      		<div className="projectViewClose">
						      		<Link to="/users/manage">
						      			CLOSE
						      		</Link>
					      		</div>
					      	</div>
					      	<div className="profileBody">
						      	<div id="createUserForm">
						      		{value.accountType == "utility" &&
						      			<div>
						      				<font color="#c62828">Select User Type:*</font>
						           			<br/>
						      				<select className="userRoleSelect" name="userType" onChange={this.handleChange} value={this.state.userType}>
						      					<option value=""></option>
								                <option value="arcgis">ArcGIS User</option>
								                <option value="email">Email</option>
								            </select>
								            <br/>
								            {this.state.userType == "arcgis" &&
								            	<div>
								            		<br/>
								            		<font color="#c62828">Username:</font>
								            		<br/>
								            		<input type="text" name="userName" id="userName" className="companyInput" placeholder="Username..." value={this.state.userName} onChange={this.handleChange} />
								        		</div>
								        	}
											<br/>
										</div>
						      		}	
									<font color="#c62828">Role:</font>
						            <br/>				            
									<select className="userRoleSelect" name="userRole" onChange={this.handleChange} value={this.state.userRole}>
						                <option value="1">Admin</option>
						                <option value="2">Creator</option>
						                <option value="3">User</option>
						            </select>
						            <br/>
						            <br/>
						            <font color="#c62828">Email:</font>
						            <br/>
						        	<input type="email" name="userEmail" id="userEmail" className="companyInput" placeholder="Email..." value={this.state.userEmail} onChange={this.handleChange} />			        
						        	<br/>
						        	<br/>
						        	{(value.accountType != "utility" || this.state.userType == "email") &&
						        		<div>
							        		<font color="#c62828">Temporary Password:</font>
								            <br/>
								        	<input type="password" name="userPassword1" id="userPassword1" className="companyInput" placeholder="Temporary Password..." value={this.state.userPassword1} onChange={this.handleChange} />			        
								        	<br/>
								        	<br/>
								        	<font color="#c62828">Verify Password:</font>
								            <br/>
								        	<input type="password" name="userPassword2" id="userPassword2" className="companyInput" placeholder="Verify Password..." value={this.state.userPassword2} onChange={this.handleChange} />			        
								        	<br/>
								        	<br/>
							        	</div>
						        	}
						        	<font color="#c62828">Phone Number:</font>
						        	<br/>
						        	<input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="userPhone" id="userPhone" className="companyInput" placeholder="Phone # (Format: xxx-xxx-xxxx)" value={this.state.userPhone} onChange={this.handleChange} />			        
						            <br/>
						        </div>
					        	<br/>
					        	<b>USER PERMISSIONS</b>

								<div className="userRoleWrapper" >
									<input type="checkbox" name="Select All" checked={this.state.selectAllBoxes}  onChange={this.selectAll} className="featureSelectedCheckbox"/>
									<b>Select All</b>
					        	</div>
					            {this.state.accountPermissions.map(permission => {
					            	if(value.accountType == "utility"){
					                    return(
					                      	<div className="userRoleWrapper" key={permission}>
					                        	<input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
					                        	{permission}
					                     	</div>
					                    );
					                }else{
				                      	if(this.state.licensePermissions.includes(permission)){

				                      	}else{
				                       		return(
				                          		<div className="userRoleWrapper" key={permission}>
				                            		<input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
				                            		{permission}
				                          		</div>
				                        	);
				                      	}
					                }
					            })}
					        	<button id="submitUserCreate" className="submitProjectBtn" onClick={this.handleSubmit} >CREATE USER</button>
					      	</div>
					    </div>
			      	</div>
			    );
			}else{
		       return(
		         	<Route>
		            	<Redirect to="/projects" />
		          	</Route>
		       );
		    }
	  	}
  	}
}

export default CreateUser;