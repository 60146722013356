import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Button} from 'react-bootstrap';
import './User.css';

class User extends React.Component{

    static contextType = Context;

    constructor(props) {
      super(props);
        this.state = {
          loading: false,
          userName: "",
          userEmail: "",
          userPhone: "",
          userRole: "",
          userLicense: "",
          userId: "",
          contactId: "",
          startName: "",
          startRole: "",
          startLicense: "",
          updating: false,
          userPermissions: [],
          accountPermissions: [],
          selectAllBoxes: false,
      }
      this.handleChange = this.handleChange.bind(this);
      this.showUpdate = this.showUpdate.bind(this);
      this.updateUser = this.updateUser.bind(this);
      this.deleteUser = this.deleteUser.bind(this);
      this.cancelUser = this.cancelUser.bind(this);
      this.changeRadio = this.changeRadio.bind(this);
      this.selectAll = this.selectAll.bind(this);
    }

    componentDidMount(){
      var accountPermissionsArray;
      var userPermissionsArray;
      if(this.props.accountPermissions != null){
        accountPermissionsArray = this.props.accountPermissions.split(',');
      }else{
        accountPermissionsArray = [];
      }
      if(this.props.userPermissions != null){
        userPermissionsArray = this.props.userPermissions.split(',');
      }else{
        userPermissionsArray = [];
      }
      accountPermissionsArray.sort();
      this.setState({accountPermissions: accountPermissionsArray, userPermissions: userPermissionsArray, userName: this.props.userName, userRole: this.props.userRole, userId: this.props.userId, startName: this.props.userName, startRole: this.props.userRole, userPhone: this.props.userPhone, userEmail: this.props.userEmail, contactId: this.props.contactId, startLicense: this.props.userLicense, userLicense: this.props.userLicense});
    }

    handleChange(e){
      this.setState({[e.target.name]:e.target.value});
    }

    showUpdate(){
      this.setState({updating: true});
    }

    updateUser(){
      let value = this.context;
      if(this.state.userName != "" && this.state.userRole != "" && this.state.userEmail != "" && this.state.userPhone != ""){
        this.setState({loading: true});
        axios.post('https://www.arutility.com/api/v5/users/update.php',{username: this.state.userName, userRole: this.state.userRole, updateUserId: this.state.userId, userPermissions: this.state.userPermissions, userEmail: this.state.userEmail, userPhone: this.state.userPhone, userLicense: this.state.userLicense},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            this.setState({loading: false, updating: false, startName: this.state.userName, startRole: this.state.userRole});
            this.props.updateSuccess();
        }).catch(error => {
          if (error.response) {
            this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, userLicense: this.state.startLicense});
            this.props.updateError();
          } else if (error.request) {
            // The request was made but no response was received
            this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, userLicense: this.state.startLicense});
            this.props.updateError();
          } else {
            // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, userLicense: this.state.startLicense});
            this.props.updateError();
          }
        });
      }else{
        this.setState({userName: this.state.startName, userRole: this.state.startRole, updating: false, userLicense: this.state.startLicense});
        this.props.updateError();
      }      
    }

    deleteUser(){
      let value = this.context;
      if(this.state.userName != "" && this.state.userRole != ""){
        this.setState({loading: true});
        axios.post('https://www.arutility.com/api/v5/users/delete.php',{username: this.state.userName, userRole: this.state.userRole, updateUserId: this.state.userId},{headers: {Authorization: 'Bearer ' + value.jwt}})
            .then(res => {
              this.setState({loading: false, updating: false, startName: "", startRole: "", startLicense: ""});
              this.props.deleteSuccess();
          }).catch(error => {
              if(error.response.status == 401) {
                this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, userLicense: this.state.startLicense});
                this.props.deleteError();
              }else{
                this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, userLicense: this.state.startLicense});
                this.props.deleteError();
              }
        });
      }else{
        this.setState({userName: this.state.startName, userRole: this.state.startRole, updating: false});
        this.props.deleteError();
      } 
    }

    cancelUser(){
      this.setState({updating: false, userName: this.state.startName, userRole: this.state.startRole, userLicense: this.state.startLicense});     
    }

    changeRadio(e){
      const index = this.state.userPermissions.indexOf(e.target.id);
      var updatedUserPermissions = this.state.userPermissions;
      if (index > -1) {
        updatedUserPermissions.splice(index, 1);
      }else{
        updatedUserPermissions.push(e.target.id);
      }
      this.setState({userPermissions: updatedUserPermissions});
      // Check Condition for Select All Boxes
      if(this.state.userPermissions.length === this.state.accountPermissions.length){
        this.setState({selectAllBoxes: true})
      }else{
      this.setState({selectAllBoxes: false})
      }
    }

     // To select all checkboxes
	  selectAll(e){
		  if(this.state.selectAllBoxes){
			  this.setState({selectAllBoxes: false, userPermissions:[]});
		  }else{
			this.setState({selectAllBoxes: true, userPermissions: [...this.state.accountPermissions]});
		  }
	  }


  render(){
    let value = this.context;
    var roleText;
    if(this.state.userRole == "1"){
      roleText = "Admin";
    }else if(this.state.userRole == "2"){
      roleText = "Creator";
    }else if(this.state.userRole == "3"){
      roleText = "User";
    }else{
      roleText = "Guest";
    }
    return (
      <div className="userWrapper">
        <div className="userInfoWrapper">
          {this.state.updating ?  
            <div>
            {this.state.loading && 
              <LoadingSpinner />
            }
              <div className="username">
                <input type="text" name="userName" className="companyInput" onChange={this.handleChange} value={this.state.userName} />
              </div>
              <div className="contactId">
                {this.state.contactId}
              </div>
              <div className="userLicense" name="userLicense">
                <select className="userRoleSelect" name="userLicense" onChange={this.handleChange} value={this.state.userLicense}>
                  <option value=""></option>
                  {(this.state.userLicense != "" && this.state.userLicense != null) &&
                    <option value={this.state.userLicense}>{this.state.userLicense}</option>
                  }
                  {this.props.availableLicenses.map(license => {
                    return(
                      <option key={license.subscription_id} value={license.subscription_id}>{license.subscription_id}</option>
                    );
                  })}
                </select>
              </div>
              <div className="userRole">
                <select className="userRoleSelect" name="userRole" onChange={this.handleChange} value={this.state.userRole}>
                    <option value="1">Admin</option>
                    <option value="2">Creator</option>
                    <option value="3">User</option>
                </select>
              </div>
              <div className="userUpdate">
                <Button variant="secondary" onClick={this.cancelUser} >Cancel</Button>
                <Button variant="success" onClick={this.updateUser} >Update</Button>
                <Button variant="danger" onClick={this.deleteUser} >Delete</Button>
              </div>
              <div className="userRolesWrapper">
                <div className="contactInfoWrapper">
                  <font color="#c62828">Email:</font>
                  <br/>
                  <input type="email" name="userEmail" className="companyInput" placeholder="Email..." onChange={this.handleChange} value={this.state.userEmail} />
                  <br/>
                  <font color="#c62828">Phone Number:</font>
                  <br/>
                  <input type="tel" name="userPhone" className="companyInput" placeholder="Phone # (Format xxx-xxx-xxxx)" onChange={this.handleChange} value={this.state.userPhone} />
                  <br/>
                </div>
                <b>USER PERMISSIONS</b>
                <div className="userRoleWrapper" >
					        <input type="checkbox" name="Select All" checked={this.state.selectAllBoxes}  onChange={this.selectAll} className="featureSelectedCheckbox"/>
					          <b>Select All</b>
					      </div>
                {this.state.accountPermissions.map(permission => {
                  if(value.accountType == "utility"){
                    return(
                      <div className="userRoleWrapper" key={permission}>
                        <input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                        {permission}
                      </div>
                    );
                  }else{
                    if(this.state.userLicense == "" || this.state.userLicense == null){
                      if(this.props.licensePermissions.includes(permission)){

                      }else{
                        return(
                          <div className="userRoleWrapper" key={permission}>
                            <input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                            {permission}
                          </div>
                        );
                      }
                    }else{
                      return(
                        <div className="userRoleWrapper" key={permission}>
                          <input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                          {permission}
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          :
            <div>
              <div className="username" name="userName">
                {this.state.userName}
              </div>
              <div className="contactId">
                {this.state.contactId}
              </div>
              <div className="userLicense" name="userLicense">
                {(this.state.userLicense == "" || this.state.userLicense == null) ?
                  <div>
                    Unassigned
                  </div>
                  :
                  <div>
                    {this.state.userLicense}
                  </div>
                }
              </div>
              <div className="userRole" name="userRole">
                {roleText}
              </div>
              <div className="userUpdate">
                {value.userPermissions.includes("user:update") &&
                  <Button variant="primary" onClick={this.showUpdate} >Change</Button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default User;