import React from 'react';
import './VideoPreview.css';

class VideoPreview extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      youtubeURL: "",
      youtubeThumb: "",
      focused: false
    }
    this.showVideo = this.showVideo.bind(this);
  }

  componentDidMount(){
    this.setState({youtubeURL: this.props.youtubeURL, youtubeThumb: this.props.youtubeThumb})
  }

  showVideo(){
    if(this.state.focused){
      this.setState({focused: false});
    }else{
      this.setState({focused: true});
    }
  }

  render(){
    return (
      <div className="videoPreviewWrapper">
        <div className="videoPreviewImage" onClick={this.showVideo}>
          <img src={this.state.youtubeThumb} className="videoThumb" alt="Youtube"/>
        </div>
        <div className="videoPreviewTitle">
          {this.props.title}
        </div>
        {this.state.focused &&
          <div className="videoBackground">
            <div className="expandedWrapper">
              <div className="expandedClose" onClick={this.showVideo}>
                <img src="/images/close.png" alt="Close Popup" />
              </div>
              <div className="expandedVideoWrapper">
                <iframe src={this.state.youtubeURL} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default VideoPreview;