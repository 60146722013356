import React from 'react';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './Privacy.css';

class Privacy extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="pageTitle">
          PRIVACY POLICY
        </div>
        <div className="pageDescription">
          <b>Published January 2020</b>
          <p>
          <b>ARUtility LLC.</b> and its subsidiaries and affiliates ("We" or “ARUtility”) are committed to protecting and respecting your privacy. In this ARUtility Privacy Policy (“Policy”), we, set out the basis on which any personal data we collect from you, a ARUtility user, through the ARUtility apps, support, and service (collectively the "Service"), or that you provide to us, will be processed by us.
          </p><p>
          <b>General</b><br/>
          This Policy covers only data collected through the Service. Occasionally, we may refer to this Policy in notices, and emails related to the Service; under such circumstances, this Policy applies as modified in the particular notice (e.g., with respect to types of data collected or purposes of collection).
          </p><p>
          We collect information required to achieve the purposes set out in this Policy, and You have the ability to limit such collection, as specified below under “Your Choices”.
          </p><p>
          <b>Information We Collect</b><br/>
          Our legal basis for collecting and using Your personal information described in this Policy depends on the personal information concerned. However, we normally collect personal information from you where (i) where the processing is in our legitimate interests and not overridden by your rights, (ii) We need the personal information to perform a service for you or (iii) we have Your consent to do so.
          </p><p>
          When You request information or support, register as a user, respond to a survey or otherwise actively send us data, we may collect data such as Your name, email address(es), phone number(s), and/or profile photo. In each such instance, You will know what data we collect, because You actively submit it.
          </p><p>
          When You use the app, we automatically receive and record data from Your mobile device and network connection, including the device model identifier, operating system identification, network signaling, IP Address, date and time You use the Service, performance of the Service, and Your feature use in our apps.
          </p><p>
          When You install our mobile applications, You will be asked to grant access to Your mobile device’s notifications, camera and microphone. Your permission for the Service to access these features is needed for the App to function properly. If You grant such permission, We may collect information about Your usage of these features. We may also ask you to grant access to Your contacts. This is simply to facilitate your use of the app. We will not collect or use any of Your contacts information unless you give us permission and only then we will use that information to enable you and your contact to use the Service.
          </p><p>
          When You enter a Call with another user, You are transmitting voice and video via the Internet. We do not view or listen to the content of Your calls made via our apps, and we do not store Your audio or video once they have been delivered to their destination.
          </p><p>
          <b>Collection Purposes, Use of Data</b><br/>
          We use the data we collect for the following general purposes:
          </p><p>
          To allow other users of the Service to contact you via the Service if they have your email address or phone number;
          To communicate with You regarding the Service;
          To administer, protect and improve our Service;
          To develop new products and services;
          To provide information to You about related products, offers and services;
          For any business purpose if the data is not in personally identifiable form;
          For any other use we describe at the point where we collect the information.
          Data Integrity
          We will take reasonable steps to ensure that personal data about You is reliable for its intended use, accurate, complete, and current. You are responsible for the accuracy of all personal data You provide to Us. As a registered user of the ARUtility apps, You can review and change personal information at any time by accessing Your account on the App. Please be sure to update Your personal information promptly if it changes or becomes inaccurate. All personal data will be removed from our systems when you deregister/close your account.
          </p><p>
          <b>Where We Store and Process Data</b><br/>
          The information that You provide us may be collected, transferred, and stored in various locations, including, in the United States of America or other locations. By submitting Your personal data, You agree to this transfer, storing and processing.
          </p><p>
          <b>Disclosures of Personal Information</b><br/>
          We do not share Your personal data with others, except as follows: We share Your information within ARUtility and its affiliates wherever they may be located. We also may share Your information with third parties who process the data on our behalf for the purposes set forth in this Policy. We may also share Your information as required by law or in the interest of protecting or exercising our or others' legal rights, e.g., without limitation, in connection with requests from law enforcement officials and in connection with court proceedings. We may share or transfer Your information in connection with a prospective or actual sale, merger, transfer or other reorganization of all or parts of our business. Finally, we may also share Your personal information where You have granted us permission.
          </p><p>
          <b>Your Choices</b><br/>
          Opt-Out. Marketing-related emails from us will always contain links which enable you to unsubscribe.
          </p><p>
          Removing your Information. To discontinue Your account and have Your personal information removed, You may contact us at info@arutility.com.
          </p><p>
          <b>Security</b><br/>
          Transmissions over the Internet are never 100% secure or error-free. However, We take reasonable steps to protect Your personal information from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. It is Your responsibility to safeguard any password You use to access the Service and to change your password if You suspect that it has been compromised. You are solely responsible for any unauthorized use of the Service conducted with Your account.
          </p><p>
          <b>Children's Privacy</b><br/>
          The Service is not intended for children under the age of 13 (16 years of age for EU residents). We do not intentionally or knowingly collect personally-identifiable information from children under this age.
          </p><p>
          <b>Amendments</b><br/>
          We reserve the right to change this Policy from time to time and in our sole discretion. We will alert You that changes have been made by indicating on the Policy the date it was last updated. When You use the Service, You are accepting the current version of this Policy as posted in the App at that time.
          </p><p>
          <b>Contact Us</b><br/>
          If you have any questions or concerns about our use of your personal information or this Privacy Policy, please contact us using the following details:
          </p><p>
          ARUtility LLC<br/>
          PO Box 82<br/>
          Mason, MI 48854<br/>
          Attn: Compliance Department - Privacy
          </p><p>
          Or
          </p><p>
          info@arutility.com
          </p></div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Privacy;