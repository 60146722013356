import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Device.css';

class Device extends React.Component{

  constructor(props) {
    super(props);
      this.state = {
        newsArticle: [],
        loading: false,
        modalBody: "",
        modalHeader: "",
        show: false
    }
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  componentDidMount(){
    /*this.setState({loading: true});
    axios.post('https://www.arutility.com/api/v4/news/readOne.php', {id: this.props.match.params.id})
    .then(res => {
      this.setState({newsArticle: res.data, loading: false});
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });*/
  }

  render(){
    const {show} = this.state;
    return (
      <div className="devicePreviewWrapper">
        <div className="devicePreviewTitle">
          <div>
            {this.props.deviceManufacturer}
            <br/>
            <div className="devicePreviewModel">
              {this.props.deviceName}
              <br/>
              {this.props.deviceCode}
            </div>
          </div>
        </div>
        <div className="devicePreviewImage">
          <img src={this.props.imgURL} alt={this.props.manufacturer} />
        </div>
      </div>
    );
  }
}

export default Device;