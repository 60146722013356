import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import './EditSessionHeader.css';

class EditSessionHeader extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      searchString: ""
    }
    this.updateSearch = this.updateSearch.bind(this);
    this.updateSearchCreator = this.updateSearchCreator.bind(this);
  }

  updateSearch(e){
    this.setState({searchString: e.target.value});
    this.props.updateSearch(e.target.value);
  }

  updateSearchCreator(e){
    this.props.updateSearchCreator(e.target.value);
  }

  render(){
    return (
    	<div className="projectsHeaderWrapper">
      		<div className="projectsHeaderText">
          Record Drawing Sessions
          </div>
          <div className="projectsHeaderSearch">
            <label htmlFor="creatorFilter">Creator:</label>
            <select id="creatorFilter" onChange={this.updateSearchCreator}>
              <option value=""></option>
              {this.props.creators.map(creator => 
                  <option value={creator}>{creator}</option>
                )
              }
            </select>
            <InputGroup className="projectsHeaderSearchNew" id="projectsHeaderSearchNew">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Search:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder=""
                aria-label=""
                aria-describedby="basic-addon1"
                value={this.state.searchString}
                onChange={this.updateSearch}
              />
            </InputGroup>
          </div>
      </div>
    );
  }
}

export default EditSessionHeader;