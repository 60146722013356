import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import User from "../user/User";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ManageUsers.css';

class ManageUsers extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    users: [],
		    numLicenses: "",
		    licensesUsed: "",
		    modalBody: "",
		    availableLicenses: [],
		    licensePermissions: [],
		    modalHeader: "",
		    show: false
		}
		this.changeRadio = this.changeRadio.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateUserSucccess = this.updateUserSucccess.bind(this);
		this.deleteUserSuccess = this.deleteUserSuccess.bind(this);
		this.updateUserError = this.updateUserError.bind(this);
		this.deleteUserError = this.deleteUserError.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v5/users/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	      	var numLicensesUsed = res.data.numLicenses - res.data.availableLicenses.length;
			this.setState({users: res.data.users, numLicenses: res.data.numLicenses, licensesUsed: numLicensesUsed, availableLicenses: res.data.availableLicenses});
	    	axios.post('https://www.arutility.com/api/v5/users/readLicensePermissions.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
		      .then(res => {
				this.setState({loading: false, licensePermissions: res.data});
		    }).catch(error => {
			    if(error.response){
			      	if(error.response.status == 401){
			        	this.setState({authorized: false, loading: false});
			      	}else{
			        	this.setState({modalBody: "Sorry, we were unable to retrieve users. Please try again.", modalHeader: "Error", show: true, loading: false});
			      	}
			    }else if(error){
			    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true})
			    }else{
			    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true})
			    }
			});
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({authorized: false, loading: false});
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve users. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true})
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true})
		    }
		});
  	}

  	changeRadio(e){
	  	if(this.state[e.target.name] == false){
	  		this.setState({[e.target.name]: true});
	  	}else{
	  		this.setState({[e.target.name]: false});
	  	}
  	}

  	handleChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	updateUserSucccess(){
  		let value = this.context;
	    axios.post('https://www.arutility.com/api/v5/users/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	      	var numLicensesUsed = res.data.numLicenses - res.data.availableLicenses.length;
			this.setState({loading: false, users: res.data.users, numLicenses: res.data.numLicenses, licensesUsed: numLicensesUsed, availableLicenses: res.data.availableLicenses});
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({authorized: false, loading: false});
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve users. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true})
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true})
		    }
		});
  		this.setState({modalBody: "User was successfully updated", modalHeader: "Success", loading: false, show: true})
  	}

  	updateUserError(){
  		this.setState({modalBody: "Unable to update user. Please verify no information was left blank", modalHeader: "Error", loading: false, show: true})
  	}

  	deleteUserSuccess(){
	  	let value = this.context;
	  	this.setState({loading: true});
	    axios.post('https://www.arutility.com/api/v5/users/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
	  		this.setState({modalBody: "User was successfully deleted", modalHeader: "Success", loading: false, show: true, users: res.data, numLicenses: res.data[0].numLicenses, licensesUsed: res.data.length})
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({authorized: false, loading: false});
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve users. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true})
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true})
		    }
		});
  	}

  	deleteUserError(){
  		this.setState({modalBody: "Unable to delete user. Please try again", modalHeader: "Error", loading: false, show: true})
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {loading, users, authorized, show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
	    	if(value.userPermissions.includes("users:view")){
			    return (
			   		<div className="mainWrapper">
			    		<div className="mainWindow">
				   			{this.state.loading &&
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      	<div className="pageHeader">
						      	MANAGE USERS  
						      	<NavLink to="/users/create" >
						      		<Button variant="primary" className="addUserBtn">+ Add User</Button>
						      	</NavLink>
						      	<div className="numLicenses">
						      	{this.state.licensesUsed} licenses used out of {this.state.numLicenses}
						      	</div>
					      	</div>
					      	<div className="plainBody">
					      		<div className="userFilter">
					      			<div className="userFilterUsername">
					      			Username <img src='/images/sorticon.png' alt="userFilter"/>
					      			</div>
					      			<div className="userFilterContactId">
					      			Contact ID <img src='/images/sorticon.png' alt="userFilter"/>
					      			</div>
					      			<div className="userFilterLicense">
					      			License <img src='/images/sorticon.png' alt="userFilter"/>
					      			</div>
					      			<div className="userFilterRole">
					      			Role
					      			</div>
					      			<div className="userFilterUpdate">
					      			</div>
					      		</div>
					      		<div className="userViewWrapper">
					      			{this.state.users.map(user => {
					      				var urlPath = "/user/update/" + user.id;
					      				if(user.licenseId == null){
					      					var userLicenseId = "";
					      				}else{
					      					var userLicenseId = user.licenseId;
					      				}
					      				return(
						      				<User key={user.id} userName={user.username} userRole={user.role} userId={user.id} userPhone={user.phone} userEmail={user.email} updateSuccess={this.updateUserSucccess} deleteSuccess={this.deleteUserSuccess} updateError={this.updateUserError} deleteError={this.deleteUserError} userPermissions={user.permissions} accountPermissions={value.accountPermissions} contactId={user.contactId} availableLicenses={this.state.availableLicenses} userLicense={userLicenseId} licensePermissions={this.state.licensePermissions}/>
						      			);
					      			})}
					      		</div>
					      	</div>
					    </div>
			      	</div>
			    );
			}else{
		       return(
		         	<Route>
		            <Redirect to="/projects" />
		          	</Route>
		       );
		    }
		}
  	}
}

export default ManageUsers;