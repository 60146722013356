import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import EditSession from '../editsession/EditSession';
import {Context} from "../configcontext/ConfigContext";
import EditSessionFilter from '../editsessionfilter/EditSessionFilter';
import EditSessionHeader from '../editsessionheader/EditSessionHeader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './EditSessions.css';

class EditSessions extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    sessions: [],
		    filteredSessions: [],
		    creators: [],
		    searching: false,
		    loading: true,
		    authorized: false
		}
		this.updateSearch = this.updateSearch.bind(this);
		this.updateSearchCreator = this.updateSearchCreator.bind(this);
	}

	updateSearch(searchString){
		if(searchString != ""){
		  	const searchResults = this.state.sessions.filter(session => 
		  		session.session_id.toLowerCase().includes(searchString.toLowerCase()) ||
		  		session.session_name.toLowerCase().includes(searchString.toLowerCase()) ||
		  		session.session_creator.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredSessions: searchResults});
		}else{
			this.setState({searching: false, filteredSessions: []});
		}
	}

	updateSearchCreator(searchString){
		if(searchString != ""){
		  	const searchResults = this.state.sessions.filter(session => 
		  		session.session_creator.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredSessions: searchResults});
		}else{
			this.setState({searching: false, filteredSessions: []});
		}
	}

  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v5/features/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	      	if(res.status == "204"){
	      		this.setState({sessions: [], loading: false});
	      	}else{
	      		const sessions = res.data["records"];
	      		var users = [];
	      		sessions.forEach(session => 
	      			users.indexOf(session.session_creator) === -1 ? users.push(session.session_creator) : null
	      		);
	        	this.setState({sessions: sessions, loading: false, creators: users});
	      	}
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, sessions: []});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, sessions: []});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false});
		    }
	    });
  	}

  	render(){
	  	const {loading} = this.state;
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.permissions.includes("editsessions:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<EditSessionHeader updateSearch={this.updateSearch} creators={this.state.creators} updateSearchCreator={this.updateSearchCreator}/>
				      		<EditSessionFilter />
				      		<div className="allRequestsWrapper">
				       		{(this.state.searching == false) ? (
						      		this.state.sessions.map(session => {
						      			if(session.status == "0"){
							      			var urlPath = "/session/edits/" + session.id;
							            	return(
							            	<NavLink key={session.id} to={urlPath}>
							            		<EditSession sessionId={session.session_id} sessionDate={session.creation_date} sessionName={session.session_name} sessionCreator={session.session_creator}/>
							            	</NavLink>
						            		);
							            }
						       		})
						       	)
						       	:
						       	(
						       		this.state.filteredSessions.map(session => {
						      			if(session.status == "0"){
							      			var urlPath = "/session/edits/" + session.id;
							            	return(
							            	<NavLink key={session.id} to={urlPath}>
							            		<EditSession sessionId={session.session_id} sessionDate={session.creation_date} sessionName={session.session_name} sessionCreator={session.session_creator}/>
							            	</NavLink>
						            		);
							            }
						       		})
						       	)
						    }
				       		</div>
				       	</div>
				    </div>
			    );
			}else{
		        return(
		          <Route>
		            <Redirect to="/projects" />
		          </Route>
		        );
		    }
		}
  	}
}

export default EditSessions;