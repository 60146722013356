import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './Support.css';

class Support extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
		this.state = {
	    
	    }
	}


  	componentDidMount() {
	  	/*let value = this.context;
	    //var jwt = localStorage.getItem("jwt");
	    var id = this.props.match.params.id;
	    this.setState({loading: true, projectId: id});
	    //this.setState({jwt: jwt});
	    axios.post('https://www.arutility.com/api/v4/project/readOne.php',{jwt: value.jwt, id: id})
	      .then(res => {
	        const projectInfo = res.data;
	        var projectBoundary;
	        if(res.data.boundaries != ""){
	        	projectBoundary = JSON.parse(res.data.boundaries);
	        	projectBoundary = projectBoundary.coordinates;
	        }else{
	        	projectBoundary = [];
	        }
	        this.setState({authorized: true, projectInfo: projectInfo, projectName: projectInfo.name, companyName: projectInfo.companyName, address1: projectInfo.address1, address2: projectInfo.address2, companyCity: projectInfo.companyCity, companyState: projectInfo.companyState, projectDescription: projectInfo.description, projectStartDate: projectInfo.start, projectEndDate: projectInfo.end, creatorFirstName: projectInfo.firstName, creatorLastName: projectInfo.lastName, phoneNum: projectInfo.userPhone, email: projectInfo.userEmail, loading: false, projectBoundary: projectBoundary });
	    }).catch(error => {
	    	this.setState({loading: false});
		});*/
  	}

  	render(){
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
		    return (
		      	<div className="mainWrapper">
		    		<div className="mainWindow">
				      	{this.state.loading &&
				      		<LoadingSpinner />
				      	}
				      	<div className="projectViewWrapper">
				      		ARUtility is currently finishing a complete support center for all your questions and needs. Soon, you'll be able to create support tickets and get help when you need it. Stay tuned.
			      		</div>
		      		</div>
		   		</div>
		    );
		}
  	}
}

export default Support;