import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import {Link} from 'react-router-dom';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsPreview from '../newspreview/NewsPreview';
import './News.css';

class News extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        newsArticles: [],
        loading: false,
        modalBody: "",
        modalHeader: "",
        show: false
    }
  }

  componentDidMount(){
    this.setState({loading: true});
    axios.get('https://www.arutility.com/api/v4/news/read.php')
    .then(res => {
      this.setState({newsArticles: res.data, loading: false});
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  render(){
    let value = this.context;
    const {show} = this.state;
    return (
      <div>
        <MainHeader />
        {this.state.loading && 
          <LoadingSpinner />
        }
        <Modal show={show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="pageTitle">
          NEWS
        </div>
        <div className="pageDescription">
          {this.state.newsArticles.map(article => {
            return(
              <Link key={article.id} to={"news/article/" + article.id}>
                <NewsPreview key={article.id} title={article.title} image={article.image} body={article.body} date={article.date}/>
              </Link>
            );
          })}
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default News;