import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminCreateUser.css';

class AdminCreateUser extends React.Component{

  static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            accountType: "utility",
            allAccounts: [],
            accountName: "",
            accountId: "",
            accountPermissions: [],
            userPermissions: [],
            userRole: "",
            userEmail: "",
            userPhone: "",
            password1: "",
            password2: "",
            firstName: "",
            lastName: "",
            userName: "",
            loading: false,
            modalBody: "",
            modalHeader: "",
            show: false,
            selectAllBoxes: false,

        }
        this.updateForm = this.updateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.selectAll = this.selectAll.bind(this);

    }

    componentDidMount(){
        const value = this.context;
        this.setState({"loading": true});
        axios.post('https://www.arutility.com/api/v4/accounts/adminReadAll.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            const accounts = res.data;
            accounts.sort((a, b) => (a.accountName > b.accountName) ? 1 : -1);
            this.setState({allAccounts: accounts, loading: false});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }

    changeRadio(e){
      const index = this.state.userPermissions.indexOf(e.target.id);
      var updatedUserPermissions = this.state.userPermissions;
      if (index > -1) {
        updatedUserPermissions.splice(index, 1);
      }else{
        updatedUserPermissions.push(e.target.id);
      }
      this.setState({userPermissions: updatedUserPermissions});
      // Check Condition for Select All Boxes
        if(this.state.userPermissions.length === this.state.accountPermissions.length){
            this.setState({selectAllBoxes: true})
        }else{
            this.setState({selectAllBoxes: false})
        }
	}
	  
	  // To select all checkboxes
	  selectAll(e){
		  if(this.state.selectAllBoxes){
			  this.setState({selectAllBoxes: false, userPermissions:[]});
		  }else{
			this.setState({selectAllBoxes: true, userPermissions: [...this.state.accountPermissions]});
		  }
	  }

    handleAccountChange(e){
        this.setState({[e.target.id]:e.target.value});
        var numAccounts = this.state.allAccounts.length;
        for(var i=0; i<numAccounts; i++){
            if(this.state.allAccounts[i].accountId == e.target.value){
                var accountType = this.state.allAccounts[i].accountType;
                var accountPermissions = this.state.allAccounts[i].accountPermissions;
                var accountPermissionsArray = accountPermissions.split(',');
                accountPermissionsArray.sort();
                this.setState({accountType: accountType, accountPermissions: accountPermissionsArray, userPermissions: []});
            }
        }
    }

    updateForm(e){
        this.setState({[e.target.id]:e.target.value});
    }

    showModal(){
        this.setState({show: true});
    }

    closeModal(){
        this.setState({show: false});
    }

    sendForm(){
        const value = this.context;
        axios.post('https://www.arutility.com/api/v4/users/adminCreate.php',{accountType: this.state.accountType, firstName: this.state.firstName, userPhone: this.state.userPhone, userEmail: this.state.userEmail, lastName: this.state.lastName, password: this.state.password1, permissions: this.state.userPermissions, accountId: this.state.accountId, userName: this.state.userName, userRole: this.state.userRole},{headers: {Authorization: 'Bearer ' + value.jwt}})
        .then(res => {
            this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
        }).catch(error => {
            if (error.response) {
                this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
            } else if (error.request) {
              // The request was made but no response was received
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
            } else {
              // Something happened in setting up the request that triggered an Error
                this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
            }
        });
    }

    submitForm(){
        if(this.state.accountType == "utility"){
            if(this.state.userName != "" && this.state.userRole != "" && this.state.userEmail != "" && this.state.userPhone != ""){
                this.setState({loading: true});
                this.sendForm();
            }else{
                this.setState({modalBody: "Sorry, it looks like you're missing some information. Please try again.", modalHeader: "Error", show: true, loading: false});
            }
        }else{
            if(this.state.userRole != "" && this.state.userEmail != "" && this.state.userPhone != "" && this.state.userPassword1 != "" && this.state.userPassword2 != ""){
                if(this.state.userPassword1 == this.state.userPassword2){
                    this.setState({loading: true});
                    this.sendForm();
                }else{
                    this.setState({modalBody: "Sorry, it looks like your passwords don't match. Please try again.", modalHeader: "Error", show: true, loading: false});
                }
            }else{
                this.setState({modalBody: "Sorry, it looks like you're missing some information. Please try again.", modalHeader: "Error", show: true, loading: false});
            }
        }
    }

    render(){
        let value = this.context;
        const {show} = this.state;
        return (
            <div className="moduleDetailWrapper">
                {this.state.loading &&
                    <LoadingSpinner />
                }
                <Modal show={show} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.modalBody}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                         Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="adminPageTitle">
                  CREATE USER
                </div>
                <div className="adminPageDescription">
                    <div className="createAccount">
                        <font color="#c62828">Account Name:</font>
                        <br/>
                        <select className="userRoleSelect" id="accountId" name="accountName" onChange={this.handleAccountChange} value={this.state.accountId}>
                            <option value= ""></option>
                            {this.state.allAccounts.map(account =>{
                                return(
                                    <option key={account.accountId} value={account.accountId}>{account.accountName}</option>
                                );
                            })}
                        </select>
                        <br/>
                        <br/>
                        {this.state.accountId != "" ?
                            (
                                <div>
                                    <div id="createUserForm">
                                    {this.state.accountType == "utility" &&
                                        <div>
                                            <font color="#c62828">Username:</font>
                                            <br/>
                                            <input type="text" name="userName" id="userName" className="companyInput" placeholder="Username..." value={this.state.userName} onChange={this.updateForm} />
                                            <br/>
                                            <br/>
                                        </div>
                                    }   
                                    <font color="#c62828">Role:</font>
                                    <br/>                           
                                    <select className="userRoleSelect" id="userRole" name="userRole" onChange={this.updateForm} value={this.state.userRole}>
                                        <option value="1">Admin</option>
                                        <option value="2">Creator</option>
                                        <option value="3">User</option>
                                    </select>
                                    <br/>
                                    <br/>
                                    <font color="#c62828">First Name:</font>
                                    <br/>
                                    <input type="text" name="firstName" id="firstName" className="companyInput" placeholder="First Name..." value={this.state.firstName} onChange={this.updateForm} />                    
                                    <br/>
                                    <br/>
                                    <font color="#c62828">Last Name:</font>
                                    <br/>
                                    <input type="text" name="lastName" id="lastName" className="companyInput" placeholder="Last Name..." value={this.state.lastName} onChange={this.updateForm} />                    
                                    <br/>
                                    <br/>
                                    <font color="#c62828">Email:</font>
                                    <br/>
                                    <input type="email" name="userEmail" id="userEmail" className="companyInput" placeholder="Email..." value={this.state.userEmail} onChange={this.updateForm} />                    
                                    <br/>
                                    <br/>
                                    {this.state.accountType != "utility" &&
                                        <div>
                                            <font color="#c62828">Temporary Password:</font>
                                            <br/>
                                            <input type="password" name="userPassword1" id="userPassword1" className="companyInput" placeholder="Temporary Password..." value={this.state.userPassword1} onChange={this.updateForm} />                    
                                            <br/>
                                            <br/>
                                            <font color="#c62828">Verify Password:</font>
                                            <br/>
                                            <input type="password" name="userPassword2" id="userPassword2" className="companyInput" placeholder="Verify Password..." value={this.state.userPassword2} onChange={this.updateForm} />                   
                                            <br/>
                                            <br/>
                                        </div>
                                    }
                                    <font color="#c62828">Phone Number:</font>
                                    <br/>
                                    <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="userPhone" id="userPhone" className="companyInput" placeholder="Phone # (Format: xxx-xxx-xxxx)" value={this.state.userPhone} onChange={this.updateForm} />                   
                                    <br/>
                                    </div>
                                    <br/>
                                    <b>USER PERMISSIONS</b>
                                    <div className="userRoleWrapper" >
                                        <input type="checkbox" name="Select All" checked={this.state.selectAllBoxes}  onChange={this.selectAll} className="featureSelectedCheckbox"/>
                                        <b>Select All</b>
					        	    </div>
                                    {this.state.accountPermissions.map(permission => {
                                        return(
                                            <div className="userRoleWrapper" key={permission}>
                                                <input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                                                {permission}
                                            </div>
                                        );
                                    })}
                                    <br/>
                                    <button value="Submit" id="createAccountSubmit" onClick={this.submitForm}>CREATE ACCOUNT</button>
                                </div>
                            )
                            :
                            (
                                <div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminCreateUser;