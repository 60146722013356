import React from 'react';
import {Link, Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import Project from '../project/Project';
import {Context} from "../configcontext/ConfigContext";
import BimHeader from '../bimheader/BimHeader';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import ProjectBIM from '../projectbim/ProjectBIM';
import './BIMProjects.css';

class BIMProjects extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    projects: [],
		    filteredProjects: [],
		    searchString: "",
		    searching: false,
		    loading: true,
		    modalBody: "",
		    modalHeader: "",
		    show: false
		}
		this.updateSearch = this.updateSearch.bind(this);
	}


  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arutility.com/api/v4/project/readBIM.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	        const projects = res.data;
	        this.setState({ projects: projects, loading: false });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
  	}

  	updateSearch(searchString){
		if(searchString != ""){
		  	const searchResults = this.state.projects.filter(project => 
		  		project.projectName.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredProjects: searchResults});
		}else{
			this.setState({searching: false, filteredProjects: []});
		}
  	}

  	render(){
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("projectsbim:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<BimHeader updateSearch={this.updateSearch}/>
				      		{(value.accountPermissions.includes("project:create")) ? (
				      			null
				      		):(
				      			<div className="noProjects">
				      				Upgrade your account, or request a free 30 day trial, by contacting <a href="mailto:sales@arutility.com">sales@arutility.com</a> in order to take advantage of your accounts full capabilities. Project creation is a paid feature that requires an upgrade to your account.
				      			</div>
				      		)}
				      		<div className="bimProjectWrapper">
					      		{(this.state.searching == false) ? (
						      		this.state.projects.map(project => {
						      			var urlPath = "/bimproject/view/" + project.projectID;
						            	return(
							            	<Link key={project.projectID} to={urlPath}>
							            		<ProjectBIM key={project.projectID} projectName={project.projectName} projectDescription={project.projectDescription} projectDirectory={project.projectDirectory} projectThumb={project.projectThumb}/>
							            	</Link>
					            		);
						       		})
						       		)
						       		:
						       		(this.state.filteredProjects.map(project => {
						      			var urlPath = "/bimproject/view/" + project.projectID;
						            	return(
							            	<Link key={project.projectID} to={urlPath}>
							            		<ProjectBIM key={project.projectID} projectName={project.projectName} projectDescription={project.projectDescription} projectDirectory={project.projectDirectory} projectThumb={project.projectThumb}/>
							            	</Link>
					            		);
						       		})
						       		)
						      	}
						    </div>
					    </div>
			       	</div>
			    );
			}else if(value.userPermissions.includes("requests:view")){
				return(
					<Route>
						<Redirect to="/requests" />
					</Route>
				);
			}else{
				return(
					<Route>
						<Redirect to="/login" />
					</Route>
				);
			}
		}
  	}
}

export default BIMProjects;